<template>
  <table
    v-if="$reportType(selectedMonthlyReport)"
    class="w-full table-fixed justify-center p-2 text-sm text-gray-500"
  >
    <thead>
      <tr class="bg-green-100 pb-2 pt-2 text-xs xl:text-sm font-semibold text-green-700">
        <th class="p-3" style="width: 30%">{{ $reportType(selectedMonthlyReport).name }}</th>
        <th
          v-for="month in months"
          v-bind:key="month"
          class="w-1/5 border-t p-3 pl-2"
        >
          {{ month }}
        </th>
        <th class="p-3" style="width: 20%">Yearly</th>
      </tr>
    </thead>
    <!-- <div
      class="col-span-2 border-t bg-green-100 py-4 pl-5 text-xs font-bold text-gray-700 xl:text-base"
    >
      {{ $reportType(selectedMonthlyReport).name }}
    </div>
    <div
        class="border-t col-span-1 bg-green-100 py-4 text-center text-xs font-bold text-gray-700 xl:text-base"
      >
        Gender
      </div>
    <div
      :class="[
        $reportType(selectedMonthlyReport).type === 'defermentReason'
          ? 'col-span-8'
          : 'col-span-9',
        'grid grid-cols-12',
      ]"
    >
      
      <thead
        v-for="(month, index) in months"
        :key="index"
        class="border-t bg-green-100 text-base text-gray-700"
      >
        <th class="py-4 px-6 pl-2">
          <div class="text-xs xl:text-base">
            {{ month }}
          </div>
        </th>
      </thead>
    </div>

    <div
      class="border-t bg-green-100 py-4 text-center text-xs font-bold text-gray-700 xl:text-base"
    >
      Yearly
    </div> -->
    <tbody class="text-xs xl:text-sm">
      <tr
        v-for="(group, gid) in fetchedData"
        :key="gid"
        class="bg-white hover:bg-green-50 group"
      >
        <td
          :class="[
            gid === fetchedData.length - 1 &&
            group[$reportType(selectedMonthlyReport).type] === 'Total'
              ? 'font-bold'
              : 'font-semibold',
            'w-1/4 border-b-2 px-2 py-4 text-center capitalize bg-gray-100 text-gray-700 group-hover:bg-green-200',
          ]"
        >
          {{
            $reportType(selectedMonthlyReport).type === "age"
              ? group[$reportType(selectedMonthlyReport).type]
              : $removeUnderscores(
                  $removeHyphens(group[$reportType(selectedMonthlyReport).type])
                )
          }}
        </td>

        <td
          v-for="(type, idx) in group[$reportType(selectedMonthlyReport).data]"
          :key="idx"
          width="25%"
          :class="[
            gid === fetchedData.length - 1 &&
            group[$reportType(selectedMonthlyReport).type] === 'Total'
              ? 'text-gray-600 group-hover:bg-green-200'
              : 'hover:text-gray-600 hover:bg-green-200',
            'w-1/4 items-center border-b-2 py-3 text-center font-medium',
          ]"
        >
          <span v-if="type[$reportType(selectedMonthlyReport).value] > 0">{{
            type[$reportType(selectedMonthlyReport).value]
          }}</span>
          <span
            v-else-if="
              type[$reportType(selectedMonthlyReport).value] === 0 &&
              selectedYear <= new Date().getFullYear() &&
              !(
                selectedYear === new Date().getFullYear() &&
                type.monthInt > new Date().getMonth() + 1
              )
            "
            >-</span
          >
          <span v-else class="text-xs lowercase italic">N/A</span>
        </td>

        <td
          :class="[
            gid === fetchedData.length - 1 &&
            group[$reportType(selectedMonthlyReport).type] === 'Total'
              ? 'bg-green-50 font-bold'
              : 'bg-gray-50 font-medium',
            'items-center py-2 text-center capitalize text-gray-700 group-hover:bg-green-300',
          ]"
        >
          {{ group.total }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "report-table",
  props: {
    // dataType: [String, Object, Number, Array],
    fetchedData: [Array, Object],
    months: Array,
    selectedYear: Number,
    selectedMonthlyReport: String,
  },
};
</script>
