<template>
  <div></div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "UserLogin",
  methods: {
    ...mapActions(["authenticateOidc"]),
  },
  created() {
    this.authenticateOidc();
  },
};
</script>
