export default [
  {
    path: "/donors",
    name: "Donors",
    component: () => import(/* webpackChunkName: "donors-group" */ "@/views/donors/List.vue"),
    children: [
      {
        path: "register",
        name: "RegisterDonor",
        component: () =>
          import(
            /* webpackChunkName: "donors-group" */
            "@/views/donors/Test.vue"
          ),
        props: () => ({
          toFetch: false,
          title: "Register Donor",
          postUrl: "/registration/donors",
          size: "max-w-5xl",
        }),
      },
    ],
  },

  {
    path: "/donors/:id/details",
    meta: { breadcrumb: { parent: "Donors" } },
    name: "DonorDetails",
    component: () =>
      import(
        /* webpackChunkName: "donors-group" */
        "@/views/donors/DonorDetails.vue"
      ),
    props: (x) => ({
      title: `${x.params.name || "Donor"} Donation Details`,
      fetchUrl: `/registration/donors/${x.params.id}`,
    }),
    children: [
      {
        path: "amend/:form_eligibility",
        name: "AmendDonor",
        component: () =>
          import(
            /* webpackChunkName: "donors-group" */
            "@/views/donors/Test.vue"
          ),
        props: (x) => ({
          title: "Amend Donor Details ",
          postUrl: `/registration/donors/${x.params.id}`,
          size: "max-w-5xl",
        }),
      },
      {
        path: "view-counselling-results/:donorNumber",
        name: "ViewCounsellingResults",
        component: () =>
            import(
                "@/views/donors/post_donation_counselling/ViewResults.vue"
                ),
        props: () => ({
          toFetch: false,
          title: "Donor Test Results",
          size: "max-w-5xl",
        }),
      },
      {
        path: "send-to-counselling",
        name: "SendToCounselling",
        component: () => import(/* webpackChunkName: "donors-group" */ "@/components/data/SchemaModal.vue"),
        props: (x) => ({
          verb: "POST",
          title: "Send to Counselling ",
          postUrl: `/registration/donors/sendtocounselling/${x.params.id}`,
          optionsUrls: ["registration/blood-drives"],
          jsonSchema: "registration/forms/send-to-counseling",
          toFetch: false,
        }),
      },
    ],
  },
  // lookups
  {
    path: "/donors/optionsgroups",
    name: "DonorOptionsGroups",
    component: () =>
      import(
        /* webpackChunkName: "optionsgroups" */
        "@/views/donors/lookups/GroupsList.vue"
      ),
    children: [
      {
        path: "create",
        name: "CreateDonorOptionsGroup",
        component: () =>
          import(
            /* webpackChunkName: "optionsgroups" */
            "@/components/data/SchemaModal.vue"
          ),
        props: () => ({
          toFetch: false,
          title: "Add Options Group ",
          postUrl: "registration/lookups/0",
          // postUrl: "setup/lookups",
          optionsUrls: [],
          jsonSchema: "registration/forms/add-option-group",
          size: "max-w-xs",
        }),
      },
      {
        path: "edit/:id",
        title: "Edit Options Group ",
        name: "AmendDonorOptionsGroup",
        component: () =>
          import(
            /* webpackChunkName: "optionsgroups" */
            "@/components/data/SchemaModal.vue"
          ),
        props: (x) => ({
          title: "Edit Options Group ",
          // postUrl: `setup/lookups/${x.params.id}`,
          postUrl: `registration/lookups/${x.params.id}`,
          optionsUrls: [],
          jsonSchema: "registration/forms/add-option-group",
          size: "max-w-xs",
        }),
      },
      {
        path: "delete/:id",
        title: "Delete Options Group",
        name: "DeleteOptionsGroup",
        component: () =>
          import(
            /* webpackChunkName: "optionsgroups" */
            "@/components/data/DeleteModal.vue"
          ),
        props: (x) => ({
          title: "Remove Option Group ",
          deleteUrl: `registration/lookups/${x.params.id}`,
          size: "max-w-xs",
          name: "Option Group",
        }),
      },
    ],
  },
  {
    path: "/donors/optionsgroups/:groupid/options",
    meta: { breadcrumb: { parent: "DonorOptionsGroups" } },
    name: "DonorOptions",
    props: (x) => ({
      title: x.params.title,
    }),
    component: () =>
      import(
        /* webpackChunkName: "optionsgroups" */
        "@/views/donors/lookups/OptionsList.vue"
      ),
    children: [
      {
        path: "create",
        name: "CreateDonorOption",
        component: () =>
          import(
            /* webpackChunkName: "optionsgroups" */
            "@/components/data/SchemaModal.vue"
          ),
        props: (x) => ({
          toFetch: false,
          title: "Add Option",
          verb: "POST",
          postUrl: `registration/lookups/${x.params.groupid}`,
          // postUrl: `registration/lookups`,
          optionsUrls: [],
          jsonSchema: "registration/forms/add-option",
          size: "max-w-xs",
          extraFields: { parentLookupId: x.params.groupid },
        }),
      },
      {
        path: "edit/:id",
        title: "Edit Option ",
        name: "AmendDonorOption",
        component: () =>
          import(
            /* webpackChunkName: "optionsgroups" */
            "@/components/data/SchemaModal.vue"
          ),
        props: (x) => ({
          title: "Amend Option ",
          verb: "PUT",
          // postUrl: `setup/lookups/${x.params.id}`,
          postUrl: `registration/lookups/${x.params.id}`,
          optionsUrls: [],
          jsonSchema: "registration/forms/add-option",
          size: "max-w-xs",
          extraFields: { parentLookupId: x.params.groupid },
        }),
      },
      {
        path: "delete/:id",
        title: "Delete Option",
        name: "DeleteDonorOption",
        component: () =>
          import(
            /* webpackChunkName: "optionsgroups" */
            "@/components/data/DeleteModal.vue"
          ),
        props: (x) => ({
          title: "Remove Option ",
          deleteUrl: `registration/lookups/${x.params.id}`,
          size: "max-w-xs",
          name: "Option",
        }),
      },
    ],
  },
  // FORMS
  {
    path: "/donors/forms",
    name: "RegistrationForms",
    component: () => import("@/views/donors/FormsList.vue"),
    children: [
      {
        path: "create",
        name: "AddRegistrationForm",
        component: () => import("@/components/data/JsonForm.vue"),
        props: () => ({
          toFetch: false,
          title: "Add Form ",
          postUrl: "registration/forms",
        }),
      },
      {
        path: "edit/:id",
        title: "Update Form",
        name: "UpdateRegistrationForm",
        component: () => import("@/components/data/JsonForm.vue"),
        props: (x) => ({
          title: "Update Form ",
          postUrl: `registration/forms/${x.params.id}`,
        }),
      },
      {
        path: "delete/:id",
        title: "Delete Form",
        name: "DeleteRegistrationForm",
        component: () => import("@/components/data/DeleteModal.vue"),
        props: (x) => ({
          title: "Delete Form ",
          deleteUrl: `registration/forms/${x.params.id}`,
          size: "max-w-xs",
          name: "Delete Form",
        }),
      },
    ],
  },
];
