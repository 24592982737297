<template>
  <div class="h-full">
    <div class="rounded grid grid-rows-layout h-full w-full" v-show="oidcIsAuthenticated">

      <div class="px-2 pb-1">
        <div class="md:grid md:grid-cols-12 gap-2 md:gap-4 items-center my-2 mx-3">
          <div class="col-span-6">
            <h3 class="border-b-2 border-primary-300 py-4 space-x-2 inline-flex items-center text-primary-400  -mb-px">
              <button type="button" v-if="!!showBack" @click.prevent="back"
                class="text-6xl hover:bg-primary-200 transition p-2 hover:text-primary-600 rounded-full text-primary-500 bg-transparent outline-none focus:outline-none flex items-center justify-center"
                title="Back">
                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18">
                  </path>
                </svg>
              </button>
              <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 md:w-5 md:h-5" fill="currentColor"
                viewBox="0 0 16 16">
                <path
                  d="M1 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2zM1 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7zM1 12a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2z" />
              </svg>
              <span class="text-xs text-primary-700 md:text-base   font-normal   tracking-wider uppercase"> {{
                  this.pagedResult.totalItems ? this.pagedResult.totalItems : ""
              }} {{ $removeHyphens(selectedReport)
}}</span>
            </h3>
          </div>
          <div class="col-span-6 flex items-center justify-end space-x-1">
            <button
              class="flex items-center rounded-md bg-green-600 px-6 py-2 text-sm font-medium tracking-wide transition text-green-50 shadow-sm hover:shadow-md hover:border-green-600 hover:bg-green-700 hover:text-white focus:outline-none"
              @click="$printCSVData(fetchedCSVData, selectedReport, selectedYear, selectedRegion, selectedCounty)"
              v-if="(fetchedCSVData && fetchedCSVData.length > 0)">
              <span class="mr-2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="h-6 w-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                </svg>
              </span>
              Download Report
            </button>

          </div>
        </div>

        <div>
          <div class="my-3 border-y hover:bg-gray-50">
            <div class="sm:grid sm:grid-cols-4 ml-2 gap-4">
              <div>
                <FormulateInput type="number" label="Specify Year:" name="selectedYear"
                  :value="new Date().getUTCFullYear()" v-model="selectedYear" @change="formatInput" class="mt-2"
                  :validation-rules="{
                    noLetter: ({ value }) => !/[a-zA-Z]/g.test(value),
                  }" :validation-messages="{
  noLetter: `Letters are not allowed`,
}" validation="noLetter" :show-optional="false" error-behavior="value" />
              </div>

              <div class="col-span-2">
                <FormulateInput type="suggest" @input="clearAllFilters" v-model="selectedReport"
                  placeholder="-- Select report type --" label="Please specify report type"
                  class="mt-2 font-semibold text-gray-600" :disabled="!selectedYear || loading ? true : false"
                  validation="required" :options="reportOptions" />
              </div>

              <div class="justify-self-end mr-4">
                <button
                  class="flex items-center rounded-md  px-6 py-2 text-sm font-medium tracking-wide transition shadow-sm hover:shadow-md focus:outline-none mt-10"
                  @click="showFilters"
                  :class="[filters ? 'bg-red-100 text-red-500 hover:bg-red-200 hover:text-red-900 ' : 'bg-green-100 text-green-800 hover:bg-green-200 hover:text-green-900 ',]">
                  <span class="mr-2">

                    <svg v-if="filters" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                      stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>

                    <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75" />
                    </svg>
                  </span>
                  {{ (filters ? 'Close Filters' : 'All Filters') }}
                </button>
              </div>
            </div>

            <div v-if="filters" class="xl:grid xl:grid-cols-4 mx-2 gap-2">
              <div>
                <FormulateInput type="suggest" name="regions" placeholder="Type to select" label="Filter By Regions:"
                  class="mt-2" v-model="selectedRegion" @input="fetchCounties" :options="options[0]"
                  :show-optional="false" :disabled="loading && true"/>
              </div>

              <div>
                <FormulateInput type="suggest" label="Filter By County:" name="county" @input="selectCountyFilter"
                  placeholder="Type to select" v-model="selectedCounty" :options="counties" class="mt-2"
                  :show-optional="false" :disabled="loading && true"/>
              </div>

              <div class="col-span-2">
                <FormulateInput type="suggest" label="Filter By Facility:" name="facility" @input="selectFacilityFilter"
                  placeholder="Type to select" v-model="selectedFacilityCode" :options="facilities" class="mt-2"
                  :show-optional="false" :disabled="loading && true"/>
              </div>
            </div>
          </div>

          <!-- <div class="flex flex-row w-full justify-center">
                <button class="transition  text-white bg-blue-500   border hover:bg-blue-600 hover:text-white rounded-md  tracking-normal text-normal leading-3 py-2 px-3 flex items-center" @click="printResults" v-if="fetchedData && fetchedData.length>0">Print Report</button>
            </div> -->

        </div>

        <div
          class="flex justify-between text-xs font-semibold tracking-wide text-left text-gray-500 uppercase rounded-tl-md rounded-tr-md bg-gray-50 px-1">
          <div :tableHeadingTitles="tableHeadingTitles" v-for="(tableTitle, index) in tableHeadingTitles" :key="index">
            <div v-html="tableTitle" class="px-4 py-3">
              {{ tableTitle }}
            </div>
          </div>
        </div>
      </div>
      <div class="h-full border-b border-gray-100 ">

        <report-shimmer v-if="loading"></report-shimmer>

        <div v-else>
          <report-view v-if="selectedReport !== 'Blood-units-separated'" :fetchedData="fetchedData" :selectedReport="selectedReport" :url="url"></report-view>

          <div v-if="hasList" class="flex-1">
            <div class=" border-b  border-gray-100  ">

              <!-- blood components collected -->
              <div
                v-if="fetchedData && !fetchedData.currentPage && (fetchedData instanceof Object && fetchedData instanceof Array)">

                <!-- Blood units separated -->
                <table v-if="fetchedData[0].monthInt" class="w-full table-fixed justify-center ">
                  <thead>
                    <tr class="text-gray-700 bg-green-100 pb-2 pt-2 font-semibold text-sm">
                      <th class="p-3" style="width: 30%">Units</th>

                      <th v-for="month in months" v-bind:key="month" class="w-1/5 p-3 pl-2 border-t ">
                        {{ month }}
                      </th>
                      <th class="p-3" style="width: 30%">Yearly</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="text-sm group">
                      <td
                        class="w-1/4 border-b py-3 text-center font-semibold capitalize bg-gray-100 text-gray-700 group-hover:bg-green-200">
                        <div class="py-3 border-b text-xs font-medium">
                          All Units
                        </div>
                        <div class="py-3 border-b text-xs font-medium">
                          Separated Units
                        </div>
                        <div class="py-3 border-b text-xs font-medium">
                          Separation Rate
                        </div>
                        <div class="py-3 border-b text-xs font-medium">
                          Total Components
                        </div>

                      </td>

                      <td v-for="(groups, index) in fetchedData" v-bind:key="index" width="25%"
                        class="w-1/4 border-b font-medium text-center py-3 hover:text-gray-600 hover:bg-green-200">

                        <div v-if="groups.months.totalNumberOfUnits > 0">
                          <div class="h-1/3 border-b text-sm py-3">
                            {{ groups.months.totalNumberOfUnits }}
                          </div>
                        </div>
                        <div class="text-center" v-else>
                          <div class="h-1/3 border-b text-sm py-3">-</div>
                        </div>

                        <div v-if="groups.months.totalNumberOfUnitsSeparated > 0">
                          <div class="h-1/3 border-b text-sm py-3">
                            {{ groups.months.totalNumberOfUnitsSeparated }}
                          </div>
                        </div>
                        <div class="text-center" v-else>
                          <div class="h-1/3 border-b text-sm py-3">-</div>
                        </div>
                        
                        <div v-if="groups.months.percentageRatio">
                          <div class="h-1/3 border-b file:text-sm py-3">
                            {{ groups.months &&
                                (groups.months.percentageRatio) ? $roundOff(groups.months.percentageRatio.replace('%', '')) + '%' : ''
                            }}
                          </div>
                        </div>
                        <div class="text-center" v-else>
                          <div class="h-1/3 border-b text-sm py-3">-</div>
                        </div>

                        <div v-if="groups.months.totalNumberOfComponents > 0">
                          <div class="h-1/3 border-b text-sm py-3">
                            {{ groups.months.totalNumberOfComponents }}
                          </div>
                        </div>
                        <div class="text-center" v-else>
                          <div class="h-1/3 border-b text-sm py-3">-</div>
                        </div>


                      </td>
                      <td
                        class="bg-gray-50 font-medium items-center py-2 text-center capitalize text-gray-700 group-hover:bg-green-300">
                        <div v-if="fetchedTotals.totalNumberOfUnits > 0">
                          <div class="h-1/3 text-sm py-3">
                            {{ fetchedTotals.totalNumberOfUnits }}
                          </div>
                        </div>
                        <div class="text-center" v-else>
                          <div class="h-1/3 text-sm py-3">-</div>
                        </div>

                         <div v-if="fetchedTotals.totalNumberOfUnitsSeparated > 0">
                          <div class="h-1/3 text-sm py-3">
                            {{ fetchedTotals.totalNumberOfUnitsSeparated }}
                          </div>
                        </div>
                        <div class="text-center" v-else>
                          <div class="h-1/3  text-sm py-3">-</div>
                        </div>

                        <div v-if="fetchedTotals.percentageRatio">
                          <div class="h-1/3 file:text-sm py-3">
                            {{ fetchedTotals && (fetchedTotals.percentageRatio) ?
                                $roundOff(fetchedTotals.percentageRatio.replace('%', '')) + '%' : ''
                            }}
                          </div>
                        </div>
                        <div class="text-center" v-else>
                          <div class="h-1/3 text-sm py-3">-</div>
                        </div>

                        <div v-if="fetchedTotals.totalNumberOfComponents > 0">
                          <div class="h-1/3 text-sm py-3">
                            {{ fetchedTotals.totalNumberOfComponents }}
                          </div>
                        </div>
                        <div class="text-center" v-else>
                          <div class="h-1/3 text-sm py-3">-</div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>


              <!-- <div v-if="!fetchedData.totalNumberOfDonations">
              <div v-for="(item, index) in fetchedData" :key="item.id" class="px-6 py-4 transition-all hover:bg-gray-50 ">
                <slot v-bind:index="index" v-bind:item="item" v-bind:selectedYear="selectedYear"></slot>                
              </div>
            </div> -->
              <!-- <div v-if="fetchedData.totalNumberOfDonations" class="bg-gray-50 hover:bg-gray-100 p-4">
            <p class="flex justify-between font-semibold capitalize ">
              <span>Total components</span>
              <span>Total donations</span>
              <span>Percentage Ratio</span>
            </p>
            <p class="flex justify-between ">
              <span class="flex justify-center">{{fetchedData.totalNumberOfComponents}}</span>
              <span class="flex justify-center">{{fetchedData.totalNumberOfDonations}}</span>
              <span class="flex justify-center">{{fetchedData.percentageRatio}}</span>
            </p>
            </div> -->
            </div>
          </div>
          <div v-if="!hasList && !loading" class="h-full  flex items-center justify-center">
            <span class="text-xl text-yellow-600 font-semibold animate-pulse self-center">No records found.</span>
          </div>
        </div>


        <div v-if="loading" class="flex items-center justify-center hidden">
          <loading-indicator></loading-indicator>
        </div>
        <div v-if="hasList" class="flex-1 hidden">
          <div class=" border-b  border-gray-100  ">

            <!-- blood components collected -->
            <div
              v-if="fetchedData && !fetchedData.currentPage && (fetchedData instanceof Object && fetchedData instanceof Array)">

              <ReportTable :fetchedData="fetchedData" :months="months" :selectedYear="selectedYear"
                :selectedMonthlyReport="selectedReport"></ReportTable>

              <!-- Blood units separated -->
              <table v-if="fetchedData[0].monthInt" class="w-full table-fixed justify-center hidden">
                <thead>
                  <tr class="text-gray-700 bg-green-100 pb-2 pt-2 font-semibold text-sm">
                    <th class="p-3" style="width: 30%">Units</th>

                    <th v-for="month in months" v-bind:key="month" class="w-1/5 p-3 pl-2 border-t ">
                      {{ month }}
                    </th>
                    <th class="p-3" style="width: 30%">Yearly</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="text-sm group">
                    <td
                      class="w-1/4 border-b py-3 text-center font-semibold capitalize bg-gray-100 text-gray-700 group-hover:bg-green-200">
                      <div class="py-3 border-b text-sm font-medium">
                        Separated Units
                      </div>
                      <div class="py-3 border-b text-sm font-medium">
                        All Units
                      </div>
                      <div class="py-3 border-b text-sm font-medium">
                        Rate of Separation
                      </div>
                      <div class="py-3 border-b text-sm font-medium">
                        Total Components
                      </div>

                    </td>

                    <td v-for="(groups, index) in fetchedData" v-bind:key="index" width="25%"
                      class="w-1/4 border-b font-medium text-center py-3 hover:text-gray-600 hover:bg-green-200">

                      <div v-if="groups.months.totalNumberOfUnitsSeparated > 0">
                        <div class="h-1/3 border-b text-sm py-3">
                          {{ groups.months.totalNumberOfUnitsSeparated }}
                        </div>
                      </div>
                      <div class="text-center" v-else>
                        <div class="h-1/3 border-b text-sm py-3">-</div>
                      </div>


                      <div v-if="groups.months.totalNumberOfUnits > 0">
                        <div class="h-1/3 border-b text-sm py-3">
                          {{ groups.months.totalNumberOfUnits }}
                        </div>
                      </div>
                      <div class="text-center" v-else>
                        <div class="h-1/3 border-b text-sm py-3">-</div>
                      </div>

                      <div v-if="groups.months.percentageRatio">
                        <div class="h-1/3 border-b file:text-sm py-3">
                          {{ groups.months &&
                              (groups.months.percentageRatio) ? $roundOff(groups.months.percentageRatio.replace('%', '')) + '%' : ''
                          }}
                        </div>
                      </div>
                      <div class="text-center" v-else>
                        <div class="h-1/3 border-b text-sm py-3">-</div>
                      </div>

                      <div v-if="groups.months.totalNumberOfComponents > 0">
                        <div class="h-1/3 border-b text-sm py-3">
                          {{ groups.months.totalNumberOfComponents }}
                        </div>
                      </div>
                      <div class="text-center" v-else>
                        <div class="h-1/3 border-b text-sm py-3">-</div>
                      </div>


                    </td>
                    <td
                      class="bg-gray-50 font-medium items-center py-2 text-center capitalize text-gray-700 group-hover:bg-green-300">

                      <div v-if="fetchedTotals.totalNumberOfUnitsSeparated > 0">
                        <div class="h-1/3 text-sm py-3">
                          {{ fetchedTotals.totalNumberOfUnitsSeparated }}
                        </div>
                      </div>
                      <div class="text-center" v-else>
                        <div class="h-1/3  text-sm py-3">-</div>
                      </div>


                      <div v-if="fetchedTotals.totalNumberOfUnits > 0">
                        <div class="h-1/3 text-sm py-3">
                          {{ fetchedTotals.totalNumberOfUnits }}
                        </div>
                      </div>
                      <div class="text-center" v-else>
                        <div class="h-1/3 text-sm py-3">-</div>
                      </div>

                      <div v-if="fetchedTotals.percentageRatio">
                        <div class="h-1/3 file:text-sm py-3">
                          {{ fetchedTotals && (fetchedTotals.percentageRatio) ?
                              $roundOff(fetchedTotals.percentageRatio.replace('%', '')) + '%' : ''
                          }}
                        </div>
                      </div>
                      <div class="text-center" v-else>
                        <div class="h-1/3 text-sm py-3">-</div>
                      </div>

                      <div v-if="fetchedTotals.totalNumberOfComponents > 0">
                        <div class="h-1/3 text-sm py-3">
                          {{ fetchedTotals.totalNumberOfComponents }}
                        </div>
                      </div>
                      <div class="text-center" v-else>
                        <div class="h-1/3 text-sm py-3">-</div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>


            <!-- <div v-if="!fetchedData.totalNumberOfDonations">
              <div v-for="(item, index) in fetchedData" :key="item.id" class="px-6 py-4 transition-all hover:bg-gray-50 ">
                <slot v-bind:index="index" v-bind:item="item" v-bind:selectedYear="selectedYear"></slot>                
              </div>
            </div> -->
            <!-- <div v-if="fetchedData.totalNumberOfDonations" class="bg-gray-50 hover:bg-gray-100 p-4">
            <p class="flex justify-between font-semibold capitalize ">
              <span>Total components</span>
              <span>Total donations</span>
              <span>Percentage Ratio</span>
            </p>
            <p class="flex justify-between ">
              <span class="flex justify-center">{{fetchedData.totalNumberOfComponents}}</span>
              <span class="flex justify-center">{{fetchedData.totalNumberOfDonations}}</span>
              <span class="flex justify-center">{{fetchedData.percentageRatio}}</span>
            </p>
            </div> -->
          </div>
        </div>
        <div v-if="!hasList && !loading" class="h-full  flex items-center justify-center hidden">
          <span class="text-xl text-yellow-600 font-semibold animate-pulse self-center">No records found.</span>
        </div>
      </div>
      <div>
        <div class="px-4  py-4 ">
          <nav v-if="!isEmpty(pagedResult)">
            <ul class="flex pl-0 rounded list-none flex-wrap" v-if="pagedResult.currentPage > 0">
              <li>
                <span v-if="pagedResult.currentPage === 1"
                  class="opacity-50 first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-gray-500 bg-white text-gray-500">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current">
                    <path d="M12.707 7.707l-1.414-1.414L5.586 12l5.707 5.707 1.414-1.414L8.414 12z" />
                    <path d="M16.293 6.293L10.586 12l5.707 5.707 1.414-1.414L13.414 12l4.293-4.293z" />
                  </svg>
                </span>
                <router-link v-else :to="{ query: { page: 1 } }"
                  class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-primary-400 bg-white text-primary-400">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current">
                    <path d="M12.707 7.707l-1.414-1.414L5.586 12l5.707 5.707 1.414-1.414L8.414 12z" />
                    <path d="M16.293 6.293L10.586 12l5.707 5.707 1.414-1.414L13.414 12l4.293-4.293z" />
                  </svg>
                </router-link>
              </li>
              <li>
                <span v-if="pagedResult.currentPage === 1"
                  class="opacity-50 first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-gray-500 bg-white text-gray-500">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current">
                    <path d="M13.293 6.293L7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z" />
                  </svg>
                </span>
                <router-link v-else :to="{ query: { page: pagedResult.currentPage - 1 } }"
                  class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-primary-400 bg-white text-primary-400">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current">
                    <path d="M13.293 6.293L7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z" />
                  </svg>
                </router-link>
              </li>
              <li v-for="page in pagedResult.pages" :key="page">
                <span v-if="page === pagedResult.currentPage"
                  class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-primary-400 bg-primary-400 text-white">{{
                      page
                  }}</span>
                <router-link v-else :to="{ query: { page: page } }"
                  class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-primary-400 bg-white text-primary-400">{{
                      page
                  }}</router-link>
              </li>
              <li>
                <span v-if="pagedResult.currentPage === pagedResult.totalPages"
                  class="opacity-50 first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-gray-500 bg-white text-gray-500">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current">
                    <path d="M10.707 17.707L16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z" />
                  </svg>
                </span>
                <router-link v-else :to="{ query: { page: pagedResult.currentPage + 1 } }"
                  class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-primary-400 bg-white text-primary-400">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current">
                    <path d="M10.707 17.707L16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z" />
                  </svg>
                </router-link>
              </li>
              <li>
                <span v-if="pagedResult.currentPage === pagedResult.totalPages"
                  class="opacity-50 first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-gray-500 bg-white text-gray-500">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current">
                    <path d="M10.296 7.71L14.621 12l-4.325 4.29 1.408 1.42L17.461 12l-5.757-5.71z" />
                    <path d="M6.704 6.29L5.296 7.71 9.621 12l-4.325 4.29 1.408 1.42L12.461 12z" />
                  </svg>
                </span>
                <router-link v-else :to="{ query: { page: pagedResult.totalPages } }"
                  class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-primary-400 bg-white text-primary-400">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current">
                    <path d="M10.296 7.71L14.621 12l-4.325 4.29 1.408 1.42L17.461 12l-5.757-5.71z" />
                    <path d="M6.704 6.29L5.296 7.71 9.621 12l-4.325 4.29 1.408 1.42L12.461 12z" />
                  </svg>
                </router-link>
              </li>
            </ul>
          </nav>
        </div>
        <router-view> </router-view>
      </div>
    </div>
    <lock v-show="!oidcIsAuthenticated" />

    <!-- report to be printed -->
    <div id="report" class="p-12 border border-gray-200 m-8 rounded-sm flex flex-col hidden">
      <!--header area -->
      <div class="flex flex-row justify-between">
        <div class="flex flex-col space-y-2">
          <p class="font-bold"> {{ oidcUser.facility }}<span class="font-normal"> ({{ oidcUser.facility_code }})</span></p>
          <p class="capitalize">{{ $removeHyphens(selectedReport) }} {{ selectedYear }}</p>
        </div>
        <div>
          <img src="https://damuyetustaging.health.go.ke/img/KNBTS.7939d0ee.png" class="h-16" />
        </div>
      </div>
      <!--header area end-->

      <!--  table-->
      <div class="border border-0.5  mt-12 ">
        <table class="w-full whitespace-no-wrap ">
          <thead>
            <tr class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b  bg-gray-50  ">
              <th class="px-4 py-3 border border-black">Month</th>
              <th class="px-4 py-3 border border-black">Report</th>
              <th class="px-4 py-3 border border-black">Remarks</th>
            </tr>
          </thead>
          <tbody v-if="fetchedData.length > 0" class="bg-white divide-y">
            <tr v-for="(item, index) in fetchedData" class="" :key="index">
              <td class="px-4 py-2 text-sm border border-black font-bold ">
                {{ $monthIntToName(item.monthInt) }}
              </td>
              <!-- by types -->
              <td class="px-4 py-3 text-xs border border-black"
                v-if="item.donationTypes && item.donationTypes.length > 0">
                <div v-for="(type, index) in item.donationTypes" :key="index"
                  class="px-6 py-2 transition-all font-semibold font-mono">
                  <span class="capitalize">{{ $removeHyphens(type.type) }}</span>:
                  <span>{{ type.successfulDonations }}</span>
                </div>
              </td>
              <td class="px-10 py-2 text-xs border border-black text-orange-400 font-semibold italic"
                v-if="item.donationTypes && item.donationTypes.length === 0">
                <span
                  v-if="item.donationTypes && item.donationTypes.length === 0 && selectedYear <= new Date().getFullYear() && !(selectedYear === new Date().getFullYear() && item.monthInt >= new Date().getMonth() + 1)">No
                  donations recorded</span>
                <span
                  v-if="selectedYear > new Date().getFullYear() || (selectedYear === new Date().getFullYear() && item.monthInt > new Date().getMonth() + 1)">No
                  records yet</span>
              </td>
              <!-- by age -->
              <td class="px-4 py-3 text-xs border border-black"
                v-if="item.ageGroups && item.ageGroups.length > 0 && (selectedYear <= new Date().getFullYear() && item.monthInt <= new Date().getMonth() + 1)">
                <div v-for="(group, index) in item.ageGroups" :key="index"
                  class="px-6 py-2 transition-all font-semibold font-mono">
                  <span class="capitalize">{{ group.age }}</span>:
                  <span>{{ group.successfulDonations }}</span>
                </div>
              </td>
              <td class="px-10 py-2 text-xs border border-black text-orange-400 font-semibold italic"
                v-if="item.ageGroups && item.ageGroups.length > 0 && (selectedYear <= new Date().getFullYear() && item.monthInt > new Date().getMonth() + 1)">
                <span
                  v-if="item.ageGroups && item.ageGroups.length === 0 && selectedYear <= new Date().getFullYear() && !(selectedYear === new Date().getFullYear() && item.monthInt >= new Date().getMonth() + 1)">No
                  donations recorded</span>
                <span
                  v-if="selectedYear > new Date().getFullYear() || (selectedYear === new Date().getFullYear() && item.monthInt > new Date().getMonth() + 1)">No
                  records yet</span>
              </td>
              <!-- by discards -->
              <td class="px-4 py-3 text-xs border border-black"
                v-if="item.unitsDiscarded && item.unitsDiscarded.length > 0 && (selectedYear <= new Date().getFullYear() && item.monthInt <= new Date().getMonth() + 1)">
                <div v-for="(discard, index) in item.unitsDiscarded" :key="index"
                  class="px-6 py-2 transition-all font-semibold font-mono">
                  <span class="capitalize p-2">{{ $removeHyphens(discard.reason) }}:</span>
                  <span>{{ discard.unitsDiscarded }}</span>
                </div>
              </td>
              <td class="px-10 py-2 text-xs border border-black text-orange-400 font-semibold italic"
                v-if="item.unitsDiscarded && item.unitsDiscarded.length === 0">
                <span
                  v-if="selectedYear <= new Date().getFullYear() && !(selectedYear === new Date().getFullYear() && item.monthInt > new Date().getMonth() + 1)">No
                  discards recorded</span>
                <span
                  v-if="selectedYear > new Date().getFullYear() || (selectedYear === new Date().getFullYear() && item.monthInt > new Date().getMonth() + 1)">No
                  records yet</span>
              </td>
              <!--  -->
              <td class="px-10 py-2 border border-black"></td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr class="h-12 w-full text-center">
              <td></td>
              <td class="capitalize">No data available</td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- end of table-->
      <!-- sign out info-->
      <div class="flex flex-col space-y-2 mt-14">
        <div class="flex flex-row space-x-2">
          <span> Name: </span>
          <div class="border-0 border-b mb-1 border-gray-200 w-40"></div>
        </div>
        <div class="flex flex-row space-x-2">
          <span> Signature: </span>
          <div class="border-0 border-b mb-1 border-gray-200 w-40"></div>
        </div>
      </div>
      <!-- end sign out info-->
      <!-- start disclaimer and footer-->
      <div class="mb-0 flex flex-row justify-center mt-14 w-full">
        <p class="text-gray-400"> Disclaimer: This is a system generated document</p>
      </div>
      <!-- end disclaimer and footer-->
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Lock from "@/components/layout/Lock";
export default {
  async created() {
    await this.fetchOptions(); this.fetchFacilityOptions();
  },
  props: {
    title: String,
    // url: String,
    showCreate: { type: Boolean, default: true },
    showBack: { type: Boolean, default: false },
    createName: { type: String, default: "Create" },
    createTitle: { type: String, default: "New" },
    showCustomAction: { type: Boolean, default: false },
    customActionParams: {
      type: Array,
      default() {
        return [];
      },
    },
    customActionTitle: { type: String, default: "Action" },
    customActionName: { type: String, default: "Action" },
    tableHeadingTitles: {
      Array,
      default: function () {
        return [];
      },
    },
  },
  components: { Lock },
  computed: {
    ...mapGetters(["oidcIsAuthenticated", "oidcUser"]),
    hasList() {
      return this.fetchedData?.length > 0 || Object.keys(this.fetchedData).length;
    },
    url() {
      return 'component-separation/ComponentReports/' + this.selectedYear + '/' + this.selectedReport + '?facilityCode=' + this.selectedFacilityCode + '&region=' + this.formattedRegion + '&county=' + this.formattedCounty
    }
  },
  data() {
    return {
      loading: false,
      pagedResult: {},
      fetchedData: [],
      fetchedTotals: [],
      fetchedCSVData: [],
      selectedYear: "",
      selectedRegion: "",
      selectedCounty: "",
      formattedRegion: "",
      formattedCounty: "",
      selectedFacilityCode: "",
      selectedReport: "Blood-units-separated",
      error: {},
      q: this.$route.query.q,
      months: ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
      options: [],
      optionsUrls: [
        "component-separation/ComponentSeparationLookups/regions/options",
      ],
      reportOptions: {
        "Blood-units-separated": "Blood units separated",
        "Blood-Components-collected": "Components Prepared by Type",
      },
      counties: [],
      facilities: [],
      filters: false
    };
  },
  //   watch: {
  //     $route: {
  //       immediate: true,
  //     //   async handler() {
  //     //     if (this.$route.matched.length === 1 || (this.$route.matched.length > 1 && Object.keys(this.pagedResult).length === 0 && this.pagedResult.constructor === Object)) {
  //     //       await this.fetch();
  //     //     }
  //     //   },
  //     },
  //   },
  methods: {
    printResults() {
      this.$htmlToPaper('report');
    },
    showFilters() {
      if (this.filters === false) {
        this.filters = true
      } else {
        this.filters = false
        this.clearAllFilters()
      }
    },
    selectMonthlyReport() {
      this.fetch();
      this.clearAllFilters();
    },
    formatInput() {
      this.selectedYear = this.selectedYear.replace(/ /g, '')
      if (this.selectedReport) this.fetch()
    },
    fetchFacilityOptions() {
      this.$callApi
        .get(`component-separation/Facilities/facility-options?region=${this.formattedRegion}&county=${this.formattedCounty}`)
        .then((x) => {
          this.facilities = x.data;
        });
    },
    selectFacilityFilter() {
      if (this.selectedFacilityCode === null) {
        this.selectedFacilityCode = ""
      }
      this.fetch();
    },
    selectCountyFilter(e) {
      if (e) {
        this.facilities = []
        this.selectedFacilityCode = ""
        this.$callApi
          .get(`component-separation/ComponentSeparationLookups/value/${e}`)
          .then((x) => {
            this.formattedCounty = x.data.label;
            this.fetchFacilityOptions()
            this.fetch();
          });
      }
      if (this.selectedCounty === null) {
        this.selectedCounty = ""
        this.formattedCounty = ""
        this.facilities = []
        this.selectedFacilityCode = ""
        this.fetchFacilityOptions()
        this.fetch();
      }
    },
    fetchCounties(e) {
      if (e) {
        this.clearFilters()
        this.$callApi
          .get(`component-separation/ComponentSeparationLookups/${e}/options`)
          .then((x) => {
            this.counties = x.data;
            this.fetchRegionName(e);
          });
      }
      if (this.selectedRegion === null) {
        this.selectedRegion = ""
        this.formattedRegion = ""
        this.clearFilters()
        this.fetch();
      }
    },
    fetchRegionName(code) {
      this.$callApi
        .get(`component-separation/ComponentSeparationLookups/value/${code}`)
        .then((x) => {
          this.formattedRegion = x.data.label;
          this.fetchFacilityOptions()
          this.fetch();
        });
    },
    clearFilters() {
      this.selectedCounty = ""
      this.formattedCounty = ""
      this.facilities = []
      this.counties = []
      this.selectedFacilityCode = ""
      this.fetchFacilityOptions()
    },
    clearAllFilters() {
      this.formattedRegion = "",
        this.selectedRegion = "",
        this.selectedCounty = ""
      this.formattedCounty = ""
      this.facilities = []
      this.counties = []
      this.selectedFacilityCode = ""
      this.fetchFacilityOptions()
      this.fetch()
    },
    fetchOptions() {
      window.Promise.all([...this.optionsUrls.map((x) => this.$callApi.get(x))])
        .then((x) => {
          this.options = [...x.map((y) => y.data)];
        })
        .catch((err) => console.log(err));
    },
    async fetch() {
      this.loading = true;
      this.fetchedData = []
      this.fetchedCSVData = []
      this.fetchedTotals = []
      this.$callApi
        .get(this.url, {
          params: { ...this.$route.query },
        })
        .then((x) => {
          this.fetchedCSVData = x.data.csv || x.data.csvData;
          this.fetchedData = x.data.data;
          this.fetchedTotals = x.data.total;
        })
        .catch(() => {
          //this.error = err;
          //console.log(err);
        })
        .then(() => (this.loading = false));
    },
    search() {
      if (this.q.length >= 3) {
        this.$router.push({ query: { q: this.q } });
      } else {
        if (this.$route.query.q !== undefined) {
          this.q = "";
          this.$router.push({ query: {} });
        }
      }
    },
    clear(e) {
      if (e.target.value.trim().length === 0) {
        this.q = "";
      }
    },
    navigate(queryString) {
      this.$router.push({ path: this.$route.path, query: { ...queryString } });
    },
    isEmpty(obj) {
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    },
    back() {
      this.$router.back();
    },
  },
  mounted() {
    this.fetch()
  }
};
</script>
