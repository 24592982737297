<template>
  <vue-editor :class="[context.classes.element]" :editor-toolbar="customToolbar" :data-type="context.type" @input="context.blurHandler" v-on="$listeners" v-bind="context.attributes" v-model="context.model" />
</template>
<script>
import { VueEditor } from "vue2-editor";
export default {
  name: "custom-editor",
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  components: { VueEditor },
  data: () => ({
    customToolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["image"],
      // ["blockquote"],
      [{ size: ["small", false, "large", "huge"] }],
    ],
  }),
};
</script>
