import home from "@/views/home/Home";
import OidcCallback from "@/views/OidcCallback";
import SilentRenew from "@/views/SilentRenew";
import Login from "@/views/home/Login";
import OidcCallbackError from "@/views/OidcCallbackError";
export default [{
        path: "/",
        name: "Home",
        component: home,
        meta: {
            isPublic: true,
        },
    },
    {
        path: "/oidc-callback",
        name: "oidcCallback",
        component: OidcCallback,
    },
    {
        path: "/silent-renew-oidc",
        name: "SilentRenew",
        component: SilentRenew,
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
        meta: {
            isPublic: true,
        },
    },
    {
        path: "/oidc-callback-error",
        name: "oidcCallbackError",
        component: OidcCallbackError,
        meta: {
            isPublic: true,
        },
    },
];