<template>
    <tr class="group">
        <td
            class="w-1/4 border-b py-3 text-center font-semibold capitalize bg-gray-100 text-gray-700 group-hover:bg-green-200">
            <div class="py-3 border-b text-sm font-medium">
                Separated Units
            </div>
            <div class="py-3 border-b text-sm font-medium">
                All Units
            </div>
            <div class="py-3 border-b text-sm font-medium">
                Rate of Separation
            </div>
            <div class="py-3 border-b text-sm font-medium">
                Total Components
            </div>

        </td>

        <!-- <td v-for="(groups, index) in data" v-bind:key="index" width="25%"
            class="w-1/4 border-b font-medium text-center py-3 hover:text-gray-600 hover:bg-green-200">

            <div v-if="groups.months.totalNumberOfUnitsSeparated > 0">
                <div class="h-1/3 border-b text-sm py-3">
                    {{ groups.months.totalNumberOfUnitsSeparated }}
                </div>
            </div>
            <div class="text-center" v-else>
                <div class="h-1/3 border-b text-sm py-3">-</div>
            </div>


            <div v-if="groups.months.totalNumberOfUnits > 0">
                <div class="h-1/3 border-b text-sm py-3">
                    {{ groups.months.totalNumberOfUnits }}
                </div>
            </div>
            <div class="text-center" v-else>
                <div class="h-1/3 border-b text-sm py-3">-</div>
            </div>

            <div v-if="groups.months.percentageRatio">
                <div class="h-1/3 border-b file:text-sm py-3">
                    {{ groups.months &&
                            (groups.months.percentageRatio) ? $roundOff(groups.months.percentageRatio.replace('%', '')) + '%' :
                            ''
                    }}
                </div>
            </div>
            <div class="text-center" v-else>
                <div class="h-1/3 border-b text-sm py-3">-</div>
            </div>

            <div v-if="groups.months.totalNumberOfComponents > 0">
                <div class="h-1/3 border-b text-sm py-3">
                    {{ groups.months.totalNumberOfComponents }}
                </div>
            </div>
            <div class="text-center" v-else>
                <div class="h-1/3 border-b text-sm py-3">-</div>
            </div>


        </td> -->
        <!-- <td
            class="bg-gray-50 font-medium items-center py-2 text-center capitalize text-gray-700 group-hover:bg-green-300">

            <div v-if="fetchedTotals.totalNumberOfUnitsSeparated > 0">
                <div class="h-1/3 text-sm py-3">
                    {{ fetchedTotals.totalNumberOfUnitsSeparated }}
                </div>
            </div>
            <div class="text-center" v-else>
                <div class="h-1/3  text-sm py-3">-</div>
            </div>


            <div v-if="fetchedTotals.totalNumberOfUnits > 0">
                <div class="h-1/3 text-sm py-3">
                    {{ fetchedTotals.totalNumberOfUnits }}
                </div>
            </div>
            <div class="text-center" v-else>
                <div class="h-1/3 text-sm py-3">-</div>
            </div>

            <div v-if="fetchedTotals.percentageRatio">
                <div class="h-1/3 file:text-sm py-3">
                    {{ fetchedTotals && (fetchedTotals.percentageRatio) ?
                            $roundOff(fetchedTotals.percentageRatio.replace('%', '')) + '%' : ''
                    }}
                </div>
            </div>
            <div class="text-center" v-else>
                <div class="h-1/3 text-sm py-3">-</div>
            </div>

            <div v-if="fetchedTotals.totalNumberOfComponents > 0">
                <div class="h-1/3 text-sm py-3">
                    {{ fetchedTotals.totalNumberOfComponents }}
                </div>
            </div>
            <div class="text-center" v-else>
                <div class="h-1/3 text-sm py-3">-</div>
            </div>
        </td> -->
    </tr>

</template>

<script>

export default {
    props: {
        selectedMonthlyReport: String,
        data: [Array, Object],
    },

    data() {
        return {
            months: ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
        };
    },
}
</script>
