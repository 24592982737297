<template>
    <t-datepicker :class="[context.classes.element]"
                  :data-type="context.type"
                  @blur="context.blurHandler"
                  v-on="$listeners" :min-date="minDate"
                  v-bind="context.attributes" v-model="context.model">
        <template slot="day"
                  slot-scope="{ day, dayFormatted }">

            <span v-if="day.getDay() === 0" class="text-red-500">
                {{dayFormatted}}
            </span>
            <span v-else>
                {{ dayFormatted }}
            </span>
        </template>
    </t-datepicker>
</template>
<script>
    //import TDatepicker from "vue-tailwind/dist/t-datepicker"
    import dayjs from "dayjs"
    export default {

        props: {
            context: {
                type: Object,
                required: true
            },
            
        },
        data() {
            return {
                minDate: dayjs().format("YYYY-MM-DD")

            }
        },
        //components: {
        //    "t-datepicker": TDatepicker
        //},

    }
</script>