import callApi from "@/services/http";
export default {
    state: () => ({
        client: {}
    }),
    mutations: {
        setClient: (state, data) => state.client = data,
    },
    getters: {
        getClient: (state) => state.client
    },
    actions: {
        loadClient: (url, { commit }) => {
            callApi.get(url)
                .then(x => {

                    commit("setClient", x.data);
                })
                .catch(() => {
                    commit("setError", "Failed getting client");
                })
                .then(() => {
                    commit("clearBusy");
                });
        },
    }
}