<template>
  <div v-on-clickaway="closeAppsMenu" class="relative">
    <button aria-haspopup="true" aria-label="Notifications" class="transition relative align-middle p-2 rounded-full hover:bg-secondary-600 text-white hover:text-white focus:outline-none focus:shadow-outline-purple" @click.prevent="toggleAppsMenu()" @keydown.escape="closeAppsMenu">
      <svg fill="none" class="w-5 h-5" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
        <rect height="7" width="7" x="3" y="3"></rect>
        <rect height="7" width="7" x="14" y="3"></rect>
        <rect height="7" width="7" x="14" y="14"></rect>
        <rect height="7" width="7" x="3" y="14"></rect>
      </svg>
    </button>
    <transition appear enter-active-class="animate-tilt-in-top-2" leave-active-class="animate-flip-out-diag-2-tl">
      <div v-if="isAppsMenuOpen" aria-label="submenu" :class="['w-72 z-30 absolute right-0 p-4  space-y-2 max-h-90vh scrollbar:w-1.5 scrollbar:h-1.5 scrollbar:bg-transparent scrollbar-track:bg-slate-100 scrollbar-thumb:rounded scrollbar-thumb:!bg-slate-300 overflow-y-auto text-gray-600 bg-white border border-gray-100 rounded elevation-2 ']">
        <div class="grid grid-cols-2 gap-y-4 gap-x-2 ">
          <a v-for="(area, index) in areas" :title="area.label" @click.prevent="switchToArea(area)" :key="index" class="text-gray-900 py-2 px-2 cursor-pointer flex flex-col  transition group   hover:text-secondary-500">
            <div class="flex place-content-center">
              <span class=" text-gray-400 group-hover:text-secondary-400 " v-if="area.icon" v-html="area.icon"> </span>
              <span v-else class=" text-gray-400 group-hover:text-secondary-400 ">
                <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20">
                  <path clip-rule="evenodd" d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z" fill-rule="evenodd"></path>
                </svg>
              </span>
            </div>
            <p class="text-center flex-1 min-w-0 truncate">{{ area.label }}</p>
          </a>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { mixin as clickaway } from "vue-clickaway";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "app-areas",
  mixins: [clickaway],
  data() {
    return {
      isAppsMenuOpen: false,
    };
  },
  computed: {
    ...mapGetters(["areas"]),
  },
  mounted() {},
  methods: {
    toggleAppsMenu() {
      this.isAppsMenuOpen = !this.isAppsMenuOpen;
    },
    closeAppsMenu() {
      this.isAppsMenuOpen = false;
    },
    ...mapActions(["switchArea"]),
    switchToArea(area) {
      this.switchArea(area?.name).then(() => {
        this.closeAppsMenu();
        if (this.$router.currentRoute.name !== area.links[0]?.name) {
          this.$router.push({ name: area.links[0]?.name });
        }
      });
    },
  },
};
</script>
