<template>
  <vue-select :class="[stateCss, context.classes.element]" :reduce="(x) => x.value" :data-type="context.type" :options="context.options" :required="context.errors" @input="context.blurHandler" v-on="$listeners" @search:blur="context.blurHandler" v-bind="context.attributes" v-model="context.model">
    <template slot="no-options">
      Type to search...
    </template>
  </vue-select>
</template>
<script>
import vSelect from "vue-select";
export default {
  name: "auto-suggest",
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  components: {
    "vue-select": vSelect,
  },
  computed: {
    stateCss() {
      if (!this.context.isValid && this.context.showValidationErrors) {
        return "px-1 rounded border border-red-500/90";
      }
      if (this.context.rules.length > 0 && this.context.isValid && this.context.hasValue) {
        return "px-1 rounded border border-green-300";
      }
      return "px-1 rounded border border-gray-300";
    },
  },
};
</script>
