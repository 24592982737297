<template>
    <router-link :to="{ name: name, params:{id:param}}" class="transition shadow-sm rounded-full p-2 flex items-center text-xs text-green-500 hover:bg-green-100 hover:text-green-700 focus:outline-none transform hover:-translate-y-px hover:scale-110" :title="title">
      <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path>
      </svg>
        <!--<span class="ml-1">{{caption}}</span>-->
    </router-link>
</template>
<script>
    export default {
        name: "edit-link",
        props: {
            name: String,
            caption: { String, default: "Edit" },
            param: Number,
            title: { type: String, default: "Edit" }
        }
    }
</script>