<template>
  <div class="h-full">
    <div class="rounded grid grid-rows-layout h-full w-full" v-show="oidcIsAuthenticated">
      <div class="px-2 py-4">
        <div class="grid grid-cols-12 gap-2 md:gap-4 items-center ">
          <div class="col-span-6">
            <h3 class="border-b-2 border-primary-300 py-4 space-x-2 inline-flex items-center text-primary-400  -mb-px">
              <button type="button" v-if="!!showBack" @click.prevent="back" class="text-6xl hover:bg-primary-200 transition p-2 hover:text-primary-600 rounded-full text-primary-500 bg-transparent outline-none focus:outline-none flex items-center justify-center" title="Back">
                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"></path>
                </svg>
              </button>
              <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 md:w-5 md:h-5" fill="currentColor" viewBox="0 0 16 16">
                <path
                  d="M1 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2zM1 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7zM1 12a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2z"
                />
              </svg>
              <span class="text-xs text-primary-700 md:text-base   font-normal   tracking-wider"
                >{{ title }} {{ this.pagedResult.totalItems ? "(" + this.pagedResult.totalItems + " " : "" }}{{ this.pagedResult.totalItems > 1 ? "Vacutainers)" : this.pagedResult.totalItems == 1 ? "Vacutainer)" : "" }}</span
              >
            </h3>
          </div>
          <div class="col-span-6 flex items-center justify-end space-x-1">
            <span class="font-semibold italic">Filter:</span>
            <select
              @change="filter"
              v-model="vacutainerType"
              placeholder="select vacutainer type"
              :class="['rounded-xl  border border-gray-300 font-semibold focus:border-blue-100', vacutainerType === 'purple-top' ? 'bg-purple-100 text-purple-600' : vacutainerType === 'red-top' ? 'text-red-600 bg-red-100' : 'bg-auto']"
            >
              <option value="" class="bg-white font-semibold">All vacutainers</option>
              <option value="red-top" class="bg-red-100 text-red-600 font-semibold">Screening vacutainers</option>
              <option value="purple-top" class="text-purple-600 bg-purple-100 font-semibold">Grouping vacutainers</option>
            </select>
            <div class="text-gray-500 w-full md:w-1/2 transition duration-300 flex items-center border-gray-300 border focus-within:shadow-md rounded focus-within:border-opacity-25 focus-within:border-primary-300 focus-within:text-gray-900  focus-within:ring-1 focus-within:ring-primary-600">
              <input @input="search" type="search" v-model="q" placeholder="Search..." class="outline-none px-3 w-full placeholder-gray-400 rounded-l py-1  border-0 border-none focus:ring-0 " @keyup.enter="search" />
              <button @click="search" class="p-2 focus:outline-none">
                <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                </svg>
              </button>
            </div>
            <router-link v-if="!!showCustomAction" :to="{ name: customActionName }" class=" px-2 py-2 bg-primary-50 rounded flex items-center text-sm text-primary-500 focus:outline-none space-x-1 hover:bg-primary-400 hover:text-white" :title="createTitle">
              <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="w-4 h-4" viewBox="0 0 384 512">
                <defs />
                <path
                  fill="currentColor"
                  d="M377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-153 31V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm64 160v48c0 4.4-3.6 8-8 8h-56v56c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8v-56h-56c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h56v-56c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v56h56c4.4 0 8 3.6 8 8z"
                />
              </svg>
              <span class="hidden md:block">{{ customActionTitle }}</span>
            </router-link>
            <router-link v-if="!!showCreate" :to="{ name: createName }" class=" px-2 py-2 bg-primary-50 rounded flex items-center text-sm text-primary-500 focus:outline-none space-x-1 hover:bg-primary-400 hover:text-white" :title="createTitle">
              <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="w-4 h-4" viewBox="0 0 384 512">
                <defs />
                <path
                  fill="currentColor"
                  d="M377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-153 31V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm64 160v48c0 4.4-3.6 8-8 8h-56v56c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8v-56h-56c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h56v-56c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v56h56c4.4 0 8 3.6 8 8z"
                />
              </svg>
              <span class="hidden md:block">{{ createTitle }}</span>
            </router-link>
          </div>
        </div>
        <div class="flex justify-between text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b rounded-tl-md rounded-tr-md   bg-gray-50   px-1">
          <div :tableHeadingTitles="tableHeadingTitles" v-for="(tableTitle, index) in tableHeadingTitles" :key="index">
            <div v-html="tableTitle" class="px-4 py-3">
              {{ tableTitle }}
            </div>
          </div>
        </div>
      </div>
      <div class="h-full border-b border-gray-100 ">
        <div v-if="loading" class="flex items-center justify-center">
          <loading-indicator></loading-indicator>
        </div>
        <div v-if="hasList" class="flex-1">
          <div class=" border-b  border-gray-100 divide-y divide-gray-100">
            <div class="">
              <span class="font-semibold space-x-2 items-center py-1 capitalize" v-if="this.$router.currentRoute.query.vacutainerType === 'red-top'">
                <input type="checkbox" v-model="selectAllRedTopVacutainers" :id="`allRedTops`" />
                <span>Select all</span>
              </span>

              <span class="font-semibold space-x-2 items-center py-1 capitalize" v-if="this.$router.currentRoute.query.vacutainerType === 'purple-top'">
                <input type="checkbox" v-model="selectAllPurpleTopVacutainers" :id="`allPurpleTops`" />
                <span>Select all</span>
              </span>
            </div>
            <div v-for="(item, index) in pagedResult.results" :key="item.id" class="flex space-x-2 items-center px-6 py-4 transition-all hover:bg-gray-50">
              <span class="flex-initial">
                <input type="checkbox" @click="addToRedTopList(item.entityGuid, index)" v-model="selectedForScreening" :id="`redTop${index}`" :class="[item.vacutainerType === 'red-top' ? 'visible' : 'invisible']" :value="item.entityGuid" title="Add to red top list" />

                <input type="checkbox" @click="addToPurpleTopList(item.entityGuid, index)" v-model="selectedForGrouping" :id="`purpleTop${index}`" :value="item.entityGuid" :class="[item.vacutainerType === 'purple-top' ? 'visible' : 'invisible']" title="Add to purple top list" />
              </span>
              <span class="flex-auto">
                <slot v-bind:index="index" v-bind:item="item"></slot>
              </span>
            </div>
          </div>
        </div>
        <div v-if="!hasList && !loading" class="h-full  flex items-center justify-center">
          <span class="text-xl text-yellow-600 font-semibold animate-pulse self-center">No records found.</span>
        </div>
      </div>

      <div>
        <div class="px-4  py-4 ">
          <nav v-if="!isEmpty(pagedResult)">
            <ul class="flex pl-0 rounded list-none flex-wrap" v-if="pagedResult.currentPage > 0">
              <li>
                <span v-if="pagedResult.currentPage === 1" class="opacity-50 first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-gray-500 bg-white text-gray-500">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current">
                    <path d="M12.707 7.707l-1.414-1.414L5.586 12l5.707 5.707 1.414-1.414L8.414 12z" />
                    <path d="M16.293 6.293L10.586 12l5.707 5.707 1.414-1.414L13.414 12l4.293-4.293z" />
                  </svg>
                </span>
                <router-link v-else :to="{ query: { page: 1 } }" class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-primary-400 bg-white text-primary-400">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current">
                    <path d="M12.707 7.707l-1.414-1.414L5.586 12l5.707 5.707 1.414-1.414L8.414 12z" />
                    <path d="M16.293 6.293L10.586 12l5.707 5.707 1.414-1.414L13.414 12l4.293-4.293z" />
                  </svg>
                </router-link>
              </li>
              <li>
                <span v-if="pagedResult.currentPage === 1" class="opacity-50 first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-gray-500 bg-white text-gray-500">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current">
                    <path d="M13.293 6.293L7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z" />
                  </svg>
                </span>
                <router-link v-else :to="{ query: { page: pagedResult.currentPage - 1 } }" class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-primary-400 bg-white text-primary-400">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current">
                    <path d="M13.293 6.293L7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z" />
                  </svg>
                </router-link>
              </li>
              <li v-for="page in pagedResult.pages" :key="page">
                <span v-if="page === pagedResult.currentPage" class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-primary-400 bg-primary-400 text-white">{{ page }}</span>
                <router-link v-else :to="{ query: { page: page } }" class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-primary-400 bg-white text-primary-400">{{ page }}</router-link>
              </li>
              <li>
                <span v-if="pagedResult.currentPage === pagedResult.totalPages" class="opacity-50 first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-gray-500 bg-white text-gray-500">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current">
                    <path d="M10.707 17.707L16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z" />
                  </svg>
                </span>
                <router-link v-else :to="{ query: { page: pagedResult.currentPage + 1 } }" class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-primary-400 bg-white text-primary-400">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current">
                    <path d="M10.707 17.707L16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z" />
                  </svg>
                </router-link>
              </li>
              <li>
                <span v-if="pagedResult.currentPage === pagedResult.totalPages" class="opacity-50 first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-gray-500 bg-white text-gray-500">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current">
                    <path d="M10.296 7.71L14.621 12l-4.325 4.29 1.408 1.42L17.461 12l-5.757-5.71z" />
                    <path d="M6.704 6.29L5.296 7.71 9.621 12l-4.325 4.29 1.408 1.42L12.461 12z" />
                  </svg>
                </span>
                <router-link v-else :to="{ query: { page: pagedResult.totalPages } }" class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-primary-400 bg-white text-primary-400">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current">
                    <path d="M10.296 7.71L14.621 12l-4.325 4.29 1.408 1.42L17.461 12l-5.757-5.71z" />
                    <path d="M6.704 6.29L5.296 7.71 9.621 12l-4.325 4.29 1.408 1.42L12.461 12z" />
                  </svg>
                </router-link>
              </li>
            </ul>
          </nav>
          <span v-if="redTopVacutainers && pagedResult">
            <span v-if="pagedResult.results">
              <div class="grid justify-items-end" v-if="redTopVacutainers.vacutainers.length > 0">
                <RouteLink
                  :route="{ name: 'SendScreeningVacutainersToLab', params: { vacutainers: this.redTopVacutainers, donationDriveGuid: $route.params.donationDriveGuid } }"
                  class="flex-auto transition rounded-md text-center py-2 px-2 bg-red-500 flex items-center text-xs text-white font-mono hover:bg-red-400 hover:text-red-900 focus:outline-none transform hover:-translate-y-px hover:scale-110"
                  title="Send for screening"
                >
                  <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                  </svg>
                  <p class="px-2 text-lg font-medium capitalize">Send for Screening</p>
                </RouteLink>
              </div>
            </span>
          </span>
          <span v-if="purpleTopVacutainers && pagedResult">
            <span v-if="pagedResult.results">
              <div class="grid justify-items-end" v-if="purpleTopVacutainers.vacutainers.length > 0">
                <RouteLink
                  :route="{ name: 'SendGroupingVacutainersToLab', params: { vacutainers: this.purpleTopVacutainers, donationDriveGuid: $route.params.donationDriveGuid } }"
                  class="flex-auto transition rounded-md text-center py-2 px-2 bg-purple-500 flex items-center text-xs text-white font-mono hover:bg-purple-400 hover:text-purple-900 focus:outline-none transform hover:-translate-y-px hover:scale-110"
                  title="Send for grouping"
                >
                  <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5l7 7-7 7M5 5l7 7-7 7"></path></svg>
                  <p class="px-2 text-lg font-medium capitalize">Send for grouping</p>
                </RouteLink>
              </div>
            </span>
          </span>
        </div>
        <router-view> </router-view>
      </div>
    </div>
    <lock v-show="!oidcIsAuthenticated" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Lock from "@/components/layout/Lock";
export default {
  props: {
    title: String,
    url: String,
    showCreate: { type: Boolean, default: true },
    showBack: { type: Boolean, default: false },
    createName: { type: String, default: "Create" },
    createTitle: { type: String, default: "New" },
    showCustomAction: { type: Boolean, default: false },
    customActionParams: {
      type: Array,
      default() {
        return [];
      },
    },
    customActionTitle: { type: String, default: "Action" },
    customActionName: { type: String, default: "Action" },
    tableHeadingTitles: {
      Array,
      default: function() {
        return [];
      },
    },
  },
  components: { Lock },
  computed: {
    ...mapGetters(["oidcIsAuthenticated"]),
    hasList() {
      return this.pagedResult?.results?.length > 0;
    },
    selectAllRedTopVacutainers: {
      get: function() {
        return this.pagedResult.results ? this.selectedForScreening.length == this.pagedResult.results.length : false;
      },
      set: function(value) {
        var selectedForScreening = [];

        if (value) {
          this.pagedResult.results.forEach(function(result) {
            selectedForScreening.push(result.entityGuid);
          });
        }

        this.selectedForScreening = selectedForScreening;
        for (let guid of selectedForScreening) {
          this.redTopVacutainers.vacutainers.push({ entityGuid: guid });
        }
        var decider = document.getElementById("allRedTops");
        if (!decider.checked) {
          this.redTopVacutainers.vacutainers = [];
        }
      },
    },
    selectAllPurpleTopVacutainers: {
      get: function() {
        return this.pagedResult.results ? this.selectedForGrouping.length == this.pagedResult.results.length : false;
      },
      set: function(value) {
        var selectedForGrouping = [];

        if (value) {
          this.pagedResult.results.forEach(function(result) {
            selectedForGrouping.push(result.entityGuid);
          });
        }

        this.selectedForGrouping = selectedForGrouping;
        for (let guid of selectedForGrouping) {
          this.purpleTopVacutainers.vacutainers.push({ entityGuid: guid });
        }
        var decider = document.getElementById("allPurpleTops");
        if (!decider.checked) {
          this.purpleTopVacutainers.vacutainers = [];
        }
      },
    },
  },
  data() {
    return {
      loading: false,
      pagedResult: {},
      error: {},
      q: this.$route.query.q,
      toCentrifuge: true,
      redTopVacutainers: {
        vacutainers: [],
      },
      purpleTopVacutainers: {
        vacutainers: [],
      },
      vacutainerType: "",
      selectedForGrouping: [],
      selectedForScreening: [],
    };
  },
  watch: {
    $route: {
      immediate: true,
      async handler() {
        if (this.$route.matched.length === 1 || (this.$route.matched.length > 1 && Object.keys(this.pagedResult).length === 0 && this.pagedResult.constructor === Object)) {
          await this.fetch();
          this.redTopVacutainers.vacutainers = [];
          this.purpleTopVacutainers.vacutainers = [];
        }
      },
    },
  },
  methods: {
    filter() {
      if (this.vacutainerType !== "") {
        this.$router.push({ query: { vacutainerType: this.vacutainerType } });
      } else {
        this.$router.push({ query: {} });
      }
    },
    addToRedTopList(entity_guid, index) {
      let redTopId = `redTop${index}`;
      var decider = document.getElementById(redTopId);
      if (decider.checked) {
        this.redTopVacutainers.vacutainers.push({ entityGuid: entity_guid });
      } else {
        this.redTopVacutainers.vacutainers.pop({ entityGuid: entity_guid });
      }
      if (this.purpleTopVacutainers.vacutainers.length > 0 && this.redTopVacutainers.vacutainers.length > 0) {
        this.purpleTopVacutainers.vacutainers = [];
        this.redTopVacutainers.vacutainers = [];
      }
    },
    addToPurpleTopList(entity_guid, index) {
      let purpleTopId = `purpleTop${index}`;
      var decider = document.getElementById(purpleTopId);
      if (decider.checked) {
        this.purpleTopVacutainers.vacutainers.push({ entityGuid: entity_guid });
      } else {
        this.purpleTopVacutainers.vacutainers.pop({ entityGuid: entity_guid });
      }
      if (this.purpleTopVacutainers.vacutainers.length > 0 && this.redTopVacutainers.vacutainers.length > 0) {
        this.purpleTopVacutainers.vacutainers = [];
        this.redTopVacutainers.vacutainers = [];
      }
    },

    async fetch() {
      this.loading = true;
      this.pagedResult = {};
      //const http = await this.$callApi.get();
      //console.log(http);
      this.$callApi
        .get(this.url, {
          params: { ...this.$route.query },
          //baseURL: "https://localhost:5001",
        })
        .then((x) => {
          this.pagedResult = x.data;
        })
        .catch(() => {
          //this.error = err;
          //console.log(err);
        })
        .then(() => (this.loading = false));
    },
    search() {
      if (this.q.length >= 3) {
        this.$router.push({ query: { q: this.q } });
      } else {
        if (this.$route.query.q !== undefined) {
          this.q = "";
          this.$router.push({ query: {} });
        }
      }
    },
    navigate(queryString) {
      this.$router.push({ path: this.$route.path, query: { ...queryString } });
    },
    isEmpty(obj) {
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    },
    back() {
      this.$router.back();
    },
  },
};
</script>
