export default [
  {
    path: "/administration/users",
    name: "ManageHandles",
    component: () =>
      import(
        /* webpackChunkName: "admin-group" */
        "@/views/admin/UsersList.vue"
      ),
    children: [
      {
        path: "create-user",
        name: "AddUser",
        component: () =>
          import(
            /* webpackChunkName: "admin-group" */
            "@/components/data/SchemaModal.vue"
          ),
        props: () => ({
          toFetch: false,
          title: "Add User ",
          postUrl: "identity/managehandles",
          optionsUrls: ["identity/designations/all", "identity/idplookups/departments/options"],
          jsonSchema: "identity/idpforms/handles",
          size: "max-w-2xl",
          verb: "POST",
        }),
      },
      {
        path: "edit/:id",
        title: "Update User Account ",
        name: "UpdateUser",
        component: () =>
          import(
            /* webpackChunkName: "admin-group" */
            "@/components/data/SchemaModal.vue"
          ),
        props: (x) => ({
          title: "Update User Account ",
          fetchUrl: `identity/managehandles/${x.params.id}`,
          postUrl: `identity/managehandles/${x.params.id}`,
          optionsUrls: ["identity/designations/all", "identity/idplookups/departments/options"],
          jsonSchema: "identity/idpforms/handles",
          size: "max-w-4xl",
        }),
      },
      {
        path: "transfer/:id/:accountName",
        name: "TransferUser",
        component: () =>
          import(
            /* webpackChunkName: "admin-group" */
            "@/views/admin/Transfer.vue"
          ),
        props: (x) => ({
          title: `Transfer ${x.params.accountName}'s Account?`,
          postUrl: `identity/managehandles/${x.params.id}/transfer`,
        }),
      },
      {
        path: "unlock-confirm/:accountId/:accountName",
        name: "UnlockIdentityConfirmation",
        component: () =>
          import(
            /* webpackChunkName: "Transfusion" */
            "@/components/data/ConfirmPostModal.vue"
          ),
        props: (x) => ({
          verb: "POST",
          title: `Unlock ${x.params.accountName}`,
          postUrl: `identity/managehandles/${x.params.accountId}/unlock`,
          size: "max-w-xs",
        }),
      },
      {
        path: "disable-confirm/:accountId/:accountName",
        name: "DisableIdentityConfirmation",
        component: () =>
          import(
            /* webpackChunkName: "Transfusion" */
            "@/components/data/ConfirmPostModal.vue"
          ),
        props: (x) => ({
          verb: "POST",
          title: `Disable ${x.params.accountName}`,
          postUrl: `identity/managehandles/${x.params.accountId}/disable`,
          size: "max-w-xs",
        }),
      },
      {
        path: "reset-confirm/:accountId/:accountName",
        name: "ResetIdentityConfirmation",
        component: () =>
          import(
            /* webpackChunkName: "Transfusion" */
            "@/components/data/ConfirmPostModal.vue"
          ),
        props: (x) => ({
          verb: "POST",
          title: `Reset ${x.params.accountName}`,
          postUrl: `identity/managehandles/${x.params.accountId}/reset`,
          size: "max-w-xs",
        }),
      },
      {
        path: "delete/:id",
        title: "Delete Form",
        name: "DeleteUserAccount",
        component: () =>
          import(
            /* webpackChunkName: "doctors-pool-group" */
            "@/components/data/DeleteModal.vue"
          ),
        props: (x) => ({
          title: "Delete Form ",
          deleteUrl: `identity/managehandles/${x.params.id}`,
          size: "max-w-xs",
          name: "Delete Form",
        }),
      },
    ],
  },
  {
    path: "/administration/permissions",
    name: "Permissions",
    component: () =>
      import(
        /* webpackChunkName: "permissions-group" */
        "@/views/admin/permissions/GroupsList.vue"
      ),
    children: [
      {
        path: "create",
        name: "AddPermission",
        component: () =>
          import(
            /* webpackChunkName: "permissions-group" */
            "@/components/data/SchemaModal.vue"
          ),
        props: () => ({
          toFetch: false,
          title: "Add Permission ",
          postUrl: "identity/permissions",
          jsonSchema: "identity/idpforms/permissions",
          size: "max-w-xs",
        }),
      },
      {
        path: "edit/:id",
        name: "UpdatePermission",
        component: () =>
          import(
            /* webpackChunkName: "permissions-group" */
            "@/components/data/SchemaModal.vue"
          ),
        props: (x) => ({
          title: "Update Permission ",
          postUrl: `identity/permissions/${x.params.id}`,
          jsonSchema: "identity/idpforms/permissions",
          size: "max-w-xs",
        }),
      },
      {
        path: "delete/:id",
        name: "DeletePermission",
        component: () =>
          import(
            /* webpackChunkName: "permissions-group" */
            "@/components/data/DeleteModal.vue"
          ),
        props: (x) => ({
          title: "Delete Permission ",
          deleteUrl: `identity/permissions/${x.params.id}`,
          size: "max-w-xs",
          name: "Service Area",
        }),
      },
    ],
  },
  {
    path: "/administration/permissions/:groupid/children",
    name: "PermissionChildren",
    meta: { breadcrumb: { parent: "Permissions" } },
    component: () =>
      import(
        /* webpackChunkName: "permission-children" */
        "@/views/admin/permissions/OptionsList.vue"
      ),
    children: [
      {
        path: "create",
        name: "CreatePermissionChild",
        component: () =>
          import(
            /* webpackChunkName: "permission-children" */
            "@/components/data/SchemaModal.vue"
          ),
        props: (x) => ({
          toFetch: false,
          title: "Add Permission Child",
          verb: "POST",
          postUrl: `identity/permissions/${x.params.groupid}`,
          optionsUrls: [],
          jsonSchema: "identity/idpforms/permissions",
          size: "max-w-xs",
        }),
      },
      {
        path: "edit/:id",
        title: "Edit Permission Child",
        name: "EditPermissionChild",
        component: () =>
          import(
            /* webpackChunkName: "permission-children" */
            "@/components/data/SchemaModal.vue"
          ),
        props: (x) => ({
          title: "Amend Permission Child ",
          verb: "PUT",
          postUrl: `identity/permissions/${x.params.id}`,
          optionsUrls: [],
          jsonSchema: "identity/idpforms/permissions",
          size: "max-w-xs",
        }),
      },
      {
        path: "delete/:id",
        title: "Delete Permission Child",
        name: "DeletePermissionChild",
        component: () =>
          import(
            /* webpackChunkName: "permission-children" */
            "@/components/data/DeleteModal.vue"
          ),
        props: (x) => ({
          title: "Remove Permission Child ",
          deleteUrl: `identity/permissions/${x.params.id}`,
          size: "max-w-xs",
          name: "Permission Child",
        }),
      },
    ],
  },
  // Clients
  {
    path: "/administration/manage-clients",
    name: "IdpClients",
    component: () =>
      import(
        /* webpackChunkName: "permissions-group" */
        "@/views/admin/Clients.vue"
      ),
    children: [
      {
        path: "add",
        name: "AddClient",
        component: () =>
          import(
            /* webpackChunkName: "permissions-group" */
            "@/components/data/SchemaModal.vue"
            // "@/components/data/JsonModal.vue"
          ),
        props: () => ({
          toFetch: false,
          title: "Add Client ",
          postUrl: "identity/idpclients",
          jsonSchema: "identity/idpforms/add-client",
          moduleName: "administration",
          moduleSection: "manageIdentityClient",
          // jsonSchema: "schemas/administration/Forms/add-client.json",
          size: "max-w-2xl",
        }),
      },
      {
        path: "edit/:id",
        name: "UpdateClient",
        component: () =>
          import(
            /* webpackChunkName: "permissions-group" */
            "@/components/data/SchemaModal.vue"
          ),
        props: (x) => ({
          title: "Update Client ",
          moduleName: "administration",
          moduleSection: "manageIdentityClient",
          postUrl: `identity/idpclients/${x.params.id}`,
          jsonSchema: "identity/idpforms/add-client",
          size: "max-w-2xl",
        }),
      },
      {
        path: "delete/:id",
        name: "DeleteClient",
        component: () =>
          import(
            /* webpackChunkName: "permissions-group" */
            "@/components/data/DeleteModal.vue"
          ),
        props: (x) => ({
          title: "Delete Client ",
          deleteUrl: `identity/idpclients/${x.params.id}`,
          size: "max-w-xs",
          name: "",
        }),
      },
    ],
  },
  // Designations
  {
    path: "/administration/designations",
    name: "Designations",
    component: () =>
      import(
        /* webpackChunkName: "designations-group" */
        "@/views/admin/designations/List.vue"
      ),
    children: [
      {
        path: "create",
        name: "AddDesignation",
        component: () =>
          import(
            /* webpackChunkName: "designations-group" */
            "@/views/admin/designations/Edit.vue"
          ),
        props: () => ({
          toFetch: true,
          title: "Add Designation ",
          postUrl: "identity/designations",
          fetchUrl: "identity/permissions/all",
          size: "max-w-xs",
          verb: "POST",
        }),
      },
      {
        path: "edit/:id",
        name: "UpdateDesignation",
        component: () =>
          import(
            /* webpackChunkName: "designations-group" */
            "@/views/admin/designations/Edit.vue"
          ),
        props: (x) => ({
          title: "Update Designation ",
          postUrl: `identity/designations/${x.params.id}`,
          size: "max-w-xs",
        }),
      },
      {
        path: "delete/:id",
        name: "DeleteDesignation",
        component: () =>
          import(
            /* webpackChunkName: "designations-group" */
            "@/components/data/DeleteModal.vue"
          ),
        props: (x) => ({
          title: "Delete Designation ",
          deleteUrl: `identity/designations/${x.params.id}`,
          size: "max-w-xs",
          name: "Service Area",
        }),
      },
    ],
  },
  {
    path: "/administration/identity-forms",
    name: "IdpForms",
    component: () =>
      import(
        /* webpackChunkName: "admin-group" */
        "@/views/admin/FormList.vue"
      ),
    children: [
      {
        path: "create",
        name: "AddIdpForm",
        component: () =>
          import(
            /* webpackChunkName: "admin-group" */
            "@/components/data/JsonFormx.vue"
          ),
        props: () => ({
          toFetch: false,
          title: "Add Form ",
          postUrl: "identity/idpforms",
        }),
      },
      {
        path: "edit/:id",
        title: "Update Form",
        name: "UpdateIdpForm",
        component: () =>
          import(
            /* webpackChunkName: "admin-group" */
            "@/components/data/JsonFormx.vue"
          ),
        props: (x) => ({
          title: "Update Form ",
          postUrl: `identity/idpforms/${x.params.id}`,
        }),
      },
      {
        path: "delete/:id",
        title: "Delete Form",
        name: "DeleteIdpForm",
        component: () =>
          import(
            /* webpackChunkName: "admin-group" */
            "@/components/data/DeleteModal.vue"
          ),
        props: (x) => ({
          title: "Delete Form ",
          deleteUrl: `identity/idpforms/${x.params.id}`,
          size: "max-w-xs",
          name: "Delete Form",
        }),
      },
    ],
  },
  {
    path: "/administration/identity/identity-optionsgroups",
    name: "IdpLookups",
    component: () =>
      import(
        /* webpackChunkName: "optionsgroups" */
        "@/views/admin/lookups/Groups.vue"
      ),
    children: [
      {
        path: "create",
        name: "CreateIdpOptionsGroup",
        component: () =>
          import(
            /* webpackChunkName: "optionsgroups" */
            "@/components/data/SchemaModal.vue"
          ),
        props: () => ({
          verb: "POST",
          title: "Add Options Group ",
          postUrl: "identity/idplookups/0",
          optionsUrls: [],
          jsonSchema: "identity/idpforms/options-groups",
          size: "max-w-xs",
          toFetch: false,
        }),
      },
      {
        path: "edit/:id",
        name: "AmendIdpOptionsGroup",
        component: () =>
          import(
            /* webpackChunkName: "optionsgroups" */
            "@/components/data/SchemaModal.vue"
          ),
        props: (x) => ({
          title: "Edit Options Group ",
          postUrl: `identity/idplookups/${x.params.id}`,
          optionsUrls: [],
          jsonSchema: "identity/idpforms/options-groups",
          size: "max-w-xs",
        }),
      },
    ],
  },
  {
    path: "/administration/identity/identity-optionsgroups/:groupid/options",
    name: "IdpOptions",
    meta: { breadcrumb: { parent: "IdpLookups" } },
    props: (x) => ({
      title: x.params.title,
    }),
    component: () =>
      import(
        /* webpackChunkName: "optionsgroups" */
        "@/views/admin/lookups/Options.vue"
      ),
    children: [
      {
        path: "create",
        name: "CreateIdpOption",
        component: () =>
          import(
            /* webpackChunkName: "optionsgroups" */
            "@/components/data/SchemaModal.vue"
          ),
        props: (x) => ({
          toFetch: false,
          title: "Add Option",
          verb: "POST",
          postUrl: `identity/idplookups/${x.params.groupid}`,
          optionsUrls: [],
          jsonSchema: "identity/idpforms/options",
          size: "max-w-xs",
        }),
      },
      {
        path: "edit/:id",
        title: "Edit Option ",
        name: "AmendIdpOption",
        component: () =>
          import(
            /* webpackChunkName: "optionsgroups" */
            "@/components/data/SchemaModal.vue"
          ),
        props: (x) => ({
          title: "Amend Option ",
          verb: "PUT",
          postUrl: `identity/idplookups/${x.params.id}`,
          optionsUrls: [],
          jsonSchema: "identity/idpforms/options",
          size: "max-w-xs",
        }),
      },
    ],
  },
  {
    path: "/administration/facilities",
    name: "Facilities",
    component: () =>
      import(
        /* webpackChunkName: "facilities-group" */
        "@/views/admin/facilities/FacilitiesList.vue"
      ),
    children: [
      {
        path: ":id/assign-modules",
        name: "AssignModules",
        component: () =>
          import(
            /* webpackChunkName: "designations-group" */
            "@/components/data/SchemaModal.vue"
          ),
        props: (x) => ({
          title: "Assign Facility Modules",
          verb: "PUT",
          postUrl: `identity/facilities/${x.params.id}`,
          optionsUrls: ["identity/permissions/options"],
          jsonSchema: "identity/idpforms/assign-facility-modules",
          size: "max-w-md",
        }),
      },
    ],
  },
  // manage admins
  {
    path: "/administration/facilities/:groupid/:facilityName/:facilityCode/manage-admins",
    meta: { breadcrumb: { parent: "Facilities" } },
    name: "ManageFacilityAdmins",
    component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/admin/facilities/ManageAdmins.vue"),
    props: (x) => {
      let propData = {
        title: "Update contact person",
        liaisonName: x.params.liaison,
        size: "max-w-4xl",
        extraFields: { liaisonId: x.params.liaisonId },
      };
      propData.title = `${x.params.liaisonName} Liason`;
      return propData;
    },
    children: [
      {
        path: "add-facility-admin",
        name: "AddFacilityAdmin",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/SchemaModalWithExtraFields.vue"),
        props: (x) => ({
          toFetch: false,
          verb: "POST",
          postUrl: `identity/managehandles/${x.params.facilityCode}`,
          jsonSchema: `identity/idpforms/admin`,
          size: "max-w-xl",
          title: `Add '${x.params.facilityName}' Admin`,
          extraFields: { facilityCode: x.params.facilityCode },
        }),
      },
      {
        path: ":adminId/:adminName/update-facility-admin",
        title: "Update Facility Admin",
        name: "UpdateFacilityAdmin",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/SchemaModalWithExtraFields.vue"),
        props: (x) => ({
          verb: "PUT",
          fetchUrl: `identity/managehandles/${x.params.adminId}`,
          postUrl: `identity/managehandles/${x.params.adminId}/admin`,
          jsonSchema: `identity/idpforms/admin`,
          size: "max-w-4xl",
          extraFields: { facilityCode: x.params.facilityCode },
          title: `Update '${x.params.adminName}' details`,
        }),
      },
      {
        path: ":/adminId/:adminName/delete",
        title: "Delete facility admin",
        name: "DeleteFacilityAdminContact",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/DeleteModal.vue"),
        props: (x) => ({
          postUrl: "",
          verb: "DELETE",
          deleteUrl: `identity/managehandles/${x.params.adminId}`,
          options: [],
          size: "max-w-xl",
          title: `Delete '${x.params.adminName}' from '${x.params.facilityName}'?`,
        }),
      },
    ],
  },
];
