<template>
  <div>
    <button aria-haspopup="true" aria-label="Notifications" class="text-white hover:text-white hover:bg-secondary-600 transition p-2 relative align-middle rounded-full  focus:outline-none focus:shadow-outline-purple" @click="toggleNotificationsMenu" @keydown.escape="closeNotificationsMenu">
      <svg aria-hidden="true" class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z"></path>
      </svg>
      <!-- Notification badge -->
      <span aria-hidden="true" class="absolute top-2 left-4 inline-block w-3 h-3 transform translate-x-1 -translate-y-1 bg-primary-600 border-2 border-white rounded-full dark:border-gray-800"></span>
    </button>

    <div v-if="isNotificationsMenuOpen">
      <ul
        v-on-clickaway="closeNotificationsMenu"
        x-transition:leave="transition ease-in duration-150"
        x-transition:leave-start="opacity-100"
        x-transition:leave-end="opacity-0"
        @keydown.escape="closeNotificationsMenu"
        class="absolute right-0 w-56 p-2 mt-2 md:mt-9 space-y-2 text-gray-600 bg-white border border-gray-100 rounded-md shadow-md dark:text-gray-300 dark:border-gray-700 dark:bg-gray-700"
        aria-label="submenu"
      >
        <li class="flex">
          <a class="inline-flex items-center justify-between w-full px-2 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200" href="#">
            <router-link
              :to="{ name: 'Home' }"
              class="transition shadow-sm rounded-md py-1 px-2 bg-blue-50 flex items-center text-xs text-blue-500 hover:bg-blue-100 hover:text-blue-700 focus:outline-none transform hover:-translate-y-px hover:scale-110 pr-20"
            >
              <p class="px-2 text-xs font-medium">
                Coming Soon
              </p>
             
            </router-link>
          </a>
        </li>
        <li class="flex">
          <a class="inline-flex items-center justify-between w-full px-2 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200" href="#">
            <router-link
              :to="{ name: 'Home' }"
              class="transition shadow-sm rounded-md py-1 px-2 bg-blue-50 flex items-center text-xs text-blue-500 hover:bg-blue-100 hover:text-blue-700 focus:outline-none transform hover:-translate-y-px hover:scale-110 pr-20"
            >
              <p class="px-2 text-xs font-medium">Coming Soon</p>
            </router-link>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mixin as clickaway } from "vue-clickaway";
export default {
  name: "user-notifications",
  mixins: [clickaway],
  data() {
    return {
      isNotificationsMenuOpen: false,
    };
  },
  methods: {
    closeNotificationsMenu() {
      this.isNotificationsMenuOpen = false;
    },
    toggleNotificationsMenu() {
      this.isNotificationsMenuOpen = !this.isNotificationsMenuOpen;
    },
  },
};
</script>
