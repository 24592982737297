<template>
  <svg xmlns="http://www.w3.org/2000/svg" :id="`client${id}`"></svg>
</template>
<script>
import jsBarcode from "jsbarcode";
export default {
  name: "bar-code",
  props: {
    id: { type: [Number, String], required: true },
    text: { String, default: undefined },
  },
  mounted() {
    this.generate();
  },
  methods: {
    generate() {
      const text = this.text === undefined ? "MOH " + this.id : this.text;

      jsBarcode(document.getElementById("client" + this.id), text, {
        lineColor: "rgba(21, 26, 183,1)",
        height: 30,
        displayValue: true,
        fontSize: 12,
        width: 1,
        margin: 5,
      });
    },
  },
};
</script>
