<template>
  <vue-frappe
    v-if="labels.length > 0"
    id="barcharty"
    title="Latest Donations"
    type="bar"
    :height="300"
    :axisOptions="{ xAxisMode: 'tick' }"
    :labels="labels"
    :colors="['#AFB0B2', '#E91802']"
    :discreteDomains="false"
    :dataSets="data"
    :tooltipOptions="{ formatTooltipY: (d) => d + ' donations' }"
  ></vue-frappe>
</template>

<script>
import { VueFrappe } from "vue-frappe";
export default {
  name: "LatestDonations",
  components: { VueFrappe },
  data: () => ({
    labels: [],
    data: [],
  }),
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      this.loading = true;
      this.$callApi
        .get("donation/donation-reports/recent-week-donation-report")
        .then((x) => {
          this.labels = [...x.data.map((y) => y.day)];
          this.data = [
            { name: "Deferred", values: [...x.data.map((y) => y.diferredDonations)] },
            { name: "Successful", values: [...x.data.map((y) => y.successfulDonations)] },
          ];
        })
        .catch(() => {
          //this.error = err;
          //console.log(err);
        })
        .then(() => (this.loading = false));
    },
  },
};
</script>
