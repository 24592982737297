<template>
  <transition leave-active-class="animate-flip-out" name="custom">
    <div ref="modal" class="fixed   inset-0 z-50 overflow-x-hidden  overflow-y-auto outline-none focus:outline-none pt-10 pb-12 pr-17">
      <div :class="[' flex items-center justify-center w-full']">
        <div :class="['h-full min-h-60 grid grid-rows-layout  animate-scale-in-center border-0 rounded-sm elevation-16 w-full  bg-white outline-none focus:outline-none', size]">
          <div class="px-6 py-4">
            <div class="border-b border-gray-200 mb-2 flex items-center justify-between text-red-500">
              <h3 class="border-b border-red-600 py-3  space-x-1 flex items-center text-red-500 text-sm leading-snug font-bold -mb-px uppercase tracking-wider">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 512 512">
                  <title>Create</title>
                  <path d="M384 224v184a40 40 0 01-40 40H104a40 40 0 01-40-40V168a40 40 0 0140-40h167.48" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" />
                  <path
                    d="M459.94 53.25a16.06 16.06 0 00-23.22-.56L424.35 65a8 8 0 000 11.31l11.34 11.32a8 8 0 0011.34 0l12.06-12c6.1-6.09 6.67-16.01.85-22.38zM399.34 90L218.82 270.2a9 9 0 00-2.31 3.93L208.16 299a3.91 3.91 0 004.86 4.86l24.85-8.35a9 9 0 003.93-2.31L422 112.66a9 9 0 000-12.66l-9.95-10a9 9 0 00-12.71 0z"
                  />
                </svg>
                <span class="">{{ $removeHyphens(title) }}</span>
              </h3>
              <button class="text-6xl hover:bg-gray-300 p-2 hover:text-red-600 rounded-full bg-transparent outline-none focus:outline-none flex items-center justify-center" type="button" v-on:click="back()">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 512 512">
                  <title>Close</title>
                  <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M368 368L144 144M368 144L144 368" />
                </svg>
              </button>
            </div>
          </div>
          <div class=" h-full px-6 flex flex-col">
            <div class="h-full">
              <FormulateForm v-model="form" class="h-full"  autocomplete="off" :errors="errors" :form-errors="serverError" @keydown.enter.prevent="submitForm()">
                <div v-if="loading" class="flex items-center justify-center">
                  <loading-indicator></loading-indicator>
                </div>
                <search-list-modal title="Components" class="h-full" :url="fetchUrl" :showCreate="false">
                  <template v-slot="{ item }">
                    <div class="grid grid-cols-12">
                      <component-item :item="item" class="col-span-10"  />

                      <FormulateInput
                        :name="String(item.id)"
                        :options="[{ value: item.id, label: checkBoxLabel }]"
                        type="checkbox"
                        @input="storeObject(item)"
                        :outer-class="() => 'mb-0 col-span-2 px-4 py-2 bg-red-100 flex items-center text-sm'"
                        :value="getKeysInSelectedBloodComponents.indexOf(item.id.toString()) !== -1 ? [String(item.id)] : undefined"
                      />
                    </div>
                  </template>
                </search-list-modal>
              </FormulateForm>

            </div>
              <div class="h-full" v-if="getSelectedBloodComponentsLength > 0">
                  <div class="grid grid-cols-12 gap-2 md:gap-4 items-center px-2 pb-4">
                      <div class="col-span-5 md:col-span-4">
                        <h3 class="border-b-2 border-red-300 py-4 space-x-2 inline-flex items-center text-red-300 ">
                  
                          <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 md:w-5 md:h-5" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M1 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2zM1 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7zM1 12a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2z" />
                          </svg>
                          <span class="text-xs text-red-800 md:text-base   font-normal  uppercase tracking-wider">Selected Components ({{ getSelectedBloodComponentsLength }})</span>
                        </h3>
                      </div>
                    </div>
   
                
                <div>
                  <div class="flex -b-2 hover:bg-gray-50" v-for="(component, componentIndex) in getSelectedBloodComponents" :key="componentIndex">
                    <span class="flex-auto p-2">
                      <component-item  :item="component" class="col-span-10"/>



                    </span>
                    <span class="flex-auto flex justify-center text-red-500">
                      <button @click="removeObject(component.id)" type="button">Remove</button>
                    </span>
                  </div>
                
                </div> 
             

       
              </div>
             
           

            <div class="py-4"  v-if="getSelectedBloodComponentsLength > 0">
              <div class="rounded-sm text-xs px-4 py-3 text-red-700 bg-red-100 border border-red-300" v-if="serverError.length > 0">
                {{serverError}}
              </div>
              <div class="flex items-end justify-end border-t border-metal-50 rounded-b px-6">
                <div class="py-4">
                  <button
                    type="button"
                    v-bind:disabled="isLoading"
                    :class="[
                      'flex items-center justify-center transition rounded-sm  text-white   bg-red-500 font-bold uppercase px-4 py-2 text-sm outline-none focus:outline-none space-x-1 space-y-1',
                      isLoading ? 'opacity-75 cursor-not-allowed' : 'hover:bg-red-700',
                    ]"
                    @click="submitForm"
                  >
                    <loading-indicator v-show="isLoading"></loading-indicator>
                    <span v-if="isLoading" class="">Submitting...</span>
                    <span v-else class="">Submit</span>
                  </button>
                </div>
              </div>

          </div>
         

            <router-view />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// import ComponentItem from "../../views/bloodBank/components/ComponentItem.vue";
// import SearchListModal from "../../views/bloodBank/components/SearchListModal.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  // components: { ComponentItem, SearchListModal },
  name: "form-modal",
  computed: {
    ...mapGetters(["getSelectedBloodComponents", "getSelectedBloodComponentsLength", "getKeysInSelectedBloodComponents"]),
    animation() {
      return this.wobble ? "animate-wobble" : "";
    },
  },

  props: {
    size: {
      type: String,
      default: "max-w-sm",
    },
    submitText: {
      type: String,
      default: "Save",
    },
    title: {
      type: String,
      default: "Sample",
    },
    postUrl: {
      type: String,
      default: "",
    },
    fetchUrl: {
      type: String,
      default: "",
    },
    fetchPanelUrl: {
      type: String,
      default: "",
    },
    verb: {
      type: String,
      default: "",
    },
    toFetch: { type: Boolean, default: true },
    optionsUrls: { type: Array, default: () => [] },
    jsonSchema: { type: String, required: false },
    backSteps: { type: Number, default: 0 },
    backRouterName: String,
    checkBoxLabel: {type: String, default:  "Mark for Assignment"}
  },
  data() {
    return {
      wobble: false,
      loading: false,
      isLoading: false,
      form: {},
      formData: new FormData(),
      errors: {},
      serverError: [],
      show: true,
      optionsArray: [],
      schema: [],
      checkBoxData: {},
    };
  },
  mounted() {
    const body = document.body;
    body.classList.remove("overflow-y-scroll");
    body.classList.add("overflow-hidden", "pr-scrollbar");
    const elemDiv = document.createElement("div");
    elemDiv.id = "backdrop";
    elemDiv.classList.add("fixed", "inset-0", "z-40", "bg-modal");
    body.appendChild(elemDiv);
  },
  methods: {
    ...mapActions(["resetSelectedBloodComponents", "addBloodComponent", "removeBloodComponent"]),
    storeObject(item) {
      if (this.form[item.id].length > 0) {
        this.addBloodComponent(item);

        this.$router.push({ query: {} }).then(() => {});
      } else if (this.form[item.id].length === 0) {
        this.removeObject(item.id);
        this.$router.push({ query: {} }).then(() => {});
      }
    },
    removeObject(itemId) {
      this.removeBloodComponent(itemId);

      let formCopy = this.form;
      formCopy[itemId] = [];
      this.form = Object.assign({}, formCopy);
      // this.$router.push({ query: { q: this.q } }).then(() => {});
    },
    submitForm() {
      this.uploading = true;
      this.isLoading = true;
      this.serverError = [];
      this.errors = {};
      this.wobble = false;
      delete this.form.terms;
      let hasUpload = "file" in this.form === true;
      if (hasUpload) {
        if (this.form.file.files.length > 0) {
          this.formData.append("file", this.form.file.files[0].file);
          for (var key in this.form) {
            this.formData.append(key, this.form[key]);
          }
        }
      }

      let data = [];
      for (let item in this.getSelectedBloodComponents) {
        data.push({ itemId: Number(this.getSelectedBloodComponents[item].id) });
      }

      this.$callApi({
        method: this.verb !== "" ? this.verb : this.toFetch ? "PUT" : "POST",
        url: this.postUrl,
        data: hasUpload ? this.formData : { itemIds: data },
      })
        .then((x) => {
          if ("message" in x.data) {
            this.$toast.success({
              title: "Success",
              message: x.data.message,
            });
          }
          this.back();
          // setTimeout(location.reload.bind(location), 2000);
        })
        .catch((err) => {
          const resp = err.response;
          if (resp.status === 500) {
            this.serverError = [resp.data.title];
          }
          if (resp.status === 400) {
            if ("generic" in resp.data) {
              this.serverError = resp.data["generic"];
            } else {
              this.errors = resp.data.errors;
              this.serverError = [resp.data.title];
            }
          }
          this.wobble = true;
        })
        .then(() => (this.isLoading = false));
    },
    back() {
      if (this.backRouterName) {
        this.$router.push({ name: this.backRouterName });
      } else {
        if (this.backSteps !== 0) {
          this.$router.go(this.backSteps - this.backSteps * 2);
        } else {
          this.$router.back();
        }
      }
    },
  },
  beforeDestroy() {
    this.resetSelectedBloodComponents();
    this.$refs.modal.classList.add("overflow-hidden");
    const body = document.body;
    const backdrop = document.getElementById("backdrop");
    body.classList.remove("overflow-hidden", "pr-scrollbar");
    body.classList.add("overflow-y-scroll");
    body.removeChild(backdrop);
  },
};
</script>
