<template>
  <router-link :to="{ name: name, params: { id: param } }" class="transition shadow-sm rounded-full p-2 flex items-center text-xs text-red-500 hover:bg-red-100 hover:text-red-700 focus:outline-none transform hover:-translate-y-px hover:scale-110" custom :title="title">
    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 fill-current">
      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
      <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
    </svg>
  </router-link>
</template>
<script>
export default {
  name: "delete-link",
  props: {
    name: String,
    caption: { String, default: "Delete" },
    param: Number,
    title: { type: String, default: "Delete" },
  },
};
</script>
