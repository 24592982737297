<template>
  <div :class="bg">
    <svg xmlns="http://www.w3.org/2000/svg" class="animate-spin h-8 w-8" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <path d="M21 50a29 29 0 0058 0 29 33.5 0 01-58 0" fill="currentColor"></path>
    </svg>
  </div>
</template>
<script>
export default {
  name: "loading-indicator",
  props: {
    bg: { Type: String, default: "text-primary-400" },
  },
};
</script>
