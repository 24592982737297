<template>
  <v-jsoneditor v-model="context.model" :class="[context.classes.element]" height="45vh" :data-type="context.type" @blur="context.blurHandler" v-bind="context.attributes" :options="options" :plus="false" v-on="$listeners" @error="context.blurHandler"></v-jsoneditor>
</template>
<script>
import VJsoneditor from "v-jsoneditor";
export default {
  name: "json-editor",
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      options: {
        mode: "code",
      },
    };
  },
  components: {
    "v-jsoneditor": VJsoneditor,
  },
};
</script>
