<template>
  <div class="">
    <div ref="tooltip" class="absolute bg-white border-gray-100 border  opacity-0 shadow-xl transition rounded" role="alert">
      <div class=" space-y-1">
      <p class="font-medium uppercase text-primary text-xs tracking-wider bg-gray-100 px-4 py-2">{{ selectedCounty }} county</p>
      <p class="px-4 py-2 flex items-center space-x-1 text-sm">
        <span class="text-primary font-medium">{{$formatNumber(donations||0)}}</span> 
        <span class="text-gray-500">donations</span></p>
      </div>
    </div>
    <h4 class="px-4 text-center text-xs md:text-sm font-medium leading-none text-gray-500 uppercase">County Donations</h4>
    <svg ref="svg"></svg>
  </div>
</template>

<script>
import { select, geoPath, geoMercator, zoom } from "d3";
import { feature } from "topojson";
export default {
  data: () => ({
    optionsUrls: ["identity/geojson", "donation/donation-reports/donations-by-county"],
    selectedCounty: null,
    donations: null,
  }),
  mounted() {
    this.createMap();
  },
  methods: {
    createMap() {
      const width = 1080,
        height = 1050;
      const projection = geoMercator()
        .center([36.8, -1.3])
        .scale(1000 * 4.5)
        .translate([width / 2, height / 2]);
      const svg = select(this.$refs.svg);

      svg.attr("viewBox", `0 0 ${width} ${height}`).attr("preserveAspectRatio", "none");
      const g = svg.append("g");
      const pathGenerator = geoPath().projection(projection);
      window.Promise.all([...this.optionsUrls.map((x) => this.$callApi.get(x))])
        .then((x) => {
          const countyData = feature(x[0].data, x[0].data.objects.kenya).features;
          countyData.forEach((d) => {
            if (d.properties["COUNTY_NAM"]) {
              d.properties["donation"] = x[1].data.find((donation) => donation?.county?.toLowerCase() === d.properties["COUNTY_NAM"]?.replace(" - "," ")?.replace("-"," ")?.toLowerCase())?.successfulDonations || 0;
            }
          });
          g.selectAll("path")
            .data(countyData)
            .enter()
            .append("path")
            .attr("class", this.styleOnHover)
            .attr("d", pathGenerator)
            .on("mouseover",this.mouseOver)
            .on("mouseout", this.mouseOut);
        })
        .catch((err) => console.log(err));
      //this.zoomMap(g);
    },
    zoomMap(g) {
      select(this.$refs.svg).call(
        zoom().on("zoom", (e) => {
          g.attr("transform", e.transform);
        })
      );
    },
    styleOnHover(d) {
      const commonStyles = "transition stroke-white stroke-width-[0.05px] hover:cursor-pointer ";
      if (d.properties.donation > 0) {
        return `${commonStyles} fill-primary-500/60 hover:fill-primary-500`;
      } else {
        return `${commonStyles} fill-gray-300 hover:fill-gray-500`;
      }
    },
    mouseOver(e, d) {
      if (d.properties.COUNTY_NAM) {
        const countyName = d.properties.COUNTY_NAM;
        this.selectedCounty = `${countyName.substring(0, 1)}${countyName.substring(1).toLowerCase()}`;
        this.donations = `${d.properties.donation}`;
      }
      select(this.$refs.tooltip)
        .classed("opacity-0", false)
        .style("left", `${e.pageX + 10}px`)
        .style("top", `${e.pageY - 15}px`);
    },
    mouseOut(e) {
      this.selectedCounty = null;
      this.donations = null;
      select(e.target)
         .classed("opacity-0", false);
      select(this.$refs.tooltip)
        .classed("opacity-0", true);
    },
  },
};
</script>
