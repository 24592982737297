<template>
  <div class=" bg-gray-50">
    <div class="">
      <dashboard v-if="oidcIsAuthenticated" />
      <hero v-else />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import hero from "@/views/home/Hero";
import dashboard from "@/views/home/Dashboard";
export default {
  name: "home-view",
  computed: {
    ...mapGetters(["oidcIsAuthenticated", "oidcUser"]),
  },
  components: {
    hero,
    dashboard,
  },
};
</script>
