import autosuggest from "@/components/buttons/Suggest.vue";
import autosuggestmultiple from "@/components/buttons/SuggestMultiple.vue";
import FacilitiesAutoSuggest from "@/components/buttons/FacilitiesAutoSuggest.vue";
import autosuggestmultiplereduced from "@/components/buttons/SuggestMultipleReduced.vue";
import picker from "@/components/custom/DatePicker.vue";
import donation_picker from "@/components/custom/DonationDatePicker.vue";
import new_picker from "@/components/custom/NewDatePicker.vue";
import dropdown from "@/components/custom/Dropdown.vue";
import jsonEditor from "@/components/custom/JsonEditor.vue";
import editor from "@/components/custom/Editor";
import BirthPicker from "@/components/custom/BirthPickerx";
import BirthDate from "@/components/custom/BirthDate";

export default function(formulateInstance) {
  formulateInstance.extend({
    components: {
      autosuggest,
      FacilitiesAutoSuggest,
      picker,
      donation_picker,
      new_picker,
      dropdown,
      jsonEditor,
      autosuggestmultiple,
      autosuggestmultiplereduced,
      editor,
      BirthPicker,
      BirthDate,
    },
    library: {
      suggest: {
        classification: "suggest",
        component: "autosuggest",
      },
      suggestmultiple: {
        classification: "suggest",
        component: "autosuggestmultiple",
      },
      suggestreduced: {
        classification: "suggest",
        component: "autosuggestmultiplereduced",
      },
      FacilitiesAutoSuggest: {
        classification: "suggest",
        component: "FacilitiesAutoSuggest",
      },
      picker: {
        classification: "picker",
        component: "picker",
      },
      donation_picker: {
        classification: "donation_picker",
        component: "donation_picker",
      },
      new_picker: {
        classification: "new_picker",
        component: "new_picker",
      },
      dropdown: {
        classification: "dropdown",
        component: "dropdown",
      },
      json: {
        classification: "json",
        component: "jsonEditor",
      },
      "quill-editor": {
        classification: "quill-editor",
        component: "editor",
      },
      BirthPicker: {
        classification: "birth-picker",
        component: "BirthPicker",
      },
      BirthDate: {
        classification: "birth-date",
        component: "BirthDate",
      },
    },
  });
}
