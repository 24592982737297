export default [
  // Donor sites
  {
    path: "/donor-sites",
    name: "DonorSites",
    component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/recruitment/DonorSites.vue"),
    children: [
      {
        path: "start-routine-drive",
        name: "StartRoutineDrive",
        // component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/recruitment/RecruitmentJsonModal.vue"),
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/ConfirmPostModal.vue"),
        props: () => ({
          toFetch: false,
          title: "Start Routine Drive",
          postUrl: "recruitment/DonorSites/createdonationdrive",
          options: [],
          optionsUrls: [],
          size: "max-w-sm",
        }),
      },
      {
        path: "create",
        name: "AddDonorSite",
        // component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/recruitment/RecruitmentJsonModal.vue"),
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/recruitment/Test.vue"),
        props: () => ({
          toFetch: false,
          title: "Add Site",
          postUrl: "recruitment/DonorSites",
          options: [],
          optionsUrls: ["recruitment/lookups/options/donor-site-category", "recruitment/lookups/options/contact-types", "recruitment/lookups/options/counties", "recruitment/facilities"],
          size: "max-w-4xl",
        }),
      },
      {
        path: "update/:id/:donorSiteName",
        title: "Update  DonorSite",
        name: "UpdateDonorSite",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/recruitment/Test.vue"),
        props: (x) => {
          let propData = {
            id: x.params.id,
            donorSiteName: x.params.donorSiteName,
            verb: "PUT",
            fetchUrl: `recruitment/DonorSites/${x.params.id}/schedules`,
            postUrl: `recruitment/DonorSites/${x.params.id}`,
            options: [],
            optionsUrls: ["recruitment/lookups/options/donor-site-category", "recruitment/lookups/options/contact-types", "recruitment/lookups/options/counties"],
            size: "max-w-5xl",
          };
          propData.title = `Update ${x.params.donorSiteName} `;

          return propData;
        },
      },
    ],
  },

  // schedules
  {
    path: "/donor-sites/scheduleDetails/:id/:donorSiteName",
    meta: { breadcrumb: { parent: "DonorSites" } },
    name: "ScheduleDetails",
    component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/recruitment/ScheduleDetails.vue"),
    children: [
      {
        path: "create-schedule",
        name: "AddSchedule",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/SchemaModal.vue"),
        props: (x) => {
          let propData = {
            toFetch: false,
            title: "Add Schedule ",
            id: x.params.id,
            donorSiteName: x.params.donorSite,
            postUrl: `recruitment/ScheduleDetails/${x.params.id}`,
            optionsUrls: [],
            jsonSchema: "recruitment/forms/create-schedule",
            moduleName: "recruitment",
            size: "max-w-5xl",
          };
          propData.title = `Add ${x.params.donorSiteName} Schedule`;
          return propData;
        },
      },
      {
        path: "update-schedule/:scheduleDetailId",
        name: "UpdateSchedule",
        // component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/recruitment/ScheduleJsonModal.vue"),
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/SchemaModal.vue"),
        props: (x) => {
          let propData = {
            title: "Update schedule",
            id: x.params.id,
            donorSite: x.params.donorSite,
            verb: "PUT",
            fetchUrl: `recruitment/ScheduleDetails/${x.params.scheduleDetailId}`,
            postUrl: `recruitment/ScheduleDetails/${x.params.id}/${x.params.scheduleDetailId}`,
            optionsUrls: ["recruitment/lookups/schedule-status/options"],
            jsonSchema: "recruitment/forms/create-schedule",
            moduleName: "recruitment",
            size: "max-w-5xl",
          };
          propData.title = "Update schedule";
          return propData;
        },
      },
      {
        path: "start-drive/:scheduleDetailsId",
        name: "StartDrive",
        component: () =>
          import(
            /* webpackChunkName: "Transfusion" */
            "@/components/data/ConfirmPostModal.vue"
          ),
        props: (x) => ({
          verb: "POST",
          title: `Start this drive`,
          postUrl: `recruitment/scheduledetails/${x.params.scheduleDetailsId}/startdrive`,
          size: "max-w-xs",
        }),
      },
      {
        path: "close-schedule/:scheduleDetailsId",
        name: "CloseSchedule",
        component: () =>
          import(
            /* webpackChunkName: "Transfusion" */
            "@/components/data/ConfirmPostModal.vue"
          ),
        props: (x) => ({
          verb: "POST",
          title: `Close this schedule`,
          postUrl: `recruitment/scheduledetails/${x.params.scheduleDetailsId}/enddrive`,

          size: "max-w-xs",
        }),
      },
    ],
  },
  // Partners
  {
    path: "/donor-sites/schedule-details/:id/:donorSiteName/partners/:scheduleDetailsId/:scheduleNumber",
    meta: { breadcrumb: { parent: "DonorSites" } },
    name: "Partners",
    props: (x) => ({
      title: x.params.title,
    }),
    component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/recruitment/Partners.vue"),
    children: [
      {
        path: "add-partner",
        name: "AddPartner",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/SchemaModal.vue"),
        props: (x) => ({
          toFetch: false,
          title: "Add Schedule Partner ",
          id: x.params.id,
          postUrl: `recruitment/scheduledetails/${x.params.scheduleDetailsId}/selectandaddpartners`,
          optionsUrls: ["recruitment/Partners/Partners"],
          jsonSchema: "recruitment/forms/add-partner",
          size: "max-w-4xl",
        }),
      },
      {
        path: "update-partner/:partnerId",
        title: "Update partner",
        name: "UpdatePartner",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/SchemaModal.vue"),
        props: (x) => ({
          title: "Update partner",
          verb: "PUT",
          fetchUrl: `recruitment/partners/${x.params.partnerId}`,
          postUrl: `recruitment/partners/${x.params.partnerId}`,
          optionsUrls: ["recruitment/Partners/Partners"],
          jsonSchema: "recruitment/forms/add-partner",
          size: "max-w-4xl",
        }),
      },
    ],
  },
  // Partner preregistration
  {
    path: "/recruitment/partner-registration",
    name: "PartnerRegistration",
    meta: { breadcrumb: { parent: "DonorSites" } },
    props: (x) => ({
      title: x.params.title,
    }),
    component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/recruitment/setup/PartnerRegistry.vue"),
    children: [
      {
        path: "register-partner",
        name: "RegisterPartner",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/SchemaModal.vue"),
        props: () => ({
          verb: "POST",
          title: "Register Partner",
          postUrl: "recruitment/partners",
          optionsUrls: ["recruitment/facilities", "recruitment/lookups/partner-service-types/options", "recruitment/lookups/contact-types/options"],
          jsonSchema: "recruitment/forms/register-partner",
          size: "max-w-4xl",
        }),
      },
      {
        path: "edit-partner/:registeredPartnerId",
        name: "EditPartner",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/SchemaModal.vue"),
        props: (x) => ({
          verb: "PUT",
          title: "Update Partner",
          fetchUrl: `recruitment/partners/${x.params.registeredPartnerId}`,
          postUrl: `recruitment/partners/${x.params.registeredPartnerId}`,
          optionsUrls: ["recruitment/facilities", "recruitment/lookups/partner-service-types/options", "recruitment/lookups/contact-types/options"],
          jsonSchema: "recruitment/forms/register-partner",
          size: "max-w-4xl",
        }),
      },
    ],
  },

  // End of Partner preregistration

  //reports
  {
    path:'/recruitment/daily-reports',
    name:"recruitmentReports",
    component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/recruitment/RecruitmentReport.vue"),

  },

  // lookups
  {
    path: "/recruitment/lookups",
    name: "RecruitmentLookups",
    meta: { breadcrumb: { parent: "DonorSites" } },
    component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/recruitment/setup/lookups/Groups.vue"),
    children: [
      {
        path: "create",
        name: "CreateRecruitmentGroup",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/SchemaModal.vue"),
        // import ( /* webpackChunkName: "optionsgroups" */ "@/components/data/JsonModal.vue"),
        props: () => ({
          toFetch: false,
          title: "Add Options Group ",
          postUrl: "recruitment/lookups/0",
          optionsUrls: [],
          jsonSchema: "recruitment/forms/options-group",
          size: "max-w-xs",
        }),
      },
      {
        path: "edit/:id",
        title: "Edit Options Group ",
        name: "AmendRecruitmentGroup",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/SchemaModal.vue"),
        // import ( /* webpackChunkName: "optionsgroups" */ "@/components/data/JsonModal.vue"),
        props: (x) => ({
          title: "Edit Options Group ",
          postUrl: `recruitment/lookups/${x.params.id}`,
          optionsUrls: [],
          jsonSchema: "recruitment/forms/options-group",
          size: "max-w-xs",
        }),
      },
      {
        path: "delete/:id",
        title: "Delete Options Group",
        name: "DeleteRecruitmentGroup",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/DeleteModal.vue"),
        props: (x) => ({
          title: "Remove Option Group ",
          deleteUrl: `recruitment/lookups/${x.params.id}`,
          size: "max-w-xs",
          name: "Option Group",
        }),
      },
    ],
  },
  {
    path: "/recruitment/lookups/:groupid/options",
    meta: { breadcrumb: { parent: "RecruitmentLookups" } },
    name: "RecruitmentOptions",
    props: (x) => ({
      title: x.params.title,
    }),
    component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/recruitment/setup/lookups/Options.vue"),
    children: [
      {
        path: "create",
        name: "CreateRecruitmentOption",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/SchemaModalWithExtraFields.vue"),
        props: (x) => ({
          toFetch: false,
          title: "Add Option",
          verb: "POST",
          postUrl: `recruitment/lookups/${x.params.groupid}`,
          optionsUrls: [],
          jsonSchema: "recruitment/forms/option",
          size: "max-w-xs",
          extraFields: { parentLookupId: x.params.groupid },
        }),
      },
      {
        path: "edit/:id",
        title: "Edit Option ",
        name: "AmendRecruitmentOption",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/SchemaModalWithExtraFields.vue"),
        props: (x) => ({
          title: "Amend Option ",
          verb: "PUT",
          postUrl: `recruitment/lookups/${x.params.id}`,
          optionsUrls: [],
          jsonSchema: "recruitment/forms/option",
          size: "max-w-xs",
          extraFields: { parentLookupId: x.params.groupid },
        }),
      },
      {
        path: "delete/:id",
        title: "Delete Option",
        name: "DeleteRecruitmentption",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/DeleteModal.vue"),
        props: (x) => ({
          title: "Remove Option ",
          deleteUrl: `recruitment/lookups/${x.params.id}`,
          size: "max-w-xs",
          name: "Option",
        }),
      },
    ],
  },

  // Forms
  {
    path: "/recruitment/forms",
    name: "RecruitmentForms",
    meta: { breadcrumb: { parent: "DonorSites" } },
    component: () =>
      import(
        /* webpackChunkName: "doctors-pool-group" */
        "@/views/recruitment/setup/Forms/List.vue"
      ),
    children: [
      {
        path: "create",
        name: "AddRecruitmentForm",
        component: () =>
          import(
            /* webpackChunkName: "doctors-pool-group" */
            "@/components/data/JsonForm.vue"
          ),
        props: () => ({
          toFetch: false,
          title: "Add Form ",
          postUrl: "recruitment/forms",
        }),
      },
      {
        path: "edit/:id",
        title: "Update Form",
        name: "UpdateRecruitmentForm",
        component: () =>
          import(
            /* webpackChunkName: "doctors-pool-group" */
            "@/components/data/JsonForm.vue"
          ),
        props: (x) => ({
          title: "Update Form ",
          postUrl: `recruitment/forms/${x.params.id}`,
        }),
      },
      {
        path: "delete/:id",
        title: "Delete Form",
        name: "DeleteRecruitmentForm",
        component: () =>
          import(
            /* webpackChunkName: "doctors-pool-group" */
            "@/components/data/DeleteModal.vue"
          ),
        props: (x) => ({
          title: "Delete Form ",
          deleteUrl: `recruitment/forms/${x.params.id}`,
          size: "max-w-xs",
          name: "Delete Form",
        }),
      },
    ],
  },
];
