<template>
    <div class="overflow-x-auto">
        <report-shimmer v-if="loading"></report-shimmer>
        <ejs-grid :dataSource="data" :allowTextWrap="true" :textWrapSettings="wrapSettings" gridLines="Both" allowPaging="true" :rowTemplate="'rowTemplate'" :dataStateChange="dataStateChange" :pageSettings="pageOptions" ref="egrid">
            <e-columns>
                <e-column field="location" :headerText="header" textAlign="center"></e-column>
            </e-columns>
            <template v-slot:rowTemplate="{ data }">
                <component :is="getComponent(selectedReport).component" :data="data" :first="data.index === 0" :last="data.index === lastItem - 1" :topHeaders="topHeaders" :bottomHeaders="bottomHeaders" :totalResult="totalResult" :selectedReport="selectedReport"></component>
            </template>
        </ejs-grid>
    </div>
</template>

<script>
    import { Freeze, Page, PdfExport } from "@syncfusion/ej2-vue-grids";
    // import PostDriveSessions from "@/components/data/recruitment/reports/PostDriveSessions.vue";
    import DynamicTable from "@/components/data/donation/reports/DynamicTable.vue";
    // import SessionAnalysis from "@/components/data/recruitment/reports/SessionAnalysis.vue";
    import DynamicAgeGroupAndGender from "@/components/data/donation/reports/DynamicAgeGroupAndGender.vue";
    import DynamicMultipleHeaders from "@/components/data/donation/reports/DynamicMultipleHeaders.vue";

    export default {
        props: {
            selectedReport: String,
            fetchedData: [Array, Object],
            totalResult: [Array, Object],
            url: String,
            header: String,
        },
        computed: {
            getUniqueTopHeader() {
                return this.data.result.reduce((acc, obj) => {
                    obj[this.getComponent(this.selectedReport).parent]?.forEach((parent) => {
                        if (!acc.includes(parent[this.getComponent(this.selectedReport).topChild])) {
                            acc.push(parent[this.getComponent(this.selectedReport).topChild]);
                        }
                    });
                    return acc;
                }, []);
            },

            getUniqueBottomHeader() {
                return this.data.result.reduce((acc, obj) => {
                    obj[this.getComponent(this.selectedReport).parent]?.forEach((parent) => {
                        if (Array.isArray(parent[this.getComponent(this.selectedReport).bottomChild])) {
                            parent[this.getComponent(this.selectedReport).bottomChild]?.forEach((child) => {
                                !acc.includes(child[this.getComponent(this.selectedReport).bottomGrandChild]) && acc.push(child[this.getComponent(this.selectedReport).bottomGrandChild]);
                            });
                        } else {
                            Object.keys(parent[this.getComponent(this.selectedReport).bottomChild])?.forEach((child) => {
                                !acc.includes(child) && acc.push(child);
                            });
                        }
                    });
                    return acc;
                }, []);
            },
        },
        data() {
            return {
                loading: false,
                wrapSettings: { wrapMode: "Content, Header" },
                pageOptions: {
                    pageSize: this.fetchedData.pageSize,
                    pageCount: 4,
                },
                data: {
                    result: this.fetchedData?.results || this.fetchedData,
                    count: this.fetchedData.totalItems,
                },
                lastItem: Object.keys(this.fetchedData).length,
                topHeaders: {},
                bottomHeaders: {},
            };
        },
        provide: {
            grid: [Freeze, Page, PdfExport],
        },
        methods: {
            downloadPDF: function () {},
            getComponent: (selected) => {
                const components = {
                    "post-outreach-session-analysis": {
                        component: DynamicTable,
                    },
                    "donors-report": {
                        component: DynamicTable,
                    },
                    "Outreach-Sessions-analysis": {
                        component: DynamicTable,
                    },
                    "donation-by-type": {
                        component: DynamicAgeGroupAndGender,
                        parent: "ageGroups",
                        topChild: "age",
                        bottomChild: "genders",
                        bottomGrandChild: "sex",
                    },
                    "donation-by-frequency": {
                        component: DynamicAgeGroupAndGender,
                        parent: "ageGroups",
                        topChild: "age",
                        bottomChild: "genders",
                        bottomGrandChild: "sex",
                    },
                    "donation-by-location": {
                        component: DynamicAgeGroupAndGender,
                        parent: "ageGroups",
                        topChild: "age",
                        bottomChild: "genders",
                        bottomGrandChild: "sex",
                    },
                    "static-outreach-donations": {
                        component: DynamicMultipleHeaders,
                        parent: "donations",
                        topChild: "site",
                        bottomChild: "frequency",
                    },
                    "donor-deferal": {
                        component: DynamicAgeGroupAndGender,
                        parent: "ageGroups",
                        topChild: "age",
                        bottomChild: "genders",
                        bottomGrandChild: "sex",
                    },
                };
                return components[selected];
            },
            dataStateChange: function () {
                var grid = document.getElementsByClassName("e-grid")[0].ej2_instances[0];
                this.fetchData(grid.pagerModule.pagerObj.currentPage);
                this.$refs.egrid.$el.hidden = true;
            },
            fetchData(page) {
                this.loading = true;
                this.$callApi
                    .get(this.url + "&page=" + page, {
                        params: { ...this.$route.query },
                    })
                    .then((x) => {
                        this.$refs.egrid.$el.hidden = false;
                        var grid = document.getElementsByClassName("e-grid")[0].ej2_instances[0];

                        grid.dataSource = {
                            result: x.data?.data?.results || x.data?.drives?.results || x.data?.data,
                            count: x.data?.data?.totalItems || x.data?.drives?.totalItems,
                        };

                        (this.data.result = x.data?.data?.results || x.data?.drives?.results || x.data?.data), (this.data.count = x.data?.data?.totalItems || x.data?.drives?.totalItems);
                    })
                    .catch(() => {
                        //this.error = err;
                    })
                    .then(() => (this.loading = false));
            },
        },
        mounted() {
            this.topHeaders = this.getUniqueTopHeader;
            this.bottomHeaders = this.getUniqueBottomHeader;
        },
    };
</script>

<!-- <template>
  <div class="flex flex-col">
    <div class="grid grid-cols-12">
      <div>Location</div>
      <div>Site</div>
      <div>Drive</div>
      <div>First Time Donors</div>
      <div>Regular Time Donors</div>
      <div>Repeat Time Donors</div>
    </div>

    <div class="grid grid-cols-12" v-for="(data, idx ) in fetchedData" :key="idx">
      <div>  {{ data.location }} </div>
      <div class="col-span-11"> 
        <div class="grid grid-cols-11" v-for="(site, idx) in data.sites" :key="idx"> 
          <div> {{ site.name }} </div>
          <div class="col-span-10"> 
            <div class="grid grid-cols-10" v-for="(drive, idx) in site.drives" :key="idx"> 
              <div> {{ drive.name }} </div> 
              <div> {{ drive.firstTimeDonor }} </div>
              <div> {{ drive.regularDonor }} </div>
              <div> {{ drive.repeatDonors }} </div>
            </div> 
          </div>
      </div>
      </div>
    </div>

  </div> -->
