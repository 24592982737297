import Vue from "vue";
import App from "./App.vue";

import VueHtmlToPaper from "vue-html-to-paper";
import router from "./router";
import store from "./store";
import { GridPlugin } from "@syncfusion/ej2-vue-grids";
import "@/components/_common";
import "@/assets/main.css";
//import "vue-select/dist/vue-select.css";
Vue.config.productionTip = false;

const html_to_paper_options = {
  styles: [
    ...new Set(
      [...window.document.styleSheets]
        .map((style) => {
          return style.href !== null
            ? style.href
            : `data:text/css;base64,${btoa(
                unescape(
                  encodeURIComponent(
                    [...style.cssRules].map((rule) => rule.cssText).join("")
                  )
                )
              )}`;
        })
        .filter((s) => s !== null && s !== "data:text/css;base64,")
    ),
  ],
  autoClose: true,
};
Vue.use(VueHtmlToPaper, html_to_paper_options);
Vue.use(GridPlugin);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
