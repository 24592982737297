import vue from "vue";
import Barcode from "./Barcode.vue";
import Loading from "./Loading.vue";
import EditLink from "./buttons/EditLink.vue";
import MultipleButtons from "./buttons/MultipleButtons.vue";
import SplitButton from "./buttons/SplitButton.vue";
import DeleteLink from "./buttons/DeleteLink.vue";
import DownloadReport from "./buttons/DownloadReport.vue";
import ConfirmPostModal from "./data/ConfirmPostModal.vue";
import RouteLink from "./buttons/RouteLink.vue";
import TabRoute from "./buttons/TabRoute.vue";
import List from "./data/List.vue";
import BaseTable from "./data/BaseTable.vue";
import SearchList from "./data/SearchList.vue";
import SearchListWIthFilter from "./data/SearchListWithFilter.vue";
import ChildList from "./data/ChildList.vue";
import NameChildList from "./data/NameChildList.vue";
import DonorList from "./data/donation/DonorList.vue";
import DonationReports from "./data/donation/DonationReports.vue";
import OtherDonationReports from "./data/donation/OtherDonationReports.vue";
import LabReports from "./data/lab/LabReports.vue";
import ComponentReports from "./data/componentSeparation/reports/ComponentReports.vue";
import DriveList from "./data/donation/DriveList.vue";
import PartnersChildList from "@/components/data/recruitment/PartnersChildList.vue";
import VacutainerChildList from "@/components/data/lab/VacutainerChildList.vue";
import DeliveryList from "@/components/data/lab/DeliveryList.vue";
import DriveVacutainerChildList from "@/components/data/donation/DriveVacutainerChildList.vue";
import DriveBagChildList from "@/components/data/donation/DriveBagChildList.vue";
import ComponentTypeList from "@/components/data/donation/ComponentTypeList.vue";
import DonationChildList from "@/components/data/lab/DonationChildList.vue";
import RejectList from "@/components/data/lab/RejectList.vue";
import ExtractedComponetsList from "@/components/data/componentSeparation/ExtractedComponetsList.vue";
import ListWithFilters from "@/components/data/ListWithFilters.vue";
import ChildListWithFilters from "@/components/data/ChildLIstWithFilters.vue";
import LiaisonContactList from "@/components/data/setup/LiaisonContactList.vue";
import FacilityChildList from "@/components/data/setup/FacilityChildList.vue";
import CxltToastr from "cxlt-vue2-toastr";
import "cxlt-vue2-toastr/dist/css/cxlt-vue2-toastr.css";
import VueFormulate from "@braid/vue-formulate";
import Ajax from "@/services/http";
import Suggest from "@/plugins/autosuggest";
import VueTailwind from "vue-tailwind";
import settings from "@/components/custom/vue-tailwind-settings";
import Label from "@/components/custom/Label";
import { helpers } from "@/plugins/helpers";
import VueTimeago from 'vue-timeago'
import ReportTable from '@/components/ReportTable.vue'
import FilterPopover from './custom/FilterPopover.vue'
import ReportShimmer from '@/components/ReportShimmer.vue'
import ReportView from '@/components/custom/ReportView.vue'
import NoData from '@/components/custom/dailyReports/NoData.vue'


vue.use(VueTimeago,{locale:"en-Nairobi"});
vue.use(helpers);
vue.component("MyLabel", Label);
vue.use(VueTailwind, settings)

vue.use(VueFormulate, {
  slotProps: {
    label: ["showOptional"],
  },
  slotComponents: {
    // Use the string name of the globally registered component.
    label: "MyLabel",
  },
  validationNameStrategy: (vm) => vm.context.validationStrategy || vm.label,
  classes: {
    //grouping: "flex items-center",
    outer(context) {
      if (context.classification === "box") {
        return "mb-0";
      } else {
        return "mb-4";
      }
    },
    wrapper(context) {
      if (context.classification === "box") {
        return "flex space-x-3 items-center";
      }
    },
    input(context) {
      const stateCss = context.validation !== false && context.isValid && context.hasValue ? "border-green-300 " : "border-gray-300 ";
      switch (context.classification) {
        case "button":
          return "px-4 py-2 rounded bg-green-500 text-white hover:bg-green-600";
        case "box":
          return "flex  text-red-500 focus:ring-red-500";
        case "group":
          return "";
        // case "suggest":
        // return [stateCss, "border leading-none focus:border-green-500 outline-none border-box w-full"];
        case "datepicker":
        case "birth-date":
          return [stateCss, "border border-gray-400 rounded-sm px-3 py-2 leading-none focus:border-green-500 outline-none border-box w-full mb-1"];
        case "picker":
          return "border border-gray-400 rounded-sm px-3 py-2 leading-none focus:border-green-500 focus:ring-0 focus:outline-none border-box w-full mb-1";
        default:
          return [stateCss, "border  rounded px-3 py-2 leading-none focus:shadow-sm focus:border-blue-400 transition duration-300 focus:ring-0 outline-none border-box w-full mb-1"];
      }
    },
    // label: "font-normal tracking-wider text-xs uppercase  py-1",
    label(context) {
      if (context.classification !== "box") {
        return "font-normal inline-block tracking-wider text-xs text-gray-900 uppercase  py-2";
      }
    },
    // inputHasValue: "border-green-500/60",
    inputHasErrors: "border-red-500/90",
    help: "text-xs mb-1 text-gray-600",
    error: "text-red-500/90 text-xs ",
  },
  plugins: [Suggest],
  rules: {
    validateScheduleFields: ({ name, value, getFormValues }) => validateScheduleFields(name, value, getFormValues),
  },
  locales: {
    en: {
      validateScheduleFields({ name }) {
        return validateScheduleFieldsMessage(name);
      },
    },
  },
});

vue.use(CxltToastr);
vue.prototype.$callApi = Ajax;
vue.component("bar-code", Barcode);
vue.component("loading-indicator", Loading);
vue.component("ReportTable", ReportTable);
vue.component("EditLink", EditLink);
vue.component("MultipleButtons", MultipleButtons);
vue.component("SplitButton", SplitButton);
vue.component("DeleteLink", DeleteLink);
vue.component("DownloadReport", DownloadReport);
vue.component("confirm-post-modal", ConfirmPostModal);
vue.component("RouteLink", RouteLink);
vue.component("TabRoute", TabRoute);
vue.component("base-list", List);
vue.component("base-table", BaseTable);
vue.component("search-list", SearchList);
vue.component("search-list-with-filter", SearchListWIthFilter);
vue.component("child-list", ChildList);
vue.component("name-child-list", NameChildList);
vue.component("partners-child-list", PartnersChildList);
vue.component("vacutainer-child-list", VacutainerChildList);
vue.component("delivery-list", DeliveryList);
vue.component("drive-vacutainer-child-list", DriveVacutainerChildList);
vue.component("drive-bag-child-list", DriveBagChildList);
vue.component("component-type-list", ComponentTypeList);
vue.component("donation-child-list", DonationChildList);
vue.component("extracted-components-list", ExtractedComponetsList);
vue.component("reject-list", RejectList);
vue.component("liaison-contact-list", LiaisonContactList);
vue.component("facility-child-list", FacilityChildList);
vue.component("donor-list", DonorList);
vue.component("donation-reports", DonationReports);
vue.component("other-donation-reports", OtherDonationReports);
vue.component("lab-reports", LabReports);
vue.component("component-reports", ComponentReports);
vue.component("drive-list", DriveList);
vue.component("ListWithFilters", ListWithFilters);
vue.component("ChildListWithFilters", ChildListWithFilters);
vue.component("filter-popover", FilterPopover);
vue.component("report-shimmer", ReportShimmer);
vue.component("no-report", NoData);
vue.component("report-view", ReportView);
//donor validations
function validateScheduleFields(name, value, getFormValues) {
  const { maximumDonors, expectedDonors, donationStartDate, donationEndDate } = getFormValues();
  if (name == "maximumDonors") {
    if (expectedDonors) {
      if (Number(value) < expectedDonors) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
  if (name == "expectedDonors") {
    if (maximumDonors) {
      if (Number(value) > maximumDonors) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
  if (name == "donationStartDate") {
    if (donationEndDate) {
      if (value > donationEndDate) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
  if (name == "donationEndDate") {
    if (donationStartDate) {
      if (value < donationStartDate) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
}

function validateScheduleFieldsMessage(name) {
  if (name == "Total population") {
    return "Maximum donors should be greater than expected donors";
  }
  if (name == "Expected Donors") {
    return "Expected donors should be less than total population";
  }
  if (name == "Donation Start Date") {
    return "Donation start date should be less than donation end date";
  }
  if (name == "Donation End Date") {
    return "Donation end date should be greater than donation start date";
  }
}

export default {
  methods: {},
};
