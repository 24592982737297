export default {
    state: () => ({
        selectedComponentsTransfusion : {},
    }),
    mutations: {
        setSelectedComponentTransfusion(state, bloodComponent) { 
            let selectedComponentsTransfusionCopy = state.selectedComponentsTransfusion
             selectedComponentsTransfusionCopy[bloodComponent.entityGuid] = bloodComponent;

            state.selectedComponentsTransfusion =  Object.assign({}, state.selectedComponentsTransfusion, selectedComponentsTransfusionCopy);
            
             },
        removeSelectedComponentTransfusion(state, bloodComponentId) { 
            let selectedComponentsTransfusionCopy = state.selectedComponentsTransfusion
            delete selectedComponentsTransfusionCopy[bloodComponentId];
            state.selectedComponentsTransfusion =  Object.assign({}, state.selectedComponentsTransfusion, selectedComponentsTransfusionCopy);
        },
        resetSelectedComponentsTransfusion(state){
            state.selectedComponentsTransfusion =  Object.assign({}, {});
        },
      
    },
    getters: {
        getSelectedComponentsTransfusion: (state)=> state.selectedComponentsTransfusion,
        getSelectedComponentsTransfusionLength: (state) =>{ 
            return Object.keys(state.selectedComponentsTransfusion).length},
        getKeysInSelectedComponentsTransfusion:(state)=>{
           return Object.keys(state.selectedComponentsTransfusion)
        }
        
    },
    actions: {
        addBloodComponentTransfusion: ({commit}, payload) =>{
            commit("setSelectedComponentTransfusion", payload);
        },
        removeBloodComponentTransfusion: ({commit}, payload) =>{
            commit("removeSelectedComponentTransfusion", payload);
        },
        resetSelectedComponentsTransfusion: ({commit}) =>{
            commit("resetSelectedComponentsTransfusion");

        }
    
    }
}