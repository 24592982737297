<template >
    <tr class="group">
        <td class="w-full mx-auto text-center text-gray-500 border-b-2 group-hover:bg-green-200">
            <div class=" font-semibold text-gray-600 capitalize"> {{ $removeHyphens(data.itemTypeRequested) }}
            </div>
        </td>
        <td class="w-full mx-auto text-center text-gray-500 border-b-2 group-hover:bg-green-50">
            <div class="h-1/3 py-1 text-sm font-medium border hidden xl:block  ">
                Requested
            </div>
            <div class="h-1/3 py-1 text-sm font-medium border block xl:hidden">
                R
            </div>
            <div class="h-1/3 py-1 text-sm font-medium border hidden xl:block">
                Issued
            </div>
            <div class="h-1/3 py-1 text-sm font-medium border block xl:hidden">
                I
            </div>
            <div class="h-1/3 py-1 text-sm font-medium border">%</div>

        </td>
        <td v-for="(month, idx) in data.months" v-bind:key="idx" width="25%"
            class="w-1/4 text-center hover:bg-green-100 border-b-2 ">
            <div class="h-1/3 border py-1 text-sm ">
                {{ month.bloodGroup.totalRequested }}
            </div>
            <div class="h-1/3 py-1 border text-sm">
                {{ month.bloodGroup.totalIssued }}
            </div>
            <div class="h-1/3 py-1 border text-sm">
                {{ month.bloodGroup.ratio }}
            </div>
        </td>
        <td width="25%"
            class="text-gray-600 group-hover:bg-green-100 w-1/4 items-center border-b-2 py-2 text-center font-medium">
            <div class="h-1/3 border py-1 text-sm ">
                {{ data.totals.totalRequested }}
            </div>
            <div class="h-1/3 py-1 border text-sm">
                {{ data.totals.totalIssued }}
            </div>
            <div class="h-1/3 py-1 border text-sm">
                {{ data.totals.ratio }}
            </div>
        </td>
    </tr>
</template>

<script>

export default {
    props: {
        selectedMonthlyReport: String,
        data: [Array, Object],
    },

    data() {
        return {
            months: ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
        };
    },
}
</script>
