import humanize from "humanize-duration";
import moment from "moment";
import dayjs from "dayjs";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
const helpers = {};

helpers.install = function (Vue) {
  Vue.prototype.$calculateAge = (value, largest = 5) => {
    return humanize(dayjs().diff(dayjs(value)), {
      largest: largest,
      round: true,
    });
  };
  Vue.prototype.$capitalizeString = (stringToCapitalize) => {
    let capitalizedString = "";
    if (stringToCapitalize) {
      if (stringToCapitalize.charAt(0).match(/[a-z]/i)) {
        capitalizedString =
          stringToCapitalize[0].toUpperCase() + stringToCapitalize.substring(1);
      }
      return capitalizedString;
    }
  };
  Vue.prototype.$monthIntToName = (month) => {
    var months = [
      0,
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months[parseInt(month)];
  };
  Vue.prototype.$removeHyphens = (stringToFormat) => {
    let formattedString = "";
    if (stringToFormat) {
      formattedString = stringToFormat.replace(/[\]-]+/g, " ");
      return formattedString;
    }
  };
  Vue.prototype.$removeHyphensCapitalize = (stringToFormat) => {
    let formattedString = "";
    let capitalizedString = "";
    if (stringToFormat) {
      formattedString = stringToFormat.replace(/[\]-]+/g, " ");
      capitalizedString = formattedString.replace(
        /(^\w{1})|(\s+\w{1})/g,
        (letter) => letter.toUpperCase()
      );
      return capitalizedString;
    }
  };
  Vue.prototype.$toUppercase = (stringToFormat) => {
    let formattedString = "";
    if (stringToFormat) {
      formattedString = stringToFormat.toUpperCase();
      return formattedString;
    }
  };
  Vue.prototype.$removeUnderscores = (stringToFormat) => {
    let formattedString = "";
    if (stringToFormat) {
      formattedString = stringToFormat.replace(/[\]_]+/g, " ");
      return formattedString;
    }
  };
  Vue.prototype.$removeCamelCase = (stringToFormat) => {
    let formattedString = "";
    let capitalizedString = "";
    if (stringToFormat) {
      formattedString = stringToFormat.replace(/([a-z])([A-Z])/g, '$1 $2');
      if (formattedString) {
        if (formattedString.charAt(0).match(/[a-z]/i)) {
          capitalizedString =
            formattedString[0].toUpperCase() + formattedString.substring(1);
        }
        return capitalizedString;
      }
    }
  };
  
  Vue.prototype.$formatDate = (date, includeSeconds = true, forDownload = false) => {
    if (includeSeconds) {
      return dayjs(date).format("DD MMM YYYY HH:mm");
    } else if (forDownload) {
      return dayjs(date).format("DD/MM/YYYY");
    } else {
      return dayjs(date).format("DD MMM YYYY");
    }
  };
  Vue.prototype.$dateWithMoment = (date) => {
    const outputDate = moment(date).format('YYYY-MM-DDTHH:mm:ss[Z]');
    return moment(outputDate, "YYYY-MM-DDTHH:mm:ssZ", true)
  };
  Vue.prototype.$calcToday = () => {
    return new Date().toISOString().replace(/T.*/, "").split("-").join("-");
  };

  Vue.prototype.$calcStartDate = (time, period) => {
    if (period === "days") {
      return new Date(new Date().setDate(new Date().getDate() - time))
        .toISOString()
        .replace(/T.*/, "")
        .split("-")
        .join("-");
    } else
      return new Date(new Date().setMonth(new Date().getMonth() - time))
        .toISOString()
        .replace(/T.*/, "")
        .split("-")
        .join("-");
  };


  Vue.prototype.$formatNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  Vue.prototype.$roundOff = (number) => {
    return Math.round(number);
  };

  Vue.prototype.$formatBloodGroup = (bloodGroup) => {
    if (bloodGroup) {
      if (bloodGroup.charAt(0) === "+" || bloodGroup.charAt(0) === "-") {
        return bloodGroup.substring(1) + bloodGroup[0];
      } else {
        return bloodGroup;
      }
    }
  };
  Vue.prototype.$translateRhesus = (bloodGroup) => {
    if (bloodGroup) {
      let rhesusPosition = bloodGroup.search(/[+|-]/i);
      if (bloodGroup[rhesusPosition] === "+") {
        return "Rh(D) POSITIVE";
      }
      if (bloodGroup[rhesusPosition] === "-") {
        return "Rh(D) NEGATIVE";
      }
    }
  };
  Vue.prototype.$stripRhesus = (bloodGroup) => {
    if (bloodGroup) {
      let rhesusPosition = bloodGroup.search(/[+|-]/i);
      if (bloodGroup[rhesusPosition] !== -1) {
        return bloodGroup.replace(bloodGroup[rhesusPosition], "");
      }
    }
  };
 
  Vue.prototype.$getDurationFromCurrentTime = (startTime) => {
    startTime = new Date(startTime).setHours(
      new Date(startTime).getHours() - 3
    );
    var differenceInSeconds = Math.floor(
      Math.abs(new Date().getTime() - new Date(startTime).getTime()) / 1000
    );
    var differenceInMinutes = Math.floor(differenceInSeconds / 60);
    var differenceInHours = Math.floor(differenceInMinutes / 60);
    var differenceInDays = Math.floor(differenceInHours / 24);
    var differenceInMonths = Math.floor(differenceInDays / 28);

    return differenceInMonths > 0
      ? differenceInMonths + " month/s "
      : differenceInDays > 0
      ? differenceInDays + " day/s "
      : differenceInHours > 0
      ? differenceInHours + " hour/s "
      : differenceInMinutes > 0
      ? differenceInMinutes + " minute/s "
      : differenceInSeconds + " second/s ";
  };
  Vue.prototype.$GMTtoEAT = (time) => {
    time = new Date(time).setHours(new Date(time).getHours() - 3);
    return new Date(time);
  };
  Vue.prototype.$printData = (id) => {
    if (id) {
      let divContents = document.getElementById(id).innerHTML;
      let docStyles = "";
      let styles = document.querySelectorAll('link[rel="stylesheet"], style');

      for (let style of styles) {
        docStyles = docStyles + style.outerHTML;
      }
      var a = window.open("", "", "height=800, width=1200");
      a.document.write("<html>");

      const style = `<head id="styleSheet">${docStyles}</head><body>`;
      a.document.write(style);
      a.document.write(divContents);
      a.document.write("</body></html>");
      a.document.close();
      a.document.body.onload = function () {
        a.print();
      };
    }
  };
  Vue.prototype.$printReport = (head, body, fileName) => {
    const doc = new jsPDF({
      orientation: "landscape",
    });
    autoTable(doc, {
      head: head,
      body: body,
      startY: 15,
      horizontalPageBreak: true,
    });
    doc.save(fileName);
  };

  Vue.prototype.$printCSVData = (csvFile, type, year, region, county) => {
    const removeHyphens = (stringToFormat) => {
      let formattedString = "";
      if (stringToFormat) {
        formattedString = stringToFormat
          .replace(/[\]-]+/g, " ")
          .replace(/^./, function (str) {
            return str.toUpperCase();
          });
        return formattedString;
      }
    };
    var fileName = {
      all: "All Blood Groups",
      "a+": "A(Plus) Blood Group",
      "a-": "A(Minus) Blood Group",
      "b+": "B(Plus) Blood Group",
      "b-": "B(Minus) Blood Group",
      "ab+": "AB(Plus) Blood Group",
      "ab-": "AB(Minus) Blood Group",
      "o+": "O(Plus) Blood Group",
      "o-": "O(Minus) Blood Group",
    };
    var regions = "";
    var counties = "";
    if (region) {
      regions = "-"+ region + "-";
    }
    if (county) {
      counties = county + " County";
    }
    const anchor = document.createElement("a");
    anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csvFile);
    anchor.target = "_blank";
    anchor.download = `${fileName[type] === undefined ? removeHyphens(type) : fileName[type]}-${year}${regions}${counties}.csv`;
    anchor.click();
  };

  Vue.prototype.$reportType = (type) => {
    var getType = {
      // Donation
      "monthly-donations-by-type-report": {
        name: "Donation Type",
        type: "type",
        data: "donations",
        value: "successfulDonations",
      },
      "monthly-donations-by-age-report": {
        name: "Age Group",
        type: "age",
        data: "donations",
        value: "successfulDonations",
      },
      "monthly-units-discarded-report": {
        name: "Discard Reason",
        type: "reason",
        data: "unitsDiscarded",
        value: "unitsDiscarded",
      },
      "monthly-donations-by-county": {
        name: "County",
        type: "county",
        data: "donations",
        value: "successfulDonations",
      },
      "monthly-Site-Donation-Report": {
        name: "Facility",
        type: "facilityName",
        data: "donations",
        
        value: "successfulDonations",
      },
      "monthly-Donations-By-Frequency": {
        name: "Frequency",
        type: "frequency",
        data: "months",
        value: "totalDonations",
      },
      "monthly-Donor-Deferrals": {
        name: "Deferment Reason",
        type: "defermentReason",
        data: "months",
        value: "deferals",
      },

      //component separation
      "Blood-Components-collected": {
        name: "Component",
        type: "type",
        data: "months",
        value: "totalCollected",
      },
      "Blood-units-separated": {
        name: "Component",
        type: "type",
        data: "months",
        value: "totalCollected",
      },

      // Blood bank
      "Monthly-Blood-Issued-Requested-Ratio": {
        name: "Component",
        action: "Action",
      },
      "Components-Available": {
        name: "Component Type",
        type: "componentType",
        data: "bloodGroups",
        value: "quantityAvailableInMl",
      },
      "Monthly-Discarded-Blood": {
        name: "Reason",
        type: "reason",
        data: "months",
        value: "totalDiscarded",
      },

      // lab
      "monthly-blood-grouping": {
        name: "Blood Group",
        type: "bloodGroup",
        data: "results",
        value: "count",
      },
      "monthly-samples-tested-TTI": {
        name: "Facility",
        type: "facility",
        data: "samples",
        value: "samplesTested",
      },

      // recruitment
      "post-drive-analysis": { name: "Post Drive Session Analysis" },
    };
    return getType[type];
  };

  Vue.prototype.$downloadPDF = () => {
    const fetchedPrintData = this.fetchedData;
    const printPagedResult = this.pagedResult.results;

    if (fetchedPrintData && !fetchedPrintData.currentPage) {
      // Report by Type
      if (fetchedPrintData[0]["type"]) {
        this.head = [["Donation type"].concat(this.months)];
        this.body = [];
        for (var type_i = 0; type_i < fetchedPrintData.length; type_i++) {
          let row = [];
          row.push(fetchedPrintData[type_i]["type"]);
          for (
            var type_j = 0;
            type_j < fetchedPrintData[type_i]["donationTypes"].length;
            type_j++
          ) {
            row.push(
              fetchedPrintData[type_i]["donationTypes"][type_j][
                "successfulDonations"
              ]
            );
          }
          this.body.push(row);
        }
        this.fileName = "MONTHLY DONATIONS BY TYPE.pdf";
      }

      // Report by Age
      if (fetchedPrintData[0]["age"]) {
        this.head = [["Age Group"].concat(this.months)];
        this.body = [];
        for (var age_i = 0; age_i < fetchedPrintData.length; age_i++) {
          let row = [];
          row.push(fetchedPrintData[age_i]["age"]);
          for (
            var age_j = 0;
            age_j < fetchedPrintData[age_i]["donations"].length;
            age_j++
          ) {
            row.push(
              fetchedPrintData[age_i]["donations"][age_j]["successfulDonations"]
            );
          }
          this.body.push(row);
        }

        this.fileName = "MONTHLY DONATIONS BY AGE REPORT.pdf";
      }

      // Report by Discarded Units
      if (fetchedPrintData[0]["reason"]) {
        this.head = [["Discard Reason"].concat(this.months)];
        this.body = [];
        for (
          var discarded_i = 0;
          discarded_i < fetchedPrintData.length;
          discarded_i++
        ) {
          let row = [];
          row.push(fetchedPrintData[discarded_i]["reason"]);
          for (
            var discarded_j = 0;
            discarded_j <
            fetchedPrintData[discarded_i]["unitsDiscarded"].length;
            discarded_j++
          ) {
            row.push(
              fetchedPrintData[discarded_i]["unitsDiscarded"][discarded_j][
                "unitsDiscarded"
              ]
            );
          }
          this.body.push(row);
        }

        this.fileName = "MONTHLY UNITS DISCARDED REPORT.pdf";
      }
    }
    if (printPagedResult && printPagedResult && printPagedResult.length > 0) {
      // Report by County
      if (printPagedResult[0].county) {
        this.head = [["County"].concat(this.months)];
        this.body = [];
        for (var county_i = 0; county_i < printPagedResult.length; county_i++) {
          let row = [];
          row.push(printPagedResult[county_i].county);
          for (
            var county_j = 0;
            county_j < printPagedResult[county_i].donations.length;
            county_j++
          ) {
            row.push(
              printPagedResult[county_i].donations[county_j].successfulDonations
            );
          }
          this.body.push(row);
        }

        this.fileName = "MONTHLY DONATIONS BY COUNTY.pdf";
      }
      // Report by Donation
      if (printPagedResult[0].facilityName) {
        this.head = [["Facility"].concat(this.months)];
        this.body = [];
        for (
          var donation_i = 0;
          donation_i < printPagedResult.length;
          donation_i++
        ) {
          let row = [];
          row.push(printPagedResult[donation_i].facilityName);
          for (
            var donation_j = 0;
            donation_j < printPagedResult[donation_i].donations.length;
            donation_j++
          ) {
            row.push(
              printPagedResult[donation_i].donations[donation_j]
                .successfulDonations
            );
          }
          this.body.push(row);
        }

        this.fileName = "MONTHLY SITE DONATION REPORT.pdf";
      }
    }
  };
};

export { helpers };
