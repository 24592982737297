<template>
  <div class="rounded py-2 px-6 shadow-lg md:py-4 md:px-8">
    <div class="flex items-center justify-between py-4">
      <span class="flex flex-col items-center space-y-1">
        <img src="@/assets/coa.png" alt="" class="w-10 md:w-20" />
        <span class="text-sm  font-medium  tracking-wider text-sky-500">Ministry of Health</span>
      </span>
      <div class="flex flex-col items-center">
        <img src="@/assets/ktta_logo.svg" alt="logo" class="w-64" />
      </div>
    </div>
    <div class="grid place-items-center  py-8">
      <img alt="Brand" src="@/assets/g38.png" class="w-64" />
    </div>
    <div class="grid grid-cols-2 place-items-center gap-8">
      <damu-yetu-white class="w-36 md:w-40" />
      <button @click.prevent="authenticateOidc" class="flex items-center space-x-1 rounded bg-primary-500  px-4 py-2 text-sm text-white hover:bg-primary-600 focus:outline-none focus:ring focus:ring-primary-500 focus:ring-offset-2 md:space-x-2 md:px-10  md:py-4">
        <span>Get Started</span>
        <svg class="h-4 w-4 md:h-6 md:w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import DamuYetuWhite from "./DamuYetuWhite.vue";
export default {
  components: {
    DamuYetuWhite,
  },
  name: "hero-view",
  data: () => ({}),
  methods: {
    ...mapActions(["authenticateOidc"]),
  },
};
</script>
