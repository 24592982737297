<template>
  <transition leave-active-class="animate-slide-out-left">
    <aside :class="['transition bg-gray-50 md:flex md:flex-col md:justify-between md:col-span-2', showSideMenu ? 'fixed w-full h-full bg-modal inset-0 z-20 ' : 'hidden']">
      <div class="w-full h-full flex flex-col">
        <div :class="[showSideMenu ? 'transition elevation-16 animate-slide-in-left relative bg-gray-50 w-3/5 h-full' : '']">
          <div :class="['py-6 px-4 flex items-center justify-between', showSideMenu ? '' : 'hidden']">
            <div class="relative flex items-center">
              <img alt="Brand" src="@/assets/damuyetu.svg" class="w-6 md:w-10" />
              <span class="text-3xl text-gray-400 font-semibold"><em class="text-primary">Damu</em>Yetu</span>
            </div>
            <button class="p-2 rounded-full hover:bg-gray-100 focus:outline-none transition shadow-sm" @click="closeSideMenu">
              <svg xmlns="http://www.w3.org/2000/svg" class="w-6" viewBox="0 0 512 512">
                <title>Close</title>
                <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M368 368L144 144M368 144L144 368" />
              </svg>
            </button>
          </div>
          <div :class="['w-full md:w-1/6 fixed h-full', showSideMenu ? 'top-20' : '']">
            <h4 v-if="currentArea" :title="currentArea.label" class="truncate pt-8 pb-5 px-3 uppercase text-base font-medium tracking-widest text-gray-800 ">{{ currentArea.label }}</h4>
            <div v-if="currentArea" class="border-t border-gray-200 h-[80%] scrollbar:w-1.5 scrollbar:h-1.5 scrollbar:bg-transparent scrollbar-track:bg-slate-100 scrollbar-thumb:rounded scrollbar-thumb:!bg-slate-300  overflow-y-auto">
              <router-link :active-class="activeClasses" v-for="(link, index) in currentArea.links" :key="index" :class="[normalClasses]" :to="{ name: link.name }" @click.native="closeSideMenu">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="w-5 h-5" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                </svg>
                <span :title="link.label" class="truncate">{{ link.label }}</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </aside>
  </transition>
</template>
<script>
import { mixin as clickaway } from "vue-clickaway";
import { mapActions, mapGetters } from "vuex";
import ClickOutside from "vue-click-outside";

export default {
  name: "SideMenu",
  mixins: [clickaway],
  computed: {
    ...mapGetters(["showSideMenu", "currentArea"]),
  },
  data: () => ({
    activeClasses: "bg-gray-100 text-primary-500 border-r-2 border-primary-400",
    normalClasses: "transition  hover:bg-gray-100 hover:text-primary-400 px-4 xl:px-6 py-4 w-full text-gray-700 flex items-center text-xs lg:text-sm font-medium space-x-2",
  }),
  methods: {
    ...mapActions(["toggleSideMenu", "closeSideMenu"]),
  },
  directives: {
    ClickOutside,
  },
};
</script>
