<template>
  <div class="h-full">
    <div class="rounded  place-content-center h-full w-full" v-if="oidcIsAuthenticated">
      <div class="px-6 pb-4" v-if="$route.query.q">
        <div class="grid grid-cols-12 gap-2 md:gap-4 items-center ">
          <div class="col-span-6">
            <h3 class="border-b-2 border-primary-300 py-4 space-x-2 inline-flex items-center text-primary-400  -mb-px">
              <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 md:w-5 md:h-5" fill="currentColor" viewBox="0 0 16 16">
                <path
                  d="M1 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2zM1 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7zM1 12a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2z"
                />
              </svg>
              <span class="text-xs text-primary-700 md:text-base   font-normal  uppercase tracking-wider">{{ title }}</span>
            </h3>
          </div>
          <div class="col-span-6 flex items-center justify-end space-x-1">
            <div class="text-gray-500 w-full md:w-1/2 transition duration-300 flex items-center border-gray-300 border focus-within:shadow-md rounded focus-within:border-opacity-25 focus-within:border-primary-300 focus-within:text-gray-900  focus-within:ring-1 focus-within:ring-primary-600">
              <input @input="clearSearch" type="search" v-model="q" placeholder="Search..." class="outline-none px-3 w-full placeholder-gray-400 rounded-l py-1  border-0 border-none focus:ring-0 " @keyup.enter="updateUrl" />
              <button @click="search" class="p-2 focus:outline-none">
                <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="h-full w-full grid grid-rows-footer ">
        <div v-if="loading" class="flex items-center justify-center">
          <loading-indicator></loading-indicator>
        </div>
        <div v-if="hasList">
          <div class=" border-b  border-gray-100 divide-y divide-gray-100  ">
            <div v-for="(item, index) in pagedResult.results" :key="item.id" class="px-6 py-4 transition-all hover:bg-gray-50 ">
              <slot v-bind:index="index" v-bind:item="item"></slot>
            </div>
          </div>
          <div class="grid justify-items-end" v-if="header===`SampleList` && selectedVacutainers.length>0">
            <span>
              <RouteLink :route="{ name: 'ResendSelectedResults', params: { vacutainers: selectedVacutainers, label:pagedResult.results[0].label} }" class="flex-auto transition rounded-md text-center py-1 px-2 mt-3 bg-blue-400 flex items-center text-xs text-white font-mono hover:bg-blue-500 hover:text-blue-900 focus:outline-none transform hover:-translate-y-px hover:scale-110" title="Click to upload results">
                <svg class="w-4 h-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"></path></svg>
                <p class="px-2 text-lg font-medium capitalize">Resend Results</p>
              </RouteLink>
            </span>
          </div>
        </div>
        <div class="h-full w-full justify-center flex flex-col">
          <div v-show="!$route.query.q && !hasList" class="px-6 flex items-center flex-col md:flex-row justify-center space-y-3 md:space-y-0 md:space-x-3">
            <div class="text-gray-500 w-full md:w-1/2 transition duration-300 flex items-center border-gray-300 border focus-within:shadow-md rounded focus-within:border-primary-300  focus-within:text-gray-900 ">
              <input ref="search" tabindex="0" type="search" v-model="q" :placeholder="searchTitle" class="transition peer outline-none px-3 w-full placeholder:italic placeholder:text-gray-400 rounded-l py-2 ring-0 focus:ring-0 border-none  " @keyup.enter="updateUrl" />
              <button @click="search" class=" transition p-3 focus:outline-none peer-focus:bg-primary peer-focus:text-white font-medium">
                <svg class="w-5 h-5 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M10 2.75a7.25 7.25 0 0 1 5.63 11.819l4.9 4.9a.75.75 0 0 1-.976 1.134l-.084-.073-4.901-4.9A7.25 7.25 0 1 1 10 2.75Zm0 1.5a5.75 5.75 0 1 0 0 11.5 5.75 5.75 0 0 0 0-11.5Z" /></svg>
              </button>
            </div>
            <div>
              <router-link v-if="!!showCreate" :to="{ name: createName }" :title="createTitle" custom>
                <button class="focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 flex items-center space-x-2 border border-primary-500/50 text-primary-500 hover:text-white sm:ml-3 mt-4 sm:mt-0  px-6 py-3 hover:bg-primary-500 focus:outline-none rounded transition">
                  <svg class="w-5 h-5 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.75 3a.75.75 0 0 1 .743.648l.007.102.001 7.25h7.253a.75.75 0 0 1 .102 1.493l-.102.007h-7.253l.002 7.25a.75.75 0 0 1-1.493.101l-.007-.102-.002-7.249H3.752a.75.75 0 0 1-.102-1.493L3.752 11h7.25L11 3.75a.75.75 0 0 1 .75-.75Z" />
                  </svg>
                  <p class="text-sm font-medium leading-none">{{ createTitle }}</p>
                </button>
              </router-link>
            </div>
          </div>
          <div v-if="noData" class="text-center flex items-center justify-center">
            <span class="text-xl text-yellow-600 font-semibold animate-pulse self-center">No records found.</span>
          </div>
        </div>
      </div>
      <div>
        <router-view :key="$route.fullPath" />
      </div>
    </div>
    <lock v-if="!oidcIsAuthenticated" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Lock from "@/components/layout/Lock";
import { isEmpty } from "lodash";
export default {
  props: {
    title: String,
    url: String,
    createTitle: String,
    createName: String,
    showCreate: { type: Boolean, default: true },
    searchText:String,
    header:String,
    selectedVacutainers:[Array]

  },
  components: { Lock },
  mounted() {
    this.focusInput();
    const body = document.body;
    if (this.oidcIsAuthenticated) {
      body.classList.add("overflow-y-scroll");
    } else {
      body.classList.remove("overflow-y-scroll");
    }
  },
  computed: {
    ...mapGetters(["oidcIsAuthenticated"]),
    hasList() {
      return this.pagedResult?.results?.length > 0;
    },
    searchTitle() {
      return `Search ${this.searchText||this.title}...`;
    },
    noData() {
      return !isEmpty(this.pagedResult) && this.pagedResult.results.length === 0;
    },
  },
  data() {
    return {
      loading: false,
      pagedResult: {},
      error: {},
      q: this.$route.query.q,
    };
  },
  watch: {
    $route: {
      immediate: true,
      async handler() {
        if (this.$route.query.q) {
          if (this.$route.matched.length === 1 || (this.$route.matched.length > 1 && Object.keys(this.pagedResult).length === 0 && this.pagedResult.constructor === Object)) {
            await this.fetch();
          }
        }
      },
    },
  },
  methods: {
    focusInput() {
      this.$refs.search.focus();
    },
    async fetch() {
      this.loading = true;
      this.pagedResult = {};
      if(this.header==="SampleList"){
        this.$callApi
        .get(this.url.replace('donationNumber',this.q), {
          params: {},
        })
        .then((x) => {
          this.pagedResult = x.data;
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });  
      }else{
        this.$callApi
        .get(this.url, {
          params: { ...this.$route.query },
        })
        .then((x) => {
          this.pagedResult = x.data;
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
      }
    },
    updateUrl() {
      if (this.q) {
        this.$router.push({ query: { q: this.q } }).then(() => {});
        
      } else {
        this.$router.push({ query: {} }).then(() => {});
      }
      
    },
    clearSearch() {
      if (!this.q) {
        this.pagedResult = {};
        this.updateUrl();
        this.$nextTick(() => this.$refs.search.focus());
      }
    },
    search() {
      if (this.q?.length >= 3) {
        this.$router.push({ query: { q: this.q } }).then(() => {});
      } else {
        if (this.$route.query.q !== undefined) {
          this.q = "";
          this.$router.push({ query: {} }).then(() => {});
        }
      }
    },
    back() {
      this.$router.back();
    },
  },
};
</script>
