<template>
  <div class="h-full">
    <div class="grid h-full grid-rows-layout rounded " v-show="oidcIsAuthenticated">
      <div class="">
        <div class="grid grid-cols-12 items-center gap-2 px-8 pt-4 md:gap-4 ">
          <div class="col-span-6">
            <h3 class="-mb-px inline-flex items-center space-x-2 border-b-2 border-primary-300 py-4  text-primary-400">
              <button type="button" v-if="!!showBack" @click.prevent="back" class="flex items-center justify-center rounded-full bg-transparent p-2 text-6xl text-primary-500 outline-none transition hover:bg-primary-200 hover:text-primary-600 focus:outline-none" title="Back">
                <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"></path>
                </svg>
              </button>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 md:h-5 md:w-5" fill="currentColor" viewBox="0 0 16 16">
                <path
                  d="M1 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2zM1 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7zM1 12a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2z"
                />
              </svg>
              <span class="text-xs font-normal uppercase   tracking-wider  text-primary-700 md:text-base">{{ title }}</span>
              <span v-if="hasList">({{pagedResult.totalItems}})</span>
            </h3>
          </div>
          <div class="col-span-6 flex items-center justify-end space-x-1">
            <div class="group flex w-full items-center rounded border border-gray-300 text-gray-500 transition duration-300 focus-within:border-primary-500 focus-within:text-gray-900   focus-within:shadow-md  md:w-1/2 ">
              <input @search="clear" type="search" v-model="q" placeholder="Search... (minimum 3 characters)" class="peer w-full rounded-l border-none px-3 py-1.5 outline-none transition placeholder:italic  placeholder:text-gray-400 focus:ring-0 " @keyup.enter="search" />
              <button @click="search" class="p-3 transition focus:outline-none peer-focus:bg-primary-500 peer-focus:text-white ">
                <svg class="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                </svg>
              </button>
            </div>
            <router-link v-if="!!showCustomAction" :to="{ name: customActionName }" class=" flex items-center space-x-1 rounded bg-primary-50 px-2 py-2 text-sm text-primary-500 hover:bg-primary-400 hover:text-white focus:outline-none" :title="createTitle">
              <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="h-4 w-4" viewBox="0 0 384 512">
                <defs />
                <path fill="currentColor" d="M377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-153 31V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm64 160v48c0 4.4-3.6 8-8 8h-56v56c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8v-56h-56c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h56v-56c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v56h56c4.4 0 8 3.6 8 8z" />
              </svg>
              <span class="hidden md:block">{{ customActionTitle }}</span>
            </router-link>
            <router-link v-if="!!showCreate" :to="{ name: createName }" class="flex items-center space-x-1 rounded border  border-primary-500/50 px-2 py-2.5 text-sm text-primary-500 transition hover:bg-primary-500 hover:text-white focus:outline-none" :title="createTitle">
              <svg class="h-4 w-4 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.75 3a.75.75 0 0 1 .743.648l.007.102.001 7.25h7.253a.75.75 0 0 1 .102 1.493l-.102.007h-7.253l.002 7.25a.75.75 0 0 1-1.493.101l-.007-.102-.002-7.249H3.752a.75.75 0 0 1-.102-1.493L3.752 11h7.25L11 3.75a.75.75 0 0 1 .75-.75Z" />
              </svg>
              <span class="hidden truncate md:block">{{ createTitle }}</span>
            </router-link>
            <slot name="more-actions"></slot>
          </div>
        </div>
        <div class="border-b"></div>
      </div>
      <div class="h-full border-b border-gray-100 ">
        <div v-if="loading" class="grid h-full place-items-center">
          <loading-indicator />
        </div>
        <div v-if="hasList" class="w-full">
          <div class=" w-full  min-w-full divide-y divide-gray-100 border-b border-gray-100 ">
            <div v-for="(item, index) in pagedResult.results" :key="item.id" class="px-8 py-4 transition-all hover:bg-gray-50 ">
              <slot v-bind:index="index" v-bind:item="item"></slot>
            </div>
          </div>
        </div>
        <div v-if="!hasList && !loading" class="flex  h-full items-center justify-center">
          <span class="animate-pulse self-center text-xl font-semibold text-yellow-600">No records found.</span>
        </div>
      </div>
      <div>
        <div class="flex  bg-gray-100/60 px-8 py-4 ">
          <nav v-if="!isEmpty(pagedResult)" class="mx-auto">
            <div class="flex items-center">
              <span v-if="pagedResult.currentPage === 1" class="w-full rounded-l-xl border bg-gray-50/60 p-4 text-base text-gray-600 ">
                <svg width="9" height="8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 19l-7-7 7-7m8 14l-7-7 7-7"></path></svg>
              </span>
              <router-link v-else :to="{ query: { page: 1 } }" class="w-full rounded-l-xl border bg-white p-4 text-base text-gray-600 hover:bg-gray-100">
                <svg width="9" height="8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 19l-7-7 7-7m8 14l-7-7 7-7"></path></svg>
              </router-link>
              <span v-if="pagedResult.currentPage === 1" class="w-full border-y border-r bg-gray-50/60 p-4  text-base text-gray-600">
                <svg width="9" height="8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>
              </span>
              <router-link v-else :to="{ query: { page: pagedResult.currentPage - 1 } }" class="w-full border-y border-r bg-white p-4 text-base text-gray-600 hover:bg-gray-100">
                <svg width="9" height="8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>
              </router-link>
              <template v-for="page in pagedResult.pages">
                <span v-if="page === pagedResult.currentPage" :key="page" class="w-full border-primary-400 bg-primary-400 px-4 py-2  text-base text-white odd:border-y even:border ">
                  {{ page }}
                </span>
                <router-link :to="{ query: { page: page } }" v-else :key="page" class="w-full bg-white px-4 py-2 text-base text-gray-600 odd:border-y even:border hover:bg-gray-100 hover:text-primary-400 ">
                  {{ page }}
                </router-link>
              </template>

              <span v-if="pagedResult.currentPage === pagedResult.totalPages" class="w-full border-y border-r bg-gray-50/60 p-4  text-base text-gray-600">
                <svg width="9" height="8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
              </span>
              <router-link v-else :to="{ query: { page: pagedResult.currentPage + 1 } }" class="w-full border-y border-r bg-white p-4 text-base text-gray-600 hover:bg-gray-100">
                <svg width="9" height="8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
              </router-link>
              <span v-if="pagedResult.currentPage === pagedResult.totalPages" class="w-full rounded-r-xl border-y border-r bg-gray-50/60 p-4 text-base text-gray-600 ">
                <svg width="9" height="8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5l7 7-7 7M5 5l7 7-7 7"></path></svg>
              </span>
              <router-link v-else :to="{ query: { page: pagedResult.totalPages } }" class="w-full rounded-r-xl border-y border-r bg-white p-4 text-base text-gray-600 hover:bg-gray-100 hover:text-primary-400">
                <svg width="9" height="8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5l7 7-7 7M5 5l7 7-7 7"></path></svg>
              </router-link>
            </div>
          </nav>
          <span v-if="showMarkAsComplete && !loading && hasList">
            <span v-if="pagedResult.results && pagedResult.results.length > 0">
              <RouteLink
                :route="{ name: 'MarkAsComplete', 
              params: { bloodPintId:$route.params.bloodPintId, donationNumber:$route.params.donationNumber, bloodBagType:$route.params.bloodBagType } }"
                class="flex flex-auto transform items-center rounded-md bg-blue-400 py-1 px-2 text-center font-mono text-xs text-white transition hover:scale-110 hover:bg-blue-500 hover:text-blue-900 hover:-translate-y-px focus:outline-none"
                title="Click to mark as complete"
                v-if="pagedResult.results[0].bloodPintStatus !== 'Complete'"
              >
                <svg class="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                  ></path>
                </svg>
                <p class="px-2 text-lg font-medium capitalize">Mark As Complete</p>
              </RouteLink>

              <span v-if="pagedResult.results[0].bloodPintStatus === 'Complete'" class="text-orange-500">Marked as complete</span>
            </span>
          </span>
        </div>
        <router-view />
      </div>
    </div>
    <lock v-show="!oidcIsAuthenticated" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Lock from "@/components/layout/Lock";
export default {
  name: "base-list",
  props: {
    title: String,
    url: String,
    showCreate: { type: Boolean, default: true },
    showBack: { type: Boolean, default: false },
    createName: { type: String, default: "Create" },
    createTitle: { type: String, default: "New" },
    showCustomAction: { type: Boolean, default: false },
    customActionParams: {
      type: Array,
      default() {
        return [];
      },
    },
    customActionTitle: { type: String, default: "Action" },
    customActionName: { type: String, default: "Action" },
    backRouterName: String,
    backSteps: { type: Number, default: 0 },
    showMarkAsComplete: [Boolean],
  },
  components: { Lock },
  computed: {
    ...mapGetters(["oidcIsAuthenticated"]),
    hasList() {
      return this.pagedResult?.results?.length > 0;
    },
  },
  data() {
    return {
      loading: false,
      pagedResult: {},
      error: {},
      q: this.$route.query.q,
    };
  },
  watch: {
    $route: {
      immediate: true,
      async handler() {
        if (this.$route.matched.length === 1 || (this.$route.matched.length > 1 && Object.keys(this.pagedResult).length === 0 && this.pagedResult.constructor === Object)) {
          await this.fetch();
        }
      },
    },
  },
  mounted() {
    const body = document.body;
    if (this.oidcIsAuthenticated) {
      body.classList.add("overflow-y-scroll");
    } else {
      body.classList.remove("overflow-y-scroll");
    }
  },
  methods: {
    async fetch() {
      this.loading = true;
      this.pagedResult = {};
      this.$callApi
        .get(this.url, {
          params: { ...this.$route.query },
        })
        .then((x) => {
          this.pagedResult = x.data;
        })
        .catch(() => {})
        .then(() => (this.loading = false));
    },
    search() {
      if (this.q?.length >= 3) {
        this.$router.push({ query: { q: this.q } });
      } else {
        if (this.$route.query.q !== undefined) {
          this.q = "";
          this.$router.push({ query: {} });
        }
      }
    },
    clear(e) {
      if (e.target._value.trim().length === 0) {
        this.$router.push({ query: {} });
      }
    },
    navigate(queryString) {
      this.$router.push({ path: this.$route.path, query: { ...queryString } });
    },
    isEmpty(obj) {
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    },
    back() {
      if (this.backRouterName) {
        this.$router.push({ name: this.backRouterName });
      } else {
        if (this.backSteps !== 0) {
          this.$router.go(this.backSteps - this.backSteps * 2);
        } else {
          this.$router.back();
        }
      }
    },
  },
};
</script>
