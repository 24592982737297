<template>
  <div class="h-full">
    <div class="rounded grid grid-rows-layout h-full w-full" v-show="oidcIsAuthenticated">
      <div class="px-2 pb-4">
        <div class="grid grid-cols-12 gap-2 md:gap-4 items-center ">
          <div class="col-span-6">
            <h3 class="border-b-2 border-primary-300 py-4 space-x-2 inline-flex items-center text-primary-400  -mb-px">
              <button
                type="button"
                v-if="!!showBack"
                @click.prevent="back"
                class="text-6xl hover:bg-primary-200 transition p-2 hover:text-primary-600 rounded-full text-primary-500 bg-transparent outline-none focus:outline-none flex items-center justify-center"
                title="Back"
              >
                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"></path>
                </svg>
              </button>
              <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 md:w-5 md:h-5" fill="currentColor" viewBox="0 0 16 16">
                <path
                  d="M1 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2zM1 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7zM1 12a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2z"
                />
              </svg>
              <span class="text-xs text-primary-700 md:text-base   font-normal  uppercase tracking-wider">{{ title }}</span>
            </h3>
          </div>
          <div class="col-span-6 flex items-center justify-end space-x-1">
            
            <div
              class="text-gray-500 w-full md:w-1/2 transition duration-300 flex items-center border-gray-300 border focus-within:shadow-md rounded focus-within:border-opacity-25 focus-within:border-primary-300 focus-within:text-gray-900  focus-within:ring-1 focus-within:ring-primary-600"
            >
              <FormulateInput class="outline-none px-3 w-full placeholder-gray-400 rounded-l  border-0 border-none focus:ring-0 " @keyup.enter="search" v-model="facilityName" name="facilityName" placeholder="Type name..."  validation="" type="FacilitiesAutoSuggest" @input="filterListByFacility(facilityName?facilityName.value:'')" v-if="header==='LabDeliveries' || header==='ScreeningBatches' || header==='GroupingBatches' || header==='reviewScreeningBatches' || header==='reviewGroupingBatches' || header==='completedScreeningBatches' || header==='completedGroupingBatches'" :show-optional="false"/>              
            </div>
            <div
              class="text-gray-500 w-full md:w-1/2 transition duration-300 flex items-center border-gray-300 border focus-within:shadow-md rounded focus-within:border-opacity-25 focus-within:border-primary-300 focus-within:text-gray-900  focus-within:ring-1 focus-within:ring-primary-600"
            >
              <input @input="clear" type="search" v-model="q" placeholder="Search..." class="outline-none px-3 w-full placeholder-gray-400 rounded-l py-1  border-0 border-none focus:ring-0 " @keyup.enter="search" />
              <button @click="search" class="p-2 focus:outline-none">
                <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                </svg>
              </button>
            </div>
            <router-link
              v-if="!!showCustomAction"
              :to="{ name: customActionName }"
              class=" px-2 py-2 bg-primary-50 rounded flex items-center text-sm text-primary-500 focus:outline-none space-x-1 hover:bg-primary-400 hover:text-white"
              :title="createTitle"
            >
              <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="w-4 h-4" viewBox="0 0 384 512">
                <defs />
                <path
                  fill="currentColor"
                  d="M377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-153 31V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm64 160v48c0 4.4-3.6 8-8 8h-56v56c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8v-56h-56c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h56v-56c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v56h56c4.4 0 8 3.6 8 8z"
                />
              </svg>
              <span class="hidden md:block">{{ customActionTitle }}</span>
            </router-link>
            <router-link v-if="!!showCreate" :to="{ name: createName }" class=" px-2 py-2 bg-primary-50 rounded flex items-center text-sm text-primary-500 focus:outline-none space-x-1 hover:bg-primary-400 hover:text-white" :title="createTitle">
              <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="w-4 h-4" viewBox="0 0 384 512">
                <defs />
                <path
                  fill="currentColor"
                  d="M377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-153 31V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm64 160v48c0 4.4-3.6 8-8 8h-56v56c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8v-56h-56c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h56v-56c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v56h56c4.4 0 8 3.6 8 8z"
                />
              </svg>
              <span class="hidden md:block">{{ createTitle }}</span>
            </router-link>
          </div>
        </div>
        <div class="flex justify-between text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b rounded-tl-md rounded-tr-md   bg-gray-50   px-1">
          <div :tableHeadingTitles="tableHeadingTitles" v-for="(tableTitle, index) in tableHeadingTitles" :key="index">
            <div v-html="tableTitle" class="px-4 py-3">
              {{ tableTitle }}
            </div>
          </div>
        </div>
      </div>
      <div class="h-full border-b border-gray-100 ">
        <div v-if="loading" class="flex items-center justify-center">
          <loading-indicator></loading-indicator>
        </div>
        <div v-if="hasList" class="flex-1">
          <div class=" border-b  border-gray-100 divide-y divide-gray-100  ">
            <div v-for="(item, index) in pagedResult.results" :key="item.id" class="px-6 py-4 transition-all hover:bg-gray-50 ">
              <slot v-bind:index="index" v-bind:item="item"></slot>
            </div>
          </div>
        </div>
        <div v-if="!hasList && !loading" class="h-full  flex items-center justify-center">
          <span class="text-xl text-yellow-600 font-semibold animate-pulse self-center">No records found.</span>
        </div>
      </div>
      <div>
        <div class="px-8  py-4 bg-gray-100/60 flex ">
          <nav v-if="!isEmpty(pagedResult)" class="mx-auto">
            <div class="flex items-center">
              <span v-if="pagedResult.currentPage === 1" class="w-full p-4 border text-base rounded-l-xl text-gray-600 bg-gray-50/60 ">
                <svg width="9" height="8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 19l-7-7 7-7m8 14l-7-7 7-7"></path></svg>
              </span>
              <router-link v-else :to="{ query: { page: 1 } }" class="w-full p-4 border text-base rounded-l-xl text-gray-600 bg-white hover:bg-gray-100">
                <svg width="9" height="8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 19l-7-7 7-7m8 14l-7-7 7-7"></path></svg>
              </router-link>
              <span v-if="pagedResult.currentPage === 1" class="w-full p-4 border-y border-r text-base  text-gray-600 bg-gray-50/60">
                <svg width="9" height="8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>
              </span>
              <router-link v-else :to="{ query: { page: pagedResult.currentPage - 1 } }" class="w-full p-4 border-y border-r text-base text-gray-600 bg-white hover:bg-gray-100">
                <svg width="9" height="8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>
              </router-link>
              <template v-for="page in pagedResult.pages">
                <span v-if="page === pagedResult.currentPage" :key="page" class="w-full px-4 py-2 odd:border-y even:border  text-base text-white bg-primary-400 border-primary-400 ">
                  {{ page }}
                </span>
                <router-link :to="{ query: { page: page } }" v-else :key="page" class="w-full px-4 py-2 even:border odd:border-y text-base text-gray-600 bg-white hover:bg-gray-100 hover:text-primary-400 ">
                  {{ page }}
                </router-link>
              </template>

              <span v-if="pagedResult.currentPage === pagedResult.totalPages" class="w-full p-4 border-y border-r text-base  text-gray-600 bg-gray-50/60">
                <svg width="9" height="8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
              </span>
              <router-link v-else :to="{ query: { page: pagedResult.currentPage + 1 } }" class="w-full p-4 border-y border-r text-base text-gray-600 bg-white hover:bg-gray-100">
                <svg width="9" height="8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
              </router-link>
              <span v-if="pagedResult.currentPage === pagedResult.totalPages" class="w-full p-4 border-y border-r text-base rounded-r-xl text-gray-600 bg-gray-50/60 ">
                <svg width="9" height="8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5l7 7-7 7M5 5l7 7-7 7"></path></svg>
              </span>
              <router-link v-else :to="{ query: { page: pagedResult.totalPages } }" class="w-full p-4 border-y border-r text-base rounded-r-xl text-gray-600 bg-white hover:bg-gray-100 hover:text-primary-400">
                <svg width="9" height="8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5l7 7-7 7M5 5l7 7-7 7"></path></svg>
              </router-link>
            </div>
          </nav>
        </div>
        <router-view> </router-view>
      </div>
    </div>
    <lock v-show="!oidcIsAuthenticated" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Lock from "@/components/layout/Lock";
export default {
  props: {
    header:[String],
    title: String,
    url: String,
    showCreate: { type: Boolean, default: true },
    showBack: { type: Boolean, default: false },
    createName: { type: String, default: "Create" },
    createTitle: { type: String, default: "New" },
    showCustomAction: { type: Boolean, default: false },
    customActionParams: {
      type: Array,
      default() {
        return [];
      },
    },
    customActionTitle: { type: String, default: "Action" },
    customActionName: { type: String, default: "Action" },
    tableHeadingTitles: {
      Array,
      default: function() {
        return [];
      },
    },
  },
  components: { Lock },
  computed: {
    ...mapGetters(["oidcIsAuthenticated"]),
    hasList() {
      return this.pagedResult?.results?.length > 0;
    },
  },
  data() {
    return {
      loading: false,
      pagedResult: {},
      facilityName:'',
      batchType: "",
      error: {},
      q: this.$route.query.q,
      facilityFilterUrl:''
    };
  },
  watch: {
    $route: {
      immediate: true,
      async handler() {
        if (this.$route.matched.length === 1 || (this.$route.matched.length > 1 && Object.keys(this.pagedResult).length === 0 && this.pagedResult.constructor === Object)) {
          await this.fetch();
        }
      },
    },
  },
  methods: {
    filter() {
      if (this.batchType !== "") {
        this.$router.push({ query: { batchType: this.batchType } });
      } else {
        this.$router.push({ query: {} });
      }
    },
    filterListByFacility(facilityFromCode){
      this.loading = true;
      this.pagedResult = {};
      
      if(this.url.includes('q')){
        console.log('includes')
        this.url = this.url.substring(0, this.url.indexOf('q'));
      }
      
      this.facilityFilterUrl=this.url.concat(`&FacilityFromCode=${facilityFromCode}`)
     
      this.$callApi
        .get(this.facilityFilterUrl, {
          params: { ...this.$route.query },
        })
        .then((x) => {
          this.pagedResult = x.data;
        })
        .catch(() => {
          //this.error = err;
          //console.log(err);
        })
        .then(() => (this.loading = false));

    },
    async fetch() {
      this.loading = true;
      this.pagedResult = {};
      //const http = await this.$callApi.get();
      //console.log(http);
      this.$callApi
        .get(this.url, {
          params: { ...this.$route.query },
          //baseURL: "https://localhost:5001",
        })
        .then((x) => {
          this.pagedResult = x.data;
        })
        .catch(() => {
          //this.error = err;
          //console.log(err);
        })
        .then(() => (this.loading = false));
    },
    search() {
      this.facilityName=""
      if (this.q.length >= 3) {
        this.$router.push({ query: { q: this.q } });
      } else {
        if (this.$route.query.q !== undefined) {
          this.q = "";
          this.$router.push({ query: {} });
        }
      }
    },    
    clear(e) {
      if (e.target.value.trim().length === 0) {
        this.q = "";
      }
    },
    navigate(queryString) {
      this.$router.push({ path: this.$route.path, query: { ...queryString } });
    },
    isEmpty(obj) {
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    },
    back() {
      this.$router.back();
    },
  },
};
</script>
