<template>
  <form ref="modal" @submit.prevent="submit" autocomplete="off" class="fixed inset-0 z-50 overflow-x-hidden overflow-y-auto outline-none focus:outline-none pt-10 pr-17">
    <div :class="[' flex items-center justify-center w-full']">
      <div :class="['grid grid-rows-layout animate-rotate-in-2-fwd-ccw border-0 rounded-sm elevation-16 w-full  bg-white outline-none focus:outline-none', size]">
        <div class="px-6 py-4">
          <div class="border-b border-gray-200 mb-2 flex items-center justify-between text-red-500">
            <h3 class="border-b border-red-600 py-3 inline-flex items-center text-red-500 text-xs leading-snug font-bold -mb-px uppercase tracking-wider">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 stroke-current">
                <path
                  fill-rule="evenodd"
                  d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z"
                />
              </svg>
              <span class="ml-1">{{ title + (titleName ? titleName : "") }}</span>
            </h3>

            <button class="text-6xl hover:bg-gray-300 p-2 hover:text-red-600 rounded-full bg-transparent outline-none focus:outline-none flex items-center justify-center" type="button" v-on:click="$router.back()">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 stroke-current" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z" />
                <path fill-rule="evenodd" d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z" />
              </svg>
            </button>
          </div>
        </div>
        <div class="px-6">
          <div class="text-gray-700 px-4 py-4 text-sm space-x-4 rounded-sm mb-4 flex">
            <div>
              <h6 class="leading-snug tracking-wide text-base font-bold">
                {{ title ? title : "" }} <span v-if="titleName" :class="['lowercase', titleNameClass ? titleNameClass : '']"> {{ titleName ? titleName : "" }}</span
                >?
              </h6>
              <p>
                Proceed to
                <span :class="[moduleName === 'laboratory' && $route.params.testResult.toLowerCase() === 'reactive' ? 'text-yellow-500 font-semibold uppercase' : moduleName === 'laboratory' && $route.params.testResult.toLowerCase() === 'nonreactive' ? 'text-blue-500 font-semibold uppercase' : moduleName === 'transfusion' ? 'text-blue-500 font-semibold uppercase' : 'lowercase']">{{ bodyText || title }}</span
                ><span v-if="name" class="font-semibold"> {{ name }}</span
                >?
              </p>
            </div>
          </div>
        </div>
        <span class="text-red-500 font-mono p-4">{{ serverError.length > 0 ? serverError[0] : "" }}</span> <br />

        <div class="flex items-center px-6 pt-6 pb-4 bg-gray-100">
          <button
            v-bind:disabled="submitting"
            type="submit"
            :class="['space-x-2 min-h-8 flex-1 flex items-center justify-center transition duration-500 ease-in-out transform rounded-sm  text-green-500  border border-green-600 bg-green-100 font-bold uppercase px-4 py-2 text-sm outline-none focus:outline-none mr-1 mb-1', submitting ? 'opacity-75 cursor-not-allowed' : ' hover:-translate-y-px hover:scale-110  hover:text-white hover:bg-green-600 hover:elevation-1']"
            v-if="this.serverError.length == 0"
          >
            <loading-indicator v-if="submitting" />
            <svg v-else class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"></path>
            </svg>
            <span>{{ submitting ? "Submitting..." : "Submit" }}</span>
          </button>
          <button
            v-bind:disabled="submitting"
            @click="goBack"
            :class="['space-x-2 min-h-8 flex-1 flex items-center justify-center transition duration-500 ease-in-out transform rounded-sm  text-green-500  border border-green-600 bg-green-100 font-bold uppercase px-4 py-2 text-sm outline-none focus:outline-none mr-1 mb-1', cancelling ? 'opacity-75 cursor-not-allowed' : ' hover:-translate-y-px hover:scale-110  hover:text-white hover:bg-green-600 hover:elevation-1']"
            v-if="this.serverError.length > 0"
          >
            <loading-indicator v-if="cancelling"></loading-indicator>
            <svg v-else class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"></path>
            </svg>
            <span>{{ submitting ? "Cancelling..." : "Cancel" }}</span>
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: "confirm-modal",
  props: {
    name: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "Sample",
    },
    deleteUrl: {
      type: String,
      default: "",
    },
    id: [String, Number],
    size: {
      type: String,
      default: "max-w-xs",
    },
    submitText: {
      type: String,
      default: "Save",
    },
    postUrl: {
      type: String,
      default: "",
    },
    verb: {
      type: String,
      default: "",
    },

    toFetch: { type: Boolean, default: true },
    bodyText: String,
    extraFields: [Object, Array],
    backSteps: { type: Number, default: 0 },
    backRouterName: String,
    titleNameClass: String,
    titleName: String,
    moduleName: [String],
  },
  data() {
    return {
      loading: false,
      submitting: false,
      cancelling: false,
      form: {},
      errors: {},
      serverError: [],
    };
  },
  mounted() {
    const body = document.body;
    body.classList.remove("overflow-y-scroll");
    body.classList.add("overflow-hidden", "pr-scrollbar");
    const elemDiv = document.createElement("div");
    elemDiv.id = "backdrop";
    elemDiv.classList.add("fixed", "inset-0", "z-40", "bg-modal");
    body.appendChild(elemDiv);
  },
  methods: {
    goBack() {
      this.cancelling = true;
      this.$router.back();
    },
    submit() {
      this.uploading = true;
      this.serverError = [];
      this.errors = {};
      this.submitting = true;

      this.$callApi({
        method: this.verb !== "" ? this.verb : this.toFetch ? "PUT" : "POST",
        url: this.postUrl,
        data: this.extraFields ? this.extraFields : this.form,
      })
        .then((x) => {
          if ("message" in x.data) {
            this.$toast.success({
              title: "Success",
              message: x.data.message,
            });
          }
          this.back();
        })
        .catch((err) => {
          const resp = err.response;
          if (resp.status === 500) {
            this.serverError = [resp.data.title];
          }
          if (resp.status === 400) {
            if ("generic" in resp.data) {
              this.serverError = resp.data["generic"];
            } else {
              this.errors = resp.data.errors;
              this.serverError = [resp.data.title];
            }
          }
          this.wobble = true;
        })
        .then(() => (this.submitting = false));
    },
    back() {
      if (this.backRouterName) {
        this.$router.push({ name: this.backRouterName });
      } else {
        if (this.backSteps !== 0) {
          this.$router.go(this.backSteps - this.backSteps * 2);
        } else {
          this.$router.back();
        }
      }
    },
  },
  beforeDestroy() {
    this.$refs.modal.classList.add("overflow-hidden");
    const body = document.body;
    const backdrop = document.getElementById("backdrop");
    body.classList.remove("overflow-hidden", "pr-scrollbar");
    body.classList.add("overflow-y-scroll");
    body.removeChild(backdrop);
  },
};
</script>
