var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$reportType(_vm.selectedMonthlyReport))?_c('table',{staticClass:"w-full table-fixed justify-center p-2 text-sm text-gray-500"},[_c('thead',[_c('tr',{staticClass:"bg-green-100 pb-2 pt-2 text-xs xl:text-sm font-semibold text-green-700"},[_c('th',{staticClass:"p-3",staticStyle:{"width":"30%"}},[_vm._v(_vm._s(_vm.$reportType(_vm.selectedMonthlyReport).name))]),_vm._l((_vm.months),function(month){return _c('th',{key:month,staticClass:"w-1/5 border-t p-3 pl-2"},[_vm._v(" "+_vm._s(month)+" ")])}),_c('th',{staticClass:"p-3",staticStyle:{"width":"20%"}},[_vm._v("Yearly")])],2)]),_c('tbody',{staticClass:"text-xs xl:text-sm"},_vm._l((_vm.fetchedData),function(group,gid){return _c('tr',{key:gid,staticClass:"bg-white hover:bg-green-50 group"},[_c('td',{class:[
          gid === _vm.fetchedData.length - 1 &&
          group[_vm.$reportType(_vm.selectedMonthlyReport).type] === 'Total'
            ? 'font-bold'
            : 'font-semibold',
          'w-1/4 border-b-2 px-2 py-4 text-center capitalize bg-gray-100 text-gray-700 group-hover:bg-green-200' ]},[_vm._v(" "+_vm._s(_vm.$reportType(_vm.selectedMonthlyReport).type === "age" ? group[_vm.$reportType(_vm.selectedMonthlyReport).type] : _vm.$removeUnderscores( _vm.$removeHyphens(group[_vm.$reportType(_vm.selectedMonthlyReport).type]) ))+" ")]),_vm._l((group[_vm.$reportType(_vm.selectedMonthlyReport).data]),function(type,idx){return _c('td',{key:idx,class:[
          gid === _vm.fetchedData.length - 1 &&
          group[_vm.$reportType(_vm.selectedMonthlyReport).type] === 'Total'
            ? 'text-gray-600 group-hover:bg-green-200'
            : 'hover:text-gray-600 hover:bg-green-200',
          'w-1/4 items-center border-b-2 py-3 text-center font-medium' ],attrs:{"width":"25%"}},[(type[_vm.$reportType(_vm.selectedMonthlyReport).value] > 0)?_c('span',[_vm._v(_vm._s(type[_vm.$reportType(_vm.selectedMonthlyReport).value]))]):(
            type[_vm.$reportType(_vm.selectedMonthlyReport).value] === 0 &&
            _vm.selectedYear <= new Date().getFullYear() &&
            !(
              _vm.selectedYear === new Date().getFullYear() &&
              type.monthInt > new Date().getMonth() + 1
            )
          )?_c('span',[_vm._v("-")]):_c('span',{staticClass:"text-xs lowercase italic"},[_vm._v("N/A")])])}),_c('td',{class:[
          gid === _vm.fetchedData.length - 1 &&
          group[_vm.$reportType(_vm.selectedMonthlyReport).type] === 'Total'
            ? 'bg-green-50 font-bold'
            : 'bg-gray-50 font-medium',
          'items-center py-2 text-center capitalize text-gray-700 group-hover:bg-green-300' ]},[_vm._v(" "+_vm._s(group.total)+" ")])],2)}),0)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }