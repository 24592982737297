export default {
  state: () => ({
    isSideMenuOpen: false,
  }),
  mutations: {
    setSideMenu: (state) => (state.isSideMenuOpen = !state.isSideMenuOpen),
    setSideMenuFalse: (state) => (state.isSideMenuOpen = false),
  },
  getters: {
    showSideMenu: (state) => state.isSideMenuOpen,
  },
  actions: {
    toggleSideMenu: ({ commit }) => {
      commit("setSideMenu");
    },
    closeSideMenu: ({ commit }) => {
      commit("setSideMenuFalse");
    },
  },
};
