<template>
  <div class="rounded grid grid-rows-layout h-full w-full">
    <div class="px-2 pb-4">
      <div class="grid grid-cols-12 gap-2 md:gap-4 items-center ">
        <div class="col-span-6">
          <h3 class="border-b-2 border-red-300 py-4 space-x-2 inline-flex items-center text-red-300  -mb-px">
            <button
              type="button"
              v-if="!!showBack"
              @click.prevent="back"
              class="text-6xl hover:bg-red-200 transition p-2 hover:text-red-600 rounded-full text-red-500 bg-transparent outline-none focus:outline-none flex items-center justify-center"
              title="Back"
            >
              <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"></path>
              </svg>
            </button>
            <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 md:w-5 md:h-5" fill="currentColor" viewBox="0 0 16 16">
              <path
                d="M1 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2zM1 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7zM1 12a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2z"
              />
            </svg>
            <span class="text-xs text-red-800 md:text-base   font-normal  uppercase tracking-wider">{{ title }}</span>
          </h3>
        </div>

        <div class="col-span-6 flex items-center justify-end space-x-4">
          <!-- <span class="text-md text-yellow-700 font-semibold">Filter Queue:</span> -->
          <!--                     
                    <FormulateInput type="select" 
                                    @change="filter" 
                                    v-model="type" 
                                    placeholder="select type" 
                                    :options="[{value:'normal', label:'Normal Cases'}, {value:'emergency', label:'Emergency cases'}, {value:'', label:'All'} ]"
                                    
                                    /> -->

          <select
            @change="filter"
            v-model="type"
            placeholder="select type"
            :class="['rounded-xl  border border-gray-300 font-semibold focus:border-red-100', type.toLowerCase() === 'purple top' ? 'text-purple-700 bg-purple-100' : type.toLowerCase() === 'red top' ? 'text-red-700 bg-red-100' : 'bg-auto']"
          >
            <option value="" class="bg-white">All </option>
            <option value="Purple Top" class="bg-white">Purple Top</option>
            <option value="Red Top" class="bg-white">Red Top</option>
          </select>

          <div
            class="text-gray-500 w-full md:w-1/2 transition flex items-center border-gray-300 border focus-within:shadow-md rounded focus-within:border-opacity-25 focus-within:border-red-300 focus-within:text-gray-900  focus-within:ring-1 focus-within:ring-red-600"
          >
            <input @input="search" type="search" v-model="q" placeholder="Search..." class="outline-none w-full placeholder-gray-400 rounded-l py-1 px-3 border-0 border-none focus:ring-0 " @keyup.enter="search" />
            <button @click="search" class="p-2 focus:outline-none">
              <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
              </svg>
            </button>
          </div>
          <router-link
            v-if="!!showCreate"
            :to="{ name: createName }"
            class=" bg-red-100 hover:bg-red-200 font-semibold transition shadow-sm rounded-sm p-2 flex items-center text-sm text-red-500  hover:text-red-700 focus:outline-none transform hover:-translate-y-px hover:scale-110"
            tag="button"
            :title="createTitle"
          >
            <svg xmlns="http://www.w3.org/2000/svg" class="text-red-500 w-6 h-6" viewBox="0 0 24 24" stroke="none" fill="currentColor">
              <path d="M17,19.22H5V7h7V5H5C3.9,5,3,5.9,3,7v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-7h-2V19.22z" />
              <path d="M19,2h-2v3h-3c0.01,0.01,0,2,0,2h3v2.99c0.01,0.01,2,0,2,0V7h3V5h-3V2z" />
            </svg>

            <span class="hidden md:block">{{ createTitle }}</span>
          </router-link>
        </div>
      </div>
      <div class="flex justify-between text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b rounded-tl-md rounded-tr-md  dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800 px-1">
        <div :tableHeadingTitles="tableHeadingTitles" v-for="(tableTitle, index) in tableHeadingTitles" :key="index">
          <div v-html="tableTitle" class="px-4 py-3">
            {{ tableTitle }}
          </div>
        </div>
      </div>
    </div>

    <div>
      <div v-if="loading" class="flex items-center justify-center">
        <loading-indicator></loading-indicator>
      </div>

      <div v-if="!isEmpty(pagedResult)" class="">
        <div class="px-6 pb-4 divide-y divide-redgray-100 ">
          <div v-for="(item, index) in pagedResult.results" :key="item.id" class="hover:bg-primary-50 transition py-3 px-1">
            <slot v-bind:index="index" v-bind:item="item"></slot>
          </div>
        </div>
      </div>
      <div v-if="pagedResult.results.length === 0" class="flex justify-between">
        <span></span>
        <span class="text-xl text-yellow-600 font-semibold uppercase animate-pulse self-center">no donations found.</span>
        <span></span>
      </div>
    </div>
    <div>
      <div class="px-4 border-t py-3" v-if="!isEmpty(pagedResult)">
        <nav class="block">
          <ul class="flex pl-0 rounded list-none flex-wrap">
            <li>
              <span
                v-if="pagedResult.currentPage === 1"
                class="opacity-50 first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-gray-500 bg-white text-gray-500"
              >
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current">
                  <path d="M12.707 7.707l-1.414-1.414L5.586 12l5.707 5.707 1.414-1.414L8.414 12z" />
                  <path d="M16.293 6.293L10.586 12l5.707 5.707 1.414-1.414L13.414 12l4.293-4.293z" />
                </svg>
              </span>
              <router-link v-else :to="{ query: { page: 1 } }" class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-red-400 bg-white text-red-400">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current">
                  <path d="M12.707 7.707l-1.414-1.414L5.586 12l5.707 5.707 1.414-1.414L8.414 12z" />
                  <path d="M16.293 6.293L10.586 12l5.707 5.707 1.414-1.414L13.414 12l4.293-4.293z" />
                </svg>
              </router-link>
            </li>
            <li>
              <span
                v-if="pagedResult.currentPage === 1"
                class="opacity-50 first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-gray-500 bg-white text-gray-500"
              >
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current">
                  <path d="M13.293 6.293L7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z" />
                </svg>
              </span>
              <router-link
                v-else
                :to="{ query: { page: pagedResult.currentPage - 1 } }"
                class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-red-400 bg-white text-red-400"
              >
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current">
                  <path d="M13.293 6.293L7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z" />
                </svg>
              </router-link>
            </li>
            <li v-for="page in pagedResult.pages" :key="page">
              <span v-if="page === pagedResult.currentPage" class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-red-400 bg-red-400 text-white">{{
                page
              }}</span>
              <router-link
                v-else
                :to="{ query: { page: page } }"
                class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-red-400 bg-white text-red-400"
                >{{ page }}</router-link
              >
            </li>
            <li>
              <span
                v-if="pagedResult.currentPage === pagedResult.totalPages"
                class="opacity-50 first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-gray-500 bg-white text-gray-500"
              >
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current">
                  <path d="M10.707 17.707L16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z" />
                </svg>
              </span>
              <router-link
                v-else
                :to="{ query: { page: pagedResult.currentPage + 1 } }"
                class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-red-400 bg-white text-red-400"
              >
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current">
                  <path d="M10.707 17.707L16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z" />
                </svg>
              </router-link>
            </li>
            <li>
              <span
                v-if="pagedResult.currentPage === pagedResult.totalPages"
                class="opacity-50 first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-gray-500 bg-white text-gray-500"
              >
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current">
                  <path d="M10.296 7.71L14.621 12l-4.325 4.29 1.408 1.42L17.461 12l-5.757-5.71z" />
                  <path d="M6.704 6.29L5.296 7.71 9.621 12l-4.325 4.29 1.408 1.42L12.461 12z" />
                </svg>
              </span>
              <router-link
                v-else
                :to="{ query: { page: pagedResult.totalPages } }"
                class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-red-400 bg-white text-red-400"
              >
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current">
                  <path d="M10.296 7.71L14.621 12l-4.325 4.29 1.408 1.42L17.461 12l-5.757-5.71z" />
                  <path d="M6.704 6.29L5.296 7.71 9.621 12l-4.325 4.29 1.408 1.42L12.461 12z" />
                </svg>
              </router-link>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <router-view> </router-view>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    url: String,
    showCreate: { type: Boolean, default: true },
    showBack: { type: Boolean, default: false },
    createName: { type: String, default: "Create" },
    createTitle: { type: String, default: "New" },
    tableHeadingTitles: {
      Array,
      default: function() {
        return [];
      },
    },
  },
  //async created() {
  //    await this.fetch();
  //},
  data() {
    return {
      loading: false,
      pagedResult: {},
      error: {},
      q: this.$route.query.q,
      type: "",
      displayFilter: false,
    };
  },
  watch: {
    $route: {
      immediate: true,
      async handler() {
        if (this.$route.matched.length === 1 || (this.$route.matched.length > 1 && Object.keys(this.pagedResult).length === 0 && this.pagedResult.constructor === Object)) {
          await this.fetch();
        }
      },
    },
  },
  methods: {
    fetch() {
      this.loading = true;
      this.pagedResult = {};
      this.$callApi
        .get(this.url, { params: { ...this.$route.query } })
        .then((x) => {
          this.pagedResult = x.data;
        })
        .catch(() => {
          //this.error = err;
          //console.log(err);
        })
        .then(() => (this.loading = false));
    },
    filter() {
      if (this.type !== "") {
        this.$router.push({ query: { type: this.type } });
      } else {
        this.$router.push({ query: {} });
      }
    },
    search() {
      if (this.q.length >= 3) {
        this.$router.push({ query: { q: this.q } });
      } else {
        if (this.$route.query.q !== undefined) {
          this.q = "";
          this.$router.push({ query: {} });
        }
      }
    },
    navigate(queryString) {
      this.$router.push({ path: this.$route.path, query: { ...queryString } });
    },
    isEmpty(obj) {
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    },
    back() {
      this.$router.back();
    },
    // showFilterQueueDropdown(){
    //     console.log(this.$refs)
    //     this.$refs.filterQueue.click()
    // },
    // displayComponent() {
    //     this.displayFilter = !this.displayFilter
    // }
  },
};
</script>
