<template>
  <div class="overflow-x-auto">
    <ejs-grid ref="egrid" :dataSource="data" width='auto' height='auto' :allowTextWrap='true'
      :textWrapSettings='wrapSettings' gridLines='Both' allowPaging="true" :rowTemplate="'rowTemplate'">
      <e-columns>
        <e-column :field="defermentReason" :headerText=$reportType(selectedReport).name width=129 textAlign="center"></e-column>
        <e-column field="Gender" :headerText=$reportType(selectedReport).action textAlign="center" width=100 class="w-full"></e-column>
        <e-column field="January" headerText='Jan' textAlign="center"></e-column>
        <e-column field="February" headerText='Feb' textAlign="center"></e-column>
        <e-column field="March" headerText='March' textAlign="center"></e-column>
        <e-column field="April" headerText='April' textAlign="center"></e-column>
        <e-column field="May" headerText='May' textAlign="center"></e-column>
        <e-column field="June" headerText='June' textAlign="center"></e-column>
        <e-column field="July" headerText='July' textAlign="center"></e-column>
        <e-column field="August" headerText='Aug' textAlign="center"></e-column>
        <e-column field="September" headerText='Sept' textAlign="center"></e-column>
        <e-column field="October" headerText='Oct' textAlign="center"></e-column>
        <e-column field="November" headerText='Nov' textAlign="center"></e-column>
        <e-column field="December" headerText='Dec' textAlign="center"></e-column>
        <e-column field="Yearly" headerText='Yearly' textAlign="center"></e-column>
      </e-columns>

      <template v-slot:rowTemplate="{ data }">
        <component :is="getComponent(selectedReport)" :data="data"></component>
      </template>
    </ejs-grid>
  </div>
</template>


<script>
import { Freeze, Page } from '@syncfusion/ej2-vue-grids';
import MonthlyDonorDeferrals from '@/components/data/donation/reports/MonthlyDonorDeferrals.vue'
import BloodComponentsReport from '@/components/data/bank/reports/BloodComponentsReport.vue'
import BloodUnitsSeparated from '@/components/data/componentSeparation/reports/custom/BloodUnitsSeparated.vue'
// import "../assets/bootstrap5.css";

export default {
  props: {
    selectedReport: String,
    fetchedData: [Array, Object],
    url: String,
  },

  data() {
    return {
      loading: false,
      wrapSettings: { wrapMode: 'Content' },
      pageOptions: { pageSize: this.fetchedData.pageSize, pageCount: 4 },
      data: {
        result: this.fetchedData.results,
        count: this.fetchedData.totalItems
      },
      myColumn: (month) => {
        var data = []
        if (this.selectedReport === 'monthly-Donor-Deferrals') {
          for (var i = 0; i < 4; i++) {
            var items = []
            var gender = { 0: 'M', 1: 'F', 2: 'I', 3: 'U', };
            items.field = "months." + month + ".deferals." + i + ".numberOfDeffreDonors"
            items.headerText = gender[i]
            items.width = 50
            items.textAlign = "center"
            items.minWidth = 10
            data.push(items)
          }
        }
        return data
      },
      myColumnTotal: () => {
        var data = []
        if (this.selectedReport === 'monthly-Donor-Deferrals') {
          for (var i = 0; i < 4; i++) {
            var items = []
            var gender = { 0: 'M', 1: 'F', 2: 'I', 3: 'U', };
            items.field = "total." + i + ".numberOfDeffreDonors"
            items.headerText = gender[i]
            items.width = 50
            items.textAlign = "center"
            items.minWidth = 10
            items.freeze = "Right"
            data.push(items)
          }
        }
        return data
      },
      months: ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
    };
  },
  provide: {
    grid: [Freeze, Page]
  },
  methods: {
    getComponent: (selected) => {
      const components = {
        'monthly-Donor-Deferrals': MonthlyDonorDeferrals,
        'Monthly-Blood-Issued-Requested-Ratio': BloodComponentsReport,
        'Blood-units-separated': BloodUnitsSeparated
      }
      return components[selected]
    },
    removeHyphen: function (field, data) {
      if (this.selectedReport === 'monthly-donations-by-age-report') {
        return data[field]
      } else return this.$removeHyphens(data[field])
    },
    dataStateChange: function () {
      var grid = document.getElementsByClassName("e-grid")[0].ej2_instances[0];
      this.fetchData(grid.pagerModule.pagerObj.currentPage)
      this.$refs.egrid.$el.hidden = true
    },
    fetchData(page) {
      this.loading = true;
      this.$callApi
        .get(this.url + '&page=' + page, {
          params: { ...this.$route.query },
        })
        .then((x) => {
          this.$refs.egrid.$el.hidden = false
          var grid = document.getElementsByClassName("e-grid")[0].ej2_instances[0];
          grid.dataSource = { result: x.data.data.results, count: x.data.data.totalItems }
          this.data.result = x.data.data.results
          this.data.count = x.data.data.totalItems
        })
        .catch(() => {
          //this.error = err;
        })
        .then(() => (this.loading = false));
    },
    refreshGrid() {
      this.$refs.egrid.refresh();
    }

  },
  mounted() {
    this.refreshGrid()
  },

}
</script>
