import vue from "vue"
export default {
    //namespaced: true,
    state: () => ({
        storeForm: {}
    }),
    mutations: {
        setForm: (state, form) => vue.set(state, "storeForm", form),
        clearForm: (state) => vue.set(state, "storeForm", {})
    },
    getters: {
        getForm: (state) => state.storeForm
    },
    actions: {
        saveStoreForm({ commit }, data) {
            commit("setForm", data);
        },
        clearStoreForm({ commit }) {
            commit("clearForm");
        }
    }
}