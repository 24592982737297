<template>
  <div class="flex">
    <div class="mx-auto">
      <div class="flex items-center">
        <router-link :title="button.title || button.caption" :to="button.to" v-for="(button, index) in buttons" :key="index" class="flex items-center space-x-2 transition w-full first:rounded-l-md even:border first:border-l odd:border-y last:border-r text-xs font-medium last:rounded-r-md text-gray-500 bg-white hover:text-primary-400 hover:bg-gray-100 px-4 py-2">
          <span v-if="button.icon" v-html="button.icon" class=""></span>
          <span class="truncate text-clip" v-html="button.caption "></span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MultipleButtons",
  props: {
    buttons: { type: Array, required: true },
    
  },
};
</script>
