export default [
  //recipients registry
  {
    path: "/transfusion/recipients",
    name: "TransfusionRecipientsRegistry",
    component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/transfusion/RecipientsRegistry.vue"),
    children: [
      {
        path: "add",
        name: "addTransfusionRecipient",
        component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/transfusion/RegisterRecipient.vue"),
        props: () => ({
          toFetch: false,
          title: "Add Recipient",
          postUrl: "/transfusion/Recipients",
          optionsUrls: ["transfusion/Lookups/blood-groups/options"],
          jsonSchema: "transfusion/forms/add-recipient",
          size: "max-w-2xl",
        }),
      },
      {
        path: "update/:id",
        name: "updateTransfusionRecipient",
        component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/transfusion/RegisterRecipient.vue"),
        props: (x) => ({
          toFetch: true,
          title: "Update Recipient",
          postUrl: `/transfusion/Recipients/${x.params.id}`,
          optionsUrls: [],
          jsonSchema: "transfusion/forms/add-facility",
          size: "max-w-lg",
        }),
      },
      {
        path: "add-adverse-effect/:recipientId",
        name: "AddTransfusionAdverseEffectRegistry",
        component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/transfusion/components/SchemaModal.vue"),
        props: (x) => ({
          toFetch: false,
          title: "Add an Adverse Effect",
          postUrl: `/transfusion/AdverseEffects/${x.params.recipientId}`,
          optionsUrls: ["transfusion/Lookups/report-type/options", "transfusion/Lookups/yesno/options", "transfusion/Lookups/outcome-category/options"],
          jsonSchema: "transfusion/forms/add-adverse-effect",
          size: "max-w-5xl",
          generateUserName: true,
        }),
      },

      {
        path: ":recipientId/activate/:clientName",
        name: "TransfusionRecipientActivation",
        component: () => import(/* webpackChunkName: "store" */ "@/views/transfusion/components/SchemaModal.vue"),
        props: (x) => ({
          toFetch: false,
          title: `Activate Recipient ${x.params.clientName}`,
          postUrl: `transfusion/Recipients/${x.params.recipientId}/ActivateExistingRecipient`,
          optionsUrls: [],
          jsonSchema: "transfusion/forms/activate-recipient",
          size: "max-w-lg",
        }),
      },
    ],
  },

  //active recipients
  {
    path: "/transfusion/active-recipients",
    name: "TransfusionActiveRecipients",
    component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/transfusion/ActiveRecipients.vue"),
    children: [
    
      {
        path: "end-visit/:visitGuid/:clientName",
        name: "EndTransfusionRecipientVisitRequest",
        component: () => import(/* webpackChunkName: "store" */ "@/views/transfusion/components/SchemaModal.vue"),
        props: (x) => ({
          toFetch: false,
          title: `End Visit for ${x.params.clientName}`,
          verb: "PUT",
          postUrl: `transfusion/Recipients/${x.params.visitGuid}/CloseRecipientTransfusionVisit`,
          optionsUrls: [],
          jsonSchema: "transfusion/forms/end-visit",
          size: "max-w-lg",
        }),
      },
    ],
  },

  //visit history
  {
    path: "/transfusion/recipients-visits/:recipientId/visits",
    name: "transfusionVisitsByRecipient",
    props: (x) => ({
      recipientId: x.params.recipientId,
    }),
    component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/transfusion/VisitsByRecipientList.vue"),
    children: [],
  },
  //ward request visit history
  {
    path: "/transfusion/recipients-visits/:recipientId/visits/:visitId/wardRequests",
    name: "ViewWardRequestsByVisitRecipient",
    component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/transfusion/WardRequests/VisitedRecipientWardRequestsList.vue"),
    props: (x) => ({
      visitId: x.params.visitId,
    }),
    children: [
      {
        path: ":requestId/view-request",
        name: "ViewTransfusionRecipientRequestFromVisit",
        props: (x) => ({
          title: x.params.title,
          requestId: x.params.requestId,
        }),
        component: () => import(/* webpackChunkName: "tests" */ "@/views/transfusion/WardRequests/RecipientRequest.vue"),
      },
       

    ],
  },
  //adding adverse effects
  {
    path: "/transfusion/recipients-visits/:recipientId/adverse-effects",
    name: "ViewTransfusionAdverseEffects",
    component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/transfusion/RecipientsAdverseEffectsList.vue"),
    props: (x) => ({
      recipientId: x.params.recipientId,
    }),
    children: [
      {
        path: "add",
        name: "AddTransfusionAdverseEffect",
        component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/transfusion/components/SchemaModal.vue"),
        props: (x) => ({
          toFetch: false,
          title: "Add an Adverse Effect",
          postUrl: `/transfusion/AdverseEffects/${x.params.recipientId}`,
          optionsUrls: ["transfusion/Lookups/report-type/options", "transfusion/Lookups/yesno/options", "transfusion/Lookups/outcome-category/options"],
          jsonSchema: "transfusion/forms/add-adverse-effect",
          size: "max-w-5xl",
          generateUserName: true,
        }),
      },
      {
        path: ":id/update",
        name: "UpdateTransfusionAdverseEffect",
        component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/transfusion/components/SchemaModal.vue"),
        props: (x) => ({
          toFetch: true,
          title: "Update Adverse Effect",
          postUrl: `/transfusion/AdverseEffects/${x.params.id}`,
          optionsUrls: ["transfusion/Lookups/report-type/options", "transfusion/Lookups/yesno/options", "transfusion/Lookups/outcome-category/options"],
          jsonSchema: "transfusion/forms/add-adverse-effect",
          size: "max-w-5xl",
        }),
      },
    ],
  },

  //transfusion history
  {
    path: "/transfusion/recipients-visits/:recipientId/visits/:visitId/transfusions",
    name: "ViewTransfusionTransfusionsByVisit",
    component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/transfusion/RecipientTransfusionsByVisit.vue"),
    props: (x) => ({
      visitId: x.params.visitId,
    }),
    children: [
      {
        path: "/transfusion/recipients-visits/:recipientId/visits/:visitId/transfusions/:requestId/:transfusionId/view",
        name: "ViewTransfusionRequest",
        props: (x) => ({
          title: x.params.title,
          requestId: x.params.requestId,
          transfusionId: x.params.transfusionId,
        }),
        component: () => import(/* webpackChunkName: "tests" */ "@/views/transfusion/ViewTransfusionDetails.vue"),
      },
    ],
  },
  // ward request history
  {
    path: "/transfusion/recipients-visits/:recipientId/visits/:visitId/wardRequests/:requestId/view-request",
    name: "ViewTransfusionVisitedRecipientRequest",
    props: (x) => ({
      title: x.params.title,
      requestId: x.params.requestId,
    }),
    component: () => import(/* webpackChunkName: "tests" */ "@/views/transfusion/WardRequests/RecipientRequest.vue"),
    children: [],
  },

  //active recipient sample requests list
  {
    path: "/transfusion/recipients/:visitId/:visitGuid/:recipientName/sample-requests",
    name: "ViewActiveRecipientSampleRequests",
    component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/transfusion/RecipientsSamples/ActiveRecipientSampleRequestsList.vue"),
    props: (x) => ({
      visitId: x.params.visitId,
    }),
    children: [
   
      {
        path: "send",
        name: "SendRecipientSampleTransfusion",
        component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/transfusion/components/SchemaModal.vue"),
        props: (x) => ({
          toFetch: false,
          title: `Send Sample ${x.params.recipientName}`,
          postUrl: `transfusion/RecipientSample/${x.params.visitId}`,
          optionsUrls: ['transfusion/Wards/wardsOptions', 'transfusion/RecipientSample/testOptions'],
          jsonSchema: "transfusion/forms/send-sample",
          size: "max-w-xl",
          generateDateTime: true,
          generateUserName:true
        }),
      },
    ],
  },

  //active recipient ward requests list
  {
    path: "/transfusion/recipients/:visitId/:visitGuid/:recipientName/wardRequests",
    name: "ViewActiveRecipientWardRequests",
    component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/transfusion/WardRequests/ActiveRecipientWardRequestsList.vue"),
    props: (x) => ({
      visitId: x.params.visitId,
    }),
    children: [
      {
        path: ":requestId/cancel-request/:requisitionNumber",
        name: "CancelTransfusionRecipientRequest",
        component: () => import(/* webpackChunkName: "store" */ "@/views/transfusion/components/SchemaModal.vue"),
        props: (x) => ({
          toFetch: false,
          title: `Cancel Request ${x.params.requisitionNumber}`,
          verb: "PUT",
          postUrl: `transfusion/WardRequest/${x.params.requestId}/CancelWardRequisition`,
          optionsUrls: [],
          jsonSchema: "transfusion/forms/cancel-ward-request",
          size: "max-w-lg",
        }),
      },
      {
        path: ":requestId/more-units/:requisitionNumber",
        name: "RequestBTUFulfilmentTransfusion",
        component: () => import(/* webpackChunkName: "store" */ "@/views/transfusion/components/SchemaModal.vue"),
        props: (x) => ({
          toFetch: false,
          title: `Request BTU for Further Fulfilment : ${x.params.requisitionNumber}`,
          verb: "PUT",
          postUrl: `transfusion/WardRequest/${x.params.requestId}/request-btu-processing`,
          optionsUrls: [],
          jsonSchema: "transfusion/forms/request-another-btu-fulfilment",
          size: "max-w-lg",
        }),
      },
      {
        path: ":requestId/view-request",
        name: "ViewTransfusionRecipientRequest",
        props: (x) => ({
          title: x.params.title,
          requestId: x.params.requestId,
        }),
        component: () => import(/* webpackChunkName: "tests" */ "@/views/transfusion/WardRequests/RecipientRequest.vue"),
        children: [],
      },
      {
        path: "add",
        name: "AddTransfusionActiveRecipientWardRequest",
        component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/transfusion/WardRequests/AddWardRequest.vue"),
        props: (x) => ({
          toFetch: false,
          title: `Request Blood for ${x.params.recipientName} `,
          postUrl: `/transfusion/WardRequest/${x.params.visitGuid}`,
          size: "w-3/5",
        }),
      },
    ],
  },
//active recipient ward request

  {
    path: "/transfusion/recipients/:visitId/:visitGuid/wardRequests/:requestId/view-request",
    name: "ViewTransfusionVisitRecipientRequest",
    props: (x) => ({
      title: x.params.title,
      requestId: x.params.requestId,
    }),
    component: () => import(/* webpackChunkName: "tests" */ "@/views/transfusion/WardRequests/RecipientRequest.vue"),
    children: [],
  },

//transfusion queue
  {
    path: "/transfusion/transfusion-requests",
    name: "WardRequestsForTransfusion",
    props: () => ({
      buttonTitle: "Transfuse",
      buttonRouteName: "ProcessTransfusionRequest",
      listTitle: "Transfusion Requests",
      purpose: "transfuse",
      url: "transfusion/Transfusion/GetAllWardRequests",
    }),
    component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/transfusion/WardRequests/WardRequestsList.vue"),
    children: [],
  },
// process transfusion request
  {
    path: "/transfusion/ward-requests/:requestId/transfusion-home",
    // name: "ProcessTransfusionRequest",
    props: (x) => ({
      title: x.params.title,
      requestId: x.params.requestId,
      backRouterName: "WardRequestsForTransfusion",
    }),
    component: () => import(/* webpackChunkName: "tests" */ "@/views/transfusion/components/TranfusionProcessTemplate.vue"),
    children: [
      {
        path: "transfusion-list",
        name: "ProcessTransfusionRequest",
        props: (x) => ({
          title: 'Transfusions List',
          requestId: x.params.requestId,
          backRouterName: "WardRequestsForTransfusion",
        }),
        component: () => import(/* webpackChunkName: "tests" */ "@/views/transfusion/transfusionProcess/TransfusionByWardRequests.vue"),
        children :[

          {
            path: "start-transfusion",
            name: "StartTransfusionSession",
            component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/transfusion/transfusionProcess/TransfusionTimer.vue"),
            props: (x) => ({
              toFetch: false,
              title: "Start Transfusion",
              requestId: x.params.requestId,
              generateDateTime: true,
              postUrl: `/transfusion/Transfusion/${x.params.requestId}`,
              optionsUrls: [`/transfusion/Transfusion/${x.params.requestId}/ListOFBloodProductsIssuedForTransfusion`],
              jsonSchema: "transfusion/forms/start-transfusion",
              size: "max-w-4xl",
              backRouterName: "ProcessTransfusionRequest",
              bloodUnitDataIndex: 0,
            }),
          },
          {
            path: "end-postTransfusionCare/:transfusionDetailId",
            name: "EndPostTransfusionCareFromList",
            component: () =>
              import(
                /* webpackChunkName: "transfusion-group" */
                "@/components/data/ConfirmPostModal.vue"
              ),
            props: (x) => ({
              verb: "PUT",
              title: "Has Post-Transfusion Care Ended",
              postUrl: `transfusion/Transfusion/${x.params.transfusionDetailId}/EndTransfusion`,
              size: "max-w-sm",
              bodyText: "confirm that post transfusion care is completed",
            })
          }
        ]

      },
      {
        path: "transfusion-details/:transfusionDetailId",
        name: "TransfusionRequestDetails",
        props: (x) => ({
          title: 'Transfusion Details',
          transfusionDetailId: x.params.transfusionDetailId,
          backRouterName: "ProcessTransfusionRequest",
        }),
        component: () => import(/* webpackChunkName: "tests" */ "@/views/transfusion/transfusionProcess/Transfuse.vue"),
        children: [
          {
            path: ":nextTransfusion/add-observation/:transfusionId",
            name: "AddTransfusionObservation",
            component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/transfusion/components/SchemaModal.vue"),
            props: (x) => ({
              toFetch: false,
              title: "Add Observation",
              requestId: x.params.requestId,
              nextValue: x.params.nextTransfusion,
              replaceValue: "nextObservation",
              optionsArrayPosition: 0,
              postUrl: `/transfusion/Observations/${x.params.transfusionId}`,
              optionsUrls: ["transfusion/Lookups/hours-of-observation/options"],
              jsonSchema: "transfusion/forms/add-observation",
              size: "max-w-4xl",
            }),
          },
          {
            path: ":donationNumber/:transfusionId/end-transfusion",
            name: "EndTransfusionSession",
            component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/transfusion/transfusionProcess/TransfusionTimer.vue"),
            props: (x) => ({
              toFetch: false,
              title: "End Transfusion",
              verb: "PUT",
              generateDateTime: true,
              requestId: x.params.requestId,
              postUrl: `/transfusion/Transfusion/${x.params.transfusionId}`,
              optionsUrls: [ "transfusion/Lookups/hours-of-observation/options"],
              backRouterName: "TransfusionRequestDetails",
              jsonSchema: "transfusion/forms/end-transfusion",
              compareRouterDonationNumber : true,
              routerDonationNumber: x.params.donationNumber,
              size: "max-w-4xl",
              generateUserName: true,


            }),
         
          },
          {
            path: "add-reaction/:transfusionId",
            name: "AddReactionsTransfusionSession",
            component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/transfusion/components/SchemaModal.vue"),
            props: (x) => ({
              toFetch: false,
              title: "Add Reaction Observed and Intervention Drugs",
              generateTime: true,
              requestId: x.params.requestId,
              postUrl: `/transfusion/SignsAndSymptoms/${x.params.transfusionId}`,
              optionsUrls: [
                "transfusion/Lookups/general-reactions/options",
                "transfusion/Lookups/dermatological-reactions/options",
                "transfusion/Lookups/cardiac-respiratory-reactions/options",
                "transfusion/Lookups/renal-reactions/options",
                "transfusion/Lookups/haematological-reactions/options",
              ],
              jsonSchema: "transfusion/forms/signs-and-symptoms",
              size: "max-w-4xl",
              toArrayWithObjects: true,
              arrayName: "specificSignsAndSymptoms",
              checkEmptinessReactionsForm: true,
            }),
          },
          {
            path: "end-postTransfusionCare/:transfusionId",
            name: "EndPostTransfusionCare",
            component: () =>
              import(
                /* webpackChunkName: "transfusion-group" */
                "@/components/data/ConfirmPostModal.vue"
              ),
            props: (x) => ({
              verb: "PUT",
              title: "Has Post-Transfusion Care Ended",
              postUrl: `transfusion/Transfusion/${x.params.transfusionId}/EndTransfusion`,
              size: "max-w-sm",
              bodyText: "confirm that post transfusion care is completed",
            }),
          },
          
        ],
      },
      {
        path: "end-visit/:visitGuid",
        name: "EndTransfusionRecipientVisit",
        component: () => import(/* webpackChunkName: "store" */ "@/views/transfusion/components/SchemaModal.vue"),
        props: (x) => ({
          toFetch: false,
          title: `End Visit`,
          verb: "PUT",
          postUrl: `transfusion/Recipients/${x.params.visitGuid}/CloseRecipientTransfusionVisit`,
          optionsUrls: [],
          jsonSchema: "transfusion/forms/end-visit",
          size: "max-w-lg",
        }),
      },
    ],
  },
  //departments
  {
    path: "/transfusion/departments",
    name: "TransfusionDepartments",
    component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/transfusion/Setup/Departments.vue"),
    children: [
      {
        path: "add",
        name: "addTransfusionDepartment",
        component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/transfusion/components/SchemaModal.vue"),
        props: () => ({
          toFetch: false,
          title: "Add Department",
          postUrl: "/transfusion/Departments",
          optionsUrls: [],
          jsonSchema: "transfusion/forms/add-department",
          size: "max-w-2xl",
        }),
      },
      {
        path: "update/:departmentId",
        name: "updateTransfusionDepartment",
        component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/transfusion/components/SchemaModal.vue"),
        props: (x) => ({
          toFetch: true,
          title: "Update Department",
          postUrl: `transfusion/Departments/${x.params.departmentId}`,
          optionsUrls: [],
          jsonSchema: "transfusion/forms/add-department",
          size: "max-w-2xl",
        }),
      },
    ],
  },
  //wards
  {
    path: "/transfusion/departments/:departmentId/wards",
    name: "TransfusionWardsByDepartments",
    component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/transfusion/WardsListByDepartments.vue"),
    props: (x) => ({
      departmentId: x.params.departmentId,
    }),
    children: [
      {
        path: "add",
        name: "addTransfusionWard",
        component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/transfusion/components/SchemaModal.vue"),
        props: (x) => ({
          toFetch: false,
          title: "Add Ward",
          postUrl: `/transfusion/Wards/${x.params.departmentId}`,
          optionsUrls: [],
          jsonSchema: "transfusion/forms/add-ward",
          size: "max-w-2xl",
        }),
      },
      {
        path: "update/:wardId",
        name: "updateTransfusionWard",
        component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/transfusion/components/SchemaModal.vue"),
        props: (x) => ({
          toFetch: true,
          title: "Update Ward",
          postUrl: `transfusion/Wards/${x.params.wardId}`,
          optionsUrls: [],
          jsonSchema: "transfusion/forms/add-ward",
          size: "max-w-2xl",
        }),
      },
    ],
  },
  
  {
    path: "/transfusion/return-requests",
    name: "TransfusionReturnUnitsRequests",
    component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/transfusion/ReturnUnits/ReturnUnitRequestsList.vue"),
    children: [
      {
        path: "/transfusion/return-requests/add",
        name: "SelectUnitsForReturnTransfusion",
        component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/transfusion/ReturnUnits/AssignPints.vue"),
        props: () => ({
          toFetch: false,
          title: `Return Unit`,
          verb: "POST",
          url: `transfusion/BloodUnitsToBTU/ListOfBloodProductsToBeReturned`,
          postUrl: `transfusion/BloodUnitsToBTU/ReturnBloodUnitsToStore`,
          size: "max-w-4xl",
          optionsUrls: ["transfusion/Lookups/reasons-for-returning-units/options"],
          jsonSchema : "transfusion/forms/return-unit-to-btu",
          backRouterName: "TransfusionReturnUnitsRequests",
          generateUserName: true,
          generateDateTime: true
        }),
      },

    ],
  },

  
  
  //option groups
  {
    path: "/transfusion/option-groups",
    name: "ManageTransfusionOptions",
    component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/transfusion/Setup/OptionGroupList.vue"),
    children: [
      {
        path: "add",
        name: "addTransfusionOptionGroup",
        component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/transfusion/components/SchemaModal.vue"),
        props: () => ({
          toFetch: false,
          title: "Add Option Group",
          postUrl: `transfusion/Lookups/0`,
          optionsUrls: [],
          jsonSchema: "transfusion/forms/add-option-group",
          size: "max-w-xl",
        }),
      },
      {
        path: "update/:id",
        name: "updateTransfusionOptionGroup",
        component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/transfusion/components/SchemaModal.vue"),
        props: (y) => ({
          toFetch: true,
          title: "Update Option Group",
          postUrl: `transfusion/Lookups/${y.params.id}`,
          optionsUrls: [],
          jsonSchema: "transfusion/forms/add-option",
          size: "max-w-md",
        }),
      },
    ],
  },
  {
    path: "/transfusion/option-groups/:optionGroupId/options",
    name: "viewTransfusionOptions",
    props: (x) => ({
      optionGroupId: x.params.optionGroupId,
    }),
    component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/transfusion/Setup/OptionsList.vue"),
    children: [
      {
        path: "add",
        name: "addTransfusionOption",
        component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/transfusion/components/SchemaModal.vue"),
        props: (y) => ({
          toFetch: false,
          title: "Add Option",
          postUrl: `transfusion/Lookups/${y.params.optionGroupId}`,
          optionsUrls: [],
          jsonSchema: "transfusion/forms/add-option",
          size: "max-w-xl",
        }),
      },
      {
        path: "update/:id",
        name: "updateTransfusionOption",
        component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/transfusion/components/SchemaModal.vue"),
        props: (y) => ({
          toFetch: true,
          title: "Update Option",
          postUrl: `transfusion/Lookups/${y.params.id}`,
          optionsUrls: [],
          jsonSchema: "transfusion/forms/add-option",
          size: "max-w-md",
        }),
      },
    ],
  },

//forms  
{
    path: "/transfusion/forms",
    name: "TransfusionForms",
    component: () =>
      import(
        /* webpackChunkName: "transfusion-group" */
        "@/views/transfusion/Setup/FormsList.vue"
      ),
    children: [
      {
        path: "create",
        name: "AddTransfusionForm",
        component: () =>
          import(
            /* webpackChunkName: "transfusion-group" */
            "@/components/data/JsonForm.vue"
          ),
        props: () => ({
          verb: "POST",
          toFetch: false,
          title: "Add Form ",
          postUrl: "transfusion/Forms",
        }),
      },
      {
        path: "edit/:id",
        title: "Update Form",
        name: "UpdateTransfusionForm",
        component: () =>
          import(
            /* webpackChunkName: "transfusion-group" */
            "@/components/data/JsonForm.vue"
          ),
        props: (x) => ({
          verb: "PUT",
          title: "Update Form ",
          toFetch: true,
          postUrl: `transfusion/forms/${x.params.id}`,
        }),
      },
      {
        path: "delete/:id",
        title: "Delete Form",
        name: "DeleteTransfusionForm",
        component: () =>
          import(
            /* webpackChunkName: "transfusion-group" */
            "@/components/data/DeleteModal.vue"
          ),
        props: (x) => ({
          verb: "DELETE",
          title: "Delete Form ",
          deleteUrl: `transfusion/forms/${x.params.id}`,
          size: "max-w-xs",
          name: "Delete Form",
        }),
      },
    ],
  },
  // start of expected results
  {
    path: "/transfusion/expected-results-forms",
    name: "TransfusionExpectedResultsForms",
    component: () =>
      import(
        /* webpackChunkName: "doctors-pool-group" */
        "@/views/transfusion/Setup/ExpectedResultsForms.vue"
      ),
    children: [
      {
        path: "create",
        name: "AddTransfusionTestForm",
        component: () =>
          import(
            /* webpackChunkName: "doctors-pool-group" */
            "@/components/data/JsonForm.vue"
          ),
        props: () => ({
          verb: "POST",
          toFetch: false,
          title: "Add Form ",
          postUrl: "transfusion/ExpectedResultsForms",
        }),
      },
      {
        path: "edit/:id",
        title: "Update Form",
        name: "UpdateTransfusionTestForm",
        component: () =>
          import(
            /* webpackChunkName: "doctors-pool-group" */
            "@/components/data/JsonForm.vue"
          ),
        props: (x) => ({
          verb: "PUT",
          title: "Update Form ",
          toFetch: true,
          postUrl: `transfusion/ExpectedResultsForms/${x.params.id}`,
        }),
      },
      {
        path: "delete/:id",
        title: "Delete Form",
        name: "DeleteTransfusionTestForm",
        component: () =>
          import(
            /* webpackChunkName: "doctors-pool-group" */
            "@/components/data/JsonForm.vue"
          ),
        props: (x) => ({
          verb: "DELETE",
          title: "Delete Form ",
          deleteUrl: `transfusion/ExpectedResultsForms/${x.params.id}`,
          size: "max-w-xs",
          name: "Delete Form",
        }),
      },
    ],
  },
  // expected results forms

  //recipient samples

];
