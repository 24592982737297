export default {
    state: () => ({
        selectedBloodComponents : {},
    }),
    mutations: {
        setSelectedBloodComponent(state, bloodComponent) { 
            let selectedBloodComponentsCopy = state.selectedBloodComponents
             selectedBloodComponentsCopy[bloodComponent.id] = bloodComponent;

            state.selectedBloodComponents =  Object.assign({}, state.selectedBloodComponents, selectedBloodComponentsCopy);
            
             },
        removeSelectedBloodComponent(state, bloodComponentId) { 
            let selectedBloodComponentsCopy = state.selectedBloodComponents
            delete selectedBloodComponentsCopy[bloodComponentId];
            state.selectedBloodComponents =  Object.assign({}, state.selectedBloodComponents, selectedBloodComponentsCopy);
        },
        resetSelectedBloodComponents(state){
            state.selectedBloodComponents =  Object.assign({}, {});
        },
      
    },
    getters: {
        getSelectedBloodComponents: (state)=> state.selectedBloodComponents,
        getSelectedBloodComponentsLength: (state) =>{ 
            return Object.keys(state.selectedBloodComponents).length},
        getKeysInSelectedBloodComponents:(state)=>{
           return Object.keys(state.selectedBloodComponents)
        }
        
    },
    actions: {
        addBloodComponent: ({commit}, payload) =>{
            commit("setSelectedBloodComponent", payload);
        },
        removeBloodComponent: ({commit}, payload) =>{
            commit("removeSelectedBloodComponent", payload);
        },
        resetSelectedBloodComponents: ({commit}) =>{
            commit("resetSelectedBloodComponents");

        }
    
    }
}