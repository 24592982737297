import ajax from "@/services/http";
export default {
  state: () => ({
    currentAreaName: "Home",
    currentArea: {},
    areas: [],
  }),
  mutations: {
    setArea: (state, name) => (state.currentAreaName = name),
    setAreas: (state, links) =>
      (state.areas = [
        {
          name: "Home",
          label: "Main Page",
          icon: `<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>`,
          links: [{ name: "Home", label: "Main Page" }],
        },
        ...links,
      ]),
  },
  getters: {
    currentArea: (state) => state.areas.find((x) => x.name === state.currentAreaName),
    areas: (state) => state.areas,
  },
  actions: {
    switchArea: ({ commit }, area) => {
      commit("setArea", area);
    },
    loadAreas: ({ commit }, { role, token }) => {
      ajax.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      ajax
        .get(`identity/designations/${role}`)
        .then((x) => {
          commit("setAreas", [...x.data]);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
