<template>
    <div>
        <report-shimmer v-if="loading"></report-shimmer>

        <ejs-grid :dataSource="data" ref="egrid" :allowTextWrap='true' :textWrapSettings='wrapSettings' gridLines='Both'
            allowPaging="true" :dataStateChange='dataStateChange' :pageSettings="pageOptions" rowHeight=60>
            <e-columns>
                <e-column 
                    :field="$reportType(selectedReport).type"
                    :headerText=$reportType(selectedReport).name :valueAccessor='removeHyphen' width=190
                    textAlign="center" freeze='Left'></e-column>
                <e-column
                    :field="$reportType(selectedReport).data + '.0.' + $reportType(selectedReport).value"
                    headerText='AB-' width=100 textAlign="center"></e-column>
                <e-column
                    :field="$reportType(selectedReport).data + '.1.' + $reportType(selectedReport).value"
                    headerText='A-' width=100 textAlign="center"></e-column>
                <e-column
                    :field="$reportType(selectedReport).data + '.2.' + $reportType(selectedReport).value"
                    headerText='A+' width=100 textAlign="center"></e-column>
                <e-column
                    :field="$reportType(selectedReport).data + '.3.' + $reportType(selectedReport).value"
                    headerText='AB+' width=100 textAlign="center"></e-column>
                <e-column
                    :field="$reportType(selectedReport).data + '.4.' + $reportType(selectedReport).value"
                    headerText='B-' width=100 textAlign="center"></e-column>
                <e-column
                    :field="$reportType(selectedReport).data + '.5.' + $reportType(selectedReport).value"
                    headerText='O+' width=100 textAlign="center"></e-column>
                <e-column
                    :field="$reportType(selectedReport).data + '.6.' + $reportType(selectedReport).value"
                    headerText='B+' width=100 textAlign="center"></e-column>
                <e-column
                    :field="$reportType(selectedReport).data + '.7.' + $reportType(selectedReport).value"
                    headerText='O-' width=100 textAlign="center"></e-column>
                 <e-column 
                    field="total"
                    headerText="Total" width=100
                    textAlign="center"></e-column>
            </e-columns>
        </ejs-grid>
    </div>

</template>


<script>
import vue from 'vue';
import { GridPlugin, Freeze, Page } from '@syncfusion/ej2-vue-grids';
// import "../assets/bootstrap5.css";

vue.use(GridPlugin);

export default {
    props: {
        selectedReport: String,
        fetchedData: [Array, Object],
        url: String,
    },

    data() {
        return {
            loading: false,
            wrapSettings: { wrapMode: 'Content' },
            pageOptions: { pageSize: this.fetchedData.pageSize, pageCount: 4},
            data: {
                result: this.fetchedData.results,
                count: this.fetchedData.totalItems
            },
            months: ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
        };
    },
    provide: {
        grid: [Freeze, Page]
    },
    methods: {
        removeHyphen: function (field, data) {
            return this.$removeHyphens(data[field])
        },
        dataStateChange: function () {
            var grid = document.getElementsByClassName("e-grid")[0].ej2_instances[0];
            this.fetchData(grid.pagerModule.pagerObj.currentPage)
            this.$refs.egrid.$el.hidden = true
        },
        fetchData(page) {
            this.loading = true;
            this.$callApi
                .get(this.url + '&page=' + page, {
                    params: { ...this.$route.query },
                })
                .then((x) => {
                    this.$refs.egrid.$el.hidden = false
                    var grid = document.getElementsByClassName("e-grid")[0].ej2_instances[0];
                    grid.dataSource = { result: x.data.data.results, count: x.data.data.totalItems }
                    this.data.result = x.data.data.results
                    this.data.count = x.data.data.totalItems
                })
                .catch(() => {
                    //this.error = err;
                })
                .then(() => (this.loading = false));
        },
    },
}
</script>
