export default [
    {
        path:'/reports/donation',
        name:"ReportsDonationMonthly",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/donation/reports/Reports.vue"),
    
    },
    {
        path:'/reports/lab',
        name:"ReportsLab",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/lab/Reports/Reports.vue"),

    },  
    {
        path:'/reports/component-separation',
        name:"ReportsComponent",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/component_separation/reports/Reports.vue"),

    },
  ];
  