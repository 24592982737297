<template>
  <div class="grid grid-cols-1 gap-4">
    <h2 class="my-4 px-6 text-4xl  text-gray-500 ">In<em class="text-primary/90">vein</em>tory at a Glance</h2>
    <stats />
    <!-- <monthly-donations /> -->
    <div class="grid grid-cols-1 md:grid-cols-2 gap-12 ">
      <latest-donations />
      <!-- <county-donations /> -->
      <kenya-map />
    </div>
  </div>
</template>
<script>
import Stats from "./Stats.vue";
import LatestDonations from "./LatestDonations.vue";
import KenyaMap from "./KenyaMap.vue";
// import CountyDonations from "./CountyDonations.vue";
export default {
  name: "main-dashboard",
  components: { Stats, LatestDonations, KenyaMap },
  data: () => ({}),
};
</script>
