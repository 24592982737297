<template>
  <div class="h-full">
    <div class="rounded grid grid-rows-layout h-full w-full" v-show="oidcIsAuthenticated">
      <div class="px-2 pb-4">
        <div class="grid grid-cols-12 gap-2 md:gap-4 items-center ">
          <div class="col-span-4">
            <h3 class="border-b-2 border-primary-300 py-4 space-x-2 inline-flex items-center text-primary-400  -mb-px">
              <button
                type="button"
                v-if="!!showBack"
                @click.prevent="back"
                class="text-6xl hover:bg-primary-200 transition p-2 hover:text-primary-600 rounded-full text-primary-500 bg-transparent outline-none focus:outline-none flex items-center justify-center"
                title="Back"
              >
                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"></path>
                </svg>
              </button>
              <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 md:w-5 md:h-5" fill="currentColor" viewBox="0 0 16 16">
                <path
                  d="M1 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2zM1 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7zM1 12a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2z"
                />
              </svg>
              <span class="text-xs text-primary-700 md:text-base   font-normal   tracking-wider"> {{ $removeHyphens(selectedReport) }} {{ this.pagedResult.totalItems ? '('+this.pagedResult.totalItems+')' : "" }} </span>
            </h3>
          </div>
          <div class="col-span-8 flex items-center justify-end space-x-1 ">
                Specify Year:
                <FormulateInput type="number" name="selectedYear" :value="new Date().getUTCFullYear()" v-model="selectedYear" @change="formatInput" class="mt-4 p-2 w-500" 
                    :validation-rules="{
                          noLetter:({value})=> !(/[a-zA-Z]/g.test(value)) 
                        }"
                        :validation-messages="{
                          noLetter: `Letters are not allowed`,
                        }"
                        validation="noLetter"
                        :show-optional="false"
                        error-behavior="value"                
                />
                Report Type:
                <select @change="selectMonthlyReport" v-model="selectedReport" placeholder="-- Select report type --" title="Please specify report type" class="rounded round italic px-2 font-semibold  shadow-sm">
                  <option value="monthly-donations-by-county" class="" selected>Monthly Donations per county</option>
                  <option value="monthly-Site-Donation-Report" class="">Monthly Donations per site</option>
                  <option value="monthly-Donor-Deferrals" class="">Monthly Donor deferrals</option>
                </select>
            <!-- </div> -->            
            
            <div class="flex flex-row w-full justify-center">
                <!-- <button class="transition  text-white bg-blue-500   border hover:bg-blue-600 hover:text-white rounded-md  tracking-normal text-normal leading-3 py-2 px-3 flex items-center" @click="printResults" v-if="pagedResult && pagedResult.results.length>0">Print Report</button> -->
            </div>
            <router-link
              v-if="!!showCustomAction"
              :to="{ name: customActionName }"
              class=" px-2 py-2 bg-primary-50 rounded flex items-center text-sm text-primary-500 focus:outline-none space-x-1 hover:bg-primary-400 hover:text-white"
              :title="createTitle"
            >
              <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="w-4 h-4" viewBox="0 0 384 512">
                <defs />
                <path
                  fill="currentColor"
                  d="M377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-153 31V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm64 160v48c0 4.4-3.6 8-8 8h-56v56c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8v-56h-56c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h56v-56c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v56h56c4.4 0 8 3.6 8 8z"
                />
              </svg>
              <span class="hidden md:block">{{ customActionTitle }}</span>
            </router-link>
            <router-link v-if="!!showCreate" :to="{ name: createName }" class=" px-2 py-2 bg-primary-50 rounded flex items-center text-sm text-primary-500 focus:outline-none space-x-1 hover:bg-primary-400 hover:text-white" :title="createTitle">
              <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="w-4 h-4" viewBox="0 0 384 512">
                <defs />
                <path
                  fill="currentColor"
                  d="M377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-153 31V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm64 160v48c0 4.4-3.6 8-8 8h-56v56c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8v-56h-56c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h56v-56c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v56h56c4.4 0 8 3.6 8 8z"
                />
              </svg>
              <span class="hidden md:block">{{ createTitle }}</span>
            </router-link>
          </div>
        </div>
        <div class="flex justify-between text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b rounded-tl-md rounded-tr-md   bg-gray-50   px-1">
          <div :tableHeadingTitles="tableHeadingTitles" v-for="(tableTitle, index) in tableHeadingTitles" :key="index">
            <div v-html="tableTitle" class="px-4 py-3">
              {{ tableTitle }}
            </div>
          </div>
        </div>
      </div>
      <div class="h-full border-b border-gray-100 ">
        <div v-if="loading" class="flex items-center justify-center">
          <loading-indicator></loading-indicator>
        </div>
        <div v-if="hasList" class="flex-1">
          <div class=" border-b  border-gray-100 divide-y divide-gray-100  ">
            
            <div v-for="(item, index) in pagedResult.results" :key="item.id" class="px-6 py-4 transition-all hover:bg-gray-50 ">
              <slot v-bind:index="index" v-bind:item="item" v-if="!item.age"></slot>
                
            </div>
          </div>
        </div>
        <div v-if="!hasList && !loading" class="h-full  flex items-center justify-center">
          <span class="text-xl text-yellow-600 font-semibold animate-pulse self-center">No records found.</span>
        </div>
      </div>
      <div>
        <div class="px-4  py-4 ">
          <nav v-if="!isEmpty(pagedResult)">
            <ul class="flex pl-0 rounded list-none flex-wrap" v-if="pagedResult.currentPage > 0">
              <li>
                <span
                  v-if="pagedResult.currentPage === 1"
                  class="opacity-50 first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-gray-500 bg-white text-gray-500"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current">
                    <path d="M12.707 7.707l-1.414-1.414L5.586 12l5.707 5.707 1.414-1.414L8.414 12z" />
                    <path d="M16.293 6.293L10.586 12l5.707 5.707 1.414-1.414L13.414 12l4.293-4.293z" />
                  </svg>
                </span>
                <router-link
                  v-else
                  :to="{ query: { page: 1 } }"
                  class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-primary-400 bg-white text-primary-400"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current">
                    <path d="M12.707 7.707l-1.414-1.414L5.586 12l5.707 5.707 1.414-1.414L8.414 12z" />
                    <path d="M16.293 6.293L10.586 12l5.707 5.707 1.414-1.414L13.414 12l4.293-4.293z" />
                  </svg>
                </router-link>
              </li>
              <li>
                <span
                  v-if="pagedResult.currentPage === 1"
                  class="opacity-50 first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-gray-500 bg-white text-gray-500"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current">
                    <path d="M13.293 6.293L7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z" />
                  </svg>
                </span>
                <router-link
                  v-else
                  :to="{ query: { page: pagedResult.currentPage - 1 } }"
                  class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-primary-400 bg-white text-primary-400"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current">
                    <path d="M13.293 6.293L7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z" />
                  </svg>
                </router-link>
              </li>
              <li v-for="page in pagedResult.pages" :key="page">
                <span
                  v-if="page === pagedResult.currentPage"
                  class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-primary-400 bg-primary-400 text-white"
                  >{{ page }}</span
                >
                <router-link
                  v-else
                  :to="{ query: { page: page } }"
                  class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-primary-400 bg-white text-primary-400"
                  >{{ page }}</router-link
                >
              </li>
              <li>
                <span
                  v-if="pagedResult.currentPage === pagedResult.totalPages"
                  class="opacity-50 first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-gray-500 bg-white text-gray-500"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current">
                    <path d="M10.707 17.707L16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z" />
                  </svg>
                </span>
                <router-link
                  v-else
                  :to="{ query: { page: pagedResult.currentPage + 1 } }"
                  class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-primary-400 bg-white text-primary-400"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current">
                    <path d="M10.707 17.707L16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z" />
                  </svg>
                </router-link>
              </li>
              <li>
                <span
                  v-if="pagedResult.currentPage === pagedResult.totalPages"
                  class="opacity-50 first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-gray-500 bg-white text-gray-500"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current">
                    <path d="M10.296 7.71L14.621 12l-4.325 4.29 1.408 1.42L17.461 12l-5.757-5.71z" />
                    <path d="M6.704 6.29L5.296 7.71 9.621 12l-4.325 4.29 1.408 1.42L12.461 12z" />
                  </svg>
                </span>
                <router-link
                  v-else
                  :to="{ query: { page: pagedResult.totalPages } }"
                  class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-primary-400 bg-white text-primary-400"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current">
                    <path d="M10.296 7.71L14.621 12l-4.325 4.29 1.408 1.42L17.461 12l-5.757-5.71z" />
                    <path d="M6.704 6.29L5.296 7.71 9.621 12l-4.325 4.29 1.408 1.42L12.461 12z" />
                  </svg>
                </router-link>
              </li>
            </ul>
          </nav>
        </div>
        <router-view> </router-view>
      </div>
    </div>
    <lock v-show="!oidcIsAuthenticated" />

    <!-- report to be printed -->
    <div id="report" class="p-12 border border-gray-200 m-8 rounded-sm flex flex-col hidden">
     <!--header area -->
     <div class="flex flex-row justify-between">
       <div class="flex flex-col space-y-2">
          <p class="font-bold"> {{oidcUser.facility}}<span class="font-normal"> ({{oidcUser.facility_code}})</span></p>
          <!-- <p class="capitalize">{{$removeHyphens(selectedReport)}} {{selectedYear}}</p> -->
       </div>
       <div>
         <img src="https://damuyetustaging.health.go.ke/img/KNBTS.7939d0ee.png" class="h-16"/>
       </div>
     </div>
     <!--header area end-->

     <!--  table-->
     <!-- <div class="border border-0.5  mt-12 ">
       <table  class="w-full whitespace-no-wrap ">
         <thead>
         <tr class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b  bg-gray-50  ">
           <th class="px-4 py-3 border border-black">Month</th>
           <th class="px-4 py-3 border border-black">Report</th>
           <th class="px-4 py-3 border border-black">Remarks</th>
         </tr>
         </thead>
         <tbody v-if="pagedResult.results.length >0" class="bg-white divide-y">
            <tr v-for="(item, index) in pagedResult.results" class="" :key="index">
               
            </tr>
         </tbody>
         <tbody v-else>
          <tr class="h-12 w-full text-center">
            <td></td>
            <td class="capitalize">No data available</td>
            <td></td>
          </tr>
         </tbody>
       </table>
     </div> -->
     <!-- end of table-->
     <!-- sign out info-->
     <div class="flex flex-col space-y-2 mt-14">
       <div class="flex flex-row space-x-2">
         <span> Name: </span> <div class="border-0 border-b mb-1 border-gray-200 w-40"></div>
       </div>
       <div class="flex flex-row space-x-2">
         <span> Signature: </span> <div class="border-0 border-b mb-1 border-gray-200 w-40"></div>
       </div>
     </div>
     <!-- end sign out info-->
     <!-- start disclaimer and footer-->
     <div class="mb-0 flex flex-row justify-center mt-14 w-full">
        <p class="text-gray-400"> Disclaimer: This is a system generated document</p>
     </div>
     <!-- end disclaimer and footer-->
   </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Lock from "@/components/layout/Lock";
export default {
    name:"OtherReports",
  props: {
    title: String,
    // url: String,
    showCreate: { type: Boolean, default: true },
    showBack: { type: Boolean, default: false },
    createName: { type: String, default: "Create" },
    createTitle: { type: String, default: "New" },
    showCustomAction: { type: Boolean, default: false },
    customActionParams: {
      type: Array,
      default() {
        return [];
      },
    },
    customActionTitle: { type: String, default: "Action" },
    customActionName: { type: String, default: "Action" },
    tableHeadingTitles: {
      Array,
      default: function() {
        return [];
      },
    },
  },
  components: { Lock },
  computed: {
    ...mapGetters(["oidcIsAuthenticated","oidcUser"]),
    hasList() {
      return this.pagedResult?.results?.length > 0;
    },
    url(){
        return 'donation/donation-reports/'+this.selectedYear+'/'+this.selectedReport
    }
  },
  data() {
    return {
      loading: false,
      pagedResult: {},
      selectedYear:"",
      selectedReport: "monthly-Site-Donation-Report",
      error: {},
      q: this.$route.query.q,
      months:['Jan','Feb','March','April','May','June','July','Aug','Sept','Oct','Nov','Dec']
    };
  },
  watch: {
    $route: {
      immediate: true,
      async handler() {
        if (this.$route.matched.length === 1 || (this.$route.matched.length > 1 && Object.keys(this.pagedResult).length === 0 && this.pagedResult.constructor === Object)) {
          await this.fetch();
        }
      },
    },
  },
  methods: {
    printResults(){
      this.$htmlToPaper('report');
    },
    selectMonthlyReport(){        
        this.fetch()
    },
    formatInput(){
        this.selectedYear=this.selectedYear.replace(/ /g,'')
        if(this.selectedReport) this.fetch()
    },
    async fetch() {
      this.loading = true;
      this.pagedResult={}    
      this.$callApi
        .get(this.url, {
          params: { ...this.$route.query },
        })
        .then((x) => {
          this.pagedResult = x.data;
        })
        .catch(() => {
          //this.error = err;
          //console.log(err);
        })
        .then(() => (this.loading = false));
    },
    search() {
      if (this.q.length >= 3) {
        this.$router.push({ query: { q: this.q } });
      } else {
        if (this.$route.query.q !== undefined) {
          this.q = "";
          this.$router.push({ query: {} });
        }
      }
    },
    clear(e) {
      if (e.target.value.trim().length === 0) {
        this.q = "";
      }
    },
    navigate(queryString) {
      this.$router.push({ path: this.$route.path, query: { ...queryString } });
    },
    isEmpty(obj) {
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    },
    back() {
      this.$router.back();
    },
  },
   mounted(){
    this.fetch()
  }
};
</script>
