<script>
    export default {
        props: {
            data: Object,
            selectedReport: String,
            first: Boolean,
            last: [String, Boolean, Number],
            totalResult: [Array, Object],
            topHeaders: [Array, Object],
            bottomHeaders: [Array, Object],
        },
        methods: {
            getReportData: (selected) => {
                const components = {
                    "static-outreach-donations": {
                        name: "Location",
                        parent: "donations",
                        bottomChild: "frequency",
                        type: "location",
                    },
                };
                return components[selected];
            },
        },
    };
</script>
<template>
    <tr>
        <div class="flex flex-col overflow-x-auto">
            <div v-if="first" :class="[`grid grid-cols-${Object.keys(topHeaders).length + 1} justify-center bg-green-100 text-center text-sm font-medium text-green-800`]">
                <div class="flex items-center justify-center py-2 font-bold">
                    {{ getReportData(selectedReport).name }}
                </div>
                <div v-for="(topHeader, topHeaderIndex) in topHeaders" :key="topHeaderIndex" class="flex flex-col items-center justify-center border-l border-green-200">
                    <div class="py-2 font-bold">
                        {{ $removeCamelCase(topHeader) }}
                    </div>
                    <div :class="[`grid w-full grid-cols-${bottomHeaders.length} divide-x divide-green-200 border-t border-green-200 py-2`]">
                        <div v-for="(bottomHeader, bottomHeaderIdx) in bottomHeaders" :key="bottomHeaderIdx" class="">
                            {{ $removeCamelCase(bottomHeader) }}
                        </div>
                    </div>
                </div>
                <!-- <div class="flex items-center justify-center border-l border-green-200 font-bold">Total</div> -->
            </div>

            <!-- Fields -->
            <div>
                <div :class="[`grid grid-cols-${Object.keys(topHeaders).length + 1} text-center text-sm font-medium text-gray-800`]">
                    <div class="flex items-center justify-center border-b py-3">
                        {{ $removeHyphensCapitalize(data[getReportData(selectedReport).type]) }}
                    </div>
                    <div v-for="(parent, parentIndex) in data[getReportData(selectedReport).parent]" :key="parentIndex" class="flex flex-col items-center justify-center border-l border-b">
                        <div :class="[`grid w-full grid-cols-${bottomHeaders.length} divide-x py-3`]">
                            <div v-for="(bottomHeader, idx) in parent[getReportData(selectedReport).bottomChild]" :key="idx" class="">
                                {{ bottomHeader }}
                            </div>
                        </div>
                    </div>
                    <!-- <div class="flex items-center justify-center border-l border-b">
                        {{ data.total }}
                    </div> -->
                </div>
            </div>
        </div>
    </tr>
</template>
