export default [
  /*
  * Blood bank reports
  * */
  //
  {
    path: "/blood-bank/reports/blood-component-type",
    name: "MonthlyReportTable",
    component: () => import(/* webpackChunkName: "store" */ "@/views/bloodBank/components/MonthlyReportTable.vue"),
  },
  {
    path: "/blood-bank/reports/discarded-units",
    name: "DiscardedUnits",
    component: () => import(/* webpackChunkName: "store" */ "@/views/bloodBank/reports/DiscardedUnits.vue"),
  },
  {
    path: "/blood-bank/pending-samples",
    name: "PendingSamplesBank",
    component: () => import(/* webpackChunkName: "store" */ "@/views/bloodBank/recipientSamples/PendingSamplesList.vue"),
    children: [
      {
        path: "receive/:sampleId/:recipientName",
        name: "ReceiveSampleBank",
        component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/bloodBank/components/SchemaModal.vue"),
        props: (x) => ({
          toFetch: false,
          title: `Receive Sample: ${x.params.recipientName}`,
          postUrl: `bank/RecipientSamples/${x.params.sampleId}`,
          optionsUrls: [],
          jsonSchema: "bank/forms/receive-sample",
          size: "max-w-xl",
          verb: "PUT",
          generateDateTime:true,
          generateUserName:true
        }),
      },
    ]
  },
  
  {
    path: "/blood-bank/initial-results",
    name: "InitialResultsBank",
    component: () => import(/* webpackChunkName: "store" */ "@/views/bloodBank/recipientSamples/ReceivedSamplesList.vue"),
    props: ()=>({
      showInitialResults: true
    })
  },
  {
    path: "/blood-bank/view-sample/:showInitialResults/:sampleId/",
    name: "ViewSampleBank",
    component: () => import(/* webpackChunkName: "store" */ "@/views/bloodBank/recipientSamples/RecipientSample.vue"),
    props:(x)=>({
      sampleId : x.params.sampleId,
      showInitialResults : x.params.showInitialResults
    }),
    children:[
      {
        path: "add-initial-result/:testId/:testName/:bloodGrouping",
        name: "AddInitialResultBank",
        component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/bloodBank/components/SchemaModal.vue"),
        props: (x) => ({
          toFetch: false,
          title: `Add ${x.params.testName} Result`,
          postUrl: x.params.bloodGrouping?`bank/RecipientSamples/${x.params.testId}/add-bloodgroup-results`: `bank/RecipientSamples/${x.params.testId}/add-other-test-results`,
          optionsUrls: ["bank/Lookups/blood-groups/options", "bank/Lookups/positivenegative/options"],
          jsonSchema: `bank/RecipientSamples/${x.params.testId}/fetchResultsForm`,
          size: "max-w-xl",
          verb: "PUT",
          formLocation : "results"
        }),
      },
      {
        path: "add-reviewed-result/:testId/:testName/:bloodGrouping/:result/:bloodgroupingResults",
        name: "ReviewResultsBank",
        component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/bloodBank/components/SchemaModal.vue"),
        props: (x) => ({
          toFetch: false,
          title: `Review ${x.params.testName} Result`,
          postUrl: String(x.params.bloodGrouping)==='true'?`bank/RecipientSamples/${x.params.testId}/review-bloodgroup-results`: `bank/RecipientSamples/${x.params.testId}/review-other-test-results`,
          optionsUrls: ["bank/Lookups/blood-groups/options", "bank/Lookups/postivenegative/options"],
          jsonSchema: `bank/RecipientSamples/${x.params.testId}/fetchResultsForm`,
          size: "max-w-xl",
          verb: "PUT",
          fetchRouterParams: true,
          formLocation : "results"
        }),
      },
    ]
  },
  {
    path: "/blood-bank/reviewed-results",
    name: "ReviewedResultsBank",
    component: () => import(/* webpackChunkName: "store" */ "@/views/bloodBank/recipientSamples/ReceivedSamplesList.vue"),
    props: ()=>({
      showInitialResults: false
    })
  },
  //test configurations
  {
    path: "/bloodBank/test-configs",
    name: "TestConfigsBank",

    component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/bloodBank/recipientSamples/TestConfigsList.vue"),
    children: [
      {
        path: "add",
        name: "AddTestConfigsBank",
        component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/bloodBank/components/SchemaModal.vue"),
        props: () => ({
          toFetch: false,
          title: "Add Test Config",
          postUrl: `bank/TestConfig`,
          optionsUrls: [],
          jsonSchema: "bank/forms/add-test-config",
          size: "max-w-xl",
        }),
      },
      {
        path: "update/:id",
        name: "UpdateTestConfigBank",
        component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/bloodBank/components/SchemaModal.vue"),
        props: (y) => ({
          toFetch: true,
          title: "Update Test Config",
          postUrl: `bank/TestConfig/${y.params.id}`,
          optionsUrls: [],
          jsonSchema: "bank/forms/add-test-config",
          size: "max-w-xl",
        }),
      },
    ],
  },

  //blood group configurations
  {
    path: "/bloodBank/blood-group-configs",
    name: "ManageBankBloodGroupConfigs",

    component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/bloodBank/BloodGroupSetupList.vue"),
    children: [
      {
        path: "add",
        name: "AddBloodGroupSetupBank",
        component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/bloodBank/components/SchemaModal.vue"),
        props: () => ({
          toFetch: false,
          title: "Add Blood Group Setup",
          postUrl: `bank/BloodConfigurations`,
          optionsUrls: ["bank/lookups/blood-groups/options", "bank/lookups/blood-groups/options"],
          jsonSchema: "bank/forms/add-blood-group-setup",
          size: "max-w-xl",
        }),
      },
      {
        path: "update/:id",
        name: "UpdateBloodGroupSetupBank",
        component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/bloodBank/components/SchemaModal.vue"),
        props: (y) => ({
          toFetch: true,
          title: "Update Blood Group Setup",
          postUrl: `bank/BloodConfigurations/${y.params.id}`,
          optionsUrls: ["bank/lookups/blood-groups/options", "bank/lookups/blood-groups/options"],
          jsonSchema: "bank/forms/add-blood-group-setup",
          size: "max-w-md",
        }),
      },
    ],
  },
    //ward requests
    {
      path: "/bloodBank/ward-requests",
      name: "WardRequestsForBloodBank",
      component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/bloodBank/wardRequests/WardRequestsList.vue"),
      props: () => ({
        buttonTitle: "View Request",
        buttonRouteName: "ViewBloodBankWardRequest",
        listTitle: "ward Requests",
        purpose: "store",
        url: "bank/WardRequisitions/get-ward-request-list",
      }),
      children: [],
    },
    //process ward request
    {
      path: "/bloodBank/ward-requests/:requestId/view-request",
      name: "ViewBloodBankWardRequest",
      props: (x) => ({
        title: x.params.title,
        requestId: x.params.requestId,
        backRouterName: "WardRequestsForBloodBank",
      }),
      component: () => import(/* webpackChunkName: "tests" */ "@/views/bloodBank/wardRequests/StorageFacilityRequest.vue"),
      children: [
        {
          path: "assign-pints-to-patient/:quantity/:componentType/:requestItemId",
          name: "AssignPintsToPatient",
          component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/bloodBank/wardRequests/AssignPints.vue"),
          props: (x) => ({
            toFetch: false,
            title: `Assign ${x.params.quantity} ${x.params.quantity > 1 ? "Units" : "Unit"} of ${x.params.componentType} to patient`,
            verb: "POST",
            url: `bank/WardRequisitions/${x.params.requestItemId}/productsToSelectForCompatiblityTest`,
            postUrl: `bank/WardRequisitions/${x.params.requestId}/${x.params.requestItemId}/AssignBloodBagsToWard`,
            size: "max-w-4xl",
            jsonSchema : "bank/forms/assign-pint-to-ward",
            backRouterName: "ViewBloodBankWardRequest",
            generateUserName: true
          }),
        },
      ],
    },
    {
      path: "/bloodBank/ward-requests/:requestId/view-request/dispatch-blood",
      name: "DispatchTransfusionPints",
      component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/bloodBank/wardRequests/SelectAssignedPints.vue"),
      props: (x) => ({
        toFetch: false,
        title: "Dispatch Pints",
        verb: "POST",
        fetchUrl: `bank/WardRequisitions/${x.params.requestId}/ListOfCompatibleBloodAndBloodProducts`,
        postUrl: `bank/WardRequisitions/${x.params.requestId}/dispatchCompatibleProducts`,
        size: "max-w-4xl",
        showLabelText: true,
        searchListTitle: "Assigned Pints",
        backRouterName: "ViewBloodBankWardRequest",
        checkBoxMessage: "Mark as Collected",
        fetchOptionsFunction : false
      }),
      children:[
        {
          path: "submit",
          name: "SubmitDispatchedComponents",
          component: () => import(/* webpackChunkName: "tests" */ "@/views/bloodBank/wardRequests/DispatchPints.vue"),
          props: (x) => ({
            toFetch: false,
            title: "Dispatch Pints",
            verb: "POST",
            backRouterName: "ViewBloodBankWardRequest",
            postUrl: `bank/WardRequisitions/${x.params.requestId}/dispatchCompatibleProducts`,
            size: "w-1/3",
            jsonSchema: "transfusion/forms/dispatch-pints-from-transfusing-facility-bank",
            optionsUrls: [],
            modelName: "issuedBloodAndBloodProductsViewModels",
            pintModelName: "entityGuid",
          }),
        },
      ]
    },
    //sent/outbound requests
    {
    path: "/blood-bank/sent-requests",
    name: "BloodBankSentRequests",
    component: () => import(/* webpackChunkName: "store" */ "@/views/bloodBank/facilityRequests/FacilityRequestsList.vue"),
    props: () => ({
      requestRouteName : "ViewBloodBankOutBoundRequest",
      cancelRouteName : "CancelBankSentRequestList",
      url : `bank/BloodBankFacilityRequisitions/get-bloodbank-facility-requisition-list?fromFacility=true`,
      createName : "SendBloodBankRequest",
      createTitle : "Send a Request",
      title : "Requests to Facilities",
      showCreate : true

    }),
    children: [
      {
        path: "send",
        name: "SendBloodBankRequest",
        component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/bloodBank/components/SchemaModal.vue"),
        props: () => ({
          toFetch: false,
          title: "Send Request for Blood",
          postUrl: `bank/BloodBankFacilityRequisitions/create-bloodbank-facility-requisition?fromFacility=true`,
          optionsUrls: ["bank/BloodBankFacilityRequisitions/get-inbound-facility-options", "bank/BloodBankFacilityRequisitions/get-component-type-options", "bank/Lookups/blood-groups/options"],
          jsonSchema: "bank/forms/requesting-facility-request",
          size: "max-w-4xl",
          generateUserName: true,
        }),
      },
      {
        path: "cancelRequest/:requestId",
        name: "CancelBankSentRequestList",
        component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/bloodBank/components/SchemaModal.vue"),
        props: (x) => ({
          toFetch: false,
          title: "Cancel Request",
          postUrl: `bank/BloodBankFacilityRequisitions/${x.params.requestId}/cancel-requisition`,
          optionsUrls: [],
          jsonSchema: "bank/forms/cancel-request",
          size: "max-w-xl",
          verb: "PUT"
        }),
      },
    ],
  },
  //process outbound request 
  {
    path: "/blood-bank/outbound-requests/:requestId/view",
    name: "ViewBloodBankOutBoundRequest",
    component: () => import(/* webpackChunkName: "store" */ "@/views/bloodBank/facilityRequests/FacilityRequest.vue"),
    props: (x) => ({
      toFetch: false,
      requestId: x.params.requestId,
      backRouterName: "BloodBankSentRequests",
      title:"View Sent Request",
      requestFromFacility : false
    }),
    children: [

    ],
  },
  //digitized requests
  {
    path: "/blood-bank/digitized-requests",
    name: "DigitizedFacilityRequestsBloodBank",
    component: () => import(/* webpackChunkName: "blood-bank" */ "@/views/bloodBank/facilityRequests/FacilityRequestsList.vue" ),
    props:()=>({
      requestRouteName : "ViewBankInboundRequest",
      cancelRouteName : "CancelBankInboundRequestFromList",
      url : `bank/BloodBankFacilityRequisitions/get-bloodbank-facility-requisition-list?fromFacility=false`,
      createName : "MirrorRequestBloodBank",
      createTitle : "Digitize a Blood Request",
      title : " Digiitized Requests from Facilities",
      showCreate : true,
      digitizedRequest : true
    }),
    children: [
      {
        path: "mirror-request",
        name: "MirrorRequestBloodBank",
        component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/bloodBank/facilityRequests/CreateFacilityRequest.vue"),
        props: () => ({
          toFetch: false,
          title: "Digitize Blood Request",
          postUrl: `bank/BloodBankFacilityRequisitions/create-bloodbank-facility-requisition?fromFacility=false`,
          optionsUrls: ["bank/BloodBankFacilityRequisitions/get-outbound-facility-options", "bank/BloodBankFacilityRequisitions/get-component-type-options", "bank/Lookups/blood-groups/options"],
          jsonSchema: "bank/forms/facility-request",
          size: "max-w-3xl",
          generateUserName: true,
        }),
      },
      {
        path: "cancelRequest",
        name: "CancelBankInboundRequestFromList",
        component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/bloodBank/components/SchemaModal.vue"),
        props: (x) => ({
          toFetch: false,
          title: "Cancel Request",
          postUrl: `bank/BloodBankFacilityRequisitions/${x.params.requestId}/cancel-requisition`,
          optionsUrls: [],
          jsonSchema: "bank/forms/cancel-request",
          size: "max-w-xl",
          verb: "PUT"
        }),
      },
      
    ],
  },
  //inbound requests
  {
    path: "/blood-bank/inbound-requests",
    name: "InboundFacilityRequestsBloodBank",
    component: () => import(/* webpackChunkName: "blood-bank" */ "@/views/bloodBank/facilityRequests/FacilityRequestsList.vue" ),
    props:()=>({
      requestRouteName : "ViewBankInboundRequest",
      cancelRouteName : "CancelBankInboundRequestFromList",
      url : `bank/BloodBankFacilityRequisitions/get-bloodbank-facility-requisition-list-from-facility`,
      createName : "MirrorRequestBloodBank",
      createTitle : "Digitize a Blood Request",
      title : "Requests from Facilities",
      showCreate : false,
      requestFromFacility:true

    }),
    children: [
     
    ],
  },
  //view inbound request
  {
    path: "/blood-bank/request/:requestId/:digitizedRequest/view",
    meta: { breadcrumb: { parent: "transfusionRequestsBank" } },
    name: "ViewBankInboundRequest",
    component: () => import(/* webpackChunkName: "store" */ "@/views/bloodBank/facilityRequests/FacilityRequest.vue" ),
    props: (x) => ({
      backRouterName: x.params.digitizedRequest ? "DigitizedFacilityRequestsBloodBank" : "InboundFacilityRequestsBloodBank",
      requestId: x.params.requestId,
      requestFromFacility : true,
      title : x.params.digitizedRequest ?"Digitized Facility Request" : 'View Request From Facility',
    }),
    children: [
      {
        path: ":itemId/:quantityRequested/:itemName/:itemCode/:bloodGroup/issue-pint",
        name: "issuePintsBank",
        component: () => import(/* webpackChunkName: "store" */ "@/views/bloodBank/IssuePint.vue"),
        props: (x) => ({
          verb: "POST",
          toFetch: true,
          title: `Issue ${x.params.quantityRequested} UNITS OF ${x.params.bloodGroup} ${x.params.itemName.replace(/[\]-]+/g, " ")} `,
          fetchUrl: `bank/BloodBankFacilityRequisitions/${x.params.itemCode}/${x.params.bloodGroup}/get-filtered-bloodcomponents-list`,
          postUrl: `bank/BloodBankFacilityRequisitions/${x.params.itemId}/process-bloodbank-facility-requisition`,
          optionsUrls: [],
          size: "max-w-4xl",
          backRouterName: "ViewBankInboundRequest",
        }),
      },
      {
        path: "dispatch",
        name: "dispatchPintsBank",
        component: () => import(/* webpackChunkName: "store" */ "@/views/bloodBank/components/SchemaModal.vue"),
        props: (x) => ({
          toFetch: false,
          title: "Dispatch Pints",
          postUrl: `bank/BloodBankFacilityRequisitions/${x.params.requestId}/dispatch-bloodbank-facility-requisition`,
          optionsUrls: [],
          jsonSchema: "bank/forms/dispatch-pints",
          size: "max-w-3xl",
          generateDate: true,
          generateUserName: true,
        }),
      },
      {
        path: "cancelRequest",
        name: "CancelBankRequestFromRequest",
        component: () => import(/* webpackChunkName: "transfusion-group" */ "@/views/bloodBank/components/SchemaModal.vue"),
        props: (x) => ({
          toFetch: false,
          title: "Cancel Request",
          postUrl: `bank/BloodBankFacilityRequisitions/${x.params.requestId}/cancel-requisition`,
          optionsUrls: [],
          jsonSchema: "bank/forms/cancel-request",
          size: "max-w-xl",
          verb: "PUT"

        }),
      },
    ],
  },

  {
    path: "/blood-bank/pending-batches",
    name: "ReceiveBankBatches",
    component: () => import(/* webpackChunkName: "store" */ "@/views/bloodBank/PendingBatchList.vue"),
    children: [
      {
        path: "receive/:id",
        name: "ReceiveBankBatch",
        component: () => import(/* webpackChunkName: "store" */ "@/views/bloodBank/ReceiveBatch.vue"),
        props: (x) => ({
          toFetch: true,
          title: "Receive Batch",
          postUrl: `bank/BloodManagement/receive/batch/${x.params.id}`,
          fetchUrl: `bank/BloodManageMent/components/batch/${x.params.id}`,
          optionsUrls: ["bank/Lookups/reception-status/options"],
          jsonSchema: "bank/forms/receive-batch",
          size: "w-1/2",
          verb: "POST",
        }),
      },
    ],
  },
  {
    path: "/blood-bank/pending-batches/:batchId/:quantity/components",
    name: "BankComponentsByBatches",
    component: () => import(/* webpackChunkName: "store" */ "@/views/bloodBank/ComponentsByBatchList.vue"),
    props: (x) => ({
      batchId: x.params.batchId,
    }),

    children: [
      {
        path: "receive",
        name: "ReceiveBankBatchFromComponent",
        component: () => import(/* webpackChunkName: "store" */ "@/views/bloodBank/ReceiveBatch.vue"),
        props: (x) => ({
          toFetch: true,
          title: "Receive Batch",
          postUrl: `bank/BloodManagement/receive/batch/${x.params.batchId}`,
          fetchUrl: `bank/BloodManageMent/components/batch/${x.params.batchId}`,
          optionsUrls: ["bank/Lookups/reception-status/options"],
          jsonSchema: "bank/forms/receive-batch",
          size: "w-1/2",
          verb: "POST",
          backSteps: 2,
          quantity: x.params.quantity,
        }),
      },
    ],
  },
  {
    path: "/blood-bank/batches",
    name: "BankBatches",
    component: () => import(/* webpackChunkName: "store" */ "@/views/bloodBank/BatchList.vue"),
  },
  {
    path: "/blood-bank/batches/:batchId/components",
    name: "ViewBatchListComponents",
    component: () => import(/* webpackChunkName: "store" */ "@/views/bloodBank/ComponentsByBatchList.vue"),
    props: (x) => ({
      batchId: x.params.batchId,
      showCreate: false
    }),
    children: [],
  },
  {
    path: "/blood-bank/batches/:batchId/donations/:donationId/components",
    name: "BankComponentsByDonationsFromBatch",
    component: () => import(/* webpackChunkName: "store" */ "@/views/bloodBank/ComponentsByDonationList.vue"),
    props: (x) => ({
      donationId: x.params.donationId,
      listType: "fromBatch",
    }),

    children: [],
  },
  {
    path: "/blood-bank/donations",
    name: "BankDonations",
    component: () => import(/* webpackChunkName: "store" */ "@/views/bloodBank/DonationsList.vue"),

    children: [],
  },

  {
    path: "/blood-bank/components",
    name: "BankComponents",
    component: () => import(/* webpackChunkName: "store" */ "@/views/bloodBank/ComponentsList.vue"),
    props: () => ({
      urlValue: `bank/BloodManageMent/bloodcomponents`,
    }),

    children: [
      {
        path: "discard-component/:id",
        name: "DiscardBankComponent",
        component: () => import(/* webpackChunkName: "store" */ "@/views/bloodBank/components/SchemaModal.vue"),
        props: (x) => ({
          toFetch: false,
          title: "Discard Component",
          postUrl: `bank/BloodManagement/bloodcomponent/discard/${x.params.id}`,
          optionsUrls: ["bank/Lookups/reception-status/options"],
          jsonSchema: "bank/forms/discard-blood",
          size: "max-w-2xl",
          generateUserName: true,
        }),
      },
      {
        path: "add-stock/:componentId",
        name: "AddBankComponentToStock",
        component: () => import(/* webpackChunkName: "store" */ "@/views/bloodBank/components/SchemaModal.vue"),
        props: (x) => ({
          toFetch: false,
          title: "Add Component To Stock",
          postUrl: `bank/BloodManagement/bloodcomponent/avail/${x.params.componentId}`,
          optionsUrls: [],
          jsonSchema: "bank/forms/add-component-to-stock",
          size: "max-w-3xl",
        }),
      },
    ],
  },
  {
    path: "/blood-bank/donations/:donationId/components",
    name: "BankComponentsByDonations",
    component: () => import(/* webpackChunkName: "store" */ "@/views/bloodBank/ComponentsByDonationList.vue"),
    props: (x) => ({
      listType: "fromDonation",
      donationId: x.params.donationId,
    }),

    children: [
      {
        path: "discard-component/:id",
        name: "DiscardBankComponentFromDonation",
        component: () => import(/* webpackChunkName: "store" */ "@/views/bloodBank/components/SchemaModal.vue"),
        props: (x) => ({
          toFetch: false,
          title: "Discard Component",
          postUrl: `bank/BloodManagement/bloodcomponent/discard/${x.params.id}`,
          optionsUrls: ["bank/Lookups/discard-reasons/options"],
          jsonSchema: "bank/forms/discard-blood",
          size: "max-w-2xl",
          generateUserName: true,
        }),
      },
      {
        path: "add-stock/:componentId",
        name: "AddBankComponentToStockFromDonation",
        component: () => import(/* webpackChunkName: "store" */ "@/views/bloodBank/components/SchemaModal.vue"),
        props: (x) => ({
          toFetch: false,
          title: "Add Component To Stock",
          postUrl: `bank/BloodManagement/bloodcomponent/avail/${x.params.componentId}`,
          optionsUrls: [],
          jsonSchema: "bank/forms/add-component-to-stock",
          size: "max-w-3xl",
        }),
      },
    ],
  },

  {
    path: "/blood-bank/donation-items",
    name: "manageBloodBankDonationItems",
    props: (x) => ({
      categoryId: x.params.categoryId,
      genericStatus: x.params.genericStatus,
    }),
    component: () => import(/* webpackChunkName: "store" */ "@/views/bloodBank/DonationItemsList.vue"),
    children: [
      {
        path: "add",
        name: "addBloodBankDonationItem",
        component: () => import(/* webpackChunkName: "store" */ "@/views/bloodBank/components/SchemaModal.vue"),
        props: () => ({
          toFetch: false,
          title: "Add Donation Item",
          postUrl: `bank/DonationItems`,
          optionsUrls: [],
          jsonSchema: "bank/forms/add-donation-item",
          size: "max-w-xl",
        }),
      },
      {
        path: "update/:id",
        name: "updateBloodBankDonationItem",
        component: () => import(/* webpackChunkName: "store" */ "@/views/bloodBank/components/SchemaModal.vue"),
        props: (y) => ({
          toFetch: true,
          title: "Update Donation Item",
          postUrl: `bank/DonationItems/${y.params.id}`,
          optionsUrls: [],
          jsonSchema: "bank/forms/add-donation-item",
          size: "max-w-md",
        }),
      },
      {
        path: "delete/:id",
        title: "Delete Form",
        name: "DeleteBloodBankDonationItem",
        component: () =>
          import(
            /* webpackChunkName: "BloodBank" */
            "@/components/data/DeleteModal.vue"
          ),
        props: (x) => ({
          verb: "DELETE",
          title: "Delete Donation Item",
          deleteUrl: `bank/DonationItems/${x.params.id}`,
          size: "max-w-xs",
          name: "Delete Donation Item",
        }),
      },
    ],
  },
  {
    path: "/blood-bank/options",
    name: "manageBloodBankOptions",
    props: (x) => ({
      categoryId: x.params.categoryId,
      genericStatus: x.params.genericStatus,
    }),
    component: () => import(/* webpackChunkName: "store" */ "@/views/bloodBank/OptionGroupList.vue"),
    children: [
      {
        path: "add",
        name: "addBloodBankOptionGroup",
        component: () => import(/* webpackChunkName: "store" */ "@/views/bloodBank/components/SchemaModal.vue"),
        props: () => ({
          toFetch: false,
          title: "Add Option Group",
          postUrl: `bank/Lookups/0`,
          optionsUrls: [],
          jsonSchema: "bank/forms/add-option-group",
          size: "max-w-xl",
        }),
      },
      {
        path: "update/:id",
        name: "updateBloodBankOptionGroup",
        component: () => import(/* webpackChunkName: "store" */ "@/views/bloodBank/components/SchemaModal.vue"),
        props: (y) => ({
          toFetch: true,
          title: "Update Option Group",
          postUrl: `bank/Lookups/${y.params.id}`,
          optionsUrls: [],
          jsonSchema: "bank/forms/add-option-group",
          size: "max-w-md",
        }),
      },
    ],
  },
  {
    path: "/blood-bank/optionGroup/:optionGroupId/options",
    name: "viewBloodBankOptions",
    props: (x) => ({
      optionGroupId: x.params.optionGroupId,
    }),
    component: () => import(/* webpackChunkName: "store" */ "@/views/bloodBank/OptionsList.vue"),
    children: [
      {
        path: "add",
        name: "addBloodBankOption",
        component: () => import(/* webpackChunkName: "store" */ "@/views/bloodBank/components/SchemaModal.vue"),
        props: (y) => ({
          toFetch: false,
          title: "Add Option",
          postUrl: `bank/Lookups/${y.params.optionGroupId}`,
          optionsUrls: [],
          jsonSchema: "bank/forms/add-option",
          size: "max-w-xl",
        }),
      },
      {
        path: "update/:id",
        name: "updateBloodBankOption",
        component: () => import(/* webpackChunkName: "store" */ "@/views/bloodBank/components/SchemaModal.vue"),
        props: (y) => ({
          toFetch: true,
          title: "Update Option",
          postUrl: `bank/Lookups/${y.params.id}`,
          optionsUrls: [],
          jsonSchema: "bank/forms/add-option",
          size: "max-w-md",
        }),
      },
    ],
  },
  // BloodBank forms
  {
    path: "/blood-bank/forms",
    name: "bloodBankForms",
    component: () =>
      import(
        /* webpackChunkName: "BloodBank" */
        "@/views/bloodBank/FormsList.vue"
      ),
    children: [
      {
        path: "create",
        name: "AddBloodBankForm",
        component: () =>
          import(
            /* webpackChunkName: "BloodBank" */
            "@/components/data/JsonForm.vue"
          ),
        props: () => ({
          verb: "POST",
          toFetch: false,
          title: "Add Form ",
          postUrl: "bank/Forms",
        }),
      },
      {
        path: "edit/:id",
        title: "Update Form",
        name: "UpdateBloodBankForm",
        component: () =>
          import(
            /* webpackChunkName: "BloodBank" */
            "@/components/data/JsonForm.vue"
          ),
        props: (x) => ({
          verb: "PUT",
          title: "Update Form ",
          toFetch: true,
          postUrl: `bank/forms/${x.params.id}`,
        }),
      },
      {
        path: "delete/:id",
        title: "Delete Form",
        name: "DeleteBloodBankForm",
        component: () =>
          import(
            /* webpackChunkName: "BloodBank" */
            "@/components/data/DeleteModal.vue"
          ),
        props: (x) => ({
          verb: "DELETE",
          title: "Delete Form ",
          deleteUrl: `bank/forms/${x.params.id}`,
          size: "max-w-xs",
          name: "Delete Form",
        }),
      },
    ],
  },
];
