<template>
  <div class="px-8">
    <div class="py-4 sm:py-6 ">
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-y-8 gap-x-12 2xl:gap-x-28">
        <div class="w-full">
          <p tabindex="0" class="focus:outline-none text-xs md:text-sm font-medium leading-none text-gray-500 uppercase">Donations</p>
          <p tabindex="0" class="focus:outline-none text-lg sm:text-xl md:text-2xl lg:text-3xl font-bold leading-3 text-gray-800 mt-3 md:mt-5">
            {{ $formatNumber(tweenedTotalNumberOfDonations.toFixed(0)) }}
          </p>
          <div class="flex flex-col md:w-64">
            <div class="w-full flex justify-end">
              <div class="flex items-center">
                <svg tabindex="0" class="focus:outline-none text-green-700" aria-label="up icon" role="img" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path d="M8 3.33334V12.6667" stroke="CurrentColor" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M12 7.33334L8 3.33334" stroke="CurrentColor" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M4 7.33334L8 3.33334" stroke="CurrentColor" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
                <p tabindex="0" class="focus:outline-none text-xs leading-none text-green-700">4.3%</p>
              </div>
            </div>
            <div class="mt-2.5">
              <div class="w-full h-1 bg-gray-200 rounded-full">
                <div class="w-1/2 h-1 bg-blue-500 rounded-full"></div>
              </div>
            </div>
          </div>
          <p tabindex="0" class="focus:outline-none mt-1.5 text-xs leading-3 text-gray-500">Yearly target</p>
        </div>
        <div class="w-full">
          <p tabindex="0" class="focus:outline-none text-xs md:text-sm font-medium leading-none text-gray-500 uppercase">Components</p>
          <p tabindex="0" class="focus:outline-none text-lg sm:text-xl md:text-2xl lg:text-3xl font-bold leading-3 text-gray-800 mt-3 md:mt-5">
            {{ $formatNumber(tweenedTotalNumberOfComponents.toFixed(0)) }}
          </p>
          <div class="flex flex-col">
            <div class="h-4"></div>
            <div class="md:w-64 mt-2.5">
              <div class="w-full h-1 bg-gray-200 rounded-full">
                <div class="w-40 h-1 bg-lime-500 rounded-full"></div>
              </div>
            </div>
          </div>
          <p tabindex="0" class="focus:outline-none mt-1.5 text-xs leading-3 text-gray-500">Yearly target</p>
        </div>
        <div class="w-full">
          <p tabindex="0" class="focus:outline-none text-xs md:text-sm font-medium leading-none text-gray-500 uppercase">Extraction Rate</p>
          <p tabindex="0" class="focus:outline-none text-lg sm:text-xl md:text-2xl lg:text-3xl font-bold leading-3 text-gray-800 mt-3 md:mt-5">{{ tweenedRatio.toFixed(2) }} %</p>
          <div class="flex flex-col md:w-64">
            <div class="mt-2.5">
              <div class="w-full h-1 bg-gray-200 rounded-full">
                <div :class="[ratioClasses, 'h-1 rounded-full']"></div>
              </div>
            </div>
          </div>
          <p tabindex="0" class="focus:outline-none mt-1.5 text-xs leading-3 text-gray-500">Extraction rate</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
export default {
  name: "FacilityStats",
  data() {
    return {
      totalNumberOfDonations: 0,
      totalNumberOfComponents: 0,
      tweenedTotalNumberOfDonations: 0,
      tweenedTotalNumberOfComponents: 0,
      tweenedRatio: 0,
    };
  },
  watch: {
    totalNumberOfDonations: function(v) {
      this.tween(v, "tweenedTotalNumberOfDonations");
    },
    totalNumberOfComponents: function(v) {
      this.tween(v, "tweenedTotalNumberOfComponents");
      this.tween((v / this.totalNumberOfDonations) * 100, "tweenedRatio");
    },
  },
  computed: {
    ratioClasses() {
      const nearestTen = Math.round(this.tweenedRatio / 10) * 10;
      switch (nearestTen) {
        case 10:
          return "bg-red-500 w-[10%]";
        case 20:
          return "bg-red-500/90 w-[20%]";
        case 30:
          return "bg-red-500/80 w-[30%]";
        case 40:
          return "bg-red-500/50 w-[40%]";
        case 50:
          return "bg-green-500/50 w-[50%]";
        case 60:
          return "bg-green-500/60 w-[60%]";
        case 70:
          return "bg-green-500/70 w-[70%]";
        case 80:
          return "bg-green-500/80 w-[80%]";
        case 90:
          return "bg-green-500/90 w-[90%]";
        case 100:
          return "bg-green-500 w-[100%]";

        default:
          return "";
      }
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    tween(n, key) {
      gsap.to(this, { duration: 0.5, [key]: Number(n) || 0 });
    },
    async fetch() {
      this.loading = true;
      this.$callApi
        .get("component-separation/componentreports")
        .then((x) => {
          this.totalNumberOfDonations = x.data.totalNumberOfDonations;
          this.totalNumberOfComponents = x.data.totalNumberOfComponents;
        })
        .catch(() => {
          //this.error = err;
          //console.log(err);
        })
        .then(() => (this.loading = false));
    },
  },
};
</script>
