<template>
  <div class="h-full">
    <div class="rounded grid grid-rows-layout h-full w-full" v-show="oidcIsAuthenticated">
      <div class="px-2 pb-1">
        <div class="md:grid md:grid-cols-12 gap-2 md:gap-4 items-center my-2 mx-3">
          <div class="col-span-6">
            <h3 class="border-b-2 border-primary-300 py-4 space-x-2 inline-flex items-center text-primary-400  -mb-px">
              <button type="button" v-if="!!showBack" @click.prevent="back"
                class="text-6xl hover:bg-primary-200 transition p-2 hover:text-primary-600 rounded-full text-primary-500 bg-transparent outline-none focus:outline-none flex items-center justify-center"
                title="Back">
                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18">
                  </path>
                </svg>
              </button>
              <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 md:w-5 md:h-5" fill="currentColor"
                viewBox="0 0 16 16">
                <path
                  d="M1 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2zM1 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7zM1 12a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2z" />
              </svg>
              <span class="text-xs text-primary-700 md:text-base   font-normal   tracking-wider uppercase"> {{ this.pagedResult.totalItems ? this.pagedResult.totalItems : "" }} {{ $removeHyphens(selectedReport) }}</span>
            </h3>
          </div>
          <div class="col-span-6 flex items-center justify-end space-x-1">
            <button
              class="flex items-center rounded-md bg-green-600 px-6 py-2 text-sm font-medium tracking-wide transition text-green-50 shadow-sm hover:shadow-md hover:border-green-600 hover:bg-green-700 hover:text-white focus:outline-none"
              @click="$printCSVData(fetchedCSVData, selectedReport, selectedYear, selectedRegion, selectedCounty)" v-if="(fetchedCSVData && fetchedCSVData.length > 0) ">
              <span class="mr-2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="h-6 w-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                </svg>
              </span>
              Download Report
            </button>

          </div>
        </div>

        <div>
          <div class="my-3 border-y hover:bg-gray-50">
            <div class="sm:grid sm:grid-cols-4 ml-2 gap-4">
              <div>
                  <FormulateInput type="number" label="Specify Year:" name="selectedYear" :value="new Date().getUTCFullYear()"
                    v-model="selectedYear" @change="formatInput" class="mt-2" :validation-rules="{
                      noLetter: ({ value }) => !/[a-zA-Z]/g.test(value),
                    }" :validation-messages="{
                      noLetter: `Letters are not allowed`,
                    }" validation="noLetter" :show-optional="false" error-behavior="value" />
              </div>

              <div class="col-span-2">
                  <FormulateInput
                    type="suggest" @input="clearAllFilters" v-model="selectedReport"
                    placeholder="-- Select report type --" label="Please specify report type"
                    class="mt-2 font-semibold text-gray-600"
                    :disabled="!selectedYear || loading ? true : false"
                    validation="required"
                    :options="reportOptions"
                  />
              </div>
              <div class="justify-self-end mr-4">
                <button
                  class="flex items-center rounded-md  px-6 py-2 text-sm font-medium tracking-wide transition shadow-sm hover:shadow-md focus:outline-none mt-10"
                  @click="showFilters" :class="[filters ? 'bg-red-100 text-red-500 hover:bg-red-200 hover:text-red-900 ' : 'bg-green-100 text-green-800 hover:bg-green-200 hover:text-green-900 ', ]">
                  <span class="mr-2">
                    
                    <svg v-if="filters" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>

                    <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75" />
                    </svg>
                  </span>
                  {{ (filters ?  'Close Filters' : 'All Filters' )}} 
                </button>
              </div>
              </div>
              
              <div v-if="filters" class="xl:grid xl:grid-cols-4 mx-2 gap-2">
                <div> 
                  <FormulateInput type="suggest" name="regions" placeholder="Type to select" label="Filter By Regions:" class="mt-2" v-model="selectedRegion" @input="fetchCounties" :options = "options[0]" :show-optional="false" :disabled="loading && true"/>
                </div>
                
                <div>
                  <FormulateInput type="suggest" label="Filter By County:" name="county" @input="selectCountyFilter" placeholder="Type to select" v-model="selectedCounty" :options="counties" class="mt-2" :show-optional="false" :disabled="loading && true"/>
                </div>

                <div class="col-span-2">
                  <FormulateInput type="suggest" label="Filter By Facility:" name="facility" @input="selectFacilityFilter" placeholder="Type to select" v-model="selectedFacilityCode" :options="facilities" class="mt-2" :show-optional="false" :disabled="loading && true"/>
                </div>
              </div>
              
            <!-- <div class="flex flex-row w-full justify-center">
                <button class="transition  text-white bg-blue-500   border hover:bg-blue-600 hover:text-white rounded-md  tracking-normal text-normal leading-3 py-2 px-3 flex items-center" @click="printResults" v-if="fetchedData && fetchedData.length>0">Print Report</button>
            </div> -->
          </div>
        </div>

        <div
          class="flex justify-between text-xs font-semibold tracking-wide text-left text-gray-500 uppercase rounded-tl-md rounded-tr-md bg-gray-50 px-1">
          <div :tableHeadingTitles="tableHeadingTitles" v-for="(tableTitle, index) in tableHeadingTitles" :key="index">
            <div v-html="tableTitle" class="px-4 py-3">
              {{ tableTitle }}
            </div>
          </div>
        </div>
      </div>
      <div class="h-full border-b border-gray-100">
        <report-shimmer v-if="loading"></report-shimmer>

        <report-view v-else :fetchedData="fetchedData" :selectedReport="selectedReport" :url="url">
        </report-view>

        <div v-if="loading" class="flex items-center justify-center hidden">
          <loading-indicator></loading-indicator>
        </div>
        <div v-if="hasList && selectedReport === 'TTI-Results'" class="flex-1">
          <div class=" border-b  border-gray-100 divide-y divide-gray-100  ">

            <!-- monthly-blood-units-collected -->     
            
            <div v-if="fetchedData && !fetchedData.currentPage">
              <table v-if="fetchedData[0].bloodGroup || fetchedData[1].bloodGroup" class="w-full grid grid-cols-12 p-2 text-sm text-left text-gray-500">
                  <div class="col-span-2 text-gray-700 bg-green-100 text-base
                  font-bold py-4 pl-5 border-t">Blood Group</div>
                  <div class="col-span-10 grid grid-cols-12">
                    <thead v-for="(month,index) in months" :key="index" class="text-gray-700 bg-green-100 text-base border-t">
                      <th class="pl-2 py-4 px-6">
                        {{month}}
                        </th>   
                    </thead>
                  </div>
                  <div class="col-span-12">
                    <tbody class="grid grid-cols-12">
                      <tr v-for="(group,gid) in fetchedData" :key="gid" class="bg-white col-span-12  hover:bg-green-50">
                          <td class="col-span-12 grid grid-cols-12 p-2 border">
                            <span class="py-2 col-span-2 text-gray-700 font-semibold px-2 uppercase">{{group.bloodGroup}}</span>
                              <div class="col-span-10 grid grid-cols-12 ">
                                <div v-for="(result,idx) in group.results" :key="idx"
                                class="grid grid-cols-12 font-semibold pl-2 text-center items-center">
                                <span v-if="result.count>0">{{result.count}} <span class="text-red-600 italic text-xs"> {{result.percentage}}</span></span>
                                <span
                                  v-else-if="result.count===0 && selectedYear<=new Date().getFullYear() && !(selectedYear===new Date().getFullYear() && result.monthInt>new Date().getMonth()+1)">-</span>
                                <span v-else class="italic text-xs lowercase">N/A</span>
                              </div>
                            </div>
                          </td>
                      </tr>
                    </tbody>
                  </div>
              </table>
                <table v-if="fetchedData[0].bloodGroup || fetchedData[1].bloodGroup " class=" border grid grid-cols-12 p-2 hidden">
                <div class="col-span-2 font-semibold border border-black pl-2 bg-gray-100">Blood Group</div>
                <div class="col-span-10 grid grid-cols-12 ">
                    <thead v-for="(month,index) in months" :key="index" class="border border-black bg-gray-100">
                        <th class="pl-2"> {{month}} </th>
                    </thead>
                </div>
                <div  class=" col-span-12">
                    <tbody class="grid grid-cols-12 ">
                        <tr v-for="(group,gid) in fetchedData" :key="gid" class="col-span-12 ">
                            <td class="col-span-12 grid grid-cols-12 border border-black ">
                              <span class="col-span-2 font-semibold  pl-2 uppercase">{{group.bloodGroup}}</span>
                              <div class="col-span-10 grid grid-cols-12 ">
                                <div v-for="(result,idx) in group.results" :key="idx" class="grid grid-cols-12 border-l-2 border-gray-700 text-gray-900 font-semibold pl-2">
                                  <span v-if="result.count>0">{{result.count}} <span class="text-blue-600 italic text-xs">{{$roundOff(Number(result.percentage.replace('%','')))+'%'}}</span></span>
                                  <span v-else-if="result.count===0 && selectedYear<=new Date().getFullYear() && !(selectedYear===new Date().getFullYear() && result.monthInt>new Date().getMonth()+1)">-</span>
                                  <span v-else class="italic text-xs lowercase ">N/A</span>                                       
                                </div>
                              </div>
                            </td>
                        </tr>
                    </tbody>
                </div>
              </table>  
            </div>        
              

            <div v-if="pagedResult && pagedResult.results && pagedResult.results.length>0">
              <!-- monthly-samples-tested -->                
              <table v-if="pagedResult.results[0].facility || pagedResult.results[1].facility" class="w-full grid grid-cols-12 p-2 text-sm text-left text-gray-500">
                  <div class="col-span-4 text-gray-700 bg-green-100 text-base
                  font-bold py-4 pl-5 border-t">Facility</div>
                  <div class="col-span-8 grid grid-cols-12">
                    <thead v-for="(month,index) in months" :key="index" class="text-gray-700 bg-green-100 text-base border-t">
                      <th class="pl-2 py-4 px-6">
                        {{month}}
                        </th>   
                    </thead>
                  </div>
                  <div class="col-span-12">
                    <tbody class="grid grid-cols-12">
                      <tr v-for="(group,gid) in pagedResult.results" :key="gid" class="bg-white col-span-12  hover:bg-green-50">
                          <td class="col-span-12 grid grid-cols-12 p-2 border">
                            <span class="py-2 col-span-4 text-gray-700 font-semibold px-2 uppercase">{{group.facility}}</span>
                              <div class="col-span-8 grid grid-cols-12 ">
                                <div v-for="(sample,idx) in group.samples" :key="idx"
                                class="grid grid-cols-12 font-semibold pl-2 text-center items-center">
                                <span v-if="sample.samplesTested>0">{{sample.samplesTested}} </span>
                                <span
                                  v-else-if="sample.samplesTested===0 && selectedYear<=new Date().getFullYear() && !(selectedYear===new Date().getFullYear() && sample.monthInt>new Date().getMonth()+1)">-</span>
                                <span v-else class="italic text-xs lowercase">N/A</span>
                              </div>
                            </div>
                          </td>
                      </tr>
                    </tbody>
                  </div>
              </table>
              <table v-if="pagedResult.results[0].facility || pagedResult.results[1].facility" class=" border grid grid-cols-12 p-2 hidden">
                <div class="col-span-4 font-semibold border border-black pl-2 bg-gray-100">Blood Group</div>
                <div class="col-span-8 grid grid-cols-12">
                  <thead v-for="(month,index) in months" :key="index" class="border border-black bg-gray-100">
                    <th class="pl-2 ">{{month}}</th>
                  </thead>
                </div>
                <div  class=" col-span-12">
                  <tbody class="grid grid-cols-12 ">
                    <tr v-for="(group,gid) in pagedResult.results" :key="gid" class="col-span-12 ">
                        <td class="col-span-12 grid grid-cols-12 border border-black ">
                          <span class="col-span-4  pl-2 capitalize">{{group.facility}}</span>
                          <div class="col-span-8 grid grid-cols-12 ">
                            <div v-for="(sample,idx) in group.samples" :key="idx" class="grid grid-cols-12 border-l-2 border-gray-700 text-gray-900 font-semibold pl-2">
                              <span v-if="sample.samplesTested>0">{{sample.samplesTested}} </span>
                              <span v-else-if="sample.samplesTested===0 && selectedYear<=new Date().getFullYear() && !(selectedYear===new Date().getFullYear() && sample.monthInt>new Date().getMonth()+1)">-</span>
                              <span v-else class="italic text-xs lowercase ">N/A</span>                                       
                            </div>
                          </div>
                        </td>
                    </tr>
                  </tbody>
                </div>
              </table>
            </div>


            <div v-if="fetchedData">
              <!-- TTI Results -->   
                      
              <table  v-if="fetchedData[0].testName || fetchedData[1].testName" class="w-full table-fixed justify-center text-gray-500">
                  <thead>
                    <tr
                      class="text-gray-700 bg-green-100 pb-2 pt-2 font-semibold text-sm"
                    >
                      <th class="p-3" style="width: 30%">Type</th>
                      <th class="p-3" style="width: 20%">
                        <div class="hidden xl:block">
                          Gender
                        </div>
                        <div class="block xl:hidden">
                          G
                        </div>
                      </th>
                      <th class="p-3" style="width: 20%">
                        <div class="hidden xl:block">
                        Reaction
                      </div>
                      <div class="block xl:hidden">
                        R
                      </div></th>
                      <th v-for="month in months" v-bind:key="month" class="w-1/5 p-3 pl-2 border-t " >
                        {{month}}
                      </th>   
                    </tr>
                  </thead>
                  <tbody>
                      <tr v-for="(group,gid) in fetchedData" :key="gid" class="bg-white text-sm hover:bg-gray-50 border-b-2 border-gray-300">
                        <td
                          class="w-1/4 p-3 text-center font-semibold text-gray-600 capitalize"
                        >
                          {{ $removeHyphens(group.testName) }}
                        </td>
                        <td class="text-center border-b border-black">
                          <div v-for="(gender, idx) in group.months" :key="idx">
                            <div
                              v-if="gender.genders[idx]"
                              class="py-4 border text-sm font-medium hidden xl:block"
                            >
                              {{ gender.genders[idx].gender }}
                            </div>
                            <div
                              v-if="gender.genders[idx]"
                              class="py-9 border text-sm font-medium block xl:hidden"
                            >
                             {{ gender.genders[idx].gender[0] }}
                            </div>
                          </div>
                        </td>
                        <td class="text-center">
                            <div v-for="(data, id) in group.months[0]" :key="id">
                              <div v-for="(gender, idx) in data" :key="idx">
                                <div v-for="(results, idx) in gender.results" :key="idx">
                                 <div
                                  v-if="results"
                                  :class="[ results.result.toLowerCase() === 'reactive' ? 'text-red-800' : 'text-green-800', 'p-1 border text-xs font-medium hidden xl:block']"
                                >
                                  {{ results.result.toLowerCase() === 'nonreactive' ? 'N/Reactive' : results.result }}
                                </div>
                                <div
                                  v-if="results"
                                  :class="[ results.result.toLowerCase() === 'reactive' ? 'text-red-800' : 'text-green-800', 'p-[14px] border text-xs font-medium block xl:hidden']"
                                >
                                  {{ results.result.toLowerCase() === 'nonreactive' ? 'N' : 'R' }}
                                </div>
                              </div>
                            </div>
                            </div>  
                        </td>
                        
                        <td
                            v-for="(months,idx) in group.months"
                            v-bind:key="idx"
                            width="25%"
                            class="w-1/4 text-center hover:bg-green-50"
                          >
                          <div v-if="months && months.genders">
                            <div v-if="months.genders.length">
                                <div v-for="(test,didx) in months.genders" :key="didx">
                                  <div class="h-1/3 text-sm">
                                    <span v-if="test.results && test.results.length>0">
                                        <span v-for="(result,rid) in test.results" :key="rid">

                                          <div v-if="result.result.toLowerCase() === 'reactive'" class="md:p-[2px] lg:py-[2px] h-1/3 text-sm border">
                                              <span class="text-xs text-red-800"> {{result.count}}
                                              </span>
                                          </div>

                                          <div v-else-if="result.result.toLowerCase() === 'nonreactive'" class="lg:p-[2px] xl:p-[0px] xl:py-[2px] h-1/3 text-sm border">
                                            <span class="text-xs text-green-800"> {{result.count}}
                                              </span>
                                              <!-- <span class="text-xs text-green-600"> {{result.count +' (' + result.percentage + ')'}}
                                              </span> -->
                                          </div> 
                                          </span>                         
                                        </span>
                                  </div>
                                </div>
                              </div>
                            <span v-else-if="selectedYear<=new Date().getFullYear() && !(selectedYear===new Date().getFullYear() && months.monthInt>new Date().getMonth()+1)" class="italic text-xs lowercase">N/A</span>
                          </div>
                          <div class="text-center" v-else>
                            <div class="h-1/3 border-b border-black text-sm">-</div>
                            <div class="h-1/3 border-b border-black text-sm">-</div>
                            <div class="h-1/3 border-none text-sm">-</div>
                            <div class="h-1/3 border-b border-black text-sm">-</div>
                            <div class="h-1/3 border-b border-black text-sm">-</div>
                            <div class="h-1/3 border-none text-sm">-</div>
                          </div>
                        </td>
                      </tr>
                  </tbody>
              </table> 
            </div>
          </div>
        </div>
        <div v-if="!hasList && !loading" class="h-full  flex items-center justify-center">
          <span class="text-xl text-yellow-600 font-semibold animate-pulse self-center">No records found.</span>
        </div>
      </div>
      <div>
        <div class="px-4 py-4 hidden">
          <nav v-if="!isEmpty(pagedResult)">
            <ul class="flex pl-0 rounded list-none flex-wrap" v-if="pagedResult.currentPage > 0">
              <li>
                <span
                  v-if="pagedResult.currentPage === 1"
                  class="opacity-50 first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-gray-500 bg-white text-gray-500"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current">
                    <path d="M12.707 7.707l-1.414-1.414L5.586 12l5.707 5.707 1.414-1.414L8.414 12z" />
                    <path d="M16.293 6.293L10.586 12l5.707 5.707 1.414-1.414L13.414 12l4.293-4.293z" />
                  </svg>
                </span>
                <router-link
                  v-else
                  :to="{ query: { page: 1 } }"
                  class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-primary-400 bg-white text-primary-400"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current">
                    <path d="M12.707 7.707l-1.414-1.414L5.586 12l5.707 5.707 1.414-1.414L8.414 12z" />
                    <path d="M16.293 6.293L10.586 12l5.707 5.707 1.414-1.414L13.414 12l4.293-4.293z" />
                  </svg>
                </router-link>
              </li>
              <li>
                <span
                  v-if="pagedResult.currentPage === 1"
                  class="opacity-50 first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-gray-500 bg-white text-gray-500"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current">
                    <path d="M13.293 6.293L7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z" />
                  </svg>
                </span>
                <router-link
                  v-else
                  :to="{ query: { page: pagedResult.currentPage - 1 } }"
                  class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-primary-400 bg-white text-primary-400"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current">
                    <path d="M13.293 6.293L7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z" />
                  </svg>
                </router-link>
              </li>
              <li v-for="page in pagedResult.pages" :key="page">
                <span
                  v-if="page === pagedResult.currentPage"
                  class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-primary-400 bg-primary-400 text-white"
                  >{{ page }}</span
                >
                <router-link
                  v-else
                  :to="{ query: { page: page } }"
                  class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-primary-400 bg-white text-primary-400"
                  >{{ page }}</router-link
                >
              </li>
              <li>
                <span
                  v-if="pagedResult.currentPage === pagedResult.totalPages"
                  class="opacity-50 first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-gray-500 bg-white text-gray-500"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current">
                    <path d="M10.707 17.707L16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z" />
                  </svg>
                </span>
                <router-link
                  v-else
                  :to="{ query: { page: pagedResult.currentPage + 1 } }"
                  class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-primary-400 bg-white text-primary-400"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current">
                    <path d="M10.707 17.707L16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z" />
                  </svg>
                </router-link>
              </li>
              <li>
                <span
                  v-if="pagedResult.currentPage === pagedResult.totalPages"
                  class="opacity-50 first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-gray-500 bg-white text-gray-500"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current">
                    <path d="M10.296 7.71L14.621 12l-4.325 4.29 1.408 1.42L17.461 12l-5.757-5.71z" />
                    <path d="M6.704 6.29L5.296 7.71 9.621 12l-4.325 4.29 1.408 1.42L12.461 12z" />
                  </svg>
                </span>
                <router-link
                  v-else
                  :to="{ query: { page: pagedResult.totalPages } }"
                  class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-primary-400 bg-white text-primary-400"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current">
                    <path d="M10.296 7.71L14.621 12l-4.325 4.29 1.408 1.42L17.461 12l-5.757-5.71z" />
                    <path d="M6.704 6.29L5.296 7.71 9.621 12l-4.325 4.29 1.408 1.42L12.461 12z" />
                  </svg>
                </router-link>
              </li>
            </ul>
          </nav>
        </div>
        <router-view> </router-view>
      </div>
    </div>
    <lock v-show="!oidcIsAuthenticated" />

    <!-- report to be printed -->
    <div id="report" class="p-12 border border-gray-200 m-8 rounded-sm flex flex-col hidden">
     <!--header area -->
     <div class="flex flex-row justify-between">
       <div class="flex flex-col space-y-2">
          <p class="font-bold"> {{oidcUser.facility}}<span class="font-normal"> ({{oidcUser.facility_code}})</span></p>
          <p class="capitalize">{{$removeHyphens(selectedReport)}} {{selectedYear}}</p>
       </div>
       <div>
         <img src="https://damuyetustaging.health.go.ke/img/KNBTS.7939d0ee.png" class="h-16"/>
       </div>
     </div>
     <!--header area end-->

     <!--  table-->
     <div class="border border-0.5  mt-12 ">
       <table  class="w-full whitespace-no-wrap ">
         <thead>
         <tr class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b  bg-gray-50  ">
           <th class="px-4 py-3 border border-black">Month</th>
           <th class="px-4 py-3 border border-black">Report</th>
           <th class="px-4 py-3 border border-black">Remarks</th>
         </tr>
         </thead>
         <tbody v-if="fetchedData.length >0" class="bg-white divide-y">
            <tr v-for="(item, index) in fetchedData" class="" :key="index">
                <td class="px-4 py-2 text-sm border border-black font-bold ">
                    {{$monthIntToName(item.monthInt)}}
                </td>
                <!-- by types -->
                <td class="px-4 py-3 text-xs border border-black" v-if="item.donationTypes && item.donationTypes.length>0">
                    <div v-for="(type, index) in item.donationTypes" :key="index" class="px-6 py-2 transition-all font-semibold font-mono">
                        <span class="capitalize">{{$removeHyphens(type.type)}}</span>:
                        <span>{{type.successfulDonations}}</span>
                    </div>
                </td>
                <td class="px-10 py-2 text-xs border border-black text-orange-400 font-semibold italic" v-if="item.donationTypes && item.donationTypes.length===0">
                    <span v-if="item.donationTypes && item.donationTypes.length===0 && selectedYear<= new Date().getFullYear() && !(selectedYear=== new Date().getFullYear() && item.monthInt >=new Date().getMonth()+1)">No donations recorded</span>
                    <span v-if="selectedYear> new Date().getFullYear() || (selectedYear=== new Date().getFullYear() && item.monthInt >new Date().getMonth()+1)">No records yet</span>
                </td>
                <!-- by age -->
                <td class="px-4 py-3 text-xs border border-black" v-if="item.ageGroups && item.ageGroups.length>0 && (selectedYear<= new Date().getFullYear() && item.monthInt <=new Date().getMonth()+1)">
                    <div v-for="(group, index) in item.ageGroups" :key="index" class="px-6 py-2 transition-all font-semibold font-mono">
                        <span class="capitalize">{{group.age}}</span>:
                        <span>{{group.successfulDonations}}</span>
                    </div>
                </td>
                <td class="px-10 py-2 text-xs border border-black text-orange-400 font-semibold italic" v-if="item.ageGroups && item.ageGroups.length>0 && (selectedYear<= new Date().getFullYear() && item.monthInt >new Date().getMonth()+1)">
                    <span v-if="item.ageGroups && item.ageGroups.length===0 && selectedYear<= new Date().getFullYear() && !(selectedYear=== new Date().getFullYear() && item.monthInt >=new Date().getMonth()+1)">No donations recorded</span>
                    <span v-if="selectedYear> new Date().getFullYear() || (selectedYear=== new Date().getFullYear() && item.monthInt >new Date().getMonth()+1)">No records yet</span>
                </td>
                <!-- by discards -->
                <td class="px-4 py-3 text-xs border border-black" v-if="item.unitsDiscarded && item.unitsDiscarded.length>0 && (selectedYear<= new Date().getFullYear() && item.monthInt <=new Date().getMonth()+1)">
                    <div v-for="(discard, index) in item.unitsDiscarded" :key="index" class="px-6 py-2 transition-all font-semibold font-mono">
                        <span class="capitalize p-2">{{$removeHyphens(discard.reason)}}:</span>
                        <span>{{discard.unitsDiscarded}}</span>
                    </div>
                </td>
                <td class="px-10 py-2 text-xs border border-black text-orange-400 font-semibold italic" v-if="item.unitsDiscarded && item.unitsDiscarded.length===0">
                    <span v-if="selectedYear<= new Date().getFullYear() && !(selectedYear=== new Date().getFullYear() && item.monthInt >new Date().getMonth()+1)">No discards recorded</span>
                    <span v-if="selectedYear> new Date().getFullYear() || (selectedYear=== new Date().getFullYear() && item.monthInt >new Date().getMonth()+1)">No records yet</span>
                </td>
                <!--  -->
                <td class="px-10 py-2 border border-black"></td>
            </tr>
         </tbody>
         <tbody v-else>
          <tr class="h-12 w-full text-center">
            <td></td>
            <td class="capitalize">No data available</td>
            <td></td>
          </tr>
         </tbody>
       </table>
     </div>
     <!-- end of table-->
     <!-- sign out info-->
     <div class="flex flex-col space-y-2 mt-14">
       <div class="flex flex-row space-x-2">
         <span> Name: </span> <div class="border-0 border-b mb-1 border-gray-200 w-40"></div>
       </div>
       <div class="flex flex-row space-x-2">
         <span> Signature: </span> <div class="border-0 border-b mb-1 border-gray-200 w-40"></div>
       </div>
     </div>
     <!-- end sign out info-->
     <!-- start disclaimer and footer-->
     <div class="mb-0 flex flex-row justify-center mt-14 w-full">
        <p class="text-gray-400"> Disclaimer: This is a system generated document</p>
     </div>
     <!-- end disclaimer and footer-->
   </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Lock from "@/components/layout/Lock";
export default {
  async created() {
    await this.fetchOptions(); this.fetchFacilityOptions();
  },
  props: {
    title: String,
    // url: String,
    showCreate: { type: Boolean, default: true },
    showBack: { type: Boolean, default: false },
    createName: { type: String, default: "Create" },
    createTitle: { type: String, default: "New" },
    showCustomAction: { type: Boolean, default: false },
    customActionParams: {
      type: Array,
      default() {
        return [];
      },
    },
    customActionTitle: { type: String, default: "Action" },
    customActionName: { type: String, default: "Action" },
    tableHeadingTitles: {
      Array,
      default: function() {
        return [];
      },
    },
  },
  components: { Lock },
  computed: {
    ...mapGetters(["oidcIsAuthenticated","oidcUser"]),
    hasList() {
      return this.fetchedData?.length > 0  || this.pagedResult?.results?.length > 0;
    },
    url(){
      if(this.selectedReport === "TTI-Results") {
        return 'registration/screening-reports/'+ this.selectedYear + '/' + this.selectedReport + '?facilityCode=' + this.selectedFacilityCode + '&region=' + this.formattedRegion + '&county=' + this.formattedCounty
      } else {
        return 'screening/screening-reports/'+this.selectedReport+'/'+this.selectedYear + '?facilityCode=' + this.selectedFacilityCode + '&region=' + this.formattedRegion + '&county=' + this.formattedCounty
      }
    }
  },
  data() {
    return {
      loading: false,
      pagedResult: [],
      fetchedData:[],
      fetchedCSVData: [],
      selectedYear:"",
      selectedRegion: "",
      selectedCounty: "",
      formattedRegion: "",
      formattedCounty: "",
      selectedFacilityCode: "",
      
      selectedReport: "monthly-samples-tested-TTI",
      error: {},
      q: this.$route.query.q,
      months:['Jan','Feb','March','April','May','June','July','Aug','Sept','Oct','Nov','Dec'],
      options: [],
      optionsUrls: [
        "screening/ScreeningLookups/regions/options",
      ],
      reportOptions: {
        "monthly-samples-tested-TTI": "Monthly Samples Tested TTI",
        "monthly-blood-grouping": "Monthly Blood Grouping",   
        "TTI-Results": "TTI Results"

      },
      counties: [],
      facilities: [],
      filters: false
    };
  },
//   watch: {
//     $route: {
//       immediate: true,
//     //   async handler() {
//     //     if (this.$route.matched.length === 1 || (this.$route.matched.length > 1 && Object.keys(this.pagedResult).length === 0 && this.pagedResult.constructor === Object)) {
//     //       await this.fetch();
//     //     }
//     //   },
//     },
//   },
  methods: {
    printResults(){
      this.$htmlToPaper('report');
    },
    showFilters(){
      if(this.filters === false) {
        this.filters = true
      } else {
         this.filters = false
         this.clearAllFilters()
      }
    },
    selectMonthlyReport() {
      this.fetch();
      this.clearAllFilters();
    },
    fetchFacilityOptions() {
      this.$callApi
        .get(`screening/Facilities/facility-options?region=${this.formattedRegion}&county=${this.formattedCounty}`)
        .then((x) => {
          this.facilities = x.data;
        });
    },
    selectFacilityFilter() {
      if(this.selectedFacilityCode === null){
        this.selectedFacilityCode = ""
      }
      this.fetch();
    },
    selectCountyFilter(e) {
      if (e) {
        this.facilities = []
        this.selectedFacilityCode = ""
        this.$callApi
        .get(`screening/ScreeningLookups/value/${e}`)
        .then((x) => {
          this.formattedCounty = x.data.label;
          this.fetchFacilityOptions()
          this.fetch();
        });
      }
      if(this.selectedCounty === null){
        this.selectedCounty = ""
        this.formattedCounty = ""
        this.facilities = []
        this.selectedFacilityCode = ""
        this.fetchFacilityOptions()
        this.fetch();
      }
    },
    fetchCounties(e) {
      if (e) {
        this.clearFilters()
        this.$callApi
          .get(`screening/ScreeningLookups/${e}/options`)
          .then((x) => {
            this.counties = x.data;
            this.fetchRegionName(e);
          });
      }
      if(this.selectedRegion === null){
        this.selectedRegion = ""
        this.formattedRegion = ""
        this.clearFilters()
        this.fetch();
      }
    },
    fetchRegionName(code){
      this.$callApi
        .get(`screening/ScreeningLookups/value/${code}`)
        .then((x) => {
          this.formattedRegion = x.data.label;
          this.fetchFacilityOptions()
          this.fetch();
        });
    },
    clearFilters(){  
      this.selectedCounty = ""
      this.formattedCounty = ""
      this.facilities = []
      this.counties = []
      this.selectedFacilityCode = ""
      this.fetchFacilityOptions()
    },
    clearAllFilters(){  
      this.formattedRegion = "",
      this.selectedRegion = "",
      this.selectedCounty = ""
      this.formattedCounty = ""
      this.facilities = []
      this.counties = []
      this.selectedFacilityCode = ""
      this.fetchFacilityOptions()
      this.fetch()
    },
    fetchOptions() {
      window.Promise.all([...this.optionsUrls.map((x) => this.$callApi.get(x))])
        .then((x) => {
          this.options = [...x.map((y) => y.data)];
        })
        .catch((err) => console.log(err));
    },
    formatInput(){
        this.selectedYear=this.selectedYear.replace(/ /g,'')
        if(this.selectedReport) this.fetch()
    },
    async fetch() {
      this.loading = true;
      this.fetchedData=[]  
      this.pagedResult=[] 
      this.fetchedCSVData = []
      this.$callApi
        .get(this.url, {
          params: { ...this.$route.query },
        })
        .then((x) => {
          this.fetchedData = x.data.data;
          this.fetchedCSVData = x.data.csv || x.data.csvData;
          if (x.data.data.currentPage) this.pagedResult = x.data.data;
        })
        .catch((err) => {
          //this.error = err;
          console.log(err);
        })
        .then(() => (this.loading = false));
    },
    search() {
      if (this.q.length >= 3) {
        this.$router.push({ query: { q: this.q } });
      } else {
        if (this.$route.query.q !== undefined) {
          this.q = "";
          this.$router.push({ query: {} });
        }
      }
    },
    clear(e) {
      if (e.target.value.trim().length === 0) {
        this.q = "";
      }
    },
    navigate(queryString) {
      this.$router.push({ path: this.$route.path, query: { ...queryString } });
    },
    isEmpty(obj) {
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    },
    back() {
      this.$router.back();
    },
  },
  mounted(){
    this.fetch()
  }
};
</script>
