<template>
  <t-select :class="context.classes.element" :data-type="context.type" :options="context.options" @input="context.blurHandler" v-on="$listeners" v-bind="context.attributes" v-model="context.model"> </t-select>
</template>
<script>
import TSelect from "vue-tailwind/dist/t-select";
export default {
  name: "custom-dropdown",
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  components: {
    "t-select": TSelect,
  },
};
</script>
