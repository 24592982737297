<template>
  <div id="app" :class="[{ 'bg-main bg-no-repeat bg-center bg-cover': !oidcIsAuthenticated }, 'font-sans antialiased']">
    <div :class="[{ '': !oidcIsAuthenticated }, 'grid', { ' grid-rows-layout': oidcIsAuthenticated }, 'h-full min-h-screen w-full']">
      <navbar v-show="oidcIsAuthenticated"></navbar>
      <main :class="['grid', { 'grid-cols-12 divide-x': oidcIsAuthenticated }]">
        <side-menu v-show="oidcIsAuthenticated"></side-menu>
        <div :class="[{ 'place-items-center': !oidcIsAuthenticated }, 'grid', { 'col-span-12 md:col-span-10 h-full  grid-rows-footer bg-gray-100': oidcIsAuthenticated }]">
          <div :class="[{ 'px-4 py-4 h-full overflow-x-auto w-full scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-200': oidcIsAuthenticated }]">
            <transition enter-active-class="animate-flip-in-ver-right">
              <router-view :class="[{ 'bg-white h-full  shadow-sm rounded min-w-[728px]': oidcIsAuthenticated }]" />
            </transition>
          </div>
          <footer class="px-6 md:px-8 py-2 space-y-2 md:space-y-0 text-primary-500 flex flex-col md:flex-row items-center justify-between" v-show="oidcIsAuthenticated">
            <div class="flex items-center text-xs md:text-sm ">
              <p class="pr-2">&copy; {{ new Date().getFullYear() }}</p>
              <h4 class="flex items-center space-x-2">
                <img src="@/assets/g12.png" alt="" class="w-4 h-4 md:w-8 md:h-8 flex-shrink-0" />
                <span>Kenya Tissue and Transplant Authority</span>
              </h4>
            </div>
            <div>
              <p v-if="oidcIsAuthenticated" class="text-xs md:text-sm flex items-center divide-x divide-yellow-500 divide-opacity-25  text-yellow-700">
                <span class="pr-2">{{ oidcUser.facility_code }}</span>
                <span class="px-2">{{ oidcUser.facility }}</span>
              </p>
            </div>
          </footer>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import navbar from "@/components/layout/Navbar";
import sideMenu from "@/components/layout/SideMenu";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      showSideMenu: false,
      isNotificationsMenuOpen: false,
      isAppsMenuOpen: false,
    };
  },
  created() {
    document.title = "Blood Inveintory | KNBTS";
  },
  components: {
    navbar,
    sideMenu,
  },
  computed: {
    ...mapGetters(["areas", "oidcIsAuthenticated", "oidcUser"]),
    hasAccess: function() {
      return this.oidcIsAuthenticated || this.$route.meta.isPublic;
    },
  },
  mounted() {
    window.addEventListener("vuexoidc:userLoaded", this.userLoaded);
    window.addEventListener("vuexoidc:oidcError", this.oidcError);
    window.addEventListener("vuexoidc:automaticSilentRenewError", this.automaticSilentRenewError);
  },
  methods: {
    ...mapActions(["switchArea", "loadAreas", "authenticateOidcPopup", "removeOidcUser", "signOutOidc"]),
    userLoaded: function(e) {
      console.log("I am listening to the user loaded event in vuex-oidc", e.detail);
      this.loadAreas({
        role: e.detail.profile.role,
        token: e.detail.access_token,
      });
    },
    oidcError: function(e) {
      console.log("I am listening to the oidc oidcError event in vuex-oidc", e.detail);
    },
    automaticSilentRenewError: function(e) {
      console.log("I am listening to the automaticSilentRenewError event in vuex-oidc", e.detail);
    },
    signOut: function() {
      this.signOutOidc();
    },
  },
  watch: {
    $route(to) {
      document.title = "Blood Inveintory | " + (to.meta.title || "KNBTS");
    },
    areas(value) {
      if (value.length > 0) {
        let area;
        const route = this.$route.matched[0].meta.breadcrumb ? this.$route.matched[0].meta.breadcrumb.parent : this.$route.matched[0].name;
        area = this.areas.find((area) => area.links.some((link) => link.name === route));

        if (area) {
          this.switchArea(area.name);
        } else {
          this.switchArea("Home");
        }
      }
    },
  },
  destroyed() {
    window.removeEventListener("vuexoidc:userLoaded", this.userLoaded);
    window.removeEventListener("vuexoidc:oidcError", this.oidcError);
    window.removeEventListener("vuexoidc:automaticSilentRenewError", this.automaticSilentRenewError);
  },
};
</script>
