<template>
  <div class="h-full">
    <div class="rounded grid" v-show="oidcIsAuthenticated">
      <div class="">
        <div class="px-8 pt-4 grid grid-cols-12 gap-2 md:gap-4 items-center ">
          <div class="col-span-7">
            <h3 class="border-b-2 border-primary-300 py-4 space-x-2 inline-flex items-center text-primary-400  -mb-px">
              <button type="button" v-if="!!showBack" @click.prevent="back" class="text-6xl hover:bg-primary-200 transition p-2 hover:text-primary-600 rounded-full text-primary-500 bg-transparent outline-none focus:outline-none flex items-center justify-center" title="Back">
                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"></path>
                </svg>
              </button>
              <span class="text-xs text-primary-700 md:text-base   font-normal  uppercase tracking-wider">{{ title }}</span>
              <span v-if="hasList">({{pagedResult.totalItems}})</span>
            </h3>
          </div>
          <div class="col-span-5 flex items-center justify-end space-x-1">
            <div class="group text-gray-500 w-full md:w-1/2 transition duration-300 flex items-center border-gray-300 border focus-within:shadow-md rounded   focus-within:text-gray-900  focus-within:border-primary-500 ">
              <input @search="clear" type="search" v-model="q" placeholder="Search..." class="peer transition outline-none px-3 w-full placeholder:italic placeholder:text-gray-400 rounded-l py-1.5  border-none focus:ring-0 " @keyup.enter="search" />
              <button @click="search" class="transition p-3 focus:outline-none peer-focus:bg-primary-500 peer-focus:text-white ">
                <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                </svg>
              </button>
            </div>
            <router-link v-if="!!showCustomAction" :to="{ name: customActionName }" class=" px-2 py-2 bg-primary-50 rounded flex items-center text-sm text-primary-500 focus:outline-none space-x-1 hover:bg-primary-400 hover:text-white" :title="createTitle">
              <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="w-4 h-4" viewBox="0 0 384 512">
                <defs />
                <path fill="currentColor" d="M377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-153 31V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm64 160v48c0 4.4-3.6 8-8 8h-56v56c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8v-56h-56c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h56v-56c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v56h56c4.4 0 8 3.6 8 8z" />
              </svg>
              <span class="hidden md:block">{{ customActionTitle }}</span>
            </router-link>
            <slot name="more-actions">
              <router-link :to="{ name: 'RejectBatch' }" class="transition border border-primary-500/50 px-2 py-2.5  rounded flex items-center text-sm text-primary-500 focus:outline-none space-x-1 hover:bg-primary-500 hover:text-white" :title="createTitle" v-if="headerName === 'batchDeliveries'">
                <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"></path></svg>
                <span class="hidden md:block truncate">Reject batch </span>
              </router-link>
            </slot>
            <router-link v-if="!!showCreate" :to="{ name: createName }" class="transition border border-primary-500/50 px-2 py-2.5  rounded flex items-center text-sm text-primary-500 focus:outline-none space-x-1 hover:bg-primary-500 hover:text-white" :title="createTitle">
              <svg class="w-4 h-4 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.75 3a.75.75 0 0 1 .743.648l.007.102.001 7.25h7.253a.75.75 0 0 1 .102 1.493l-.102.007h-7.253l.002 7.25a.75.75 0 0 1-1.493.101l-.007-.102-.002-7.249H3.752a.75.75 0 0 1-.102-1.493L3.752 11h7.25L11 3.75a.75.75 0 0 1 .75-.75Z" />
              </svg>
              <span class="hidden md:block truncate">{{ createTitle }}</span>
            </router-link>
            <span>
              <button
              @click="printLabResults('labResults')"
              class=" w-full  shadow-sm rounded-md py-2 px-2 bg-blue-100 hover:bg-blue-200 flex items-center text-xs text-blue-500 hover:text-blue-700 focus:outline-none transform hover:-translate-y-px hover:scale-110"
              title="Print Delivery Note"
              v-if="headerName==='completedGroupingSamples' || headerName==='completedScreeningSamples'"
            >
              <span class="w-4 h-4 mt-1">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
                  />
                </svg>
              </span>
              <span class="font-semibold">Print Results</span>
            </button>
            </span>
          </div>
        </div>
        <div class="border-b"></div>
      </div>

      <!-- Batch Deliveries Header -->
      <div class="h-full border-b border-gray-100 " v-if="headerName === 'batchDeliveries'">
        <span v-if="headerName === 'batchDeliveries'">
          <t-table
            :headers="['Donation No.', 'Missing?']"
            
          >
          </t-table>
        </span>
        <div v-else class="h-full  flex items-center justify-center">
          <span class="text-xl text-yellow-600 font-semibold animate-pulse self-center">No delivery header found.</span>
        </div>
        
      </div>
      <!-- End of Batch Deliveries Header -->
      <!-- Screening Header -->
      <div class="h-full border-b border-gray-100 " v-if="labScreeeningHeaders && (headerName === 'screeningSamples' || headerName === 'reviewScreeningSamples' || headerName==='completedScreeningSamples')">
        <span v-if="this.labScreeeningHeaders.length > 1">
          <t-table
            :headers="this.labScreeeningHeaders"
            :data="[
             
            ]"
          ></t-table>
        </span>
        <!-- <table class="text-sm tracking-wide w-full">
          <tbody class=" " v-if="this.labScreeeningHeaders.length > 1">
            <tr class="flex ">
              <td v-for="(header, index) in this.labScreeeningHeaders" :key="index" class="grid grid-cols-7 px-2 border-black border-l border-b border-t border-r">
                <span class="flex text-lg ">{{ header ? header : "Test Name" }}</span>
              </td>
            </tr>
          </tbody>
        </table> -->
        <div v-if="!this.labScreeeningHeaders" class="h-full  flex items-center justify-center">
          <span class="text-xl text-yellow-600 font-semibold animate-pulse self-center">No screening header found.</span>
        </div>
      </div>
      <!-- End of Screening Header -->

      <!-- Grouping Header -->
      <div class="h-full border-b border-gray-100 " v-if="this.labGroupingHeaders && (headerName === 'groupingSamples' || headerName === 'reviewGroupingSamples' || headerName==='completedGroupingSamples')">
        <span v-if="this.labGroupingHeaders.length > 1">
          <t-table
            :headers="this.labGroupingHeaders"
            :data="[
             
            ]"
          ></t-table>
        </span>
        <!-- <table class="text-sm tracking-wide w-full">
          <tbody class=" " v-if="this.labGroupingHeaders.length > 1">
            <tr class="flex ">
              <td v-for="(header, index) in this.labGroupingHeaders" :key="index" class="grid grid-cols-4 px-2 border-black border-l border-b border-t">
                <span class="flex text-lg ">{{ header ? header : "Test Name" }}</span>
              </td>
            </tr>
          </tbody>
        </table> -->
        <div v-if="!this.labGroupingHeaders" class="h-full  flex items-center justify-center">
          <span class="text-xl text-yellow-600 font-semibold animate-pulse self-center">No headers found.</span>
        </div>
      </div>
      <!-- End of Grouping Header -->

      <div class="h-full  border-gray-100 ">
        <div v-if="loading" class="grid h-full place-items-center">
          <loading-indicator />
        </div>
        <div v-if="hasList" class="w-full overflow-x-auto">
          <div class="   border-gray-100 divide-y  divide-gray-400 w-full min-w-full ">
            <div v-for="(item, index) in pagedResult.results" :key="item.id" :class="['px-8 py-1  transition-all  bg-gray-100 flex hover:bg-gray-200', item.missing ? 'bg-yellow-200 hover:bg-yellow-300' : item.redTopResults === 'safe' ? 'bg-green-200 hover:bg-green-300' : item.redTopResults === 'not-safe' ? 'bg-red-200 hover:bg-red-300' : '']">
              <slot v-bind:index="index" v-bind:item="item" v-bind:itemLabel="item.label" v-bind:itemType="item.type" v-bind:vacutainerStatus="item.vacutainerStatus" v-bind:headerData="headerData" v-bind:sortedTestDetails="sortedTestDetails" v-bind:sortedGroupingTestDetails="sortedGroupingTestDetails"></slot>

              <span class="flex px-2" v-if="headerName === 'batchDeliveries'">
                <input type="checkbox" @click="addMissingSample(item.entityGuid, index, item.label)" v-model="selectedMissingSamples" :id="`missing${index}`" :value="item.entityGuid" :class="[item.vacutainerStatus === 'Not_Received' && !item.missing ? 'text-primary-400 focus:ring-primary-400 visible' : 'invisible']" title="Mark sample as missing" />
              </span>
              <span v-if="item.missing && (headerName === 'screeningSamples' || headerName === 'groupingSamples' || headerName==='reviewScreeningSamples' || headerName==='reviewGroupingSamples' || headerName==='completedScreeningSamples' || headerName==='completedGroupingSamples')" :class="[item.missing ? ' rounded-md text-black font-mono italic ' : '']">Missing{{item.requestSent?', request sent':''}}</span>
              <span v-if="item.missing && headerName === 'batchDeliveries'" :class="[item.missing ? ' rounded-md text-black font-mono italic ' : '']">Missing {{item.requestSent?', request sent':''}}</span>
              <!-- <span v-if="item.vacutainerStatus === 'Pending_Review' && (headerName === 'screeningSamples' || headerName === 'groupingSamples')" :class="['rounded-md text-pink-500 italic']">Results added, pending review</span> -->
            </div>
          </div>          
          
        </div>
        <div v-if="!hasList && !loading" class="h-full  flex items-center justify-center">
          <span class="text-xl text-yellow-600 font-semibold animate-pulse self-center">No records found.</span>
        </div>
      </div>

      <div>
        <div class="px-8  py-4 bg-gray-100/60 flex ">
          <nav v-if="!isEmpty(pagedResult)" class="mx-auto">
            <div class="flex items-center">
              <span v-if="pagedResult.currentPage === 1" class="w-full p-4 border text-base rounded-l-xl text-gray-600 bg-gray-50/60 ">
                <svg width="9" height="8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 19l-7-7 7-7m8 14l-7-7 7-7"></path></svg>
              </span>
              <router-link v-else :to="{ query: { page: 1 } }" class="w-full p-4 border text-base rounded-l-xl text-gray-600 bg-white hover:bg-gray-100">
                <svg width="9" height="8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 19l-7-7 7-7m8 14l-7-7 7-7"></path></svg>
              </router-link>
              <span v-if="pagedResult.currentPage === 1" class="w-full p-4 border-y border-r text-base  text-gray-600 bg-gray-50/60">
                <svg width="9" height="8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>
              </span>
              <router-link v-else :to="{ query: { page: pagedResult.currentPage - 1 } }" class="w-full p-4 border-y border-r text-base text-gray-600 bg-white hover:bg-gray-100">
                <svg width="9" height="8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>
              </router-link>
              <template v-for="page in pagedResult.pages">
                <span v-if="page === pagedResult.currentPage" :key="page" class="w-full px-4 py-2 odd:border-y even:border  text-base text-white bg-primary-400 border-primary-400 ">
                  {{ page }}
                </span>
                <router-link :to="{ query: { page: page } }" v-else :key="page" class="w-full px-4 py-2 even:border odd:border-y text-base text-gray-600 bg-white hover:bg-gray-100 hover:text-primary-400 ">
                  {{ page }}
                </router-link>
              </template>

              <span v-if="pagedResult.currentPage === pagedResult.totalPages" class="w-full p-4 border-y border-r text-base  text-gray-600 bg-gray-50/60">
                <svg width="9" height="8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
              </span>
              <router-link v-else :to="{ query: { page: pagedResult.currentPage + 1 } }" class="w-full p-4 border-y border-r text-base text-gray-600 bg-white hover:bg-gray-100">
                <svg width="9" height="8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
              </router-link>
              <span v-if="pagedResult.currentPage === pagedResult.totalPages" class="w-full p-4 border-y border-r text-base rounded-r-xl text-gray-600 bg-gray-50/60 ">
                <svg width="9" height="8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5l7 7-7 7M5 5l7 7-7 7"></path></svg>
              </span>
              <router-link v-else :to="{ query: { page: pagedResult.totalPages } }" class="w-full p-4 border-y border-r text-base rounded-r-xl text-gray-600 bg-white hover:bg-gray-100 hover:text-primary-400">
                <svg width="9" height="8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5l7 7-7 7M5 5l7 7-7 7"></path></svg>
              </router-link>
            </div>
          </nav>
          <span v-if="missingSamples.length > 0">
            <RouteLink :route="{ name: 'MissingSamples', params: { missingSamples: missingSamples } }" class="flex-auto transition rounded-md text-center py-2 px-2 bg-yellow-400 flex items-center text-xs text-white font-mono hover:bg-yellow-500 hover:text-yellow-900 focus:outline-none transform hover:-translate-y-px hover:scale-110" title="Click to centrifuge">
              <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
              <p class="px-2 text-lg font-medium capitalize">Mark as missing</p>
            </RouteLink>
          </span>
          <!-- screening results -->
          <span v-if="upload_results && headerName === 'screeningSamples'">
            <span v-if="upload_results.length > 0">
              <RouteLink :route="{ name: 'UploadSelectedResults', params: { uploadResults: upload_results, vacutainerType: $route.params.batchType } }" class="flex-auto transition rounded-md text-center py-2 px-2 bg-blue-400 flex items-center text-xs text-white font-mono hover:bg-blue-500 hover:text-blue-900 focus:outline-none transform hover:-translate-y-px hover:scale-110" title="Click to upload results">
                <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"></path></svg>
                <p class="px-2 text-lg font-medium capitalize">Submit Results</p>
              </RouteLink>
            </span>
          </span>
          <span v-if="review_results && headerName === 'reviewScreeningSamples'">
            <span v-if="review_results.length > 0">
              <RouteLink :route="{ name: 'SubmitReviewedScreeningResults', params: { vacutainers: review_results, vacutainerType: $route.params.batchType } }" class="flex-auto transition rounded-md text-center py-2 px-2 bg-blue-400 flex items-center text-xs text-white font-mono hover:bg-blue-500 hover:text-blue-900 focus:outline-none transform hover:-translate-y-px hover:scale-110" title="Click to upload results">
                <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"></path></svg>
                <p class="px-2 text-lg font-medium capitalize">Submit Reviewed Results</p>
              </RouteLink>
            </span>
          </span>
          <!-- grouping results -->
          <span v-if="upload_results && headerName === 'groupingSamples'">
            <span v-if="upload_results.length > 0">
              <RouteLink :route="{ name: 'UploadSelectedGroupingResults', params: { uploadResults: upload_results, vacutainerType: $route.params.batchType } }" class="flex-auto transition rounded-md text-center py-2 px-2 bg-blue-400 flex items-center text-xs text-white font-mono hover:bg-blue-500 hover:text-blue-900 focus:outline-none transform hover:-translate-y-px hover:scale-110" title="Click to upload results">
                <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"></path></svg>
                <p class="px-2 text-lg font-medium capitalize">Submit Results</p>
              </RouteLink>
            </span>
          </span>
          <span v-if="review_results && headerName === 'reviewGroupingSamples'">
            <span v-if="review_results.length > 0">
              <RouteLink :route="{ name: 'SubmitReviewedGroupingResults', params: { vacutainers: review_results, vacutainerType: $route.params.batchType } }" class="flex-auto transition rounded-md text-center py-2 px-2 bg-blue-400 flex items-center text-xs text-white font-mono hover:bg-blue-500 hover:text-blue-900 focus:outline-none transform hover:-translate-y-px hover:scale-110" title="Click to upload results">
                <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"></path></svg>
                <p class="px-2 text-lg font-medium capitalize">Submit Reviewed Results</p>
              </RouteLink>
            </span>
          </span>
          <!-- update sample status -->
          <span v-if="selected_vacutainers && headerName === 'updateSampleStatus'">
            <span v-if="selected_vacutainers.length > 0">
              <RouteLink :route="{ name: 'UpdateSamples', params: { selectedVacutainers: selected_vacutainers } }" class="flex-auto transition rounded-md text-center py-2 px-2 bg-blue-400 flex items-center text-xs text-white font-mono hover:bg-blue-500 hover:text-blue-900 focus:outline-none transform hover:-translate-y-px hover:scale-110" title="Click to upload results">
                <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"></path></svg>
                <p class="px-2 text-lg font-medium capitalize">Update status</p>
              </RouteLink>
            </span>
          </span>
        </div>
        <router-view />
      </div>
    </div>
    <lock v-show="!oidcIsAuthenticated" />

  <!--  -->
  <!-- component label -->
        <div>
          <div class="hidden p-4" :id="'labResults'">
            <span class="flex justify-center"><img src="@/assets/coa.svg" alt="Logo Unavailable" class="h-8 w-8"/></span>
            <span class="flex justify-center uppercase text-sm font-semibold"> Kenya National Blood Transfusion Service</span>
            <span class="flex justify-center capitalize text-xs text-blue-500 font-semibold px-3 col-span-1">{{ oidcUser.facility ? "(" + oidcUser.facility + ")" : "" }}</span>
            <span class="flex justify-center">Batch: <span class="font-semibold font-sans">{{$route.params.batchNumber}}</span></span>
            
            <br />
            <span :class="['flex justify-center font-semibold font-mono',headerName === 'completedGroupingSamples'?'text-purple-500':headerName === 'completedScreeningSamples'?'text-red-500':'']">{{(pagedResult.totalItems?pagedResult.totalItems:'') + (headerName === 'completedScreeningSamples'?' Screening': headerName === 'completedGroupingSamples'?' Grouping':'')}} Samples </span>
            <!-- screening results table -->
            <table class="w-full pl-4 pr-4 border-black border-b border-t" v-if="headerName === 'completedScreeningSamples'">
              <thead v-if="labScreeeningHeaders && labScreeeningHeaders.length>0">
                <th class="border border-black">#</th>
                <th class="border border-black">{{labScreeeningHeaders[0]}}</th>
                <th class="border border-black">{{labScreeeningHeaders[1]}}</th>
                <th class="border border-black">{{labScreeeningHeaders[2]}}</th>
                <th class="border border-black">{{labScreeeningHeaders[3]}}</th>
                <th class="border border-black">{{labScreeeningHeaders[4]}}</th>
                <th class="border border-black">Outcome</th>
                 <th class="border border-black">Added by</th>
                <th class="border border-black">Reviewed by</th>
                <th class="border border-black">Date</th>
              </thead>
              <tbody v-if="pagedResult.results && pagedResult.results.length>0">               
                <tr :class="['text-sm',result.redTopResults==='safe'?'bg-green-100':result.redTopResults==='not-safe'?'bg-red-100':'']" v-for="(result,index) in pagedResult.results" :key="index">
                  <!-- <td class="text-xs px-3 border-l border-r border-black border-b">{{pagedResult.currentPage>1?(pagedResult.currentPage+index+1+pagedResult.pageSize):index+1}}</td> -->
                  <td class="text-xs px-3 border-l border-r border-black border-b">{{pagedResult.currentPage>1?(((pagedResult.currentPage-1)*pagedResult.pageSize)+index+1):index+1}}</td>
                  <td class="text-xs px-3 border-l border-r border-black border-b">{{result.label}}</td>
                  <td :class="['text-xs px-3 font-bold border-l border-r border-black border-b',result.testDetails[0].testResult==='Reactive'?'text-red-500':result.testDetails[0].testResult==='Nonreactive'?'text-green-600':'']" v-if="result.testDetails && result.testDetails.length>0">{{result.testDetails[0].testResult}}</td>
                  <td :class="['text-xs px-3 font-bold border-l border-r border-black border-b',result.testDetails[1].testResult==='Reactive'?'text-red-500':result.testDetails[1].testResult==='Nonreactive'?'text-green-600':'']" v-if="result.testDetails && result.testDetails.length>0">{{result.testDetails[1].testResult}}</td>
                  <td :class="['text-xs px-3 font-bold border-l border-r border-black border-b',result.testDetails[2].testResult==='Reactive'?'text-red-500':result.testDetails[2].testResult==='Nonreactive'?'text-green-600':'']" v-if="result.testDetails && result.testDetails.length>0">{{result.testDetails[2].testResult}}</td>
                  <td :class="['text-xs px-3 font-bold border-l border-r border-black border-b',result.testDetails[3].testResult==='Reactive'?'text-red-500':result.testDetails[3].testResult==='Nonreactive'?'text-green-600':'']" v-if="result.testDetails && result.testDetails.length>0">{{result.testDetails[3].testResult}}</td>
                  <td :class="['text-xs px-3 font-bold border-l border-r border-black border-b uppercase',result.redTopResults==='not-safe'?'text-red-500':result.redTopResults==='safe'?'text-green-600':'']" >{{$removeHyphens(result.redTopResults)}}</td>
                  <td class="px-3 border-l border-r border-black border-b">
                     {{result.resultsAddedBy}} 
                  </td>
                  <td class="px-3 border-l border-r border-black border-b text-xs">
                    {{result.resultsReviewedBy}}
                  </td>
                  <td class="px-3 border-l border-r border-black border-b text-xs" v-if="result.testDetails && result.testDetails.length>0">{{$formatDate(result.testDetails[0].createdOnUtc)}}</td>
                  
                </tr>
              </tbody>
            </table>
            <!-- grouping results table -->
            <table class="w-full p-4 border-black border-b border-t" v-if="headerName === 'completedGroupingSamples'">
              <thead v-if="labGroupingHeaders && labGroupingHeaders.length>0">
                <th class="border border-black">#</th>
                <th class="border border-black">{{labGroupingHeaders[0]}}</th>
                <th class="border border-black" >{{labGroupingHeaders[1]}}</th>
                <th class="border border-black">Added by</th>
                <th class="border border-black">Reviewed by</th>
                <th class="border border-black">Date</th>
              </thead>
              <tbody v-if="pagedResult.results && pagedResult.results.length>0">               
                <tr class="text-sm" v-for="(result,index) in pagedResult.results" :key="index">
                  <td class="text-xs px-3 border-l border-r border-black border-b">{{pagedResult.currentPage>1?(((pagedResult.currentPage-1)*pagedResult.pageSize)+index+1):index+1}}</td>
                  <td class="px-3 border-l border-r border-black border-b">{{result.label}}</td>
                  <td :class="['px-3 font-bold border-l border-r border-black border-b uppercase text-orange-400',result.purpleTopResults.includes('-')?'text-orange-400':'text-green-600']" >{{result.purpleTopResults?result.purpleTopResults:''}}</td>
                  <td class="px-3 border-l border-r border-black border-b">
                     {{result.resultsAddedBy}} 
                  </td>
                  <td class="px-3 border-l border-r border-black border-b">
                    {{result.resultsReviewedBy}}
                  </td>
                  <td class="px-3 border-l border-r border-black border-b" v-if="result.testDetails && result.testDetails.length>0">{{$formatDate(result.testDetails[0].createdOnUtc)}}</td>
                  
                </tr>
              </tbody>
            </table>

            <div class="grid grid-cols-2 font-semibold mt-5">
              <span class="flex">Added By:............................................................</span>
              <span>Signature:...........................</span>
              <span class="flex">Reviewed By:.........................................................</span>
              <span>Signature:...........................</span>
              <span class="col-span-2">Stamp:.........................................................</span>

            </div>

          </div>
        </div>
  <!--  -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Lock from "@/components/layout/Lock";
export default {
  name: "base-table",
  props: {
    title: String,
    url: String,
    footerAction: [String, Object, Number, Array],
    upload_results: [String, Object, Number, Array],
    review_results: [String, Object, Number, Array],
    selected_vacutainers: [String, Object, Number, Array],
    headerUrl: String,
    headerName: String,
    showCreate: { type: Boolean, default: true },
    showBack: { type: Boolean, default: false },
    createName: { type: String, default: "Create" },
    createTitle: { type: String, default: "New" },
    showCustomAction: { type: Boolean, default: false },
    customActionParams: {
      type: Array,
      default() {
        return [];
      },
    },
    customActionTitle: { type: String, default: "Action" },
    customActionName: { type: String, default: "Action" },
    backRouterName: String,
    backSteps: { type: Number, default: 0 },
  },
  components: { Lock },
  computed: {
    ...mapGetters(["oidcIsAuthenticated","oidcUser"]),
    hasList() {
      return this.pagedResult?.results?.length > 0;
    },
    hasHeaderData() {
      return this.headerData?.results?.length > 0;
    },
  },
  data() {
    return {
      loading: false,
      pagedResult: {},
      headerData: {},
      screeningTestData: [],
      groupingTestData: [],
      labScreeeningHeaders: ["Donation No"],
      labGroupingHeaders: ["Donation No"],
      error: {},
      q: this.$route.query.q,
      selectedMissingSamples: [],
      missingSamples: [],
      sortedTestDetails: [],
      sortedGroupingTestDetails: [],
    };
  },
  watch: {
    $route: {
      immediate: true,
      async handler() {
        if (this.$route.matched.length === 1 || (this.$route.matched.length > 1 && Object.keys(this.pagedResult).length === 0 && this.pagedResult.constructor === Object)) {
          await this.fetch();
          this.selectedMissingSamples= []
          this.missingSamples=[]

        }
      },
    },
  },
  mounted() {
    const body = document.body;
    if (this.oidcIsAuthenticated) {
      body.classList.add("overflow-y-scroll");
    } else {
      body.classList.remove("overflow-y-scroll");
    }
  },
  methods: {
    async fetch() {
      this.loading = true;
      this.pagedResult = {};
      this.$callApi
        .get(this.url, {
          params: { ...this.$route.query },
        })
        .then((x) => {
          this.pagedResult = x.data;
          for (let i = 0; i < this.pagedResult.results.length; i++) {
            if (this.pagedResult.results[i].type === "red-top") {
              this.sortedTestDetails[i] = this.pagedResult.results[i].testDetails;
              this.sortedTestDetails[i].sort((a, b) => (a.testConfig.displayOrder > b.testConfig.displayOrder ? 1 : -1));
            }
            if (this.pagedResult.results[i].type === "purple-top") {
              this.sortedGroupingTestDetails[i] = this.pagedResult.results[i].testDetails;
              this.sortedGroupingTestDetails[i].sort((a, b) => (a.testConfig.displayOrder > b.testConfig.displayOrder ? 1 : -1));
            }
          }
        })
        .catch(() => {
          //this.error = err;
          //console.log(err);
        })
        .then(() => (this.loading = false));

      // Table Header Data
      if (this.headerUrl) {
        this.$callApi
          .get(this.headerUrl)
          .then((x) => {
            this.headerData = x.data;
            // screening Header Data
            if (this.labScreeeningHeaders.length < 7) {
              for (let header of this.headerData.results) {
                if (header.type === "red-top") {
                  this.labScreeeningHeaders.push(header.name);
                  this.screeningTestData.push(header);
                }
              }
              this.labScreeeningHeaders.push("Confirm result", "Action","Comment","Actors");
            }
            //end of screening header data

            // grouping Header Data
            if (this.labGroupingHeaders.length < 5) {
              for (let header of this.headerData.results) {
                if (header.type === "purple-top") {
                  this.labGroupingHeaders.push(header.name);
                }
              }
              this.labGroupingHeaders.push("Confirm result", "Action","Comment","Actors");
            }
            //end of grouping header data
          })
          .catch(() => {
            //this.error = err;
            //console.log(err);
          })
          .then(() => (this.loading = false));
      }
    },
    printLabResults(item_id) {
      var divContents = document.getElementById(item_id).innerHTML;
      var a = window.open("", "", "height=800, width=800");
      a.document.write("<html>");
      const style = '<head> <link rel="stylesheet" href="https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css" id="styleSheet"></head><body>';
      a.document.write(style);
      a.document.write(divContents);
      a.document.write("</body></html>");
      a.document.close();
      a.document.querySelector("#styleSheet").onload = function() {
        a.print();
      };
    },
    addMissingSample(entity_guid, index, label) {
      let missingSampleId = `missing${index}`;
      var decider = document.getElementById(missingSampleId);
      if (decider.checked) {
        this.missingSamples.push({
          label: label,
          entityGuid: entity_guid,
        });
      } else {
        this.missingSamples.pop({
          label: label,
          entityGuid: entity_guid,
        });
      }
    },
    addToCompleteList(entity_guid, index) {
      let toCompleteId = `missing${index}`;
      var decider = document.getElementById(toCompleteId);
      if (decider.checked) {
        this.missingSamples.push({
          entityGuid: entity_guid,
        });
      } else {
        this.missingSamples.pop({ entityGuid: entity_guid });
      }
    },
    search() {
      if (this.q?.length >= 3) {
        this.$router.push({ query: { q: this.q } });
      } else {
        if (this.$route.query.q !== undefined) {
          this.q = "";
          this.$router.push({ query: {} });
        }
      }
    },
    clear(e) {
      if (e.target._value.trim().length === 0) {
        this.$router.push({ query: {} });
      }
    },
    navigate(queryString) {
      this.$router.push({ path: this.$route.path, query: { ...queryString } });
    },
    isEmpty(obj) {
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    },
    back() {
      if (this.backRouterName) {
        this.$router.push({ name: this.backRouterName });
      } else {
        if (this.backSteps !== 0) {
          this.$router.go(this.backSteps - this.backSteps * 2);
        } else {
          this.$router.back();
        }
      }
    },
  },
};
</script>
