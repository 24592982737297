<template>
  <nav class="py-1 grid grid-cols-header md:grid-cols-12 gap-y-4 sticky z-10 shadow-sm top-0 place-content-center divide-x divide-secondary-600 bg-secondary-500">
    <div class="md:hidden grid items-center px-2 ">
      <button class="text-primary focus:outline-none w-full" @click="toggleSideMenu">
        <svg class="w-8 h-8 " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
        </svg>
      </button>
    </div>
    <div class="grid items-center px-2 md:col-span-2">
      <router-link to="/" class="flex place-content-center " custom>
        <img src="@/assets/path7.png" alt="" class="flex-shrink-0 h-10 ">
      </router-link>
    </div>
    <div class="flex items-center md:justify-between justify-end px-4 md:col-span-10">
      <h1 class="hidden md:flex md:items-center font-semibold text-white text-base dark:text-gray-200 tracking-wide md:pl-3">
        <span v-if="oidcIsAuthenticated">{{ oidcUser.facility_code }} {{ oidcUser.facility }}</span>
      </h1>
      <div class="flex items-center space-x-2  md:space-x-4 py-0.5" v-if="oidcIsAuthenticated">
        <app-areas></app-areas>
        <notifications></notifications>
        <account></account>
      </div>
    </div>
  </nav>
</template>
<script>
import appAreas from "@/components/layout/AppAreas";
import notifications from "@/components/layout/Notifications";
import account from "@/components/layout/Account";
import { mapActions, mapGetters } from "vuex";
// import DamuYetuWhite from "@/views/home/DamuYetuWhite.vue";
// import DamuKe from "@/components/icons/DamuKe.vue";
export default {
  name: "nav-bar",
  components: {
    appAreas,
    notifications,
    account,
    // DamuYetuWhite,
    // DamuKe
  },
  data: () => ({
    isAppsMenuOpen: false,
  }),
  computed: {
    ...mapGetters(["oidcIsAuthenticated", "oidcUser"]),
  },
  methods: {
    toggleAppsMenu() {
      this.isAppsMenuOpen = !this.isAppsMenuOpen;
    },
    ...mapActions(["toggleSideMenu", "closeSideMenu"]),
  },
};
</script>
