export default [
    // component separation     
    {
        path:"/component-separation/pint-batch-deliveries",
        name:"ComponentDeliveries",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/component_separation/Deliveries.vue"),
        children:[     
               
            {
                path:"add-delivery-person/",
                name:"AddComponentSeparationDeliveryPerson",
                component: () =>
                    import ( /* webpackChunkName: "tests" */ "@/components/data/SchemaModal.vue"),
                props: () => {
                    let propData={

                        toFetch: true,
                        title:"Add delivery batches",
                        verb: "POST",
                        fetchUrl:``,
                        postUrl: `component-separation/deliveries`,
                        options: [],
                        optionsUrls: [`component-separation/componentseparationlookups/identification-types/options`],
                        jsonSchema: "component-separation/componentseparationforms/add-delivery-person",                        
                        size: "max-w-3xl"
                    };
                    propData.title = `Add Delivery Person`
                    return propData;
                }
            },   
            {
                path:"update-delivery-person",
                name:"UpdateComponentSeparationDeliveryPerson",
                component: () =>
                    import ( /* webpackChunkName: "tests" */ "@/components/data/SchemaModal.vue"),
                props: (x) => ({
                    toFetch: true,
                    title:"Amend delivery person details",
                    verb: "PUT",
                    fetchUrl:`component-separation/deliveries/${x.params.id}`,
                    postUrl: `component-separation/deliveries/${x.params.id}`,
                    options: [],
                    optionsUrls: [`component-separation/componentseparationlookups/identification-types/options`],
                    jsonSchema: "component-separation/componentseparationforms/add-delivery-person",
                    size: "max-w-3xl"
                })

            },
            {
                path: "delete/:id",
                title: "Delete Form",
                name: "DeleteComponentSeparationDeliveryPerson",
                component: () =>
                    import (
                        /* webpackChunkName: "doctors-pool-group" */
                        "@/components/data/DeleteModal.vue"
                    ),
                props: (x) => ({
                    title: "Delete Form ",
                    deleteUrl: `component-separation/deliveries/${x.params.id}`,
                    size: "max-w-xs",
                    name: "Delete Form",
                }),
            },

           
        ]

    },
    // batch components
    {
        path:"/component-separation/pint-batch-deliveries/:batchNumber/batch-components/:teamLead/:batchGuid",
        name:"ViewBatchComponents",
        meta: { breadcrumb: { parent: "ComponentDeliveries" } },
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/component_separation/DeliveryBatchPints.vue"),
        children:[
            {
                path:"receive-batch",
                name:"ReceiveComponentSeparationBatch",
                component: () =>
                    import ( /* webpackChunkName: "tests" */ "@/components/data/componentSeparation/SchemaModal.vue"),
                props: (x) => {
                    let propData={

                        toFetch: false,
                        verb: "POST",
                        fetchUrl:``,
                        teamLead:x.params.teamLead,
                        postUrl: `component-separation/bloodpintbatches/${x.params.batchGuid}/receive-blood-pint-batches`,
                        options: [],
                        optionsUrls: [`component-separation/componentseparationlookups/batch-conditions/options`],
                        jsonSchema: "component-separation/componentseparationforms/receive-batch",                        
                        moduleName:'component-separation',
                        size: "max-w-3xl"
                    };
                    propData.title = `Receive batch '${x.params.batchNumber}'`
                    return propData;
                }
            }, 
        ]
    },
    {
        path:"/component-separation/received-pints",
        name:"BloodPints",
        meta: { breadcrumb: { parent: "ComponentDeliveries" } },
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/component_separation/ReceivedPints.vue"),
        children:[               
                
            
        ]  
        
    },
    {
        path:"/component-separation/received-pints/view-extracted-components/:bloodPintId/:donationNumber/:bloodBagType/:quantity",
        name:"ExtractedComponentsPerPint",
        meta: { breadcrumb: { parent: "ComponentDeliveries" } },
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/component_separation/ExtractedComponentsPerDonation.vue"),

        props: (x) => {
            let propData={

                toFetch: true,
                bloodBagType:x.params.bloodBagType,
                title:"Add delivery batches",
                verb: "POST",
                fetchUrl:``,
                postUrl: `component-separation/bloodpints/${x.params.bloodPintId}/add-blood-components`,
                options: [],
                optionsUrls: [`component-separation/componentseparationlookups/component-measurement-units/options`,`component-separation/bloodpints/${x.params.bloodPintId}/unprepared-component-options`,`component-separation/componentseparationlookups/double-blood-bag-possible-components/options`,`component-separation/componentseparationlookups/triple-blood-bag-possible-components/options`,`component-separation/componentseparationlookups/quadruple-bag-possible-components/options`],
                jsonSchema: "component-separation/componentseparationforms/record-extracted-component",                        
                size: "max-w-3xl"
            };
            propData.title = `${x.params.donationNumber} extracted components`
            return propData;
        },
        children:[
            {
                path:"record-component/:bloodPintId/:donationNumber/:bloodBagType",
                name:"RecordExtractedComponent",
                component: () =>
                    import ( /* webpackChunkName: "tests" */ "@/components/data/componentSeparation/ExtractComponentSchemaModal.vue"),
                props: (x) => {
                    let propData={

                        toFetch: false,
                        bloodBagType:x.params.bloodBagType,
                        title:"Add delivery batches",
                        verb: "POST",
                        fetchUrl:``,
                        postUrl: `component-separation/bloodpints/${x.params.bloodPintId}/add-blood-components`,
                        options: [],
                        optionsUrls: [`component-separation/componentseparationlookups/component-measurement-units/options`,`component-separation/bloodpints/${x.params.bloodPintId}/unprepared-component-options`,`component-separation/componentseparationlookups/double-blood-bag-possible-components/options`,`component-separation/componentseparationlookups/triple-blood-bag-possible-components/options`,`component-separation/componentseparationlookups/quadruple-bag-possible-components/options`],
                        jsonSchema: "component-separation/componentseparationforms/record-multiple-components",                        
                        size: "max-w-4xl",
                        loggedInUser:true
                    };
                    propData.title = `Record component from '${x.params.donationNumber} ${x.params.bloodBagType}'`
                    return propData;
                }
            },  
            {
                path:"update/:bloodComponentId/:name",
                name:"UpdateExtractedComponent",
                component: () =>
                    import ( /* webpackChunkName: "tests" */ "@/components/data/SchemaModal.vue"),
                    // import ( /* webpackChunkName: "tests" */ "@/components/data/componentSeparation/ExtractComponentSchemaModal.vue"),
                props: (x) => {
                    let propData={
                        toFetch: true,
                        moduleName:'ComponentSeparation',
                        moduleSection:'UpdateExtractedComponent',
                        bloodBagType:x.params.bloodBagType,
                        title:`Update ${x.params.name}`,
                        verb: "PUT",
                        fetchUrl:`component-separation/bloodpints/${x.params.bloodComponentId}/get-blood-component-by-id`,
                        postUrl: `component-separation/bloodpints/${x.params.bloodComponentId}/update-blood-component`,
                        options: [],
                        optionsUrls: [`component-separation/componentseparationlookups/component-measurement-units/options`,`component-separation/bloodpints/${x.params.bloodPintId}/unprepared-component-options`,`component-separation/componentseparationlookups/double-blood-bag-possible-components/options`,`component-separation/componentseparationlookups/triple-blood-bag-possible-components/options`,`component-separation/componentseparationlookups/quadruple-bag-possible-components/options`],
                        jsonSchema: "component-separation/componentseparationforms/record-component",        
                        // jsonSchema: "component-separation/componentseparationforms/record-multiple-components",                        
                        size: "max-w-4xl",
                        loggedInUser:true
                    };
                    return propData;
                }
            }, 
            {
                path:"mark-as-complete/:bloodPintId/:donationNumber/:bloodBagType",
                name:"MarkAsComplete",
                component: () =>
                    import ( /* webpackChunkName: "tests" */ "@/components/data/ConfirmPostModal.vue"),
                props: (x) => {
                    let propData={

                        toFetch: true,
                        bloodBagType:x.params.bloodBagType,
                        verb: "POST",
                        postUrl: `component-separation/bloodpints/${x.params.bloodPintId}/complete-separation-within-blood-pint`,
                        options: [],
                        optionsUrls: [``],
                        size: "max-w-lg",
                        loggedInUser:true
                    };
                    propData.title = `Complete recording '${x.params.donationNumber}' components`
                    return propData;
                }
            },  

        ]
    },     
    // prepared components
    {
        path:"/component-separation/extracted-components",
        name:"PreparedComponents",
        meta: { breadcrumb: { parent: "ComponentDeliveries" } },
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/component_separation/ExtractedComponents.vue"),
        children:[
            {
                path:"dispatch/:facilityTo",
                name:"dispatchComponents",
                component: () =>
                    import ( /* webpackChunkName: "tests" */ "@/views/component_separation/DispatchComponents.vue"),
                props: (x) => {
                    let propData={

                        toFetch: true,
                        bloodBagType:x.params.bloodBagType,
                        title:"Add delivery batches",
                        verb: "POST",
                        fetchUrl:``,
                        postUrl: `component-separation/bloodpints/dispatch-components`,
                        options: [],
                        optionsUrls: [`component-separation/componentseparationlookups/component-measurement-units/options`,`component-separation/bloodpints/${x.params.bloodPintId}/unprepared-component-options`,`component-separation/componentseparationlookups/double-blood-bag-possible-components/options`,`component-separation/componentseparationlookups/triple-blood-bag-possible-components/options`,`component-separation/componentseparationlookups/quadruple-bag-possible-components/options`],
                        jsonSchema: "component-separation/componentseparationforms/record-extracted-component",                        
                        size: "max-w-3xl",
                        loggedInUser:true
                    };
                    propData.title = `Dispatch components`
                    return propData;
                }
            },     
            
        ]  
        
    },
    // Dispatches
    {
        path:"/component-separation/dispatched-batches",
        name:"DispatchedBatches",
        meta: { breadcrumb: { parent: "ComponentDeliveries" } },
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/component_separation/DispatchedBatches.vue"),
        children:[
               
            
        ]  
        
    },
    {
        path:"/component-separation/dispatched-batches/components/:batchNumber/:batchGuid",
        name:"DispatchedBatchComponents",
        meta: { breadcrumb: { parent: "ComponentDeliveries" } },
        component: () =>
            import ( /* webpackChunkName: "tests" */ "@/views/component_separation/DispatchedBatchComponents.vue"),
       
    }, 
    
   // Bag setup
   {
    path: "/component-separation/bag-setup",
    name: "BagAndComponentDefinitions",
    meta: { breadcrumb: { parent: "ComponentDeliveries" } },
    component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/component_separation/Setup/BagAndComponentDefinitions/BagTypes.vue"),
    children: [
        {
            path: "create-bag-type",
            name: "CreateBagType",
            component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/SchemaModal.vue"),
            props: () => ({
                toFetch: false,
                title: "Create Bag Type ",
                postUrl: "component-separation/bagandcomponentdefinitions/create-bag-type",
                optionsUrls: [`component-separation/componentseparationlookups/blood-bag-types/options`],
                jsonSchema: "component-separation/componentseparationforms/create-bag-type",
                size: "max-w-xs"
            })
        },
        {
            path: "edit/:id",
            name: "UpdateBagType",
            component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/SchemaModal.vue"),
            props: x => ({
                title: "Update Bag Type",
                fetchUrl: `component-separation/bagandcomponentdefinitions/${x.params.id}/get-bag-type-by-id`,
                postUrl: `component-separation/bagandcomponentdefinitions/${x.params.id}/update-bag-type`,
                optionsUrls: [`component-separation/componentseparationlookups/blood-bag-types/options`],
                jsonSchema: "component-separation/componentseparationforms/create-bag-type",
                size: "max-w-xs"
            })
        },
        {
            path: "delete/:id",
            title: "Delete Bag Type",
            name: "DeleteBagType",
            component: () =>
                import (
                    /* webpackChunkName: "admin-group" */
                    "@/components/data/DeleteModal.vue"
                ),
            props: (x) => ({
                title: "Delete Bag Type ",
                deleteUrl: `component-separation/bagandcomponentdefinitions/${x.params.id}/delete-bag-type`,
                size: "max-w-xs",
                name: "Delete Form",
            }),
        },
    ]
    },
    {
        path: "/component-separation/bag-setup/:bagId/:bagType/components",
        name: "ComponentsPerBag",
        meta: { breadcrumb: { parent: "ComponentDeliveries" } },
        props: x => ({
            title: x.params.title,
            bagId:x.params.bagId
        }),
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/component_separation/Setup/BagAndComponentDefinitions/Components.vue"),
        children: [
            {
                path: "create-component-type",
                name: "CreateComponentType",
                component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/SchemaModal.vue"),
                props: (x) => ({
                    toFetch: false,
                    title: "Add Component Type",
                    verb:"POST",
                    postUrl: `component-separation/bagandcomponentdefinitions/${x.params.bagId}/create-components-to-a-bag`,
                    optionsUrls: [`component-separation/bagandcomponentdefinitions/component-options`],
                    jsonSchema: "component-separation/componentseparationforms/create-component-type",
                    size: "max-w-xs",
                })
            },
            {
                path: "edit/:id",
                name: "UpdateComponentType",
                component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/SchemaModal.vue"),
                props: (x) => ({
                    title: "Update Component Type",
                    verb: "PUT",
                    fetchUrl: `component-separation/bagandcomponentdefinitions/${x.params.id}/get-component-by-id`,
                    postUrl: `component-separation/bagandcomponentdefinitions/${x.params.id}/update-component`,
                    optionsUrls: [`component-separation/bagandcomponentdefinitions/component-options`],
                    jsonSchema: "component-separation/componentseparationforms/create-component-type",
                    size: "max-w-xs",
                    moduleName:'donation',
                    moduleSection:'component-setup'
                })
            },
            {
                path: "delete/:id",
                title: "Delete Component",
                name: "DeleteComponent",
                component: () =>
                    import (
                        /* webpackChunkName: "admin-group" */
                        "@/components/data/DeleteModal.vue"
                    ),
                props: (x) => ({
                    title: "Delete Component ",
                    deleteUrl: `component-separation/bagandcomponentdefinitions/${x.params.id}/delete-component`,
                    size: "max-w-xs",
                    name: "Delete Form",
                }),
            },
        ]
    },

    // component setup
    {
        path: "/component-separation/components-setup",
        name: "Components",
        meta: { breadcrumb: { parent: "ComponentDeliveries" } },
        props: x => ({
            title: x.params.title,
        }),
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/component_separation/Setup/ComponentSetup/ComponentList.vue"),
        children: [
            {
                path: "create",
                name: "CreateComponent",
                component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/SchemaModal.vue"),
                props: () => ({
                    toFetch: false,
                    title: "Add Component Type",
                    verb:"POST",
                    postUrl: `component-separation/bagandcomponentdefinitions/create-components`,
                    optionsUrls: [],
                    jsonSchema: "component-separation/componentseparationforms/create-component",
                    size: "max-w-xl",
                })
            },
            {
                path: "edit/:id",
                name: "EditComponent",
                component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/SchemaModal.vue"),
                props: (x) => ({
                    title: "Update Component Type",
                    verb: "PUT",
                    fetchUrl: `component-separation/bagandcomponentdefinitions/${x.params.id}/get-component-by-id`,
                    postUrl: `component-separation/bagandcomponentdefinitions/${x.params.id}/update-component`,
                    optionsUrls: [],
                    jsonSchema: "component-separation/componentseparationforms/update-component",
                    size: "max-w-xl",
                })
            }
        ]
    }, 
    // reports 
    {
        path:'/component-separation/reports',
        name:"ComponentReports",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/component_separation/reports/Reports.vue"),

    },
   
    // groups and  options 
    {
        path: "/component-separation/optionsgroups",
        name: "ComponentSeparationLookups",
        meta: { breadcrumb: { parent: "ComponentDeliveries" } },
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/component_separation/Setup/Lookups/OptionGroupsList.vue"),
        children: [
            {
                path: "create",
                name: "CreateComponentSeparationOptionsGroup",
                component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/SchemaModal.vue"),
                props: () => ({
                    toFetch: false,
                    title: "Add Options Group ",
                    postUrl: "component-separation/componentseparationlookups/0",
                    optionsUrls: [],
                    jsonSchema: "component-separation/componentseparationforms/options-group",
                    size: "max-w-xs"
                })
            },
            {
                path: "edit/:id",
                title: "Edit Options Group ",
                name: "AmendComponentSeparationOptionsGroup",
                component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/SchemaModal.vue"),
                props: x => ({
                    title: "Add Options Group ",
                    postUrl: `component-separation/componentseparationlookups/${x.params.id}`,
                    optionsUrls: [],
                    jsonSchema: "component-separation/componentseparationforms/options-group",
                    size: "max-w-xs"
                })
            }
        ]
    },
    {
        path: "/component-separation/optionsgroups/:groupid/options",
        name: "ComponentSeparationOptions",
        meta: { breadcrumb: { parent: "ComponentDeliveries" } },
        props: x => ({
            title: x.params.title
        }),
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/component_separation/Setup/Lookups/OptionsList.vue"),
        children: [
            {
                path: "create",
                name: "CreateComponentSeparationOption",
                component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/SchemaModal.vue"),
                props: (x) => ({
                    toFetch: false,
                    title: "Add Option",
                    verb:"POST",
                    postUrl: `component-separation/componentseparationlookups/${x.params.groupid}`,
                    optionsUrls: [],
                    jsonSchema: "component-separation/componentseparationforms/option",
                    size: "max-w-xs"
                })
            },
            {
                path: "edit/:id",
                title: "Edit Option ",
                name: "AmendComponentSeparationOption",
                component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/SchemaModal.vue"),
                props: (x) => ({
                    title: "Amend Option ",
                    verb: "PUT",
                    postUrl: `component-separation/componentseparationlookups/${x.params.id}`,
                    optionsUrls: [],
                    jsonSchema: "component-separation/componentseparationforms/option",
                    size: "max-w-xs"
                })
            }
        ]
    },
    // Forms
    {
        path: "/component-separation/forms",
        name: "ComponentSeparationForms",
        meta: { breadcrumb: { parent: "ComponentDeliveries" } },
        component: () =>
            import (
                /* webpackChunkName: "doctors-pool-group" */
                "@/views/component_separation/Setup/Forms/List.vue"
            ),
        children: [{
                path: "create",
                name: "AddComponentSeparationForm",
                component: () =>
                    import (
                        /* webpackChunkName: "doctors-pool-group" */
                        "@/components/data/JsonForm.vue"
                    ),
                props: () => ({
                    toFetch: false,
                    title: "Add Form ",
                    postUrl: "component-separation/componentseparationforms",
                }),
            },
            {
                path: "edit/:id",
                title: "Update Form",
                name: "UpdateComponentSeparationForm",
                component: () =>
                    import (
                        /* webpackChunkName: "doctors-pool-group" */
                        "@/components/data/JsonForm.vue"
                    ),
                props: (x) => ({
                    title: "Update Form ",
                    postUrl: `component-separation/componentseparationforms/${x.params.id}`,
                }),
            },
            {
                path: "delete/:id",
                title: "Delete Form",
                name: "DeleteComponentSeparationForm",
                component: () =>
                    import (
                        /* webpackChunkName: "doctors-pool-group" */
                        "@/components/data/DeleteModal.vue"
                    ),
                props: (x) => ({
                    title: "Delete Form ",
                    deleteUrl: `component-separation/componentseparationforms/${x.params.id}`,
                    size: "max-w-xs",
                    name: "Delete Form",
                }),
            },
        ],
    },
    
]