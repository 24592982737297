import tDatepicker from "vue-tailwind/dist/t-datepicker"
import TTable from 'vue-tailwind/dist/t-table';
import {TToggle} from 'vue-tailwind/dist/components'

export default {
    "t-datepicker": {
        component: tDatepicker,
        props: {
            classes: {
                // Dropdown related classes
                wrapper: "flex flex-col",
                dropdownWrapper: "relative z-10",
                dropdown: "origin-top-left absolute rounded-sm elevation-2 bg-white overflow-hidden mt-1",
                enterClass: "",
                enterActiveClass: "transition ease-out duration-100 transform opacity-0 scale-95",
                enterToClass: "transform opacity-100 scale-100",
                leaveClass: "transition ease-in transform opacity-100 scale-100",
                leaveActiveClass: "",
                leaveToClass: "transform opacity-0 scale-95 duration-75",

                // Wrapper for inline calendar
                inlineWrapper: "",
                inlineViews: "rounded bg-white border mt-1 inline-flex",

                // Text input related classes
                inputWrapper: "",
                input: "block w-full px-3 py-2 text-black transition duration-300 placeholder-gray-400 transition duration-100 ease-in-out bg-white border border-gray-300 rounded shadow-sm focus:border-blue-500 focus:ring-0  focus:outline-none  disabled:opacity-50 disabled:cursor-not-allowed",
                clearButton: "hover:bg-gray-100 rounded transition duration-100 ease-in-out text-gray-600",
                clearButtonIcon: "",

                // Picker views
                viewGroup: "",
                view: "",

                // Navigator
                navigator: "pt-2 px-3",
                navigatorViewButton: "transition ease-in-out duration-100 inline-flex cursor-pointer rounded-full px-2 py-1 -ml-1 hover:bg-gray-100",
                navigatorViewButtonIcon: "fill-current text-gray-400",
                navigatorViewButtonBackIcon: "fill-current text-gray-400",
                navigatorViewButtonMonth: "text-gray-700 font-semibold",
                navigatorViewButtonYear: "text-gray-500 ml-1",
                navigatorViewButtonYearRange: "text-gray-500 ml-1",
                navigatorLabel: "py-1",
                navigatorLabelMonth: "text-gray-700 font-semibold",
                navigatorLabelYear: "text-gray-500 ml-1",
                navigatorPrevButton: "transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-100 rounded-full p-1 ml-2 ml-auto disabled:opacity-50 disabled:cursor-not-allowed",
                navigatorNextButton: "transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-100 rounded-full p-1 -mr-1 disabled:opacity-50 disabled:cursor-not-allowed",
                navigatorPrevButtonIcon: "text-gray-400",
                navigatorNextButtonIcon: "text-gray-400",

                // Calendar View
                calendarWrapper: "px-3 pt-2",
                calendarHeaderWrapper: "",
                calendarHeaderWeekDay: "uppercase text-xs text-gray-500 w-8 h-8 flex items-center justify-center",
                calendarDaysWrapper: "",
                calendarDaysDayWrapper: "w-full h-8 flex flex-shrink-0 items-center",

                // Day item
                otherMonthDay: "text-sm rounded-full w-8 h-8 mx-auto hover:bg-blue-100 text-gray-400 disabled:opacity-50 disabled:cursor-not-allowed",
                emptyDay: "",
                inRangeFirstDay: "text-sm bg-blue-500 text-white w-full h-8 rounded-l-full",
                inRangeLastDay: "text-sm bg-blue-500 text-white w-full h-8 rounded-r-full",
                inRangeDay: "text-sm bg-blue-200 w-full h-8 disabled:opacity-50 disabled:cursor-not-allowed",
                selectedDay: "text-sm rounded-full w-8 h-8 mx-auto bg-blue-500 text-white disabled:opacity-50 disabled:cursor-not-allowed",
                activeDay: "text-sm rounded-full bg-blue-100 w-8 h-8 mx-auto disabled:opacity-0 disabled:cursor-not-allowed",
                highlightedDay: "text-sm rounded-full bg-blue-200 w-8 h-8 mx-auto disabled:opacity-50 disabled:cursor-not-allowed",
                day: "text-sm rounded-full w-8 h-8 mx-auto hover:bg-blue-100 disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-opacity-0",
                today: "text-sm rounded-full w-8 h-8 mx-auto hover:bg-blue-100 disabled:opacity-50 disabled:cursor-not-allowed border border-blue-500",

                // Months View
                monthWrapper: "px-3 pt-2",
                selectedMonth: "text-sm rounded w-full h-12 mx-auto bg-blue-500 text-white",
                activeMonth: "text-sm rounded w-full h-12 mx-auto bg-blue-100",
                month: "text-sm rounded w-full h-12 mx-auto hover:bg-blue-100",

                // Years View
                yearWrapper: "px-3 pt-2",
                year: "text-sm rounded w-full h-12 mx-auto hover:bg-blue-100",
                selectedYear: "text-sm rounded w-full h-12 mx-auto bg-blue-500 text-white",
                activeYear: "text-sm rounded w-full h-12 mx-auto bg-blue-100",

                 // Time selector *Since 2.2*
                timepickerWrapper: 'flex items-center px-4 py-2 space-x-2',
                timepickerTimeWrapper: 'flex items-center space-x-2',
                timepickerTimeFieldsWrapper: 'bg-gray-100 rounded-md w-full text-right flex items-center border border-gray-100 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
                timepickerOkButton: 'text-blue-600 text-sm uppercase font-semibold transition duration-100 ease-in-out border border-transparent focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 rounded cursor-pointer',
                timepickerInput: 'text-center w-8 border-transparent bg-transparent p-0 h-6 text-sm transition duration-100 ease-in-out border border-transparent focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 rounded',
                timepickerTimeLabel: 'flex-grow text-sm text-gray-500',
                timepickerAmPmWrapper: 'relative inline-flex flex-shrink-0 transition duration-200 ease-in-out bg-gray-100 border border-transparent rounded cursor-pointer focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
                timepickerAmPmWrapperChecked: 'relative inline-flex flex-shrink-0 transition duration-200 ease-in-out bg-gray-100 border border-transparent rounded cursor-pointer focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
                timepickerAmPmWrapperDisabled: 'relative inline-flex flex-shrink-0 transition duration-200 ease-in-out opacity-50 cursor-not-allowed',
                timepickerAmPmWrapperCheckedDisabled: 'relative inline-flex flex-shrink-0 transition duration-200 ease-in-out opacity-50 cursor-not-allowed',
                timepickerAmPmButton: 'absolute flex items-center justify-center w-6 h-6 text-xs text-gray-800 transition duration-200 ease-in-out transform translate-x-0 bg-white rounded shadow',
                timepickerAmPmButtonChecked: 'absolute flex items-center justify-center w-6 h-6 text-xs text-gray-800 transition duration-200 ease-in-out transform translate-x-full bg-white rounded shadow',
                timepickerAmPmCheckedPlaceholder: 'flex items-center justify-center w-6 h-6 text-xs text-gray-500 rounded-sm',
                timepickerAmPmUncheckedPlaceholder: 'flex items-center justify-center w-6 h-6 text-xs text-gray-500 rounded-sm',
            }
        }
    },
    't-table': {
        component: TTable,
        props: {
          classes: {
            table: 'min-w-full divide-y divide-gray-100 shadow-sm border-gray-200 border',
            thead: '',
            theadTr: '',
            theadTh: 'px-3 py-2 font-semibold text-left bg-gray-100 border-b',
            tbody: 'bg-white divide-y divide-gray-100',
            tr: '',
            td: 'px-3 py-2 whitespace-no-wrap',
            tfoot: '',
            tfootTr: '',
            tfootTd: ''
          },
          variants: {
            thin: {
              td: 'p-1 whitespace-no-wrap text-sm',
              theadTh: 'p-1 font-semibold text-left bg-gray-100 border-b text-sm'
            }
          }
        }
      },
      't-toggle': {
        component: TToggle,
        props: {
          fixedClasses: {
            wrapper: 'relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200',
            wrapperChecked: 'relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200  border-2 border-transparent focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
            wrapperDisabled: 'relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200 opacity-50 cursor-not-allowed',
            wrapperCheckedDisabled: 'relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200 opacity-50 cursor-not-allowed border-2 border-transparent focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
            button: 'inline-block absolute transform translate-x-0 transition ease-in-out duration-200',
            buttonChecked: 'inline-block absolute transform translate-x-full transition ease-in-out duration-200',
            checkedPlaceholder: 'inline-block',
            uncheckedPlaceholder: 'inline-block'
          },
          classes: {
            wrapper: 'bg-gray-700 rounded-full border-2 border-transparent focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
            wrapperChecked: 'bg-green-700 rounded-full',
            wrapperDisabled: 'bg-gray-100 rounded-full border-2 border-transparent focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
            wrapperCheckedDisabled: 'bg-blue-500',
            button: 'h-5 w-5 rounded-full bg-white shadow flex items-center justify-center text-gray-400 text-xs',
            buttonChecked: 'h-5 w-5 rounded-full bg-white shadow flex items-center justify-center text-blue-500 text-xs',
            checkedPlaceholder: 'rounded-full w-5 h-5 flex items-center justify-center text-gray-400 text-xs',
            uncheckedPlaceholder: 'rounded-full w-5 h-5 flex items-center justify-center text-gray-400 text-xs'
          },
          variants: {
            danger: {
              wrapperChecked: 'bg-red-500 rounded-full',
              wrapperCheckedDisabled: 'bg-red-500 rounded-full'
            },
            success: {
              wrapperChecked: 'bg-green-500 rounded-full',
              wrapperCheckedDisabled: 'bg-green-500 rounded-full'
            },
            box: {
              wrapper: 'bg-gray-100 rounded-sm border-2 border-transparent focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
              wrapperChecked: 'bg-blue-500 rounded-sm',
              wrapperCheckedDisabled: 'bg-blue-500 rounded-sm',
              button: 'h-6 w-6 rounded-sm bg-white shadow flex items-center justify-center text-gray-400 text-xs',
              buttonChecked: 'h-6 w-6 rounded-sm  bg-white shadow flex items-center justify-center text-blue-500 text-xs',
              checkedPlaceholder: 'rounded-sm w-6 h-6 flex items-center justify-center text-gray-400 text-xs',
              uncheckedPlaceholder: 'rounded-sm w-6 h-6 flex items-center justify-center text-gray-400 text-xs'
            }
          }
        }
      }
    }