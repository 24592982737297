<script>
export default {
  props: {
    data: [Array, Object],
    url: String,
    first: Boolean,
    last: [String, Boolean, Number],
    totalResult: [Array, Object],
  },
  data() {
    return {
      exclude: ["index"],
    };
  },
};
</script>

<template>
  <tr>
    <div class="flex flex-col">
      <div
        v-if="first"
        :class="[
          `grid bg-green-50 grid-cols-${
            Object.keys(data).filter((key) => !exclude.includes(key)).length
          } justify-center py-2 text-center text-sm font-medium text-green-800 `,
        ]"
      >
        <div
          v-for="(value, idx) in Object.keys(data).filter(
            (key) => !exclude.includes(key)
          )"
          :key="idx"
          class="flex items-center justify-center border-l px-1"
        >
          <div>{{ $removeCamelCase(value) }}</div>
        </div>
      </div>

      <div
        :class="[
          `grid grid-cols-${
            Object.keys(data).filter((key) => !exclude.includes(key)).length
          } justify-center text-center text-sm`,
        ]"
      >
        <div
          v-for="(val, idx) in Object.keys(data)
            .filter((key) => !exclude.includes(key))
            .reduce((obj, key) => {
              obj[key] = data[key];
              return obj;
            }, {})"
          :key="idx"
          :class="[
            'flex items-center justify-center border-x border-b px-2 py-4 capitalize text-xs',
          ]"
        >
          {{ val }}
        </div>
      </div>
      <div
        v-if="last"
        class="grid grid-cols-8 justify-center text-center text-sm"
      >
        <div
          class="flex items-center justify-center border-b-2 bg-gray-100 px-3 text-center font-semibold capitalize text-gray-700 group-hover:bg-green-200"
        >
          Total
        </div>
        <!-- <div v-if="(totalResult.firstTimeDonor === 0 && totalResult.regularDonor === 0 && totalResult.repeatDonors === 0 && totalResult.donorsRecruited === 0 && totalResult.donorsDonated === 0 && totalResult.donorsDeffered === 0 && totalResult.unitsCollected === 0)" class="col-span-11 px-1 py-6 italic text-gray-400"> 
                             No Data Collected 
                </div> -->
        <!-- <div class="col-span-11 items-center border-b-2 text-center">
                    <div class="grid grid-cols-11 text-center justify-center">
                        <div class="flex justify-center items-center px-1 py-4 border-b border-x capitalize"> {{ totalResult.name }} </div>
                        <div class="flex justify-center items-center px-1 py-4 border-b border-x capitalize"> {{ totalResult.name }} </div>
                        <div class="flex justify-center items-center px-1 py-4 border-b border-x capitalize"> {{ $formatDate(totalResult.startDate) }}</div>
                        <div class="flex justify-center items-center px-1 py-4 border-b border-x capitalize"> {{ $formatDate(totalResult.endDate) }}</div>
                        <div class="flex justify-center items-center px-1 py-4 border-b border-x capitalize"> {{ totalResult.firstTimeDonor }} </div>
                        <div class="flex justify-center items-center px-1 py-4 border-b border-x capitalize"> {{ totalResult.regularDonor }} </div>
                        <div class="flex justify-center items-center px-1 py-4 border-b border-x capitalize"> {{ totalResult.repeatDonors }} </div>
                        <div class="flex justify-center items-center px-1 py-4 border-b border-x capitalize"> {{ totalResult.donorsRecruited }} </div>
                        <div class="flex justify-center items-center px-1 py-4 border-b border-x capitalize"> {{ totalResult.donorsDonated }} </div>
                        <div class="flex justify-center items-center px-1 py-4 border-b border-x capitalize"> {{ totalResult.donorsDeffered }} </div>
                        <div class="flex justify-center items-center px-1 py-4 border-b border-x capitalize"> {{ totalResult.unitsCollected }} </div>
                    </div>
                </div>  -->
      </div>
    </div>
  </tr>
</template>
