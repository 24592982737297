export default {
    state: () => ({
        counsellingFormData : {},
        formName : ""
    }),
    mutations: {
        setCounsellingFormData(state, payload) { 
            state.counsellingFormData = payload.formData;
            state.formName = payload.formName;
        },
     
        resetCounsellingFormData(state){
            state.counsellingFormData =  Object.assign({}, {});
        },
      
    },
    getters: {

        
    },
    actions: {
        addCounsellingFormData: ({commit}, payload) =>{
            commit("setCounsellingFormData", payload);
        },
 
        resetCounsellingFieldData: ({commit}) =>{
            commit("resetCounsellingFormData");

        }
    
    }
}