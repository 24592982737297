import axios from "axios";
import store from "@/store";
const ajax = axios.create({
    baseURL: process.env.VUE_APP_GATEWAY_URL !== undefined ?
        process.env.VUE_APP_GATEWAY_URL :
        "https://localhost:6005",
});

ajax.CancelToken = axios.CancelToken;
ajax.isCancel = axios.isCancel;

ajax.interceptors.request.use(
    (x) => {
        const token = store.state.oidcStore.access_token;
        if (token) {
            x.headers["Authorization"] = x.headers && x.headers["Authorization"] !== undefined
                ? x.headers["Authorization"]
                : `Bearer ${token}`;
            //x.withCredentials = true;
            // x.crossDomain = true;
        }
        return x;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default ajax;