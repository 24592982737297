export default [
  // Donor sites

  {
    path: "/donation/drives",
    name: "DonationSections",
    component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/donation/Drives.vue"),
    children: [
      {
        path: "end-drive/:donationDriveGuid/:driveName",
        name: "EndDrive",
        component: () =>
          import(
            /* webpackChunkName: "Transfusion" */
            "@/components/data/ConfirmPostModal.vue"
          ),
        props: (x) => ({
          verb: "POST",
          title: `End ${x.params.driveName} drive`,
          postUrl: `donation/donationsections/${x.params.donationDriveGuid}/complete-donation-drive`,
          size: "max-w-xs",
        }),
      },
      {
        path: "dispatch-blood-bags/:siteInfo/:teamLead/:noOfSingleBags/:noOfDoubleBags/:noOfTripleBags/:noOfQuadrupleBags",
        name: "DispatchBloodBags",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/donation/dispatch/DispatchBloodBags.vue"),
        props: (x) => {
          return {
            title: `Dispatch ${x.params.siteInfo} blood bags`,
            verb: "POST",
            fetchUrl: "",
            vacutainers: x.params.vacutainers,
            teamLead: x.params.teamLead,
            postUrl: `donation/donationsections/${x.params.donationDriveGuid}/dispatch-blood-bags`,
            options: [""],
            size: "max-w-3xl",
          };
        },
      },
      {
        path: "dispatch-vacutainers/:siteInfo/:teamLead/:noOfGroupingVacutainers/:noOfScreeningVacutainers",
        name: "DispatchVacutainers",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/donation/dispatch/DispatchVacutainers.vue"),
        props: (x) => {
          return {
            title: `Dispatch ${x.params.siteInfo} vacutainers`,
            verb: "POST",
            fetchUrl: "",
            vacutainers: x.params.vacutainers,
            teamLead: x.params.teamLead,
            postUrl: `donation/donationsections/${x.params.donationDriveGuid}/dispatch-vacutainers`,
            options: [""],
            size: "max-w-3xl",
          };
        },
      },
      {
        path: "grouped-dispatch/:siteInfo/:noOfGroupingVacutainers/:noOfScreeningVacutainers",
        name: "GroupedDispatch",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/donation/dispatch/GroupedDispatch.vue"),
        props: (x) => {
          return {
            title: `Dispatch ${x.params.siteInfo} Bags and Vacutainers`,
            verb: "POST",
            fetchUrl: "",
            noOfScreeningVacutainers: x.params.noOfScreeningVacutainers,
            noOfGroupingVacutainers: x.params.noOfGroupingVacutainers,
            noOfSingleBags: x.params.noOfSingleBags,
            noOfDoubleBags: x.params.noOfDoubleBags,
            noOfTripleBags: x.params.noOfTripleBags,
            noOfQuadrupleBags: x.params.noOfQuadrupleBags,
            discardsAtDonations: x.params.discardsAtDonations,
            postUrl: `donation/donationsections/${x.params.donationDriveGuid}/dispatch-donation-items`,
            options: [""],
            size: "max-w-3xl",
          };
        },
      },
      {
        path: "dispatch-discards/:siteInfo/:teamLead/:discard/:discardsAtDonations",
        name: "DispatchDiscards",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/donation/dispatch/DispatchBloodBags.vue"),
        props: (x) => {
          return {
            title: `Dispatch ${x.params.siteInfo} discards`,
            verb: "POST",
            fetchUrl: "",
            vacutainers: x.params.vacutainers,
            teamLead: x.params.teamLead,
            postUrl: `donation/donationsections/${x.params.donationDriveGuid}/dispatch-discards`,
            options: [""],
            size: "max-w-3xl",
          };
        },
      },
    ],
  },

  {
    path: "/donation/drives/:donationDriveGuid/:siteInfo",
    meta: { breadcrumb: { parent: "DonationSections" } },
    name: "DonationDriveDonors",
    component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/donation/Drive.vue"),
    props: (x) => {
      return {
        donationDriveGuid: x.params.donationDriveGuid,
        siteInfo: x.params.siteInfo,
        title: x.params.title,
        fetchUrl: "",
        postUrl: "",
        size: "max-w-5xl",
      };
    },
    children: [
      {
        path: "add-bag-and-vacutainers/:donationEncounterId/:donorName",
        name: "AddBagAndVacutainers",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/donation/AddBagAndVacutainers.vue"),
        props: (x) => {
          return {
            verb: "POST",
            title: `Add ${x.params.donorName}'s' blood bag`,
            donationEncounterId: x.params.donationEncounterId,
            postUrl: `donation/donationsections/${x.params.donationEncounterId}/add-bag-and-vacutainers`,
          };
        },
      },
      {
        path: "start-donation/:donationEncounterId/:donorName",
        name: "StartDonation",
        component: () =>
          import(
            /* webpackChunkName: "Transfusion" */
            "@/components/data/SchemaModal.vue"
          ),
        props: (x) => ({
          verb: "POST",
          title: `Start ${x.params.donorName}'s' donation`,
          postUrl: `donation/donationsections/${x.params.donationEncounterId}/start-donation`,
          jsonSchema: "donation/donationforms/start-donation",
          size: "max-w-xs",
        }),
      },

      {
        path: "stop-donation/:donationEncounterId/:donorName/:donationNumber",
        name: "StopDonation",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/donation/StopDonation.vue"),
        props: (x) => {
          return {
            verb: "POST",
            title: `Stop ${x.params.donorName}'s' donation`,
            postUrl: `donation/donationsections/${x.params.donationEncounterId}/stop-donation`,
          };
        },
      },
      {
        path: "discard-donation",
        name: "DiscardDonation",
        component: () =>
          import(
            /* webpackChunkName: "Transfusion" */
            "@/components/data/ConfirmPostModal.vue"
          ),
        props: (x) => ({
          verb: "POST",
          title: `Discard ${x.params.donorName}'s' donation`,
          postUrl: `donation/donationsections/${x.params.donationEncounterId}/discard-donation`,
          size: "max-w-xs",
        }),
      },
      {
        path: "defer-donor/:donationEncounterId/:donorName",
        name: "DeferDonor",
        component: () =>
          import(
            /* webpackChunkName: "Transfusion" */
            "@/components/data/SchemaModal.vue"
          ),
        props: (x) => ({
          verb: "POST",
          title: `Defer ${x.params.donorName} ?`,
          postUrl: `donation/donationsections/${x.params.donationEncounterId}/defer`,
          jsonSchema: "donation/donationforms/defer-donor",
          options: [],
          optionsUrls: [`donation/donationlookups/deferment-types/options`,`donation/donationlookups/deferment-reasons/options`],
          size: "max-w-xl",
        }),
      },
      {
        path: "view-batch/:batch_id",
        title: "View Batch",
        name: "viewBatch",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/donation/dispatch/ViewBatch.vue"),
        // eslint-disable-next-line no-unused-vars
        props: (x) => {
          return {
            title: "Create Batch",
            verb: "POST",
            bloodBags: x.params.bloodBags,
            vacutainers: x.params.vacutainers,
            size: "max-w-5xl",
          };
        },
      },
    ],
  },
  {
    path: "/donation/dispatched-items",
    name: "DispatchedItems",
    component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/donation/DispatchedItems.vue"),
    props: (x) => {
      return {
        donationDriveGuid: x.params.donationDriveGuid,
        siteInfo: x.params.siteInfo,
        title: x.params.title,
        fetchUrl: "",
        postUrl: "",
        size: "max-w-5xl",
      };
    },
  },
  {
    path: "/donation/dispatched-items/:siteInfo/:donationDriveGuid",
    meta: { breadcrumb: { parent: "DonationSections" } },
    name: "DispatchesPerDrive",
    component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/donation/DispatchedItemsPerDrive.vue"),
    props: (x) => {
      return {
        donationDriveGuid: x.params.donationDriveGuid,
        siteInfo: x.params.siteInfo,
        title: x.params.title,
        fetchUrl: "",
        postUrl: "",
        size: "max-w-5xl",
      };
    },
    children: [
      {
        path: "redirect-dispatch/:batchNumber/:batchGuid/:facilityToName/:teamLead",
        name: "RedirectDispatch",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/donation/dispatch/RedirectDispatch.vue"),
        // eslint-disable-next-line no-unused-vars
        props: (x) => {
          return {
            title: `Redirect batch ${x.params.batchNumber} `,
            teamLead: x.params.teamLead,
            facilityToName: x.params.facilityToName,
            verb: "POST",
            postUrl: `donation/donationsections/${x.params.batchGuid}/update-batch`,
            size: "max-w-5xl",
          };
        },
      },
      // RESEND BATCH
      {
        path: "resend-batch/:batchNumber",
        name: "ResendBatch",
        component: () =>
          import(
            /* webpackChunkName: "Transfusion" */
            "@/components/data/ConfirmPostModal.vue"
          ),
        props: (x) => ({
          verb: "POST",
          title: `Resend ${x.params.batchNumber}?`,
          postUrl: `donation/donationsections/${x.params.batchNumber}/resend-donation-items`,
          size: "max-w-xs",
        }),
      },
    ],
  },
  {
    path: "/donation/dispatched-items/:siteInfo/:donationDriveGuid/dispatched-bags/:batchGuid/:batchNumber/:batchProcessingTypes",
    meta: { breadcrumb: { parent: "DonationSections" } },
    name: "DispatchedBags",
    component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/donation/DispatchedBags.vue"),
    props: (x) => {
      return {
        donationDriveGuid: x.params.donationDriveGuid,
        siteInfo: x.params.siteInfo,
        title: x.params.title,
        fetchUrl: "",
        postUrl: "",
        size: "max-w-5xl",
      };
    },
  },

  {
    path: "/donation/dispatched-items/:siteInfo/:donationDriveGuid/dispatched-bags/:batchGuid/:batchNumber/:batchProcessingTypes",
    meta: { breadcrumb: { parent: "DonationSections" } },
    name: "DispatchedDiscards",
    component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/donation/DispatchedDiscards.vue"),
    props: (x) => {
      return {
        donationDriveGuid: x.params.donationDriveGuid,
        batchGuid: x.params.batchGuid,
        siteInfo: x.params.siteInfo,
        title: x.params.title,
        fetchUrl: "",
        postUrl: "",
        size: "max-w-5xl",
      };
    },
  },
  {
    path: "/donation/dispatched-items/:siteInfo/:donationDriveGuid/dispatched-vacutainers/:batchGuid/:batchNumber/:batchProcessingTypes",
    meta: { breadcrumb: { parent: "DonationSections" } },
    name: "DispatchedVacutainers",
    component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/donation/DispatchedVacutainers.vue"),
    props: (x) => {
      return {
        donationDriveGuid: x.params.donationDriveGuid,
        siteInfo: x.params.siteInfo,
        title: x.params.title,
        fetchUrl: "",
        postUrl: "",
        size: "max-w-5xl",
      };
    },
  },

  // update donation status  
  {
    path: "/lab/update-donation-item",
    name: "DonationAdmins",
    meta: { breadcrumb: { parent: "DonationSections" } },
    component: () =>
        import (
            /* webpackChunkName: "optionsgroups" */
            "@/views/donation/UpdateDonationEncounter.vue"
        ),            
    children: [
        {
            path: "update-status/:donationEncounterId/:donorName",
            name: "UpdateDonationEncounterStatus",
            component: () =>
            import (
                /* webpackChunkName: "Transfusion" */
                "@/components/data/SchemaModal.vue"
            ),
            props: (x) => ({
                verb: "POST",
                title: `Update '${x.params.donorName}' Status?`,
                postUrl: `donation/donationadmins/${x.params.donationEncounterId}/update-donation-encounter-status`,
                size: "max-w-xs",
                jsonSchema:'donation/donationforms/update-donation-status',
                optionsUrls: [`donation/donationlookups/encounter-statuses/options`],
            }),
        },
        {
            path: "update-donation-number/:donationEncounterId/:donorName/:donationNumber",
            name: "UpdateDonationNumber",
            component: () =>
            import (
                /* webpackChunkName: "Transfusion" */
                "@/components/data/SchemaModal.vue"
            ),
            props: (x) => ({
                verb: "POST",
                title: `Update '${x.params.donorName}'s number:${x.params.donationNumber}?`,
                postUrl: `donation/donationadmins/${x.params.donationEncounterId}/update-donation-number`,
                size: "max-w-xl",
                jsonSchema:'donation/donationforms/update-donation-number',
            }),
        }
    ],
  },
  // donation update

  // reports section
  {
    path:'/donation/reports',
    name:"DonationReports",
    component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/donation/reports/Reports.vue"),

  },
  {
    path:'/donation/other-reports',
    name:"OtherDonationReports",
    component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/donation/reports/OtherReports.vue"),

  },
  // end of reports section

  // FORMS
  {
    path: "/donation/donation-forms",
    name: "DonationForms",
    component: () => import("@/views/donation/Setup/Forms/List.vue"),
    children: [
      {
        path: "create",
        name: "AddDonationForm",
        component: () => import("@/components/data/JsonForm.vue"),
        props: () => ({
          toFetch: false,
          title: "Add Form ",
          postUrl: "donation/donationforms",
        }),
      },
      {
        path: "edit/:id",
        title: "Update Form",
        name: "UpdateDonationForm",
        component: () => import("@/components/data/JsonForm.vue"),
        props: (x) => ({
          title: "Update Form ",
          postUrl: `donation/donationforms/${x.params.id}`,
        }),
      },
      {
        path: "delete/:id",
        title: "Delete Form",
        name: "DeleteDonationForm",
        component: () => import("@/components/data/DeleteModal.vue"),
        props: (x) => ({
          title: "Delete Form ",
          deleteUrl: `donation/donationforms/${x.params.id}`,
          size: "max-w-xs",
          name: "Delete Form",
        }),
      },
    ],
  },

  // LOOKUPS
  {
    path: "/donation/donation_optionsgroups",
    name: "DonationLookups",
    component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/donation/Setup/Lookups/OptionGroupsList.vue"),
    children: [
      {
        path: "create",
        name: "CreateDonationOptionsGroup",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/SchemaModal.vue"),
        props: () => ({
          toFetch: false,
          title: "Add Options Group ",
          postUrl: "donation/donationlookups/0",
          optionsUrls: [],
          jsonSchema: "donation/donationforms/add-option-group",
          size: "max-w-xs",
        }),
      },
      {
        path: "edit/:id",
        title: "Edit Options Group ",
        name: "AmendDonationOptionsGroup",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/SchemaModal.vue"),
        props: (x) => ({
          title: "Add Options Group ",
          postUrl: `donation/donationlookups/${x.params.id}`,
          optionsUrls: [],
          jsonSchema: "donation/donationforms/add-option-group",
          size: "max-w-xs",
        }),
      },
    ],
  },
  {
    path: "/donation/donation_optionsgroups/:groupid/options",
    meta: { breadcrumb: { parent: "DonationLookups" } },
    name: "DonationOptions",
    props: (x) => ({
      title: x.params.title,
    }),
    component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/donation/Setup/Lookups/OptionsList.vue"),
    children: [
      {
        path: "create",
        name: "CreateDonationOption",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/SchemaModalWithExtraFields.vue"),
        props: (x) => ({
          toFetch: false,
          title: "Add Option",
          verb: "POST",
          postUrl: `donation/donationlookups/${x.params.groupid}`,
          optionsUrls: [],
          jsonSchema: "donation/donationforms/add-option",
          size: "max-w-xs",
          extraFields: { parentLookupId: x.params.groupid },
        }),
      },
      {
        path: "edit/:id",
        title: "Edit Option ",
        name: "AmendDonationOption",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/SchemaModalWithExtraFields.vue"),
        props: (x) => ({
          title: "Amend Option ",
          verb: "PUT",
          postUrl: `donation/donationlookups/${x.params.id}`,
          optionsUrls: [],
          jsonSchema: "donation/donationforms/add-option",
          size: "max-w-xs",
          extraFields: { parentLookupId: x.params.groupid },
        }),
      },
    ],
  },
  
];
