<template >
    <tr class="group">
            <td class="w-full mx-auto text-center text-gray-500 border-b-2 group-hover:bg-green-200">
                <div class=" font-semibold text-gray-600 capitalize"> {{ $removeHyphens(data.defermentReason) }}
                </div>
            </td>
            <td  class="w-full mx-auto text-center text-gray-500 border-b-2 group-hover:bg-green-50">
                <div v-for="(deferal, idx) in data.months[0].deferals" :key="idx" class="capitalize">
                    <div class="h-1/3 py-1 text-sm font-medium border hidden xl:block">
                        {{ deferal.gender }}
                    </div>
                    <div class="h-1/3 py-1 text-sm font-medium border block xl:hidden">
                        {{ Array.from(deferal.gender)[0] }}
                    </div>
                </div>
            </td>
         <td v-for="(month, idx) in data.months" v-bind:key="idx" width="25%"
            class="w-1/4 text-center hover:bg-green-100 border-b-2 ">
            <div v-if="month && month.deferals">
                <div v-if="month.deferals.length > 0">
                    <div v-for="(deferal, didx) in month.deferals" :key="didx">
                        <div class="h-1/3 border py-1 text-sm ">
                            {{ deferal.numberOfDefferedDonors }}
                        </div>
                    </div>
                </div>
                <div
                    v-else-if="month.deferals.length === 0 && selectedYear <= new Date().getFullYear() && !(selectedYear === new Date().getFullYear() && month.monthInt > new Date().getMonth() + 1)">
                    <div class="h-1/3 border text-sm">
                        -
                    </div>
                    <div class="h-1/3 border text-sm">
                        -
                    </div>
                    <div class="h-1/3 border text-sm">
                        -
                    </div>
                    <div class="h-1/3 border-none text-sm">
                        -
                    </div>
                </div>
                <span v-else class="italic text-xs lowercase">N/A</span>
            </div>
            <div class="text-center" v-else>
                <div class="h-1/3 border text-sm">-</div>
                <div class="h-1/3 border text-sm">-</div>
                <div class="h-1/3 border text-sm">-</div>
                <div class="h-1/3 border-none text-sm">-</div>

            </div>

        </td>
        <td width="25%"
            class="text-gray-600 group-hover:bg-green-100 w-1/4 items-center border-b-2 py-2 text-center font-medium">
            <div v-for="(total, idx) in data.total" v-bind:key="idx">
                <div v-if="total.gender === 'male'" class="h-1/3 border py-1 text-sm ">
                    {{ total.numberOfDefferedDonors }}
                </div>
                <div v-else-if="total.gender === 'female'" class="h-1/3 py-1 border text-sm">
                    {{ total.numberOfDefferedDonors }}
                </div>
                <div v-else-if="total.gender === 'intersex'" class="h-1/3 py-1 border text-sm">
                    {{ total.numberOfDefferedDonors }}
                </div>
                <div v-else-if="total.gender === 'unspecified'" class="h-1/3 py-1 border text-sm">
                    {{ total.numberOfDefferedDonors }}
                </div>
            </div>
        </td> 
    </tr>
</template>
<script>
import vue from 'vue'
import { Plugin } from 'vue-fragment'

vue.use(Plugin)

export default {
    props: {
        selectedMonthlyReport: String,
        data: [Array, Object],
        url: String,
    },

    data() {
        return {
            months: ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
        };
    },
}
</script>
