<template>
  <div class="h-full">
    <div class="rounded grid grid-rows-layout h-full w-full" v-show="oidcIsAuthenticated">
      <div class="">
        <div class="px-8 pt-4 grid grid-cols-12 gap-2 md:gap-4 items-center ">
          <div class="col-span-6">
            <h3 class="border-b-2 border-primary-400 py-4 space-x-2 inline-flex items-center text-primary-300  -mb-px">
              <button type="button" v-if="!!showBack" @click.prevent="back" class="text-6xl hover:bg-primary-200 transition p-2 hover:text-primary-500 rounded-full text-primary-500 bg-transparent outline-none focus:outline-none flex items-center justify-center" title="Back">
                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"></path>
                </svg>
              </button>
              <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 md:w-5 md:h-5" fill="currentColor" viewBox="0 0 16 16">
                <path
                  d="M1 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2zM1 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7zM1 12a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2z"
                />
              </svg>
              <span class="text-xs text-primary-700 md:text-base   font-normal  uppercase tracking-wider">
                {{ pageTitle ? pageTitle : listType ? (pagedResult.label || pagedResult.name) + " " + listType : pagedResult.label || pagedResult.name }}
              </span>
              <span v-if="hasList">({{pagedResult.children.totalItems}})</span>
            </h3>
          </div>
          <div class="col-span-6 flex items-center justify-end space-x-1">
            <div class="group text-gray-500 w-full md:w-1/2 transition duration-300 flex items-center border-gray-300 border focus-within:shadow-md rounded   focus-within:text-gray-900  focus-within:border-primary-500 ">
              <input @input="clear" type="search" v-model="q" placeholder="Search..." class="peer transition outline-none px-3 w-full placeholder:italic placeholder:text-gray-400 rounded-l py-1.5  border-none focus:ring-0 " @keyup.enter="search" />
              <button @click="search" class="transition p-3 focus:outline-none peer-focus:bg-primary-500 peer-focus:text-white ">
                <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                </svg>
              </button>
            </div>

            <router-link v-if="!!showCreate" :to="{ name: createName }" class="transition border border-primary-500/50 px-2 py-2.5  rounded flex items-center text-sm text-primary-500 focus:outline-none space-x-1 hover:bg-primary-500 hover:text-white" :title="createTitle">
              <svg class="w-4 h-4 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.75 3a.75.75 0 0 1 .743.648l.007.102.001 7.25h7.253a.75.75 0 0 1 .102 1.493l-.102.007h-7.253l.002 7.25a.75.75 0 0 1-1.493.101l-.007-.102-.002-7.249H3.752a.75.75 0 0 1-.102-1.493L3.752 11h7.25L11 3.75a.75.75 0 0 1 .75-.75Z" />
              </svg>
              <span class="hidden md:block truncate">{{ createTitle }}</span>
            </router-link>
            <slot name="more-actions"></slot>
          </div>
        </div>
        <div class="flex justify-between text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b rounded-tl-md rounded-tr-md  dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800 px-1"></div>
      </div>
      <div>
        <div v-if="loading" class="grid h-full place-items-center">
          <loading-indicator />
        </div>
        <div v-if="!isEmpty(pagedResult)" class="">
          <div class=" border-b  border-gray-100 divide-y divide-gray-100  ">
            <div v-for="(item, index) in pagedResult.children.results" :key="item.id" class="px-8 py-4 transition-all hover:bg-gray-50 ">
              <slot v-bind:index="index" v-bind:item="item"></slot>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="px-8  py-4 bg-gray-100/60 flex ">
          <nav v-if="!isEmpty(pagedResult)" class="mx-auto">
            <div class="flex items-center">
              <span v-if="pagedResult.children.currentPage === 1" class="w-full p-4 border text-base rounded-l-xl text-gray-600 bg-gray-50/60 ">
                <svg width="9" height="8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 19l-7-7 7-7m8 14l-7-7 7-7"></path></svg>
              </span>
              <router-link v-else :to="{ query: { page: 1 } }" class="w-full p-4 border text-base rounded-l-xl text-gray-600 bg-white hover:bg-gray-100">
                <svg width="9" height="8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 19l-7-7 7-7m8 14l-7-7 7-7"></path></svg>
              </router-link>
              <span v-if="pagedResult.children.currentPage === 1" class="w-full p-4 border-y border-r text-base  text-gray-600 bg-gray-50/60">
                <svg width="9" height="8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>
              </span>
              <router-link v-else :to="{ query: { page: pagedResult.children.currentPage - 1 } }" class="w-full p-4 border-y border-r text-base text-gray-600 bg-white hover:bg-gray-100">
                <svg width="9" height="8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>
              </router-link>
              <template v-for="page in pagedResult.children.pages">
                <span v-if="page === pagedResult.children.currentPage" :key="page" class="w-full px-4 py-2 odd:border-y even:border  text-base text-white bg-primary-400 border-primary-400 ">
                  {{ page }}
                </span>
                <router-link :to="{ query: { page: page } }" v-else :key="page" class="w-full px-4 py-2 even:border odd:border-y text-base text-gray-600 bg-white hover:bg-gray-100 hover:text-primary-400 ">
                  {{ page }}
                </router-link>
              </template>

              <span v-if="pagedResult.children.currentPage === pagedResult.children.totalPages" class="w-full p-4 border-y border-r text-base  text-gray-600 bg-gray-50/60">
                <svg width="9" height="8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
              </span>
              <router-link v-else :to="{ query: { page: pagedResult.children.currentPage + 1 } }" class="w-full p-4 border-y border-r text-base text-gray-600 bg-white hover:bg-gray-100">
                <svg width="9" height="8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
              </router-link>
              <span v-if="pagedResult.children.currentPage === pagedResult.children.totalPages" class="w-full p-4 border-y border-r text-base rounded-r-xl text-gray-600 bg-gray-50/60 ">
                <svg width="9" height="8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5l7 7-7 7M5 5l7 7-7 7"></path></svg>
              </span>
              <router-link v-else :to="{ query: { page: pagedResult.children.totalPages } }" class="w-full p-4 border-y border-r text-base rounded-r-xl text-gray-600 bg-white hover:bg-gray-100 hover:text-primary-400">
                <svg width="9" height="8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5l7 7-7 7M5 5l7 7-7 7"></path></svg>
              </router-link>
            </div>
          </nav>
        </div>
      </div>
      <router-view> </router-view>
    </div>
    <lock v-show="!oidcIsAuthenticated" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Lock from "@/components/layout/Lock";
export default {
  name: "child-list",
  components: { Lock },
  props: {
    pageTitle: String,
    listType: String,
    url: String,
    createParams: {
      type: Object,
      default: function() {
        return {};
      },
    },
    showCreate: { type: Boolean, default: true },
    showBack: { type: Boolean, default: false },
    createName: { type: String, default: "Create" },
    createTitle: { type: String, default: "New" },
    backRouterName: String,
    backSteps: { type: Number, default: 0 },
  },
  computed: {
    ...mapGetters(["oidcIsAuthenticated"]),
    hasList() {
      return this.pagedResult?.children?.results?.length > 0;
    },
  },
  data() {
    return {
      loading: false,
      pagedResult: {},
      error: {},
      q: this.$route.query.q,
    };
  },
  watch: {
    $route: {
      immediate: true,
      async handler() {
        if (this.$route.matched.length > 1) {
          if (this.pagedResult.children) {
            if (Object.keys(this.pagedResult.children).length === 0 && this.pagedResult.children.constructor === Object) {
              this.fetch();
            }
          } else if (Object.keys(this.pagedResult).length === 0 && this.pagedResult.constructor === Object) {
            this.fetch();
          }
        } else if (this.$route.matched.length === 1) {
          this.fetch();
        } else {
          console.log("Fetch failed");
        }
      },
    },
  },
  methods: {
    clear(e) {
      if (e.target.value.trim().length === 0) {
        this.q = "";
      }
    },
    fetch() {
      this.loading = true;
      this.pagedResult = {};
      this.$callApi
        .get(this.url, { params: { ...this.$route.query } })
        .then((x) => {
          this.pagedResult = x.data;
        })
        .catch(() => {
          //this.error = err;
          //console.log(err);
        })
        .then(() => (this.loading = false));
    },
    search() {
      if (this.q.length >= 3) {
        this.$router.push({ query: { q: this.q } });
      } else {
        if (this.$route.query.q !== undefined) {
          this.q = "";
          this.$router.push({ query: {} });
        }
      }
    },
    navigate(queryString) {
      this.$router.push({ path: this.$route.path, query: { ...queryString } });
    },
    isEmpty(obj) {
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    },
    back() {
      if (this.backRouterName) {
        this.$router.push({ name: this.backRouterName });
      } else {
        if (this.backSteps !== 0) {
          this.$router.go(this.backSteps - this.backSteps * 2);
        } else {
          this.$router.back();
        }
      }
    },
  },
};
</script>
