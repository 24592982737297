<template>
  <div class="relative" v-on-clickaway="closeMenu">
    <button @click="isMenuOpen = !isMenuOpen" class="text-white flex items-center focus:outline-none hover:text-white/70 transition space-x-1" title="Profile Settings">
      <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 " viewBox="0 0 24 24" fill="currentColor">
        <path
          d="M12,2C6.579,2,2,6.579,2,12s4.579,10,10,10s10-4.579,10-10S17.421,2,12,2z M12,7c1.727,0,3,1.272,3,3s-1.273,3-3,3 c-1.726,0-3-1.272-3-3S10.274,7,12,7z M6.894,16.772c0.897-1.32,2.393-2.2,4.106-2.2h2c1.714,0,3.209,0.88,4.106,2.2 C15.828,18.14,14.015,19,12,19S8.172,18.14,6.894,16.772z"
        ></path>
      </svg>
      <!--<svg class="w-6 h-6 md:w-10 md:h-10" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>-->
      <span class="hidden md:block text-emera;d-500 text-sm font-medium">{{ oidcUser.full_name }}</span>
      <svg class="w-3 h-3 md:w-4 md:h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
      </svg>
    </button>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <div v-if="isMenuOpen" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg text-sm overflow-hidden border z-20">
        <div class="rounded-md bg-white shadow-xs" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
          <div class="bg-gray-100 p-4 flex items-center">
            <div class="w-full flex space-x-2 items-center">
              <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 " viewBox="0 0 24 24">
                <path
                  d="M12,2C6.579,2,2,6.579,2,12s4.579,10,10,10s10-4.579,10-10S17.421,2,12,2z M12,7c1.727,0,3,1.272,3,3s-1.273,3-3,3 c-1.726,0-3-1.272-3-3S10.274,7,12,7z M6.894,16.772c0.897-1.32,2.393-2.2,4.106-2.2h2c1.714,0,3.209,0.88,4.106,2.2 C15.828,18.14,14.015,19,12,19S8.172,18.14,6.894,16.772z"
                ></path>
              </svg>
              <div>
                <p class="font-semibold text-base">{{ oidcUser.full_name }}</p>
                <button class="flex items-center justify-between w-full focus:outline-none">
                  <p class="text-gray-600">{{ oidcUser.email }}</p>
                </button>
              </div>
            </div>
          </div>
          <div class="border-t border-gray-100"></div>
          <div class="py-1">
            <router-link to="/" class="p-4 flex items-center space-x-2">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 stroke-current text-gray-800" viewBox="0 0 24 24" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M10 6h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                <circle cx="17" cy="7" r="3" />
              </svg>
              <span> Notifications </span>
            </router-link>
            <router-link to="/" class="p-4 flex items-center space-x-2">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 stroke-current text-gray-800" viewBox="0 0 24 24" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M9 4.55a8 8 0 0 1 6 14.9m0 -4.45v5h5" />
                <line x1="5.63" y1="7.16" x2="5.63" y2="7.17" />
                <line x1="4.06" y1="11" x2="4.06" y2="11.01" />
                <line x1="4.63" y1="15.1" x2="4.63" y2="15.11" />
                <line x1="7.16" y1="18.37" x2="7.16" y2="18.38" />
                <line x1="11" y1="19.94" x2="11" y2="19.95" />
              </svg>
              <span> Change password </span>
            </router-link>
          </div>
          <div class="border-t border-gray-100"></div>
          <div class="py-1">
            <a href="#" @click.prevent="signOut" class="p-4 flex items-center space-x-2">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 stroke-current text-gray-700" viewBox="0 0 24 24" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
                <path d="M7 12h14l-3 -3m0 6l3 -3" />
              </svg>
              <span> Logout </span>
            </a>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { mixin as clickaway } from "vue-clickaway";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "user-account",
  data: () => ({
    isMenuOpen: false,
  }),
  computed: {
    ...mapGetters(["oidcUser", "oidcIdTokenExp"]),
  },
  created() {
    console.log(this.oidcUser);
  },
  mixins: [clickaway],
  methods: {
    ...mapActions(["signOutOidc"]),
    closeMenu() {
      this.isMenuOpen = false;
    },
    signOut() {
      // this.removeOidcUser().then(() => {
      //   this.$router.push('/')
      // })
      this.signOutOidc();
    },
  },
};
</script>
