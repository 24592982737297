<template>
  <div :class="context.classes.element" :data-type="context.type" class="flex">
    <input :value="local.day" type="text" placeholder="DD" class="rounded-0" v-bind="attributes" @change="update('day', $event.target.value)" @blur="context.blurHandler" v-on="$listeners" />
    <select aria-label="Month" class="border border-gray-300 rounded-0 px-3 py-2 leading-none focus:shadow-sm focus:border-blue-400 transition duration-300 focus:ring-0 outline-none border-box w-full mb-1" :value="local.month" @change="update('month', $event.target.value)" v-on="$listeners" @blur="context.blurHandler">
      <option value="" selected>Choose...</option>
      <option v-for="month in months" :key="month.value" :value="month.value" v-text="month.label" />
    </select>
    <input :value="local.year" type="text" placeholder="YYYY" v-bind="attributes" @change="update('year', $event.target.value)" @blur="context.blurHandler" v-on="$listeners" />
  </div>
</template>

<script>
import FormulateInputMixin from "@braid/vue-formulate/src/FormulateInputMixin";
export default {
  name: "FormulateInputTextSelect",
  mixins: [FormulateInputMixin],
  data() {
    return {
      months: [
        { label: "Jan", value: "01" },
        { label: "Feb", value: "02" },
        { label: "Mar", value: "03" },
        { label: "Apr", value: "04" },
        { label: "May", value: "05" },
        { label: "Jun", value: "06" },
        { label: "Jul", value: "07" },
        { label: "Aug", value: "08" },
        { label: "Sep", value: "09" },
        { label: "Oct", value: "10" },
        { label: "Nov", value: "11" },
        { label: "Dec", value: "12" },
      ],
      day: "",
      month: "",
      year: "",
    };
  },
  computed: {
    local() {
      return { day: this.day, month: this.month, year: this.year };
    },
  },
  methods: {
    update(key, value) {
      if (key === "day") {
        this.day = value;
      }
      if (key === "month") {
        this.month = value;
      }
      if (key === "year") {
        this.year = value;
      }
      this.context.model = `${this.local.year}-${this.local.month}-${this.local.day}`;
    },
  },
};
</script>
