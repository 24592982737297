import Vue from "vue";
import VueRouter from "vue-router";
import donors from "@/router/routes/donors";
import setup from "./routes/setup";
import donorCounselling from "@/router/routes/donorCounselling";
import home from "@/router/routes/home";
import inventory from "@/router/routes/inventory";
import bloodBank from "@/router/routes/bloodBank";
import transfusion from "@/router/routes/transfusion";
import { vuexOidcCreateRouterMiddleware } from "vuex-oidc";
import store from "@/store";
import recruitment from "@/router/routes/recruitment";
import lab from "@/router/routes/lab";
import component_separation from "@/router/routes/component_separation";
import donation from "./routes/donation";
import admin from "@/router/routes/admin";
import reports from "@/router/routes/reports";

Vue.use(VueRouter);
const routes = [
    ...transfusion,
    ...bloodBank,
    ...inventory,
    ...donors,
    ...donation,
    ...setup,
    ...donorCounselling,
    ...home,
    ...recruitment,
    ...lab,
    ...component_separation,
    ...admin,
    ...reports
];
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(() => {});
};
const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});
router.beforeEach(vuexOidcCreateRouterMiddleware(store));
export default router;