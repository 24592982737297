<template>
  <t-datepicker :date-parser="dateParser" :class="[stateClass, context.classes.element]" :data-type="context.type" @blur="context.blurHandler" v-on="$listeners" v-bind="context.attributes" v-model="context.model">
    <template slot="day" slot-scope="{ day, dayFormatted }">
      <span v-if="day.getDay() === 0" class="text-red-500">
        {{ dayFormatted }}
      </span>
      <span v-else>
        {{ dayFormatted }}
      </span>
    </template>
  </t-datepicker>
</template>
<script>
//import TDatepicker from "vue-tailwind/dist/t-datepicker"
import dayjs from "dayjs";
export default {
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      maxDate: dayjs().format("YYYY-MM-DD"),
    };
  },
  methods:{
    dateParser(date){
      return new Date(date);
    }
  },
  computed: {
    stateClass() {
      if (!this.context.isValid && this.context.showValidationErrors) {
        return "text-red-500/90";
      }
      if (this.context.rules.length > 0 && this.context.isValid && this.context.hasValue) {
        return "text-green-500/90";
      }
      return "text-gray-500";
    },
  },
};
</script>
