<template>
    <div class="h-full w-full">
        <div v-if="isDownloading" class="rounded-y flex items-center justify-center bg-orange-100 py-2 text-sm italic text-orange-700">
            <div class="animate-pulse">The requested report is being processed, we'll download it once completed...</div>
        </div>
        <div class="h-full w-full rounded md:grid md:grid-rows-layout" v-show="oidcIsAuthenticated">
            <div class="px-2 pb-1">
                <div class="my-2 mx-3 items-center gap-2 md:grid md:grid-cols-12 md:gap-4">
                    <div class="col-span-4">
                        <h3 class="-mb-px inline-flex items-center space-x-2 border-b-2 border-primary-300 py-4 text-primary-400">
                            <button type="button" v-if="!!showBack" @click.prevent="back" class="flex items-center justify-center rounded-full bg-transparent p-2 text-6xl text-primary-500 outline-none transition hover:bg-primary-200 hover:text-primary-600 focus:outline-none" title="Back">
                                <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"></path>
                                </svg>
                            </button>
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 md:h-5 md:w-5" fill="currentColor" viewBox="0 0 16 16">
                                <path
                                    d="M1 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2zM1 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7zM1 12a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2z"
                                />
                            </svg>
                            <span class="text-xs font-normal uppercase tracking-wider text-primary-700 md:text-base">
                                <!-- {{
                  this.pagedResult.totalItems ? this.pagedResult.totalItems : ""
                }} -->
                                {{ $removeHyphens(selectedReport) }}</span
                            >
                        </h3>
                    </div>
                    <div class="col-span-8 flex items-center justify-end space-x-4">
                        <div class="flex items-center rounded-md bg-gray-50 px-6 py-2 text-sm font-medium tracking-wide text-green-800 transition hover:bg-gray-200 hover:text-green-900 focus:outline-none">
                            <div class="mr-3">Daily Report</div>

                            <t-toggle v-model="isMonthly" @input="isMonthlySelected" />

                            <div class="ml-3">Monthly Report</div>

                            <!-- <span class="mr-2">
                <svg
                  v-if="filters"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-6 w-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>

                <svg
                  v-else
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-6 w-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
                  />
                </svg>
              </span>
              {{ filters ? "Close Filters" : "View Monthly" }} -->
                        </div>

                        <button
                            :class="[
                                isMonthly ? (fetchedCSVData && fetchedCSVData.length > 0 ? 'flex' : 'hidden') : 'flex',
                                isDownloading ? 'cursor-not-allowed bg-gray-600 text-gray-50 hover:border-gray-600' : 'bg-green-600 text-green-50 transition hover:border-green-600 hover:bg-green-700 hover:text-white hover:shadow-md ',
                                'items-center rounded-md px-6 py-2 text-sm font-medium tracking-wide shadow-sm focus:outline-none',
                            ]"
                            @click="isMonthly ? $printCSVData(fetchedCSVData, selectedReport, isMonthly ? selectedYear : $formatDate(startDate, false, true) + '-' + $formatDate(endDate, false, true), selectedRegion, selectedCounty) : downloadLumpsumReport()"
                        >
                            <span class="mr-2">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                                </svg>
                            </span>
                            Download Report
                        </button>
                    </div>
                </div>

                <div>
                    <div class="my-3 border-y hover:bg-gray-50">
                        <div class="ml-2 gap-4 sm:grid sm:grid-cols-4">
                            <div v-if="!isMonthly">
                                <FormulateInput
                                    type="suggest"
                                    @input="selectPeriod"
                                    v-model="selectedPeriod"
                                    placeholder="-- Select Period --"
                                    label="Please specify the period"
                                    class="mt-2 font-semibold text-gray-600"
                                    :disabled="loading ? true : false"
                                    validation="required"
                                    :options="[
                                        { value: 'today', label: 'Today', selected: true },
                                        { value: $calcStartDate(7, 'days'), label: 'Last 7 Days' },
                                        {
                                            value: $calcStartDate(3, 'months'),
                                            label: 'Last 3 months',
                                        },
                                        {
                                            value: $calcStartDate(6, 'months'),
                                            label: 'Last 6 months',
                                        },
                                        { value: 'custom', label: 'Custom Range' },
                                    ]"
                                />
                            </div>

                            <div v-if="isMonthly">
                                <FormulateInput
                                    type="number"
                                    label="Specify Year:"
                                    name="selectedYear"
                                    :value="new Date().getUTCFullYear()"
                                    v-model="selectedYear"
                                    @change="changeYear"
                                    class="mt-2"
                                    :validation-rules="{
                                        noLetter: ({ value }) => !/[a-zA-Z]/g.test(value),
                                    }"
                                    :validation-messages="{ noLetter: `Letters are not allowed` }"
                                    validation="noLetter"
                                    :show-optional="false"
                                    error-behavior="value"
                                />
                            </div>

                            <div v-if="!isMonthly" class="col-span-2">
                                <FormulateInput
                                    type="suggest"
                                    @input="selectReport"
                                    v-model="selectedReport"
                                    placeholder="-- Select report type --"
                                    label="Please specify report type"
                                    class="mt-2 font-semibold text-gray-600"
                                    :disabled="loading ? true : false"
                                    validation="required"
                                    :options="[
                                        { value: 'donors-report', label: 'Donors Report' },
                                        { value: 'donation-by-type', label: 'Donation By Type' },
                                        { value: 'donation-by-frequency', label: 'Donation By Frequency' },
                                        { value: 'donation-by-location', label: 'Donation By Location' },
                                        { value: 'static-outreach-donations', label: 'Static Outreach Donations' },
                                        { value: 'donor-deferal', label: 'Donor Deferrals' },
                                    ]"
                                />
                            </div>

                            <div v-else class="col-span-2">
                                <FormulateInput
                                    type="suggest"
                                    @input="selectReport"
                                    v-model="selectedReport"
                                    placeholder="-- Select report type --"
                                    label="Please specify report type"
                                    class="mt-2 font-semibold text-gray-600"
                                    :disabled="!selectedYear || loading ? true : false"
                                    validation="required"
                                    :options="[
                                        {
                                            value: 'monthly-Donor-Deferrals',
                                            label: 'Monthly donor deferrals',
                                        },
                                        {
                                            value: 'monthly-units-discarded-report',
                                            label: 'Discarded Units per Month',
                                        },
                                        {
                                            value: 'monthly-Site-Donation-Report',
                                            label: 'Monthly site donation',
                                        },
                                        {
                                            value: 'monthly-donations-by-type-report',
                                            label: 'Monthly Donations by Type',
                                            selected: true,
                                        },
                                        {
                                            value: 'monthly-donations-by-age-report',
                                            label: 'Monthly Donations by Age',
                                        },
                                        {
                                            value: 'monthly-donations-by-county',
                                            label: 'Monthly donations by county',
                                        },
                                        {
                                            value: 'monthly-Donations-By-Frequency',
                                            label: 'Monthly Donations by Frequency',
                                        },
                                    ]"
                                />
                            </div>

                            <div class="mr-3 flex items-center justify-end">
                                <button
                                    class="flex items-center rounded-md px-6 py-2 text-sm font-medium tracking-wide transition focus:outline-none"
                                    @click="showFilters"
                                    :class="[filters ? 'bg-red-100 text-red-500 hover:bg-red-200 hover:text-red-900 ' : 'bg-green-100 text-green-800 hover:bg-green-200 hover:text-green-900 ']"
                                >
                                    <span class="mr-2">
                                        <svg v-if="filters" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>

                                        <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6">
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
                                            />
                                        </svg>
                                    </span>
                                    {{ filters ? "Close Filters" : "Filter Report" }}
                                </button>
                            </div>

                            <div v-if="selectedPeriod === 'custom'">
                                <FormulateInput name="startDate" label="Start Date:" type="date" v-model="startDate" @change="formatInput" :max="endDate" :show-optional="false" error-behavior="value" />
                            </div>

                            <div v-if="selectedPeriod === 'custom'">
                                <FormulateInput name="endDate" label="End Date:" type="date" v-model="endDate" @change="formatInput" :max="$calcToday()" :show-optional="false" error-behavior="value" />
                            </div>
                        </div>

                        <div v-if="filters" class="mx-2 gap-2 xl:grid xl:grid-cols-4">
                            <div>
                                <FormulateInput type="suggest" name="regions" placeholder="Type to select" label="Filter By Regions:" class="mt-2" v-model="selectedRegion" @input="fetchCounties" :options="options[0]" :disabled="loading && true" :show-optional="false" />
                            </div>

                            <div>
                                <FormulateInput type="suggest" label="Filter By County:" name="county" @input="selectCountyFilter" placeholder="Type to select" v-model="selectedCounty" :options="counties" class="mt-2" :disabled="loading && true" :show-optional="false" />
                            </div>

                            <div class="col-span-2">
                                <FormulateInput
                                    type="suggest"
                                    label="Filter By Facility:"
                                    name="facility"
                                    @input="selectFacilityFilter"
                                    placeholder="Type to select"
                                    v-model="selectedFacilityCode"
                                    :options="facilities"
                                    :disabled="selectedReport === 'monthly-donations-by-county' || loading ? true : false"
                                    class="mt-2"
                                    :show-optional="false"
                                />
                            </div>
                        </div>
                        <router-link v-if="!!showCustomAction" :to="{ name: customActionName }" class="flex items-center space-x-1 rounded bg-primary-50 px-2 py-2 text-sm text-primary-500 hover:bg-primary-400 hover:text-white focus:outline-none" :title="createTitle">
                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="h-4 w-4" viewBox="0 0 384 512">
                                <defs />
                                <path
                                    fill="currentColor"
                                    d="M377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-153 31V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm64 160v48c0 4.4-3.6 8-8 8h-56v56c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8v-56h-56c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h56v-56c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v56h56c4.4 0 8 3.6 8 8z"
                                />
                            </svg>
                            <span class="hidden md:block">{{ customActionTitle }}</span>
                        </router-link>
                        <router-link v-if="!!showCreate" :to="{ name: createName }" class="flex items-center space-x-1 rounded bg-primary-50 px-2 py-2 text-sm text-primary-500 hover:bg-primary-400 hover:text-white focus:outline-none" :title="createTitle">
                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="h-4 w-4" viewBox="0 0 384 512">
                                <defs />
                                <path
                                    fill="currentColor"
                                    d="M377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-153 31V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm64 160v48c0 4.4-3.6 8-8 8h-56v56c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8v-56h-56c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h56v-56c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v56h56c4.4 0 8 3.6 8 8z"
                                />
                            </svg>
                            <span class="hidden md:block">{{ createTitle }}</span>
                        </router-link>
                    </div>
                </div>

                <div class="flex justify-between rounded-tl-md rounded-tr-md bg-gray-50 px-1 text-left text-xs font-semibold uppercase tracking-wide text-gray-500">
                    <div :tableHeadingTitles="tableHeadingTitles" v-for="(tableTitle, index) in tableHeadingTitles" :key="index">
                        <div v-html="tableTitle" class="px-4 py-3">
                            {{ tableTitle }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="mx-2 h-full border-b border-gray-100">
                <div class="w-full">
                    <div class="w-full min-w-full divide-y divide-gray-100 border-b border-gray-100">
                        <report-shimmer v-if="loading"></report-shimmer>

                        <report-view v-else :fetchedData="fetchedData" :selectedReport="selectedReport" :url="url"> </report-view>

                        <!-- <no-report v-if="!loading && fetchedData.results.length === 0"></no-report> -->
                    </div>
                </div>
            </div>
        </div>
        <lock v-show="!oidcIsAuthenticated" />
    </div>
</template>
<script>
    import { mapGetters } from "vuex";
    import Lock from "@/components/layout/Lock";
    import "../../../assets/reports/report-table-min.css";
    import { registerLicense } from "@syncfusion/ej2-base";
    registerLicense("ORg4AjUWIQA/Gnt2VVhjQlFac1hJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRd0RiUX9ZcXdRT2FdWUY=");

    export default {
        async created() {
            await this.fetchOptions();
            this.fetchFacilityOptions();
        },
        props: {
            title: String,
            // url: String,
            showCreate: { type: Boolean, default: true },
            showBack: { type: Boolean, default: false },
            createName: { type: String, default: "Create" },
            createTitle: { type: String, default: "New" },
            showCustomAction: { type: Boolean, default: false },
            customActionParams: {
                type: Array,
                default() {
                    return [];
                },
            },
            customActionTitle: { type: String, default: "Action" },
            customActionName: { type: String, default: "Action" },
            tableHeadingTitles: {
                Array,
                default: function () {
                    return [];
                },
            },
        },
        components: { Lock },
        computed: {
            ...mapGetters(["oidcIsAuthenticated", "oidcUser", "getForm"]),
            hasList() {
                return this.fetchedData?.length > 0 || this.pagedResult?.results?.length > 0;
            },

            downloadUrl() {
                return `/donation/daily-donation-reports/${this.selectedReport}?start=${new Date(this.startDate).toISOString()}&end=${new Date(this.endDate).toISOString()}&region=${this.formattedRegion}&county=${this.formattedCounty}&facilityCode=${this.selectedFacilityCode}&download=${
                    this.isDownloading
                }`;
            },

            url() {
                if (this.selectedPeriod === "monthly") {
                    return `donation/donation-reports/${this.selectedYear}/${this.selectedReport}?facilityCode=${this.selectedFacilityCode}&region=${this.formattedRegion}&county=${this.formattedCounty}`;
                } else {
                    return `/donation/daily-donation-reports/${this.selectedReport}?start=${new Date(this.startDate).toISOString()}&end=${new Date(this.endDate).toISOString()}&region=${this.formattedRegion}&county=${this.formattedCounty}&facilityCode=${this.selectedFacilityCode}`;
                }
            },

            form: {
                get() {
                    return this.getForm;
                },
                set(newData) {
                    this.saveStoreForm(newData);
                },
            },

            sortedData: function () {
                let data = this.pagedResult.results;
                return data.sort((a, b) => {
                    let modifier = 1;
                    if (this.currentSortDir === "desc") modifier = -1;
                    if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                    if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                    return 0;
                });
            },
        },
        data() {
            return {
                loading: true,
                pagedResult: [],
                fetchedData: [],
                fetchedCSVData: [],
                selectedYear: new Date().getUTCFullYear(),
                selectedPeriod: "today",
                isMonthly: false,
                selectedReport: "donors-report",
                startDate: this.$calcToday(),
                endDate: this.$calcToday(),
                selectedRegion: "",
                selectedCounty: "",
                formattedRegion: "",
                formattedCounty: "",
                selectedFacilityCode: "",
                error: {},
                q: this.$route.query.q,
                months: ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"],
                fileName: [],
                options: [],
                optionsUrls: ["donation/DonationLookups/regions/options"],
                counties: [],
                facilities: [],
                currentSort: "0",
                currentSortDir: "desc",
                filters: false,
                wrapSettings: { wrapMode: "Content" },
                isDownloading: false,
                myColumn: (month) => {
                    var data = [];
                    for (var i = 0; i < 4; i++) {
                        var items = [];
                        var gender = { 0: "M", 1: "F", 2: "I", 3: "U" };
                        items.field = "months." + month + ".deferals." + i + ".numberOfDeffreDonors";
                        items.headerText = gender[i];
                        items.width = 50;
                        items.textAlign = "center";
                        items.minWidth = 10;
                        data.push(items);
                    }
                    return data;
                },
            };
        },

        methods: {
            removeHyphen: function (field, data) {
                return this.$removeHyphens(data[field]);
            },
            printResults() {
                this.$htmlToPaper("report");
            },
            isMonthlySelected() {
                this.selectedPeriod = this.isMonthly ? "monthly" : "today";
                this.selectedReport = this.isMonthly ? "monthly-Donor-Deferrals" : "donors-report";
            },
            selectPeriod() {
                if (this.selectedPeriod !== "custom") {
                    this.startDate = this.selectedPeriod === "today" ? this.$calcToday() : this.selectedPeriod !== "monthly" ? this.selectedPeriod : this.$calcToday();
                    this.endDate = this.$calcToday();
                    this.fetch();
                    return false;
                } else {
                    return true;
                }
            },
            selectReport() {
                this.clearAllFilters();
                this.fetch();
            },
            showFilters() {
                if (this.filters === false) {
                    this.filters = true;
                } else {
                    this.filters = false;
                    this.clearAllFilters();
                    this.fetch();
                }
            },

            fetchOptions() {
                window.Promise.all([...this.optionsUrls.map((x) => this.$callApi.get(x))])
                    .then((x) => {
                        this.options = [...x.map((y) => y.data)];
                    })
                    .catch((err) => console.log(err));
            },
            fetchFacilityOptions() {
                this.$callApi.get(`donation/Facilities/facility-options?region=${this.formattedRegion}&county=${this.formattedCounty}`).then((x) => {
                    this.facilities = x.data;
                });
            },
            selectFacilityFilter() {
                if (this.selectedFacilityCode === null) {
                    this.selectedFacilityCode = "";
                }
                this.fetch();
            },
            selectCountyFilter(e) {
                if (e) {
                    this.facilities = [];
                    this.selectedFacilityCode = "";
                    this.$callApi.get(`donation/DonationLookups/value/${e}`).then((x) => {
                        this.formattedCounty = x.data.label;
                        this.fetchFacilityOptions();
                        this.fetch();
                    });
                }
                if (this.selectedCounty === null) {
                    this.selectedCounty = "";
                    this.formattedCounty = "";
                    this.facilities = [];
                    this.selectedFacilityCode = "";
                    this.fetchFacilityOptions();
                    this.fetch();
                }
            },
            fetchCounties(e) {
                if (e) {
                    this.clearFilters();
                    this.$callApi.get(`donation/DonationLookups/${e}/options`).then((x) => {
                        this.counties = x.data;
                        this.fetchRegionName(e);
                    });
                }
                if (this.selectedRegion === null) {
                    this.selectedRegion = "";
                    this.formattedRegion = "";
                    this.clearFilters();
                    this.fetch();
                }
            },
            fetchRegionName(code) {
                this.$callApi.get(`donation/DonationLookups/value/${code}`).then((x) => {
                    this.formattedRegion = x.data.label;
                    this.fetchFacilityOptions();
                    this.fetch();
                });
            },
            clearFilters() {
                this.selectedCounty = "";
                this.formattedCounty = "";
                this.facilities = [];
                this.counties = [];
                this.selectedFacilityCode = "";
                this.fetchFacilityOptions();
            },
            clearAllFilters() {
                (this.formattedRegion = ""), (this.selectedRegion = ""), (this.selectedCounty = "");
                this.formattedCounty = "";
                this.facilities = [];
                this.counties = [];
                this.selectedFacilityCode = "";
                this.fetchFacilityOptions();
            },

            // fetchWards(e) {
            //   if (e) {
            //     this.$callApi
            //       .get(`registration/lookups/label/${e}/options`)
            //       .then((x) => {
            //         this.wards = x.data;
            //         if (
            //           this.wards.some((x) => x.value === this.form.residence[0].ward)
            //         ) {
            //           vue.set(
            //             this.form.residence[0],
            //             "ward",
            //             this.form.residence[0].ward
            //           );
            //         } else {
            //           vue.set(this.form.residence[0], "ward", "");
            //         }
            //       });
            //   }
            // },
            changeYear() {
                this.selectedYear = this.selectedYear.replace(/ /g, "");
                if (this.selectedReport) this.fetch();
                if (this.selectedCounty) this.fetch();
            },

            formatInput() {
                this.selectedPeriod = "custom";
                if (this.startDate > this.endDate) {
                    this.startDate = this.endDate;
                }
                this.fetch();
            },

            fetch() {
                this.loading = true;
                this.fetchedData = [];
                this.pagedResult = [];
                this.fetchedCSVData = [];
                this.$callApi
                    .get(this.url, {
                        params: { ...this.$route.query },
                    })
                    .then((x) => {
                        this.fetchedCSVData = x.data?.csv || x.data?.csvData;
                        this.fetchedData = x.data.data;
                        if (x.data.data.currentPage) this.pagedResult = x.data.data;
                    })
                    .catch(() => {
                        //this.error = err;
                    })
                    .then(() => (this.loading = false));
            },

            downloadLumpsumReport() {
                this.isDownloading = true;
                this.lumpsumCSVData = [];
                this.$callApi
                    .get(this.downloadUrl, {
                        params: { ...this.$route.query },
                    })
                    .then((x) => {
                        this.lumpsumCSVData = x.data.csv || x.data.csvData;
                        this.$printCSVData(this.lumpsumCSVData, this.selectedReport, this.$formatDate(this.startDate, false) + " - " + this.$formatDate(this.endDate, false), this.selectedRegion, this.selectedCounty);
                    })
                    .catch(() => {
                        //this.error = err;
                    })
                    .then(() => (this.isDownloading = false));
            },

            search() {
                if (this.q.length >= 3) {
                    this.$router.push({ query: { q: this.q } });
                } else {
                    if (this.$route.query.q !== undefined) {
                        this.q = "";
                        this.$router.push({ query: {} });
                    }
                }
            },
            clear(e) {
                if (e.target.value.trim().length === 0) {
                    this.q = "";
                }
            },
            navigate(queryString) {
                this.$router.push({ path: this.$route.path, query: { ...queryString } });
                this.fetch();
            },
            isEmpty(obj) {
                return Object.keys(obj).length === 0 && obj.constructor === Object;
            },
            back() {
                this.$router.back();
            },
            sortData(s) {
                if (s === this.currentSort) {
                    this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
                }
                this.currentSort = s;
            },
        },

        mounted() {
            this.fetch();
        },
    };
</script>
