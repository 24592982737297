export default [
    // deliveries
    {
        path: "/lab/deliveries",
        name: "SampleBatches",
        component: () =>
            import (
                /* webpackChunkName: "optionsgroups" */
                "@/views/lab/Deliveries.vue"
            ),            
        children: [
            {
                path: "undo-reject/:batchGuid",
                name: "UndoRejectBatch",               
                component: () =>
                import (
                    /* webpackChunkName: "Transfusion" */
                    "@/components/data/ConfirmPostModal.vue"
                ),
                props: (x) => ({
                    verb: "POST",
                    title: `Undo reject batch`,
                    postUrl: `screening/samplebatches/${x.params.batchGuid}/undo-reject-batch`,
                    extraFields: {},
                    size: "max-w-xs",
                }),
            },
            
        ],
    },
    {
        path:"/lab/deliveries/:batchNumber/:batchType/samples/:totalNumberOfScreeningVacutainers/:totalNumberOfGroupingVacutainers/:batchGuid",
        name:"ViewBatchSamples",
        meta: { breadcrumb: { parent: "SampleBatches" } },
        component: () =>
        import (
            /* webpackChunkName: "optionsgroups" */
            "@/views/lab/DeliveryBatchSamples.vue"
        ),       
        children:[
            {
                path: "receive-batch",
                name: "AddDeliveryBatches",
                component: () =>
                    import (
                        /* webpackChunkName: "tests" */
                        "@/views/lab/components/AddBatchesSchemaModal.vue"
                    ),
                props: (x) => {
                    let propData = {
                        toFetch: false,
                        title: `Receive batch ${x.params.batchNumber}`,
                        teamLead:x.params.teamLead,
                        verb: "POST",
                        fetchUrl: ``,
                        postUrl: `screening/samplebatches/${x.params.batchGuid}/receivesamplebatch`,
                        options: [],
                        optionsUrls: [
                            `screening/screeninglookups/batch-conditions/options`,
                            `screening/screeninglookups/batch-conditions/options`,
                        ],
                        jsonSchema: `screening/screeningforms/${(x.params.totalNumberOfGroupingVacutainers>0 && x.params.totalNumberOfScreeningVacutainers>0)?'receive-both-vacutainers':x.params.totalNumberOfGroupingVacutainers>0?'receive-grouping-vacutainers':x.params.totalNumberOfScreeningVacutainers>0?'receive-screening-vacutainers':''}`,
                        size: "max-w-5xl",
                    };
                    propData.title = `Receive batch '${x.params.batchNumber}'' `;
                    return propData;
                },
            },
            {
                path: "reject-batch",
                name: "RejectBatch",
                component: () =>
                    import (
                        /* webpackChunkName: "tests" */
                        "@/components/data/SchemaModal.vue"
                    ),
                props: (x) => {
                    let propData = {
                        toFetch: false,
                        title: `Reject batch ${x.params.batchNumber}`,
                        verb: "POST",
                        fetchUrl: ``,
                        postUrl: `screening/samplebatches/${x.params.batchGuid}/reject-batch`,
                        options: [],
                        optionsUrls: [
                            `screening/screeninglookups/batch-rejection-reasons/options`,
                        ],
                        jsonSchema: `screening/screeningforms/reject-batch`,
                        size: "max-w-7xl",
                    };
                    propData.title = `Reject batch '${x.params.batchNumber}'' `;
                    return propData;
                },
            },
            {
                path: "missing-samples",
                name: "MissingSamples",               
                component: () =>
                import (
                    /* webpackChunkName: "Transfusion" */
                    "@/components/data/ConfirmPostModal.vue"
                ),
                props: (x) => ({
                    verb: "POST",
                    title: `Mark samples as missing`,
                    postUrl: `screening/vacutainers/missing-vacutainers`,
                    extraFields: {missingVacutainers:x.params.missingSamples},
                    size: "max-w-xs",
                }),
            },
        ] 

    },

    // received samples
    // screening
    {
        path: "/lab/screening-batches",
        name: "ScreeningVacutainers",
        meta: { breadcrumb: { parent: "SampleBatches" } },
        component: () =>
            import (
                /* webpackChunkName: "optionsgroups" */
                "@/views/lab/ReceivedScreeningBatches.vue"
            ),
        children:[
            
        ]

    },
    {
        path:"/lab/screening-batches/:batchNumber/samples/:batchType",
        name:"ViewReceivedScreeningSamplesPerBatch",
        meta: { breadcrumb: { parent: "SampleBatches" } },
        component: () =>
            import (
                /* webpackChunkName: "optionsgroups" */
                "@/views/lab/ReceivedScreeningSamples.vue"
            ),
        children: [
            {
                path: "add-results",
                name: "UploadSelectedResults",               
                component: () =>
                import (
                    /* webpackChunkName: "Transfusion" */
                    "@/components/data/ConfirmPostModal.vue"
                ),
                props: (x) => ({
                    verb: "POST",
                    title: `Submit results`,
                    postUrl: `screening/vacutainers/add-results-as-list`,
                    extraFields: {uploadResults:x.params.uploadResults,vacutainerType:x.params.vacutainerType},
                    size: "max-w-xs",
                }),
            },
            {
                path: "reject/:vacutainerId/:vacutainerLabel",
                name: "RejectScreeningVacutainer",               
                component: () =>
                import (
                    /* webpackChunkName: "Transfusion" */
                    "@/components/data/SchemaModal.vue"
                ),
                props: (x) => ({
                    verb: "POST",
                    title: `Reject sample '${x.params.vacutainerLabel}'`,
                    postUrl: `screening/vacutainers/${x.params.vacutainerId}/rejectvacutainer`,
                    optionsUrls: [`screening/screeninglookups/rejection-reasons/options`],
                    jsonSchema: "screening/screeningforms/reject-vacutainer",
                    extraFields: {},
                    size: "max-w-lg",
                }),
            },

        ],

    },
    // grouping
    {
        path: "/lab/grouping-batches",
        name: "Vacutainers",
        meta: { breadcrumb: { parent: "SampleBatches" } },
        component: () =>
            import (
                /* webpackChunkName: "optionsgroups" */
                "@/views/lab/ReceivedGroupingBatches.vue"
            ),
        children: [
           
        ],
    },
    {
        path:"/lab/grouping-batches/:batchNumber/samples/:batchType",
        name:"ViewReceivedGroupingSamplesPerBatch",
        meta: { breadcrumb: { parent: "SampleBatches" } },
        component: () =>
            import (
                /* webpackChunkName: "optionsgroups" */
                "@/views/lab/ReceivedGroupingSamples.vue"
            ),
            children: [
                {
                    path: "add-results",
                    name: "UploadSelectedGroupingResults",               
                    component: () =>
                    import (
                        /* webpackChunkName: "Transfusion" */
                        "@/components/data/ConfirmPostModal.vue"
                    ),
                    props: (x) => ({
                        verb: "POST",
                        title: `Submit results`,
                        postUrl: `screening/vacutainers/add-results-as-list`,
                        extraFields: {uploadResults:x.params.uploadResults,vacutainerType:x.params.vacutainerType},
                        size: "max-w-xs",
                    }),
                },
                {
                    path: "reject/:vacutainerId",
                    name: "RejectGroupingVacutainer",               
                    component: () =>
                    import (
                        /* webpackChunkName: "Transfusion" */
                        "@/components/data/SchemaModal.vue"
                    ),
                    props: (x) => ({
                        verb: "POST",
                        title: `Reject Vacutainer`,
                        postUrl: `screening/vacutainers/${x.params.vacutainerId}/rejectvacutainer`,
                        optionsUrls: [`screening/screeninglookups/rejection-reasons/options`],
                        jsonSchema: "screening/screeningforms/reject-vacutainer",
                        extraFields: {},
                        size: "max-w-lg",
                    }),
                },
    
            ],

    },
    {
        path: "/lab/vacutainers/:vacutainerId/view-details",
        name: "ViewReceivedVacutainerDetails",
        meta: { breadcrumb: { parent: "SampleBatches" } },
        component: () =>
            import (
                /* webpackChunkName: "tests" */
                "@/views/lab/ReceivedVacutainerDetails.vue"
            ),
        props: (x) => {
            let propData = {
                title: x.params.title,
                vacutainerId: x.params.vacutainerId,
                vacutainerLabel: x.params.vacutainerLabel,
            };
            propData.title = `${x.params.vacutainerLabel} vacutainer`;
            return propData;
        },
        children: [
            {
                path: "confirm-result/:testDetailId/:testResult/:testName",
                name: "AddInitialTestResult",
                component: () =>
                    import (
                        /* webpackChunkName: "Transfusion" */
                        "@/components/data/ConfirmPostModal.vue"
                    ),
                props: (x) => ({
                    verb:"POST",
                    title: `Confirm '${x.params.testResult.toUpperCase()}' `,
                    moduleName:'laboratory',
                    postUrl: `screening/vacutainers/${x.params.testDetailId}/initialaddresults`,
                    extraFields: {result:x.params.testResult},
                    size: "max-w-md",
                    titleName: `result for ${x.params.testName.toUpperCase()}`,
                    titleNameClass:`tracking-wide text-base font-bold`
                }),
            },
            {
                path:"delete-result/:testDetailId",
                name:"DeleteResult",
                component: () =>
                    import (
                        /* webpackChunkName: "Transfusion" */
                        "@/components/data/ConfirmPostModal.vue"
                    ),
                props: (x) => ({
                    verb:"DELETE",
                    title: `Delete Result: ${x.params.result.toUpperCase()}`,
                    postUrl: `screening/vacutainers/${x.params.testDetailId}/delete-results`,
                    size: "max-w-xs",
                    titleNameClass:`tracking-wide text-base font-bold`
                }),

            },
            {
                path: "confirm-result/:testDetailId/:testResult",
                name: "AddRepeatTestResult",
                component: () =>
                    import (
                        /* webpackChunkName: "Transfusion" */
                        "@/components/data/ConfirmPostModal.vue"
                    ),
                props: (x) => ({
                    verb:"PUT",
                    title: "Confirm Test Result",
                    postUrl: `screening/vacutainers/${x.params.testDetailId}/repeataddresults`,
                    extraFields: {result:x.params.testResult},
                    size: "max-w-xs",
                }),
            },          
            {
                path: "confirm-result/:result/:vacutainerGuid",
                name: "SendSingleVacutainerResult",
                component: () =>
                    import (
                        /* webpackChunkName: "Transfusion" */
                        "@/components/data/ConfirmPostModal.vue"
                    ),
                props: (x) => ({
                    verb:"POST",
                    title: `Confirm ${x.params.result} ${x.params.vacutainerType} Result`,
                    postUrl: `screening/vacutainers/sendresults`,
                    extraFields: {vacutainers:[{entityGuid:x.params.vacutainerGuid}]},
                    size: "max-w-md",
                }),
            },          
            
        ],
    },

    //end of received samples

    // review results    
    // screening
    {
        path: "/lab/review-screening-results",
        name: "ReviewScreeningResults",
        meta: { breadcrumb: { parent: "SampleBatches" } },
        component: () =>
            import (
                /* webpackChunkName: "optionsgroups" */
                "@/views/lab/ReviewResults/ReviewScreeningBatches.vue"
            ),
        children:[
            {
                path: "comment/:batchGuid/:batchNumber",
                name: "CommentOnScreeningBatch",               
                component: () =>
                import (
                    /* webpackChunkName: "Transfusion" */
                    "@/components/data/SchemaModal.vue"
                ),
                props: (x) => ({
                    verb: "POST",
                    title: `Comment on batch ${x.params.batchNumber}`,
                    postUrl: `screening/samplebatches/${x.params.batchGuid}/add-additional-notes-to-batch`,
                    jsonSchema: "screening/screeningforms/comment-on-batch",
                    extraFields: {},
                    size: "max-w-lg",
                }),
            },
        ]
        
    },
    {
        path:"/lab/review-screening-results/:batchNumber/samples/:batchType",
        name:"ViewScreeningSamplesForReview",
        meta: { breadcrumb: { parent: "SampleBatches" } },
        component: () =>
            import (
                /* webpackChunkName: "optionsgroups" */
                "@/views/lab/ReviewResults/ReviewScreeningSamples.vue"
            ),
        children: [
            {
                path: "submit",
                name: "SubmitReviewedScreeningResults",               
                component: () =>
                import (
                    /* webpackChunkName: "Transfusion" */
                    "@/components/data/ConfirmPostModal.vue"
                ),
                props: (x) => ({
                    verb: "POST",
                    title: `Submit reviewed results`,
                    postUrl: `screening/vacutainers/review-and-send-results-as-list`,
                    extraFields: {batchNumber:x.params.batchNumber,uploadResults:x.params.vacutainers, vacutainerType:x.params.batchType},
                    size: "max-w-xs",
                }),
            },
            {
                path: "reject/:vacutainerId/:vacutainerLabel",
                name: "RejectScreeningVacutainerAtReview",               
                component: () =>
                import (
                    /* webpackChunkName: "Transfusion" */
                    "@/components/data/SchemaModal.vue"
                ),
                props: (x) => ({
                    verb: "POST",
                    title: `Reject sample '${x.params.vacutainerLabel}'`,
                    postUrl: `screening/vacutainers/${x.params.vacutainerId}/rejectvacutainer`,
                    optionsUrls: [`screening/screeninglookups/rejection-reasons/options`],
                    jsonSchema: "screening/screeningforms/reject-vacutainer",
                    extraFields: {},
                    size: "max-w-lg",
                }),
            },
            {
                path: "undo-missing/:vacutainerId",
                name: "UndoMissingVacutainer",               
                component: () =>
                import (
                    /* webpackChunkName: "Transfusion" */
                    "@/components/data/ConfirmPostModal.vue"
                ),
                props: (x) => ({
                    verb: "POST",
                    title: `Undo Missing Vacutainer ${x.params.vacutainerLabel}`,
                    postUrl: `screening/vacutainers/${x.params.vacutainerId}/undo-missing-vacutainer`,
                    extraFields: {},
                    size: "max-w-lg",
                }),
            },
            {
                path: "undo-reject/:vacutainerId",
                name: "UndoRejectVacutainer",               
                component: () =>
                import (
                    /* webpackChunkName: "Transfusion" */
                    "@/components/data/ConfirmPostModal.vue"
                ),
                props: (x) => ({
                    verb: "POST",
                    title: `Undo Reject Vacutainer ${x.params.vacutainerLabel}`,
                    postUrl: `screening/vacutainers/${x.params.vacutainerId}/undo-reject-vacutainer`,
                    extraFields: {},
                    size: "max-w-lg",
                }),
            },
            {
                path: "request-sample/:vacutainerLabel",
                name: "RequestScreeningSample",               
                component: () =>
                import (
                    /* webpackChunkName: "Transfusion" */
                    "@/components/data/ConfirmPostModal.vue"
                ),
                props: (x) => ({
                    verb: "POST",
                    title: `Request sample ${x.params.vacutainerLabel}`,
                    postUrl: `screening/vacutainers/request-vacutainers`,
                    extraFields: {requestedVacutainers:x.params.requestedVacutainers},
                    size: "max-w-lg",
                }),
            },
            {
                path: "add-comment/:vacutainerId/:vacutainerLabel",
                name: "AddVacutainerComment",               
                component: () =>
                import (
                    /* webpackChunkName: "Transfusion" */
                    "@/components/data/SchemaModal.vue"
                ),
                props: (x) => ({
                    verb: "POST",
                    title: `Add comment for '${x.params.vacutainerLabel}'`,
                    postUrl: `screening/vacutainers/${x.params.vacutainerId}/add-additional-notes-to-vacutainer`,
                    extraFields: {},
                    jsonSchema:`screening/screeningforms/add-vacutainer-comment`,
                    size: "max-w-lg",
                }),
            },
        ],

    },
    // grouping
    {
        path: "/lab/review-grouping-results",
        name: "ReviewGroupingResults",
        meta: { breadcrumb: { parent: "SampleBatches" } },
        component: () =>
            import (
                /* webpackChunkName: "optionsgroups" */
                "@/views/lab/ReviewResults/ReviewGroupingBatches.vue"
            ),
        children:[
            {
                path: "comment/:batchGuid/:batchNumber",
                name: "CommentOnGroupingBatch",               
                component: () =>
                import (
                    /* webpackChunkName: "Transfusion" */
                    "@/components/data/SchemaModal.vue"
                ),
                props: (x) => ({
                    verb: "POST",
                    title: `Comment on batch ${x.params.batchNumber}`,
                    postUrl: `screening/samplebatches/${x.params.batchGuid}/add-additional-notes-to-batch`,
                    jsonSchema: "screening/screeningforms/comment-on-batch",
                    extraFields: {},
                    size: "max-w-lg",
                }),
            },
        ]
        
    },
    {
        path:"/lab/review-grouping-results/:batchNumber/samples/:batchType",
        name:"ViewGroupingSamplesForReview",
        meta: { breadcrumb: { parent: "SampleBatches" } },
        component: () =>
            import (
                /* webpackChunkName: "optionsgroups" */
                "@/views/lab/ReviewResults/ReviewGroupingSamples.vue"
            ),
        children: [
            {
                path: "submit",
                name: "SubmitReviewedGroupingResults",               
                component: () =>
                import (
                    /* webpackChunkName: "Transfusion" */
                    "@/components/data/ConfirmPostModal.vue"
                ),
                props: (x) => ({
                    verb: "POST",
                    title: `Submit reviewed grouping results`,
                    postUrl: `screening/vacutainers/review-and-send-results-as-list`,
                    extraFields: {batchNumber:x.params.batchNumber,uploadResults:x.params.vacutainers,vacutainerType:x.params.batchType},
                    size: "max-w-xs",
                }),
            },
            {
                path: "reject/:vacutainerId/:vacutainerLabel",
                name: "RejectGroupingVacutainerAtReview",               
                component: () =>
                import (
                    /* webpackChunkName: "Transfusion" */
                    "@/components/data/SchemaModal.vue"
                ),
                props: (x) => ({
                    verb: "POST",
                    title: `Reject sample '${x.params.vacutainerLabel}'`,
                    postUrl: `screening/vacutainers/${x.params.vacutainerId}/rejectvacutainer`,
                    optionsUrls: [`screening/screeninglookups/rejection-reasons/options`],
                    jsonSchema: "screening/screeningforms/reject-vacutainer",
                    extraFields: {},
                    size: "max-w-lg",
                }),
            },
            {
                path: "undo-missing/:vacutainerId",
                name: "UndoMissingGroupingVacutainer",               
                component: () =>
                import (
                    /* webpackChunkName: "Transfusion" */
                    "@/components/data/ConfirmPostModal.vue"
                ),
                props: (x) => ({
                    verb: "POST",
                    title: `Undo Missing sample ${x.params.vacutainerLabel}`,
                    postUrl: `screening/vacutainers/${x.params.vacutainerId}/undo-missing-vacutainer`,
                    extraFields: {},
                    size: "max-w-lg",
                }),
            },
            {
                path: "undo-reject/:vacutainerId",
                name: "UndoRejectGroupingVacutainer",               
                component: () =>
                import (
                    /* webpackChunkName: "Transfusion" */
                    "@/components/data/ConfirmPostModal.vue"
                ),
                props: (x) => ({
                    verb: "POST",
                    title: `Undo Reject sample ${x.params.vacutainerLabel}`,
                    postUrl: `screening/vacutainers/${x.params.vacutainerId}/undo-reject-vacutainer`,
                    extraFields: {},
                    size: "max-w-lg",
                }),
            },
            {
                path: "request-sample/:vacutainerLabel",
                name: "RequestGroupingSample",               
                component: () =>
                import (
                    /* webpackChunkName: "Transfusion" */
                    "@/components/data/ConfirmPostModal.vue"
                ),
                props: (x) => ({
                    verb: "POST",
                    title: `Request sample ${x.params.vacutainerLabel}`,
                    postUrl: `screening/vacutainers/request-vacutainers`,
                    extraFields: {requestedVacutainers:x.params.requestedVacutainers},
                    size: "max-w-lg",
                }),
            },
            {
                path: "add-comment/:vacutainerId/:vacutainerLabel",
                name: "AddGroupingVacutainerComment",               
                component: () =>
                import (
                    /* webpackChunkName: "Transfusion" */
                    "@/components/data/SchemaModal.vue"
                ),
                props: (x) => ({
                    verb: "POST",
                    title: `Add comment for '${x.params.vacutainerLabel}'`,
                    postUrl: `screening/vacutainers/${x.params.vacutainerId}/add-additional-notes-to-vacutainer`,
                    extraFields: {},
                    jsonSchema:`screening/screeningforms/add-vacutainer-comment`,
                    size: "max-w-lg",
                }),
            },
        ],

    },
    // end of review results

      // completed results    
    // screening
    {
        path: "/lab/completed-screening-results",
        name: "CompletedScreeningResults",
        meta: { breadcrumb: { parent: "SampleBatches" } },
        component: () =>
            import (
                /* webpackChunkName: "optionsgroups" */
                "@/views/lab/CompletedResults/CompletedScreeningBatches.vue"
            ),
        
    },
    {
        path:"/lab/completed-screening-results/:batchNumber/samples/:batchType",
        name:"ViewCompletedScreeningResults",
        meta: { breadcrumb: { parent: "SampleBatches" } },
        component: () =>
            import (
                /* webpackChunkName: "optionsgroups" */
                "@/views/lab/CompletedResults/CompletedScreeningSamples.vue"
            ),
        children: [
            {
                path: "add-comment/:vacutainerId/:vacutainerLabel",
                name: "AddScreeningCommentFromCompleted",               
                component: () =>
                import (
                    /* webpackChunkName: "Transfusion" */
                    "@/components/data/SchemaModal.vue"
                ),
                props: (x) => ({
                    verb: "POST",
                    title: `Add comment for '${x.params.vacutainerLabel}'`,
                    postUrl: `screening/vacutainers/${x.params.vacutainerId}/add-additional-notes-to-vacutainer`,
                    extraFields: {},
                    jsonSchema:`screening/screeningforms/add-vacutainer-comment`,
                    size: "max-w-lg",
                }),
            },
            {
                path: "undo-missing/:vacutainerId",
                name: "UndoMissingFromScreeningResult",               
                component: () =>
                import (
                    /* webpackChunkName: "Transfusion" */
                    "@/components/data/ConfirmPostModal.vue"
                ),
                props: (x) => ({
                    verb: "POST",
                    title: `Undo Missing sample ${x.params.vacutainerLabel}`,
                    postUrl: `screening/vacutainers/${x.params.vacutainerId}/undo-missing-vacutainer`,
                    extraFields: {},
                    size: "max-w-lg",
                }),
            },
            {
                path: "undo-reject/:vacutainerId",
                name: "UndoRejectFromScreeningResult",               
                component: () =>
                import (
                    /* webpackChunkName: "Transfusion" */
                    "@/components/data/ConfirmPostModal.vue"
                ),
                props: (x) => ({
                    verb: "POST",
                    title: `Undo Reject sample ${x.params.vacutainerLabel}`,
                    postUrl: `screening/vacutainers/${x.params.vacutainerId}/undo-reject-vacutainer`,
                    extraFields: {},
                    size: "max-w-lg",
                }),
            },
            {
                path: "request-sample/:vacutainerLabel",
                name: "RequestScreeningSampleFromComplete",               
                component: () =>
                import (
                    /* webpackChunkName: "Transfusion" */
                    "@/components/data/ConfirmPostModal.vue"
                ),
                props: (x) => ({
                    verb: "POST",
                    title: `Request sample ${x.params.vacutainerLabel}`,
                    postUrl: `screening/vacutainers/request-vacutainers`,
                    extraFields: {requestedVacutainers:x.params.requestedVacutainers},
                    size: "max-w-lg",
                }),
            },
           
        ],

    },
    // grouping
    {
        path: "/lab/completed-grouping-results",
        name: "CompletedGroupingResults",
        meta: { breadcrumb: { parent: "SampleBatches" } },
        component: () =>
            import (
                /* webpackChunkName: "optionsgroups" */
                "@/views/lab/CompletedResults/CompletedGroupingBatches.vue"
            ),
        
    },
    {
        path:"/lab/completed-grouping-results/:batchNumber/samples/:batchType",
        name:"ViewCompletedGroupingResults",
        meta: { breadcrumb: { parent: "SampleBatches" } },
        component: () =>
            import (
                /* webpackChunkName: "optionsgroups" */
                "@/views/lab/CompletedResults/CompletedGroupingSamples.vue"
            ),
        children: [
            {
                path: "add-comment/:vacutainerId/:vacutainerLabel",
                name: "AddGroupingCommentFromCompleted",               
                component: () =>
                import (
                    /* webpackChunkName: "Transfusion" */
                    "@/components/data/SchemaModal.vue"
                ),
                props: (x) => ({
                    verb: "POST",
                    title: `Add comment for '${x.params.vacutainerLabel}'`,
                    postUrl: `screening/vacutainers/${x.params.vacutainerId}/add-additional-notes-to-vacutainer`,
                    extraFields: {},
                    jsonSchema:`screening/screeningforms/add-vacutainer-comment`,
                    size: "max-w-lg",
                }),
            },
            {
                path: "undo-missing/:vacutainerId",
                name: "UndoMissingFromGroupingResult",               
                component: () =>
                import (
                    /* webpackChunkName: "Transfusion" */
                    "@/components/data/ConfirmPostModal.vue"
                ),
                props: (x) => ({
                    verb: "POST",
                    title: `Undo Missing sample ${x.params.vacutainerLabel}`,
                    postUrl: `screening/vacutainers/${x.params.vacutainerId}/undo-missing-vacutainer`,
                    extraFields: {},
                    size: "max-w-lg",
                }),
            },
            {
                path: "undo-reject/:vacutainerId",
                name: "UndoRejectFromGroupingResult",               
                component: () =>
                import (
                    /* webpackChunkName: "Transfusion" */
                    "@/components/data/ConfirmPostModal.vue"
                ),
                props: (x) => ({
                    verb: "POST",
                    title: `Undo Reject sample ${x.params.vacutainerLabel}`,
                    postUrl: `screening/vacutainers/${x.params.vacutainerId}/undo-reject-vacutainer`,
                    extraFields: {},
                    size: "max-w-lg",
                }),
            },
            {
                path: "request-sample/:vacutainerLabel",
                name: "RequestGroupingSampleFromComplete",               
                component: () =>
                import (
                    /* webpackChunkName: "Transfusion" */
                    "@/components/data/ConfirmPostModal.vue"
                ),
                props: (x) => ({
                    verb: "POST",
                    title: `Request sample ${x.params.vacutainerLabel}`,
                    postUrl: `screening/vacutainers/request-vacutainers`,
                    extraFields: {requestedVacutainers:x.params.requestedVacutainers},
                    size: "max-w-lg",
                }),
            },
           
        ],

    },
    // end of completed results
  
    
    // result upload
    {
        path: "/lab/upload-results",
        meta: { breadcrumb: { parent: "SampleBatches" } },
        name: "UploadResults",
        props:()=> ({
            screeningVacutainersUrl: `screening/vacutainers/screeningvacutainerslist`,
            groupingVacutainersUrl: `screening/vacutainers/groupingvacutainerslist`,

        }),
        component: () =>
            import (
                /* webpackChunkName: "optionsgroups" */
                "@/views/lab/components/FileReaderData.vue"
            ),
        children:[
            {
                path: "submit-results",
                name: "SubmitUploadedResults",
                component: () =>
                    import (
                        /* webpackChunkName: "tests" */
                        "@/components/data/ConfirmPostModal.vue"
                    ),
                props: (x) => ({
                    toFetch: false,
                    title: "Submit Uploaded Results",
                    verb: "POST",
                    extraFields:{vacutainerType:x.params.vacutainerType,uploadResults:x.params.labResults},
                    postUrl: `screening/vacutainers/upload-results`,
                    options: [],
                    optionsUrls: [``],
                    jsonSchema: "screening/screeningforms/add-test",
                    size: "max-w-3xl",
                    moduleName:"laboratory",
                    reloadPage:true,
                }),
            },

        ]
    },  
    // end of result upload

    // rejected vacutainers
    {
        path: "/lab/rejected-donations",
        meta: { breadcrumb: { parent: "SampleBatches" } },
        name: "Rejects",
        component: () =>
            import (
                /* webpackChunkName: "doctors-pool-group" */
                "@/views/lab/RejectedVacutainers.vue"
            ),
    },
  
    // end of rejected vacutainers

    // resend results
    {
        path: "/lab/resend-results",
        name: "ResendResults",
        meta: { breadcrumb: { parent: "SampleBatches" } },
        component: () =>
            import (
                /* webpackChunkName: "optionsgroups" */
                "@/views/lab/AdminEdits/SampleList.vue"
            ),  
        children:[
            {
                path: "submit",
                name: "ResendSelectedResults",               
                component: () =>
                import (
                    /* webpackChunkName: "Transfusion" */
                    "@/components/data/ConfirmPostModal.vue"
                ),
                props: (x) => ({
                    verb: "POST",
                    title: `Submit ${x.params.label} results`,
                    postUrl: `screening/superadmindataedits/resend-results`,
                    extraFields: {vacutainers:x.params.vacutainers},
                    size: "max-w-xs",
                }),
            },
        ]          
       
    },
    // resend results

     // superadmin data edits
     {
        path: "/lab/data-edits",
        name: "SuperAdminDataEdits",
        meta: { breadcrumb: { parent: "SampleBatches" } },
        component: () =>
            import (
                /* webpackChunkName: "optionsgroups" */
                "@/views/lab/AdminEdits/AdminEditBatches.vue"
            ),            
        children: [
            {
                path: "update-batch/:batchNumber",
                name: "UpdateBatch",
                component: () =>
                import (
                    /* webpackChunkName: "Transfusion" */
                    "@/views/lab/AdminEdits/EditBatch.vue"
                ),
                props: (x) => ({
                    verb: "POST",
                    title: `Update Batch '${x.params.batchNumber}' Status`,
                    postUrl: `screening/superadmindataedits/update-batches`,
                    size: "max-w-xs",
                }),
            },
            {
                path: "update-batch-type/:batchNumber",
                name: "UpdateBatchType",
                component: () =>
                import (
                    /* webpackChunkName: "Transfusion" */
                    "@/views/lab/AdminEdits/EditBatchType.vue"
                ),
                props: (x) => ({
                    verb: "POST",
                    title: `Update Batch '${x.params.batchNumber}' Status`,
                    postUrl: `screening/superadmindataedits/update-batch-types`,
                    size: "max-w-xs",
                }),
            }
        ],
    },
    {
        path:"/lab/data-edits/:batchNumber/samples",
        meta: { breadcrumb: { parent: "SampleBatches" } },
        name:"AdminEditSamples",
        component: () =>
        import (
            /* webpackChunkName: "optionsgroups" */
            "@/views/lab/AdminEdits/AdminEditSamples.vue"
        ),       
        children:[
            {
                path: "update-sample-status",
                name: "UpdateSamples",
                component: () =>
                import (
                    /* webpackChunkName: "Transfusion" */
                    "@/components/data/ConfirmPostModal.vue"
                ),
                props: (x) => ({
                    verb: "POST",
                    title: `Submit updated samples`,
                    postUrl: `screening/superadmindataedits/update-vacutainers`,
                    extraFields: {selectedVacutainers:x.params.selectedVacutainers},
                    size: "max-w-xs",
                }),
            }
        ] 

    },
    // end of superadmin data edits

    //Tests
    {
        path: "/lab/tests",
        name: "TestsConfigs",
        meta: { breadcrumb: { parent: "SampleBatches" } },
        props: (x) => ({
            title: x.params.title,
        }),
        component: () =>
            import ( /* webpackChunkName: "tests" */ "@/views/lab/Setup/Tests.vue"),
        children: [{
                path: "create-test",
                name: "CreateScreeningTest",
                component: () =>
                    import (
                        /* webpackChunkName: "tests" */
                        "@/components/data/SchemaModal.vue"
                    ),
                props: () => ({
                    toFetch: false,
                    title: "Create Test",
                    verb: "POST",
                    postUrl: `screening/testsconfigs`,
                    options: [],
                    optionsUrls: [`screening/screeninglookups/vacutainer-types/options`],
                    jsonSchema: "screening/screeningforms/add-test",
                    size: "max-w-3xl",
                }),
            },
            {
                path: "amend-test/:testId/:testName",
                name: "AmendScreeningTest",
                component: () =>
                    import (
                        /* webpackChunkName: "tests" */
                        "@/components/data/SchemaModal.vue"
                    ),
                props: (x) => {
                    let propData = {
                        title: "Amend test",
                        verb: "PUT",
                        fetchUrl: `screening/testsconfigs/${x.params.testId}`,
                        postUrl: `screening/testsconfigs/${x.params.testId}`,
                        options: [],
                        optionsUrls: [`screening/screeninglookups/vacutainer-types/options`],
                        jsonSchema: "screening/screeningforms/amend-test",
                        size: "max-w-3xl",
                    };
                    propData.title = `Amend ${x.params.testName} Details`;
                    return propData;
                },
            },
        ],
    },
    //end of tests
    //Test Results Configs
    {
        path: "/lab/tests/:testId/:testName/resultsconfigs",
        name: "ScreeningTestResultConfigs",
        meta: { breadcrumb: { parent: "SampleBatches" } },
        props: (x) => ({
            title: x.params.title,
        }),
        component: () =>
            import (
                /* webpackChunkName: "tests" */
                "@/views/lab/Setup/TestResultsConfig.vue"
            ),
        children: [{
                path: "add-result-config",
                name: "AddResultConfig",
                component: () =>
                    import (
                        /* webpackChunkName: "tests" */
                        "@/components/data/SchemaModal.vue"
                    ),
                props: (x) => ({
                    toFetch: false,
                    title: "Add result config",
                    verb: "POST",
                    postUrl: `screening/testsconfigs/${x.params.testId}`,
                    options: [],
                    moduleName:'laboratory',
                    moduleSection:'testresultconfig',
                    optionsUrls: [
                        `screening/screeninglookups/test-result-conditions/options`,
                        `screening/screeninglookups/test-report-value/options`,
                        `screening/screeninglookups/test-report-value/options`,
                        `screening/screeninglookups/blood-groups/options`,
                    ],
                    jsonSchema: "screening/screeningforms/add-test-result-config",
                    size: "max-w-3xl",
                }),
            },
            {
                path: "amend-result-config/:testResultConfigId",
                name: "AmendScreeningTestResultConfig",
                component: () =>
                    import (
                        /* webpackChunkName: "tests" */
                        "@/components/data/SchemaModal.vue"
                    ),
                props: (x) => {
                    let propData = {
                        title: "Add result config",
                        verb: "PUT",
                        moduleName:'laboratory',
                        moduleSection:'amendtestresultconfig',
                        fetchUrl: `screening/testsconfigs/${x.params.testResultConfigId}/gettestresultconfigs`,
                        postUrl: `screening/testsconfigs/${x.params.testResultConfigId}/update-test-result-config`,
                        options: [],
                        optionsUrls: [
                            `screening/screeninglookups/test-result-conditions/options`,
                        `screening/screeninglookups/test-report-value/options`,
                        `screening/screeninglookups/test-report-value/options`,
                        `screening/screeninglookups/blood-groups/options`,
                        ],
                        jsonSchema: "screening/screeningforms/update-test-result-config",
                        size: "max-w-3xl",
                    };
                    propData.title = `Amend '${x.params.testName}' Result Config`;
                    return propData;
                },
            },
        ],
    },
    //end of results configs

    //blood groups setup
    {
        path: "/lab/blood-groups",
        name: "BloodGroupConfigurations",
        meta: { breadcrumb: { parent: "SampleBatches" } },
        component: () =>
            import (
                /* webpackChunkName: "optionsgroups" */
                "@/views/lab/Setup/BloodGroups.vue"
            ),
        children: [{
                path: "create",
                name: "CreateBloodGroup",
                component: () =>
                    import (
                        /* webpackChunkName: "optionsgroups" */
                        "@/components/data/SchemaModal.vue"
                    ),
                props: () => ({
                    toFetch: false,
                    title: "Create Blood Group ",
                    postUrl: "screening/bloodgroupconfigurations/create-blood-group",
                    optionsUrls: [],
                    jsonSchema: "screening/screeningforms/create-blood-group",
                    size: "max-w-xs",
                }),
            },
            {
                path: "edit/:id",                
                name: "UpdateBloodGroup",
                component: () =>
                    import (
                        /* webpackChunkName: "optionsgroups" */
                        "@/components/data/SchemaModal.vue"
                    ),
                props: (x) => ({
                    title: "Update Blood Group ",
                    fetchUrl: `screening/bloodgroupconfigurations/${x.params.id}/get-blood-group-by-id`,
                    postUrl: `screening/bloodgroupconfigurations/${x.params.id}/update-blood-group`,
                    optionsUrls: [],
                    jsonSchema: "screening/screeningforms/create-blood-group",
                    size: "max-w-xs",
                }),
            },
            {
                path: "delete/:id/:label",
                name: "DeleteBloodGroup",
                component: () =>
                    import (
                        /* webpackChunkName: "doctors-pool-group" */
                        "@/components/data/DeleteModal.vue"
                    ),
                props: (x) => ({
                    title: `Delete ${x.params.label}`,
                    deleteUrl: `screening/bloodgroupconfigurations/${x.params.id}/delete-blood-group`,
                    size: "max-w-xs",
                    name: "Delete Blood Group",
                }),
            },
        ],
    },
     // reports section
    {
        path:'/lab/reports',
        name:"LabReports",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/lab/Reports/Reports.vue"),

    },
    // end of reports section

    // lookups
    {
        path: "/lab/optionsgroups",
        name: "ScreeningOptionGroups",
        meta: { breadcrumb: { parent: "SampleBatches" } },
        component: () =>
            import (
                /* webpackChunkName: "optionsgroups" */
                "@/views/lab/Setup/Lookups/OptionGroupsList.vue"
            ),
        children: [{
                path: "create",
                name: "CreateScreeningOptionsGroup",
                component: () =>
                    import (
                        /* webpackChunkName: "optionsgroups" */
                        "@/components/data/SchemaModal.vue"
                    ),
                props: () => ({
                    toFetch: false,
                    title: "Add Options Group ",
                    postUrl: "screening/screeninglookups/0",
                    optionsUrls: [],
                    jsonSchema: "screening/screeningforms/options-group",
                    size: "max-w-xs",
                }),
            },
            {
                path: "edit/:id",
                title: "Edit Options Group ",
                name: "AmendScreeningOptionsGroup",
                component: () =>
                    import (
                        /* webpackChunkName: "optionsgroups" */
                        "@/components/data/SchemaModal.vue"
                    ),
                props: (x) => ({
                    title: "Add Options Group ",
                    postUrl: `screening/screeninglookups/${x.params.id}`,
                    optionsUrls: [],
                    jsonSchema: "screening/screeningforms/options-group",
                    size: "max-w-xs",
                }),
            },
        ],
    },
    {
        path: "/lab/optionsgroups/:groupid/options",
        meta: { breadcrumb: { parent: "SampleBatches" } },
        name: "ScreeningOptions",
        props: (x) => ({
            title: x.params.title,
        }),
        component: () =>
            import (
                /* webpackChunkName: "optionsgroups" */
                "@/views/lab/Setup/Lookups/OptionsList.vue"
            ),
        children: [{
                path: "create",
                name: "CreateScreeningOption",
                component: () =>
                    import (
                        /* webpackChunkName: "optionsgroups" */
                        "@/components/data/SchemaModal.vue"
                    ),
                props: (x) => ({
                    toFetch: false,
                    title: "Add Option",
                    verb: "POST",
                    postUrl: `screening/screeninglookups/${x.params.groupid}`,
                    optionsUrls: [],
                    jsonSchema: "screening/screeningforms/option",
                    size: "max-w-xs",
                }),
            },
            {
                path: "edit/:id",
                title: "Edit Option ",
                name: "AmendScreeningOption",
                component: () =>
                    import (
                        /* webpackChunkName: "optionsgroups" */
                        "@/components/data/SchemaModal.vue"
                    ),
                props: (x) => ({
                    title: "Amend Option ",
                    verb: "PUT",
                    postUrl: `screening/screeninglookups/${x.params.id}`,
                    optionsUrls: [],
                    jsonSchema: "screening/screeningforms/option",
                    size: "max-w-xs",
                }),
            },
        ],
    },
    // Forms
    {
        path: "/lab/forms",
        name: "ScreeningForms",
        meta: { breadcrumb: { parent: "SampleBatches" } },
        component: () =>
            import (
                /* webpackChunkName: "doctors-pool-group" */
                "@/views/lab/Setup/Forms/List.vue"
            ),
        children: [{
                path: "create",
                name: "AddScreeningForm",
                component: () =>
                    import (
                        /* webpackChunkName: "doctors-pool-group" */
                        "@/components/data/JsonForm.vue"
                    ),
                props: () => ({
                    toFetch: false,
                    title: "Add Form ",
                    postUrl: "screening/screeningforms",
                }),
            },
            {
                path: "edit/:id",
                title: "Update Form",
                name: "UpdateScreeningForm",
                component: () =>
                    import (
                        /* webpackChunkName: "doctors-pool-group" */
                        "@/components/data/JsonForm.vue"
                    ),
                props: (x) => ({
                    title: "Update Form ",
                    postUrl: `screening/screeningforms/${x.params.id}`,
                }),
            },
            {
                path: "delete/:id",
                title: "Delete Form",
                name: "DeleteScreeningForm",
                component: () =>
                    import (
                        /* webpackChunkName: "doctors-pool-group" */
                        "@/components/data/DeleteModal.vue"
                    ),
                props: (x) => ({
                    title: "Delete Form ",
                    deleteUrl: `screening/screeningforms/${x.params.id}`,
                    size: "max-w-xs",
                    name: "Delete Form",
                }),
            },
        ],
    },
    {
        path: "/lab/expected-results-forms",
        name: "ScreeningExpectedResultsForms",
        meta: { breadcrumb: { parent: "SampleBatches" } },
        component: () =>
            import (
                /* webpackChunkName: "doctors-pool-group" */
                "@/views/lab/Setup/Forms/ExpectedResultsForms.vue"
            ),
        children: [{
                path: "create",
                name: "AddScreeningTestForm",
                component: () =>
                    import (
                        /* webpackChunkName: "doctors-pool-group" */
                        "@/components/data/JsonForm.vue"
                    ),
                props: () => ({
                    verb: "POST",
                    toFetch: false,
                    title: "Add Form ",
                    postUrl: "screening/ExpectedResultsForms",
                }),
            },
            {
                path: "edit/:id",
                title: "Update Form",
                name: "UpdateScreeningTestForm",
                component: () =>
                    import (
                        /* webpackChunkName: "doctors-pool-group" */
                        "@/components/data/JsonForm.vue"
                    ),
                props: (x) => ({
                    verb: "PUT",
                    title: "Update Form ",
                    toFetch: true,
                    postUrl: `screening/ExpectedResultsForms/${x.params.id}`,
                }),
            },
            {
                path: "delete/:id",
                title: "Delete Form",
                name: "DeleteScreeningTestForm",
                component: () =>
                    import (
                        /* webpackChunkName: "doctors-pool-group" */
                        "@/components/data/JsonForm.vue"
                    ),
                props: (x) => ({
                    verb: "DELETE",
                    title: "Delete Form ",
                    deleteUrl: `screening/ExpectedResultsForms/${x.params.id}`,
                    size: "max-w-xs",
                    name: "Delete Form",
                }),
            },
        ],
    },
];