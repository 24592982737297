export default [
  // facility
  {
    path: "/setup/manage-facility",
    name: "ManageFacility",
    component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/setup/facilities/Facilities.vue"),
    children: [
      {
        path: "create",
        name: "AddFacility",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/setup/facilities/Test.vue"),
        props: () => {
          let propData = {
            verb: "POST",
            postUrl: `setup/facilities`,
            size: "max-w-4xl",
          };
          propData.title = "Add Facility";
          return propData;
        },
      },
      {
        path: "edit/:groupid",
        title: "Update Facility Record ",
        name: "UpdateFacilityRecord",
        // component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/setup/facilities/Registration.vue"),
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/setup/facilities/Test.vue"),
        props: (x) => {
          let propData = {
            fetchUrl: `setup/facilities/${x.params.groupid}`,
            postUrl: `setup/facilities/${x.params.groupid}`,
            verb: "PUT",
            groupid: x.params.groupid,
            facilityName: x.params.facilityName,
            size: "max-w-4xl",
          };
          propData.title = `UPDATE '${x.params.facilityName}' RECORD`;
          return propData;
        },
      },
      {
        path: "delete/:groupid/:facilityName",
        title: "Delete Facility Record ",
        name: "DeleteFacilityRecord",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/DeleteModal.vue"),
        props: (x) => {
          let propData = {
            title: "Delete Facility",
            postUrl: "",
            verb: "DELETE",
            deleteUrl: `setup/facilities/${x.params.groupid}`,
            options: [],
            groupid: x.params.groupid,
            facilityName: x.params.facilityName,
            size: "max-w-xl",
          };
          propData.title = `Delete '${x.params.facilityName}'?`;
          return propData;
        },
      },
    ],
  },
  // view contact persons
  {
    path: "/setup/manage-facility/:groupid/:facilityName/view-liaisons",
    title: "View Facility Contact Person",
    meta: { breadcrumb: { parent: "ManageFacility" } },
    name: "ViewFacilityLiaisons",
    component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/setup/facilities/FacilityLiaisons.vue"),
    props: (x) => {
      let propData = {
        verb: "GET",
        title: "View contact persons",
        // postUrl: `setup/facilities/${x.params.groupid}/addContactPerson`,
        jsonSchema: `setup/forms/add-facility-contact-person`,
        optionsUrls: [`setup/lookups/contact-types/options`],
        size: "max-w-4xl",
      };
      propData.title = `View '${x.params.facilityName}' Contact Persons`;
      return propData;
    },
    children: [
      {
        path: "add-facility-contact-person",
        title: "Add Facility Contact Person",
        name: "AddFacilityLiaison",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/SchemaModalWithExtraFields.vue"),
        props: (x) => {
          let propData = {
            verb: "POST",
            title: "Add contact person",
            postUrl: `setup/liaisons`,
            jsonSchema: `setup/forms/add-facility-contact-person`,
            optionsUrls: [`setup/lookups/contact-types/options`],
            size: "max-w-4xl",
            extraFields: { facilityId: x.params.groupid, createdOnUtc: new Date() },
          };
          propData.title = `Add '${x.params.facilityName}' Contact Person`;
          return propData;
        },
      },
      {
        path: ":liaisonName/view",
        title: "Update Contact Person",
        name: "UpdateFacilityLiaison",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/SchemaModalWithExtraFields.vue"),
        props: (x) => {
          let propData = {
            verb: "PUT",
            title: "Update contact person",
            liaisonName: x.params.liaison,
            postUrl: `setup/liaisons/${x.params.liaisonId}`,
            jsonSchema: `setup/forms/add-facility-contact-person`,
            optionsUrls: [`setup/lookups/contact-types/options`],
            size: "max-w-4xl",
            extraFields: { facilityId: x.params.groupid, createdOnUtc: new Date() },
          };
          propData.title = `Update '${x.params.liaisonName}' Details`;
          return propData;
        },
      },
      {
        path: "delete/:liaisonId/:liaisonName",
        title: "Delete Liaison ",
        name: "DeleteLiaison",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/DeleteModal.vue"),
        props: (x) => {
          let propData = {
            title: "Delete Liaison",
            postUrl: "",
            verb: "DELETE",
            deleteUrl: `setup/liaisons/contact/${x.params.liaisonId}`,
            options: [],
            liaisonId: x.params.liaisonId,
            liaisonName: x.params.liaisonName,
            size: "max-w-xl",
          };
          propData.title = `Delete '${x.params.liaisonName}'?`;
          return propData;
        },
      },
    ],
  },
  
  // liason contact
  {
    path: "/setup/manage-facility/:groupid/:facilityName/view-liaisons/:liaisonName/view-contacts/:liaisonId",
    title: "View Liason Contact",
    meta: { breadcrumb: { parent: "ManageFacility" } },
    name: "ViewFacilityLiaisonContact",
    component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/setup/facilities/LiaisonContacts.vue"),
    props: (x) => {
      let propData = {
        title: "Update contact person",
        liaisonName: x.params.liaison,
        size: "max-w-4xl",
        extraFields: { liaisonId: x.params.liaisonId },
      };
      propData.title = `${x.params.liaisonName} Liason`;
      return propData;
    },
    children: [
      {
        path: "add-liaison-contact",
        name: "AddLiaisonContact",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/SchemaModalWithExtraFields.vue"),
        props: (x) => {
          let propData = {
            verb: "POST",
            postUrl: `setup/contacts/liaison`,
            jsonSchema: `setup/forms/add-liaison-contact`,
            optionsUrls: [`setup/lookups/contact-types/options`],
            size: "max-w-4xl",
            extraFields: { liaisonId: Number(x.params.liaisonId) },
          };
          propData.title = `Add ${x.params.liaisonName} Contact`;
          return propData;
        },
      },
      {
        path: "update-liaison-contact/:contactId",
        title: "Update Contact Person",
        name: "UpdateLiaisonContact",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/SchemaModalWithExtraFields.vue"),
        props: (x) => {
          let propData = {
            verb: "PUT",
            title: "Update contact",
            contactType: x.params.contactType,
            contactId: x.params.contactId,
            fetchUrl: `setup/contacts/${x.params.contactId}`,
            postUrl: `setup/contacts/liaison/${x.params.contactId}`,
            jsonSchema: `setup/forms/add-liaison-contact`,
            optionsUrls: [`setup/lookups/contact-types/options`],
            size: "max-w-4xl",
            extraFields: { contactId: x.params.contactId },
          };
          propData.title = `Update ${x.params.liaisonName} Contact`;
          return propData;
        },
      },
      {
        path: "delete",
        title: "Delete Liaison Contact",
        name: "DeleteLiaisonContact",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/DeleteModal.vue"),
        props: (x) => {
          let propData = {
            title: "Delete Liaison",
            postUrl: "",
            verb: "DELETE",
            deleteUrl: `setup/contacts/liaison/${x.params.contactId}`,
            options: [],
            size: "max-w-xl",
          };
          propData.title = `Delete ${x.params.liaisonName} contact?`;
          return propData;
        },
      },
    ],
  },
  // end of liason contact

  // facility contact
  {
    path: "/setup/manage-facility/:groupid/:facilityName/view-contacts",
    title: "View Facility Contacts",
    meta: { breadcrumb: { parent: "ManageFacility" } },
    name: "ViewFacilityContacts",
    component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/setup/facilities/FacilityContacts.vue"),
    props: (x) => {
      let propData = {
        title: "View facility person",
        liaisonName: x.params.liaison,
        size: "max-w-4xl",
        extraFields: { facilityId: x.params.groupid },
      };
      propData.title = `${x.params.facilityName} Contacts`;
      return propData;
    },
    children: [
      {
        path: "add-facility-contact",
        name: "AddFacilityContact",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/SchemaModalWithExtraFields.vue"),
        props: (x) => {
          let propData = {
            verb: "POST",
            postUrl: `setup/contacts/facility`,
            jsonSchema: `setup/forms/add-facility-contact`,
            optionsUrls: [`setup/lookups/facility-contact-types/options`],
            size: "max-w-4xl",
            extraFields: { facilityId: Number(x.params.groupid) },
          };
          propData.title = `Add ${x.params.facilityName} Contact`;
          return propData;
        },
      },
      {
        path: "update-facility-contact/:contactId",
        title: "Update facility contact",
        name: "UpdateFacilityContact",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/SchemaModalWithExtraFields.vue"),
        props: (x) => {
          let propData = {
            verb: "PUT",
            title: "Update contact",
            contactType: x.params.contactType,
            contactId: x.params.contactId,
            fetchUrl: `setup/contacts/${x.params.contactId}`,
            postUrl: `setup/contacts/facility/${x.params.contactId}`,
            jsonSchema: `setup/forms/add-facility-contact`,
            optionsUrls: [`setup/lookups/contact-types/options`],
            size: "max-w-4xl",
            extraFields: { facilityId: x.params.groupid },
          };
          propData.title = `Update ${x.params.facilityName} Contact`;
          return propData;
        },
      },
      {
        path: "delete",
        title: "Delete Liaison Contact",
        name: "DeleteFacilityContact",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/DeleteModal.vue"),
        props: (x) => {
          let propData = {
            title: "Delete Liaison",
            postUrl: "",
            verb: "DELETE",
            deleteUrl: `setup/contacts/facility/${x.params.contactId}`,
            options: [],
            size: "max-w-xl",
          };
          propData.title = `Delete ${x.params.facilityName} contact?`;
          return propData;
        },
      },
    ],
  },
  // end of facility contact

  // satellite facilities
  {
    path: "/setup/manage-facility/:groupid/satellite-facilities",
    name: "SatelliteFacilities",
    meta: { breadcrumb: { parent: "ManageFacility" } },
    props: (x) => ({
      title: x.params.title,
    }),
    component: () => import(/* webpackChunkName: "centers" */ "@/views/setup/facilities/Satellites.vue"),
    children: [
      {
        path: "create",
        name: "AddSatelliteFacility",
        component: () => import(/* webpackChunkName: "centers" */ "@/views/setup/facilities/AddSatelliteFacility.vue"),
        props: (x) => ({
          toFetch: false,
          title: "Add Satellite Facility",
          facilityId: x.params.groupid,
          verb: "POST",
          postUrl: `setup/facilities`,
          optionsUrls: [],
          jsonSchema: "",
          size: "max-w-xs",
        }),
      },
      {
        path: "edit/:id",
        title: "Edit facility ",
        name: "EditSatelliteFacility",
        component: () => import(/* webpackChunkName: "centers" */ "@/views/setup/facilities/AddSatelliteFacility.vue"),
        props: (x) => ({
          title: "Amend Facility",
          verb: "PUT",
          fetchUrl: `setup/facilities/${x.params.id}`,
          postUrl: `setup/facilities/${x.params.id}`,
          optionsUrls: [],
          jsonSchema: "",
          size: "max-w-xs",
        }),
      },
      {
        path: "delete/:id",
        title: "Delete Satellite Facility",
        name: "DeleteSatelliteFacility",
        component: () => import(/* webpackChunkName: "centers" */ "@/components/data/DeleteModal.vue"),
        props: (x) => ({
          title: "Remove Satellite Facility",
          deleteUrl: `setup/facilities/${x.params.id}`,
          size: "max-w-xs",
        }),
      },
    ],
  },
  // manage departments
  {
    path: "/setup/managedepartments",
    name: "ManageDepartments",
    meta: { breadcrumb: { parent: "ManageFacility" } },
    component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/departments/DepartmentList.vue"),
    children: [
      {
        path: "create",
        name: "AddDepartment",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/JsonModal.vue"),
        props: () => ({
          toFetch: false,
          title: "Add Department ",
          postUrl: "setup/departments",
          optionsUrls: [],
          jsonSchema: "/schemas/setup/add_department.json",
          size: "max-w-xs",
        }),
      },
      {
        path: "edit/:id",
        title: "Update Department Info",
        name: "UpdateDepartment",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/JsonModal.vue"),
        props: (x) => ({
          title: "Update Department ",
          postUrl: `setup/departments/${x.params.id}`,
          optionsUrls: [],
          jsonSchema: "/schemas/setup/add_department.json",
          size: "max-w-xs",
        }),
      },
      {
        path: "delete/:id",
        title: "Delete Service Area",
        name: "DeleteDepartment",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/DeleteModal.vue"),
        props: (x) => ({
          title: "Delete Department ",
          deleteUrl: `setup/departments/${x.params.id}`,
          size: "max-w-xs",
          name: "Service Area",
        }),
      },
    ],
  },
  {
    path: "/setup/managedepartments/:departmentid/serviceareas",
    meta: { breadcrumb: { parent: "ManageFacility" } },
    name: "ServiceAreas",
    props: (x) => ({
      title: x.params.title,
    }),
    component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/departments/ServiceAreas.vue"),
    children: [
      {
        path: "create",
        name: "CreateService",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/JsonModal.vue"),
        props: (x) => ({
          toFetch: false,
          title: "Add Service Area",
          postUrl: `setup/departments/${x.params.departmentid}/serviceareas`,
          optionsUrls: [],
          jsonSchema: "schemas/setup/add_service_area.json",
          size: "max-w-md",
        }),
      },
      {
        path: "amend/:id",
        title: "Amend Service ",
        name: "AmendService",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/JsonModal.vue"),
        props: (x) => ({
          title: "Amend Service",
          postUrl: `setup/departments/${x.params.departmentid}/serviceareas/${x.params.id}`,
          optionsUrls: [],
          jsonSchema: "/schemas/setup/add_service_area.json",
          size: "max-w-md",
        }),
      },
      {
        path: "delete/:id",
        title: "Delete Service",
        name: "DeleteService",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/DeleteModal.vue"),
        props: (x) => ({
          title: "Remove Service ",
          deleteUrl: `setup/departments/${x.params.departmentid}/serviceareas/${x.params.id}`,
          size: "max-w-xs",
          name: "Service",
        }),
      },
    ],
  },
  // financial year
  {
    path: "/setup/financial-year",
    name: "FinancialYearDefinitions",
    meta: { breadcrumb: { parent: "ManageFacility" } },
    component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/setup/FinancialYear.vue"),
    children: [
      {
        path: "add",
        name: "AddFinancialYear",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/SchemaModal.vue"),
        props: () => ({
          toFetch: false,
          title: "Add Financial Year ",
          postUrl: "setup/financialyeardefinitions",
          optionsUrls: [],
          jsonSchema: "setup/forms/financial-year",
          size: "max-w-xs",
        }),
      },
      {
        path: "amend/:yearId",
        title: "Amend Financial Year ",
        name: "AmendFinancialYear",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/SchemaModal.vue"),
        props: (x) => ({
          title: "Amend Financial Year ",
          postUrl: `setup/financialyeardefinitions/${x.params.yearId}`,
          optionsUrls: [],
          jsonSchema: "setup/forms/financial-year",
          size: "max-w-xs",
        }),
      },
      {
        path: "delete/:yearId",
        title: "Delete Financial Year",
        name: "DeleteFinancialYear",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/DeleteModal.vue"),
        props: (x) => {
          let propData = {
            title: "Remove Option ",
            deleteUrl: `setup/financialyeardefinitions/${x.params.yearId}`,
            size: "max-w-xs",
            name: "Option",
          };
          propData.title = `Delete financial year '${x.params.yearName}'?`;

          return propData;
        },
      },
    ],
  },
  // lookups
  {
    path: "/setup/optionsgroups",
    meta: { breadcrumb: { parent: "ManageFacility" } },
    name: "OptionsGroups",
    component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/setup/lookups/GroupsList.vue"),
    children: [
      {
        path: "create",
        name: "CreateOptionsGroup",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/SchemaModal.vue"),
        props: () => ({
          toFetch: false,
          title: "Add Options Group ",
          postUrl: "setup/lookups/0",
          // postUrl: "setup/lookups",
          optionsUrls: [],
          jsonSchema: "setup/forms/options-group",
          size: "max-w-xs",
        }),
      },
      {
        path: "edit/:id",
        title: "Edit Options Group ",
        name: "AmendOptionsGroup",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/SchemaModal.vue"),
        props: (x) => ({
          title: "Edit Options Group ",
          // postUrl: `setup/lookups/${x.params.id}`,
          postUrl: `setup/lookups/${x.params.id}`,
          optionsUrls: [],
          jsonSchema: "setup/forms/options-group",
          size: "max-w-xs",
        }),
      },
      {
        path: "delete/:optionGroupId/:optionGroupName",
        title: "Delete Option Group",
        name: "DeleteSetupOptionsGroup",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/DeleteModal.vue"),
        props: (x) => {
          let propData = {
            title: "Remove Option ",
            deleteUrl: `setup/lookups/${x.params.optionGroupId}`,
            size: "max-w-xs",
            name: "Option",
          };
          propData.title = `Delete '${x.params.optionGroupName}' option group?`;

          return propData;
        },
      },
    ],
  },
  {
    path: "/optionsgroups/:groupid/options",
    meta: { breadcrumb: { parent: "ManageFacility" } },
    name: "Options",
    props: (x) => ({
      title: x.params.title,
    }),
    component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/setup/lookups/OptionsList.vue"),
    children: [
      {
        path: "create",
        name: "CreateOption",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/SchemaModalWithExtraFields.vue"),
        props: (x) => ({
          toFetch: false,
          title: "Add Option",
          verb: "POST",
          postUrl: `setup/lookups/${x.params.groupid}`,
          // postUrl: `setup/lookups`,
          optionsUrls: [],
          jsonSchema: "setup/forms/option",
          size: "max-w-xs",
          extraFields: { parentLookupId: x.params.groupid },
        }),
      },
      {
        path: "edit/:id",
        title: "Edit Option ",
        name: "AmendOption",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/SchemaModalWithExtraFields.vue"),
        props: (x) => ({
          title: "Amend Option ",
          verb: "PUT",
          // postUrl: `setup/lookups/${x.params.id}`,
          postUrl: `setup/lookups/${x.params.id}`,
          optionsUrls: [],
          jsonSchema: "setup/forms/option",
          size: "max-w-xs",
          extraFields: { parentLookupId: x.params.groupid },
        }),
      },
      {
        path: "delete/:optionId/:optionName",
        title: "Delete Option",
        name: "DeleteSetupOption",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/components/data/DeleteModal.vue"),
        props: (x) => {
          let propData = {
            title: "Remove Option ",
            deleteUrl: `setup/lookups/${x.params.optionId}`,
            size: "max-w-xs",
            name: "Option",
          };
          propData.title = `Delete '${x.params.optionName}' option?`;

          return propData;
        },
      },
    ],
  },

  // Forms
  {
    path: "/setup/forms",
    name: "SetupForms",
    meta: { breadcrumb: { parent: "ManageFacility" } },
    component: () =>
      import(
        /* webpackChunkName: "doctors-pool-group" */
        "@/views/setup/Forms/List.vue"
      ),
    children: [
      {
        path: "create",
        name: "AddSetupForm",
        component: () =>
          import(
            /* webpackChunkName: "doctors-pool-group" */
            "@/components/data/JsonForm.vue"
          ),
        props: () => ({
          toFetch: false,
          title: "Add Form ",
          postUrl: "setup/forms",
        }),
      },
      {
        path: "edit/:id",
        title: "Update Form",
        name: "UpdateSetupForm",
        component: () =>
          import(
            /* webpackChunkName: "doctors-pool-group" */
            "@/components/data/JsonForm.vue"
          ),
        props: (x) => ({
          title: "Update Form ",
          postUrl: `setup/forms/${x.params.id}`,
        }),
      },
      {
        path: "delete/:id",
        title: "Delete Form",
        name: "DeleteSetupForm",
        component: () =>
          import(
            /* webpackChunkName: "doctors-pool-group" */
            "@/components/data/DeleteModal.vue"
          ),
        props: (x) => ({
          title: "Delete Form ",
          deleteUrl: `setup/forms/${x.params.id}`,
          size: "max-w-xs",
          name: "Delete Form",
        }),
      },
    ],
  },
  //health facilities
  {
    path: "/health-facilities",
    name: "HealthFacilities",
    meta: { breadcrumb: { parent: "ManageFacility" } },
    component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/setup/healthFacilities/HealthFacilities.vue"),
    children: [
      {
        path: "add-facility",
        name: "AddHealthFacility",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/setup/healthFacilities/CreateFacility.vue"),
        props: () => ({
          title: "Add Health Facility ",
          verb: "POST",
          postUrl: `setup/healthfacilities/post-health-facility`,
          options: [],
          optionsUrls: [],
          jsonSchema: "",
          size: "max-w-4xl",
        }),
      },
      {
        path: "edit/:id/:facilityName",
        name: "UpdateHealthFacility",
        component: () => import(/* webpackChunkName: "optionsgroups" */ "@/views/setup/healthFacilities/UpdateFacility.vue"),
        props: (x) => ({
          title: `Update ${x.params.facilityName}`,
          verb: "PUT",
          toFetch: true,
          fetchUrl: `setup/healthfacilities/${x.params.id}/get-health-facility-by-id`,
          postUrl: `setup/healthfacilities/${x.params.id}/update-health-facility`,
          options: [],
          optionsUrls: [""],
          jsonSchema: "",
          size: "max-w-4xl",
        }),
      },
    ],
  },
];
