<template>
  <div class="rounded bg-white py-2 px-6 shadow-lg md:py-6 md:px-12">
    <div class="flex items-center justify-between pb-4">
      <span class="flex flex-col items-center space-y-1">
        <img src="@/assets/coa.svg" alt="" class="w-12 md:w-16" />
        <span class="text-xs font-semibold uppercase tracking-wide text-sky-500 md:text-base">Ministry of Health</span>
      </span>
      <div class="flex flex-col items-center">
        <img src="@/assets/ktta_logo.svg" alt="logo" class="w-64" />
      </div>
    </div>
    <div class="grid place-items-center  py-8">
      <img alt="Brand" src="@/assets/g38.png" class="w-64" />
    </div>
    <div class="grid place-content-center">
      <damu-yetu-white class="w-48" />
    </div>
    <div class="py-6 ">
      <div class="mx-auto flex h-full max-w-7xl flex-col items-center justify-items-center px-4 sm:px-6 lg:px-8">
        <button @click.prevent="authenticateOidc" class="flex items-center space-x-2 rounded bg-primary-500  px-10 py-4 text-white hover:bg-primary-600 focus:outline-none focus:ring focus:ring-primary-500  focus:ring-offset-2">
          <span>Unlock</span>
          <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import DamuYetuWhite from "@/views/home/DamuYetuWhite.vue";
export default {
  components: { DamuYetuWhite },
  name: "account-lock",
  methods: {
    ...mapActions(["authenticateOidc"]),
  },
  mounted() {
    const backdrop = document.getElementById("backdrop");
    if (backdrop) {
      const body = document.body;
      body.classList.remove("overflow-hidden", "pr-17");
      body.removeChild(backdrop);
    }
  },
};
</script>
