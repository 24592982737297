<template>
  <div class="pikaday-container w-full">
    <input type="text" ref="input" :value="value" v-bind="$attrs" class="border border-gray-300 rounded-sm px-3 py-2 leading-none focus:border-green-500 outline-none border-box w-full mb-1" />
  </div>
</template>

<script>
import Pikaday from "pikaday";
import "pikaday/css/pikaday.css";

export default {
  props: {
    value: {
      required: true,
    },
    format: {
      default: "YYYY-MM-DD",
    },
    options: {
      default: () => ({}),
    },
  },
  mounted: function() {
    const picker = new Pikaday({
      field: this.$refs.input,
      format: this.format,
      onSelect: () => {
        this.$emit("input", picker.toString());
      },
      container: this.$refs.input.closest(".pikaday-container"),
      ...this.options,
    });
  },
};
</script>
