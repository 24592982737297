<template>
  <pikaday-picker :options="options" v-bind="[context.attributes]" :class="['w-full', context.classes.element]" :data-type="context.type" @blur="context.blurHandler" v-on="$listeners" v-model="context.model"></pikaday-picker>
</template>
<script>
import PikadayPicker from "./pikaday-picker.vue";
export default {
  components: {
    PikadayPicker,
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    options: {
      yearRange: [new Date().getFullYear() - 70, new Date().getFullYear() - 16],
    },
  }),
};
</script>
