import vue from "vue"
import vuex from "vuex"
import areas from "@/store/modules/areas"
import blood_bank from "@/store/modules/blood_bank"
import donor_counselling from "@/store/modules/donor_counselling"
import transfusion from "@/store/modules/transfusion"
import navigation from "@/store/modules/navigation"
import client from "@/store/modules/client"
import wizard from "@/store/modules/wizard";
import oidcSettings from "@/config/oidcSettings"
import { vuexOidcCreateStoreModule } from "vuex-oidc"
import { WebStorageStateStore } from 'oidc-client';
import router from "@/router/index";
vue.use(vuex);

export default new vuex.Store({
    modules: {
        areas,
        blood_bank,
        transfusion,
        donor_counselling,
        navigation,
        client,
        wizard,
        oidcStore: vuexOidcCreateStoreModule(
            //oidcSettings,
            // NOTE: If you do not want to use localStorage for tokens, in stead of just passing oidcSettings, you can
            // spread your oidcSettings and define a userStore of your choice
            {
                ...oidcSettings,
                userStore: new WebStorageStateStore({ store: window.sessionStorage })
            },
            // Optional OIDC store settings
            {
                namespaced: false,
                dispatchEventsOnWindow: true
            },
            // Optional OIDC event listeners
            {
                userLoaded: (user) => console.log('OIDC user is loaded:', user),
                userUnloaded: () => console.log('OIDC user is unloaded'),
                accessTokenExpiring: () => console.log('Access token will expire'),
                accessTokenExpired: () => {
                    console.log('Access token did expire');
                    if (router.currentRoute.name !== "Home") {
                        areas.state.currentAreaName = "Home";
                        // router.push("/");

                    }
                },
                silentRenewError: () => console.log('OIDC user is unloaded'),
                userSignedOut: () => console.log('OIDC user is signed out'),
                oidcError: (payload) => console.log('OIDC error', payload),
                automaticSilentRenewError: (payload) => console.log('OIDC automaticSilentRenewError', payload)
            }
        )
    }
})