export default [
    {
        path: "/inventory/items",
        name: "ManageStoreItems",
        component: () => import(/* webpackChunkName: "store" */ "@/views/inventory/ItemsList.vue"),
        children: [
         
        
        ], 

    },
    {
        path: "/inventory/stock",
        name: "ManageStoreStock",
        component: () => import(/* webpackChunkName: "store" */ "@/views/inventory/DepartmentStockList.vue"),
        children: [
            {
                path: "daily-stock-report/:itemName/:itemId",
                name: "AddStockDailyReport",
                component: () => import(/* webpackChunkName: "store" */  "@/views/inventory/components/SchemaModal.vue"),
                props: (x) => ({
                    toFetch: false,
                    title: `Add Daily Stock Report for the ${x.params.itemName}`,
                    postUrl: `store/Stock/dailyReport/stockItem/${x.params.itemId}`,
                    optionsUrls: [],
                    jsonSchema: "store/forms/daily-stock-report",
                    size: "max-w-xl",
                    generateDate:true
                })
            },
        
        ], 
    },
    {
        path: "/inventory/stock/:stockItemId/daily-reports",
        name: "ViewStoreStockReports",
        component: () => import(/* webpackChunkName: "store" */ "@/views/inventory/StockReportsByStockItem.vue"),
        props:(x) =>({
            stockItemId:x.params.stockItemId
        }),
        children: [
        ], 
    },
    {
        path: "/inventory/batches",
        name: "ManageStoreBatches",
        component: () => import(/* webpackChunkName: "store" */ "@/views/inventory/BatchList.vue"),
        children: [
            {
                path: "add",
                name: "AddStoreBatch",
                component: () => import(/* webpackChunkName: "store" */ "@/views/inventory/ReceiveBatch.vue"),
                props: () => ({
                    toFetch: false,
                    title: "Receive a Batch",
                    postUrl: `store/CommodityItemItems`,
                    optionsUrls: ['store/CommodityItems/options', 'store/Lookups/conditon-received/options'],
                    jsonSchema: "store/forms/receive-batch",
                    size: "w-1/2"
                })
            },
        
            {
                path: "update/:id",
                name: "UpdateStoreBatch",
                component: () => import(/* webpackChunkName: "store" */ "@/views/inventory/ReceiveBatch.vue"),
                props: (y) => ({
                    toFetch: true,
                    title:"Update Batch",
                    postUrl: `store/CommodityItemItems/${y.params.id}`,
                    optionsUrls: ['store/CommodityItems/options', 'store/Lookups/conditon-received/options'],
                    jsonSchema: "store/forms/receive-batch",
                    size: "max-w-3xl"
                })
            },     
        
        ], 

    },

    {
        path: "/inventory/items/:commodityItemId/batches",
        name: "ViewBatchByItem",
        component: () => import(/* webpackChunkName: "store" */ "@/views/inventory/BatchesByItemList.vue"),
        props: (x) => ({
            commodityItemId: x.params.commodityItemId,
            
        }),
        children: [

        ], 

    },
    {
        path: "/inventory/sent-requisitions",
        name: "SentStoreOrders",
        component: () => import(/* webpackChunkName: "store" */ "@/views/inventory/StoreOrdersList.vue"),
        props: () => ({
            routeLinkName: 'viewStoreOrder',
            showCreateValue:true
            
        }),
        children:[
            {
                path: "add",
                name: "addStoreOrder",
                component: () => import(/* webpackChunkName: "store" */ "@/views/inventory/components/SchemaModal.vue"),
                props: () => ({
                    toFetch: false,
                    title: "Request for Items",
                    postUrl: `store/Requisitions`,
                    optionsUrls: ['store/Lookups/departments/options','store/CommodityItems/options'],
                    jsonSchema: "store/forms/create-requisition",
                    size: "max-w-3xl",
                    generateDateTime:true,
                    generateUserName:true,
                    generateDepartment:true
                })
            },
            
        ]
    },
    {
        path: "/inventory/sent-requisitions/:orderId/view",
        name: "viewStoreOrder",
        component: () => import(/* webpackChunkName: "store" */ "@/views/inventory/StoreOrder.vue"),
        props: (x) => ({
            orderId: x.params.orderId,
            edit:false
        }),
        children:[
            {
                path: "cancel/:requisitionNumber",
                name: "CancelStoreRequisition",
                component: () => import(/* webpackChunkName: "store" */ "@/views/inventory/components/SchemaModal.vue"),
                props: (x) => ({
                    toFetch: false,
                    title: "Cancel Requistion",
                    postUrl: `store/Requisitions/cancelrequisition/${x.params.requisitionNumber}`,
                    optionsUrls: [],
                    jsonSchema: "store/forms/cancel-requisition",
                    size: "max-w-3xl",
                    
                })
            },
        ]
    },
    {
        path: "/inventory/received-requisitions",
        name: "ProcessStoreOrders",
        component: () => import(/* webpackChunkName: "store" */ "@/views/inventory/StoreOrdersList.vue"),
        props: (x) => ({
            routeLinkName: 'processStoreOrder',
            showCreateValue:false,
            orderId: x.params.orderId,
        }),
    },
    {
        path: "/inventory/received-requisitions/:orderId",
        name: "processStoreOrder",
        component: () => import(/* webpackChunkName: "store" */ "@/views/inventory/StoreOrder.vue"),
        props: (x) => ({
            orderId: x.params.orderId,
        }),
        children:[
            {
                path: ":itemsReceivedGuid/issue/:quantityToIssue/:itemName/id/:commodityItemId",
                name: "issueStoreItemBatch",
                component: () => import(/* webpackChunkName: "store" */ "@/views/inventory/IssueItemsFromBatches.vue"),
                props: (x) => ({
                
                    toFetch: false,
                    title: `Issue ${x.params.quantityToIssue} Items of ${x.params.itemName}`,
                    postUrl: `store/Requisitions/process/${x.params.itemsReceivedGuid}`,
                    optionsUrls:[`store/CommodityItemItems/options/commodityItem/${x.params.commodityItemId}`],
                    commodityItemId:x.params.commodityItemId,
                    size: "w-3/5",
                    jsonSchema: "store/forms/issue-items",


                })
            },
            {
                path: ":requisitionNumber/dispatch",
                name: "DispatchStoreItems",
                component: () => import(/* webpackChunkName: "store" */ "@/views/inventory/DispatchItems.vue"),
                props: (x) => ({
                
                    toFetch: false,
                    title: `Dispatch Items`,
                    postUrl: `store/Requisitions/dispatch/${x.params.requisitionNumber}`,
                    optionsUrls:[`store/CommodityItemItems/options/commodityItem/${x.params.commodityItemId}`],
                    size: "w-1/2",
                    orderId: x.params.orderId,   

                })
            },
            {
                path: "close-requisiton/:requisitionNumber",
                name: "CloseStoreRequisiton",
                component: () =>
                    import (
                        /* webpackChunkName: "Transfusion" */
                        "@/components/data/ConfirmPostModal.vue"
                    ),
                props: (x) => ({
                    verb:"POST",
                    title: "Close Requisition",
                    postUrl: `store/Requisitions/closerequisition/${x.params.requisitionNumber}`,
                    size: "max-w-sm",
                }),
            },
        ]
    },
    {
        path: "/inventory/sent-label-requests",
        name: "StoreSentLabelRequests",
        component: () => import(/* webpackChunkName: "store" */ "@/views/inventory/LabelRequestsList.vue"),
        props: () => ({
           showCreate:true
        }),
        children: [
            {
                path: "add",
                name: "AddStoreLabelRequest",
                component: () => import(/* webpackChunkName: "store" */ "@/views/inventory/components/SchemaModal.vue"),
                props: () => ({
                    toFetch: false,
                    title: "Request for Labels",
                    postUrl: `store/ItemLabels/createrequest`,
                    optionsUrls: [],
                    jsonSchema: "store/forms/request-for-labels",
                    size: "max-w-lg",
                    generateFacilityCode:true,
                    generateDateTime:true,
                    generateUserName:true
                })
            }

        ], 

    }, 
    {
        path: "/inventory/received-label-requests",
        name: "StoreReceivedLabelRequests",
        component: () => import(/* webpackChunkName: "store" */ "@/views/inventory/LabelRequestsList.vue"),
        props: () => ({
            processRequest:true
        }),
        children: [
            {
                path: ":labelRequestId/process-label-request/region/:regionCode/quantity/:quantity/request/:requestNumber",
                name: "ProcessLabelRequest",
                component: () => import(/* webpackChunkName: "store" */ "@/views/inventory/components/SchemaModal.vue"),
                props: x => ({
                    toFetch: false,
                    title: `Generate Labels:  ${x.params.requestNumber}`,
                    postUrl: `store/ItemLabels/generateLabels/${x.params.labelRequestId}`,
                    optionsUrls: [],
                    jsonSchema: "store/forms/generate-labels",
                    size: "max-w-lg",
                    fetchRouterParams: true
               
                })
            }

        ], 

    }, 
      
    {
        path: "/inventory/received-label-requests/:labelRequestId/print-labels",
        name: "ViewStoreLabelsByRequest",
        component: () => import(/* webpackChunkName: "store" */ "@/views/inventory/LabelsByRequestList.vue"),
        props: (x) => ({
            labelRequestId: x.params.labelRequestId,
            
        }),
        children: [

        ], 

    },
    {
        path: "/inventory/categories",
        name: "StoreItemCategories",
        component: () => import(/* webpackChunkName: "store" */ "@/views/inventory/CategoriesList.vue"),
        children: [
            {
                path: "add",
                name: "AddInventoryItemCategory",
                component: () => import(/* webpackChunkName: "store" */ "@/views/inventory/components/SchemaModal.vue"),
                props: () => ({
                    toFetch: false,
                    title: "Add Category",
                    postUrl: `store/Categories/0`,
                    optionsUrls: [],
                    jsonSchema: "store/forms/add-category",
                    size: "max-w-lg"
                })
            },
            {
                path: "update:id",
                name: "updateStoreItemCategory",
                component: () => import(/* webpackChunkName: "store" */ "@/views/inventory/components/SchemaModal.vue"),
                props: (y) => ({
                    toFetch: true,
                    title: "Update Category",
                    postUrl: `store/Categories/${y.params.id}`,
                    optionsUrls: [],
                    jsonSchema: "store/forms/add-category",
                    size: "max-w-lg"
                })
            },

        ], 

    }, 
    {
        path: "/inventory/categories/:categoryId/subcategories",
        name: "viewStoreSubCategories",
        props: x => ({
           
            categoryId: x.params.categoryId,
        }),
        component: () => import(/* webpackChunkName: "store" */ "@/views/inventory/SubCategoriesList.vue"),
        children: [
            {                                                                                                                                                                                                                                                             
                path: "add",
                name: "addStoreItemSubCategories",
                component: () => import(/* webpackChunkName: "store" */ "@/views/inventory/components/SchemaModal.vue"),
                props: (x) => ({
                    toFetch: false,
                    title: "Add a Sub-category",
                    postUrl: `store/Categories/${x.params.categoryId}`,
                    optionsUrls: [],
                    jsonSchema: "store/forms/add-category",
                    size: "max-w-lg"
                })
            },
            {
                path: ":id/update",
                name: "updateStoreItemSubCategories",
                component: () => import(/* webpackChunkName: "store" */ "@/views/inventory/components/SchemaModal.vue"),
                props: (y) => ({
                    toFetch: true,
                    title: "Update Category",
                    postUrl: `store/Categories/${y.params.id}`,
                    optionsUrls: [],
                    jsonSchema: "store/forms/add-category",
                    size: "max-w-lg"
                })
            },

        ], 

    }, 
    {
        path: "/inventory/categories/:categoryId/items",
        name: "viewStoreItems",
        props: x => ({
           
            categoryId: x.params.categoryId,
            genericStatus: x.params.genericStatus
        }),
        component: () => import(/* webpackChunkName: "store" */ "@/views/inventory/ItemsByCategoryList.vue"),
        children: [
            {
                path: "add",
                name: "addStoreItem",
                component: () => import(/* webpackChunkName: "store" */ "@/views/inventory/AddStoreItem.vue"),
                props: (y) =>({
                    toFetch: false,
                    title:  "Add Item",
                    postUrl: `store/CommodityItems/commodityCategory/${y.params.categoryId}`,
                    optionsUrls: [], 
                    jsonSchema:  "store/forms/add-store-item" ,
                    size: "w-3/5"
                })
            },
            {
                path: "update/:id",
                name: "UpdateStoreItem",
                component: () => import(/* webpackChunkName: "store" */  "@/views/inventory/AddStoreItem.vue"),
                props: (y) => ({
                    toFetch: true,
                    title: "Update Item",
                    postUrl: `store/CommodityItems/${y.params.id}`,
                    optionsUrls: [], 
                    jsonSchema:  "store/forms/add-store-item" ,
                    size: "w-3/5"
                })
            },

        ], 

    }, 

    {
        path: "/inventory/options",
        name: "ManageStoreOptions",
        props: x => ({
           
            categoryId: x.params.categoryId,
            genericStatus: x.params.genericStatus
        }),
        component: () => import(/* webpackChunkName: "store" */ "@/views/inventory/StoreOptionGroupList.vue"),
        children: [
            {
                path: "add",
                name: "addStoreOptionGroup",
                component: () => import(/* webpackChunkName: "store" */ "@/views/inventory/components/SchemaModal.vue"),
                props: () =>({ 
                    toFetch: false,
                    title:   "Add Option Group",
                    postUrl: `store/Lookups/0`,
                    optionsUrls: [],
                    jsonSchema: "store/forms/add-option-group",
                    size: "max-w-xl"
                })
            },
            {
                path: "update/:id",
                name: "updateStoreOptionGroup",
                component: () => import(/* webpackChunkName: "store" */ "@/views/inventory/components/SchemaModal.vue"),
                props: (y) => ({
                    toFetch: true,
                    title: "Update Option Group",
                    postUrl: `store/Lookups/${y.params.id}`,
                    optionsUrls: [],
                    jsonSchema: "store/forms/add-option-group",
                    size: "max-w-md"
                })
            },

        ], 
    },
    {
        path: "/inventory/optionGroup/:optionGroupId/options",
        name: "viewStoreOptions",
        props: x => ({
           
            optionGroupId: x.params.optionGroupId
           
        }),
        component: () => import(/* webpackChunkName: "store" */ "@/views/inventory/StoreOptionsList.vue"),
        children: [
            {
                path: "add",
                name: "addStoreOption",
                component: () => import(/* webpackChunkName: "store" */ "@/views/inventory/components/SchemaModal.vue"),
                props: (y) =>({ 
                    toFetch: false,
                    title:   "Add Option",
                    postUrl: `store/Lookups/${y.params.optionGroupId}`,
                    optionsUrls: [],
                    jsonSchema: "store/forms/add-option",
                    size: "max-w-xl"
                })
            },
            {
                path: "update/:id",
                name: "updateStoreOption",
                component: () => import(/* webpackChunkName: "store" */ "@/views/inventory/components/SchemaModal.vue"),
                props: (y) => ({
                    toFetch: true,
                    title: "Update Option",
                    postUrl: `store/Lookups/${y.params.id}`,
                    optionsUrls: [],
                    jsonSchema: "store/forms/add-option",
                    size: "max-w-md"
                })
            },

        ], 
    },
     // Store forms
     {
        path: "/inventory/forms",
        name: "InventoryForms",
        component: () =>
            import (
                /* webpackChunkName: "doctors-pool-group" */
                "@/views/inventory/FormsList.vue"
            ),
        children: [{
                path: "create",
                name: "AddinventoryForm",
                component: () =>
                    import (
                        /* webpackChunkName: "doctors-pool-group" */
                        "@/components/data/JsonForm.vue"
                    ),
                props: () => ({
                    verb:"POST",
                    toFetch: false,
                    title: "Add Form ",
                    postUrl: "store/Forms",
                }),
            },
            {
                path: "edit/:id",
                title: "Update Form",
                name: "UpdateinventoryForm",
                component: () =>
                    import (
                        /* webpackChunkName: "doctors-pool-group" */
                        "@/components/data/JsonForm.vue"
                    ),
                props: (x) => ({
                    verb: "PUT",
                    title: "Update Form ",
                    toFetch: true,
                    postUrl: `store/forms/${x.params.id}`,
                }),
            },
            {
                path: "delete/:id",
                title: "Delete Form",
                name: "DeleteinventoryForm",
                component: () =>
                    import (
                        /* webpackChunkName: "doctors-pool-group" */
                        "@/components/data/DeleteModal.vue"
                    ),
                props: (x) => ({
                    verb:"DELETE",
                    title: "Delete Form ",
                    deleteUrl: `store/forms/${x.params.id}`,
                    size: "max-w-xs",
                    name: "Delete Form",
                }),
            },
        ],
    },

]