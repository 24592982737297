<template>
    <div>
        <ejs-grid ref="egrid" :dataSource="fetchedData" gridLines='Both' :toolbar='toolbarOptions' :allowPdfExport='true' :allowTextWrap='true' :textWrapSettings='wrapSettings' rowHeight=60>
            <e-columns>
            <e-column :field="$reportType(selectedReport).type" :headerText=$reportType(selectedReport).name
                :valueAccessor='removeHyphen' width=150 textAlign="center" freeze='Left'></e-column>
            <e-column :field="$reportType(selectedReport).data + '.0.' + $reportType(selectedReport).value"
                headerText='Jan' textAlign="center"></e-column>
            <e-column :field="$reportType(selectedReport).data + '.1.' + $reportType(selectedReport).value"
                headerText='Feb' textAlign="center"></e-column>
            <e-column :field="$reportType(selectedReport).data + '.2.' + $reportType(selectedReport).value"
                headerText='March' textAlign="center"></e-column>
            <e-column :field="$reportType(selectedReport).data + '.3.' + $reportType(selectedReport).value"
                headerText='April' textAlign="center"></e-column>
            <e-column :field="$reportType(selectedReport).data + '.4.' + $reportType(selectedReport).value"
                headerText='May' textAlign="center"></e-column>
            <e-column :field="$reportType(selectedReport).data + '.5.' + $reportType(selectedReport).value"
                headerText='June' textAlign="center"></e-column>
            <e-column :field="$reportType(selectedReport).data + '.6.' + $reportType(selectedReport).value"
                headerText='July' textAlign="center"></e-column>
            <e-column :field="$reportType(selectedReport).data + '.7.' + $reportType(selectedReport).value"
                headerText='Aug' textAlign="center"></e-column>
            <e-column :field="$reportType(selectedReport).data + '.8.' + $reportType(selectedReport).value"
                headerText='Sept' textAlign="center"></e-column>
            <e-column :field="$reportType(selectedReport).data + '.9.' + $reportType(selectedReport).value"
                headerText='Oct' textAlign="center"></e-column>
            <e-column :field="$reportType(selectedReport).data + '.10.' + $reportType(selectedReport).value"
                headerText='Nov' textAlign="center"></e-column>
            <e-column :field="$reportType(selectedReport).data + '.11.' + $reportType(selectedReport).value"
                headerText='Dec' textAlign="center"></e-column>
            <e-column field='total' headerText='Yearly' width=150 textAlign="center" freeze='Right'></e-column>
            </e-columns>
      </ejs-grid>
    </div>
</template>


<script>
import vue from 'vue';
import { GridPlugin, Freeze, Page, PdfExport, Toolbar} from '@syncfusion/ej2-vue-grids';
// import "../assets/bootstrap5.css";

vue.use(GridPlugin);

export default {
  props: {
    selectedReport: String,
    fetchedData: [Array, Object],
    url: String,
  },
 
  data() {
    return {
      loading: false,
      wrapSettings: { wrapMode: 'Content' },
      pageOptions: { pageSize: this.fetchedData.pageSize, pageCount: 4 },
      data: { 
        result: this.fetchedData.results, 
        count: this.fetchedData.totalItems
        },
      months: ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
    };
  },
  provide: {
    grid: [Freeze, Page, Toolbar, PdfExport]
  },
  methods: {
    removeHyphen: function(field, data) {
      const components = { 
        'monthly-donations-by-age-report': data[field],
        'monthly-blood-grouping': this.$toUppercase(data[field]),
       }
      return components[this.selectedReport] === undefined ? this.$removeHyphens(data[field]) : components[this.selectedReport]
    },
    dataStateChange: function () {
        var grid = document.getElementsByClassName("e-grid")[0].ej2_instances[0];
        this.fetchData(grid.pagerModule.pagerObj.currentPage)
        this.$refs.egrid.$el.hidden = true
        // 
    },
    fetchData(page){
        this.loading = true;
        this.$callApi
          .get(this.url + '&page=' + page, {
            params: { ...this.$route.query },
          })
          .then((x) => {
            this.$refs.egrid.$el.hidden = false
            var grid = document.getElementsByClassName("e-grid")[0].ej2_instances[0];
            grid.dataSource = {result: x.data.data.results, count: x.data.data.totalItems}
            this.data.result = x.data.data.results
            this.data.count = x.data.data.totalItems
          })
          .catch(() => {
            //this.error = err;
          })
          .then(() => (this.loading = false));
    },
    downloadPDF() {
        let pdfExportProperties = {
                pageOrientation: 'Landscape',
            };
        this.$refs.egrid.pdfExport(pdfExportProperties);
    }
},
}
</script>
