<template>
    <div>
        <component :is="getComponent(selectedReport, custom)" :fetchedData="fetchedData" :selectedReport="selectedReport" :totalResult="totalResult" :url="url" :header="$removeHyphensCapitalize(appliedFilters || selectedReport)"></component>
    </div>
</template>

<script>
    import CustomMonthlyRows from "@/components/custom/monthlyReports/CustomRows.vue";
    import MonthlyWithPagination from "@/components/custom/monthlyReports/WithPagination.vue";
    import MonthlyView from "@/components/custom/monthlyReports/MonthlyView.vue";
    import CustomMonthlyHeader from "@/components/custom/monthlyReports/CustomHeader.vue";

    import NoData from "@/components/custom/dailyReports/NoData.vue";
    import DailyReport from "@/components/custom/dailyReports/DailyReport.vue";

    export default {
        props: {
            selectedReport: String,
            fetchedData: [Array, Object],
            totalResult: [Array, Object],
            url: String,
            custom: Boolean,
            appliedFilters: String,
        },
        methods: {
            getComponent: (selected, custom) => {
                const components = {
                    "monthly-Donor-Deferrals": CustomMonthlyRows,
                    "Monthly-Blood-Issued-Requested-Ratio": CustomMonthlyRows,
                    "monthly-donations-by-county": MonthlyWithPagination,
                    "monthly-Site-Donation-Report": MonthlyWithPagination,
                    "Monthly-Discarded-Blood": MonthlyWithPagination,
                    "monthly-samples-tested-TTI": MonthlyWithPagination,
                    "Components-Available": CustomMonthlyHeader,
                    "post-outreach-session-analysis": DailyReport,
                    "donors-report": DailyReport,
                    "Outreach-Sessions-analysis": DailyReport,
                    "donation-by-type": DailyReport,
                    "donation-by-frequency": DailyReport,
                    "donation-by-location": DailyReport,
                    "static-outreach-donations": DailyReport,
                    "donor-deferal": DailyReport,
                };
                return components[selected] === undefined ? (custom ? NoData : MonthlyView) : components[selected];
            },
        },
    };
</script>
