<template>
    <vue-select placeholder="Type facility from..." :class="context.classes.element" :data-type="context.type" :options="options" :required="context.errors" @input="context.blurHandler" v-on="$listeners" @search="onSearch" v-bind="context.attributes" v-model="context.model" :filterable="false">
        <template slot="no-options">
            Type to search...
        </template>
    </vue-select>
</template>
<script>
import { debounce } from "lodash";
import vSelect from "vue-select";
export default {
    props: {
        context: {
            type: Object,
            required: true,
        },
    },
    components: {
        "vue-select": vSelect,
    },
    data: () => ({
        options: [],
    }),
    methods: {
        setSelected(v) {
            this.context.model = v;
        },
        onSearch(search, loading) {
            this.options = [];
            if (search.length) {
                loading(true);
                this.search(loading, search, this);
            }
        },
        search: debounce((loading, search, vm) => {
            vm.$callApi
                .get(`screening/samplebatches/${search}`)
                .then(x => {
                    vm.options = x.data;
                })
                .catch(x => {
                    console.log(x);
                })
                .then(() => {
                    loading(false);
                });
        }, 350),
    },
};
</script>
