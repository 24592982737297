<template>
  <div class="h-full">
    <div class="rounded grid grid-rows-layout h-full w-full" v-show="oidcIsAuthenticated">
      <div>
      <div class="px-8 pt-4">
        <div class="grid grid-cols-12 gap-2 md:gap-4 items-center ">
          <div class="col-span-6">
            <h3 class="border-b-2 border-primary-300 py-4 space-x-2 inline-flex items-center text-primary-400  -mb-px">
              <button
                type="button"
                v-if="!!showBack"
                @click.prevent="back"
                class="text-6xl hover:bg-primary-200 transition p-2 hover:text-primary-600 rounded-full text-primary-500 bg-transparent outline-none focus:outline-none flex items-center justify-center"
                title="Back"
              >
                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"></path>
                </svg>
              </button>
              <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 md:w-5 md:h-5" fill="currentColor" viewBox="0 0 16 16">
                <path
                  d="M1 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2zM1 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7zM1 12a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2z"
                />
              </svg>
              <span class="text-xs text-primary-700 md:text-base   font-normal  uppercase tracking-wider">{{ title }}</span>
            </h3>
          </div>
          <div class="col-span-6 flex items-center justify-end space-x-1">
            <span class="font-semibold italic">Filter:</span>
            <select
              @change="filter"
              v-model="status"
              placeholder="select status"
              :class="[
                'rounded-xl  border border-gray-300 font-semibold focus:border-blue-100',
                status === 'Pending Centrifuging' ? 'bg-yellow-100 text-yellow-600' : status === 'Centrifuged' ? 'text-blue-600 bg-blue-100' : status === 'Complete' ? 'text-green-600 bg-green-100' : 'bg-auto',
              ]"
            >
              <option value="" class="bg-white font-semibold">All vacutainers</option>
              <option value="Pending Centrifuging" class="bg-yellow-100 text-yellow-600 font-semibold">Pending Centrifuging</option>
              <option value="Centrifuged" class="text-blue-600 bg-blue-100 font-semibold">Centrifuged, awaiting results</option>
              <option value="Complete" class="text-green-600 bg-green-100 font-semibold">Complete, awaiting result submission</option>
            </select>
            <div
              class="text-gray-500 w-full md:w-1/2 transition duration-300 flex items-center border-gray-300 border focus-within:shadow-md rounded focus-within:border-opacity-25 focus-within:border-primary-300 focus-within:text-gray-900  focus-within:ring-1 focus-within:ring-primary-600"
            >
              <input @input="search" type="search" v-model="q" placeholder="Search..." class="outline-none px-3 w-full placeholder-gray-400 rounded-l py-1  border-0 border-none focus:ring-0 " @keyup.enter="search" />
              <button @click="search" class="p-2 focus:outline-none">
                <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                </svg>
              </button>
            </div>
            <router-link
              v-if="!!showCustomAction"
              :to="{ name: customActionName }"
              class=" px-2 py-2 bg-primary-50 rounded flex items-center text-sm text-primary-500 focus:outline-none space-x-1 hover:bg-primary-400 hover:text-white"
              :title="createTitle"
            >
              <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="w-4 h-4" viewBox="0 0 384 512">
                <defs />
                <path
                  fill="currentColor"
                  d="M377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-153 31V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm64 160v48c0 4.4-3.6 8-8 8h-56v56c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8v-56h-56c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h56v-56c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v56h56c4.4 0 8 3.6 8 8z"
                />
              </svg>
              <span class="hidden md:block">{{ customActionTitle }}</span>
            </router-link>
            <router-link v-if="!!showCreate" :to="{ name: createName }" class=" px-2 py-2 bg-primary-50 rounded flex items-center text-sm text-primary-500 focus:outline-none space-x-1 hover:bg-primary-400 hover:text-white" :title="createTitle">
              <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="w-4 h-4" viewBox="0 0 384 512">
                <defs />
                <path
                  fill="currentColor"
                  d="M377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-153 31V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm64 160v48c0 4.4-3.6 8-8 8h-56v56c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8v-56h-56c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h56v-56c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v56h56c4.4 0 8 3.6 8 8z"
                />
              </svg>
              <span class="hidden md:block">{{ createTitle }}</span>
            </router-link>
          </div>
        </div>
      </div>
      
      <div class="border-b"></div>

      </div>
      <div class="h-full border-b border-gray-100 ">
        <div v-if="loading" class="flex items-center justify-center">
          <loading-indicator></loading-indicator>
        </div>
        <div v-if="hasList" class="flex-1">
          <div class=" border-b  border-gray-100 divide-y divide-gray-100">
            <div class="px-8 py-4">
              <span class="font-semibold space-x-2 items-center py-1 capitalize" v-if="this.$router.currentRoute.query.status === 'Pending Centrifuging'">
                <input type="checkbox" class="text-primary-400 focus:ring-primary-400" v-model="selectAllForCentrifuging" id="allForCentrifuging" />
                <span>Select all</span>
              </span>

              <span class="font-semibold space-x-2 items-center py-1 capitalize" v-if="this.$router.currentRoute.query.status === 'Complete'">
                <input type="checkbox" class="text-primary-400 focus:ring-primary-400" v-model="selectAllForCompletion" id="allForCompletion" />
                <span>Select all</span>
              </span>
            </div>
            <div v-for="(item, index) in pagedResult.results" :key="item.id" class="flex space-x-2 items-center px-8 py-4 transition-all hover:bg-gray-50 ">
              <span class="flex-initial">
                <input
                  type="checkbox"
                  @click="addToCentrifugeList(item.entityGuid, index)"
                  v-model="selectedForCentrifuge"
                  :id="`toCentrifuge${index}`"
                  :class="[item.status === 'Pending Centrifuging' ? 'text-primary-400 focus:ring-primary-400 visible' : 'invisible']"
                  :value="item.entityGuid"
                  title="Click to mark as centrifuged"
                />

                <input
                  type="checkbox"
                  @click="addToCompleteList(item.entityGuid, index)"
                  v-model="selectedForComplete"
                  :id="`toComplete${index}`"
                  :value="item.entityGuid"
                  :class="[!item.resultSent && item.status === 'Complete' ? 'text-primary-400 focus:ring-primary-400 visible' : 'invisible']"
                  title="Click to send results"
                />
              </span>
              <span class="flex-auto">
                <slot v-bind:index="index" v-bind:item="item"></slot>
              </span>
            </div>
          </div>
        </div>
        <div v-if="!hasList && !loading" class="h-full  flex items-center justify-center">
          <span class="text-xl text-yellow-600 font-semibold animate-pulse self-center">No records found.</span>
        </div>
      </div>

      <div>
        <div class="px-8  py-4 bg-gray-100/60 flex ">
          <nav v-if="!isEmpty(pagedResult)" class="mx-auto">
            <div class="flex items-center">
              <span v-if="pagedResult.currentPage === 1" class="w-full p-4 border text-base rounded-l-xl text-gray-600 bg-gray-50/60 ">
                <svg width="9" height="8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 19l-7-7 7-7m8 14l-7-7 7-7"></path></svg>
              </span>
              <router-link v-else :to="{ query: { page: 1 } }" class="w-full p-4 border text-base rounded-l-xl text-gray-600 bg-white hover:bg-gray-100">
                <svg width="9" height="8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 19l-7-7 7-7m8 14l-7-7 7-7"></path></svg>
              </router-link>
              <span v-if="pagedResult.currentPage === 1" class="w-full p-4 border-y border-r text-base  text-gray-600 bg-gray-50/60">
                <svg width="9" height="8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>
              </span>
              <router-link v-else :to="{ query: { page: pagedResult.currentPage - 1 } }" class="w-full p-4 border-y border-r text-base text-gray-600 bg-white hover:bg-gray-100">
                <svg width="9" height="8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>
              </router-link>
              <template v-for="page in pagedResult.pages">
                <span v-if="page === pagedResult.currentPage" :key="page" class="w-full px-4 py-2 odd:border-y even:border  text-base text-white bg-primary-400 border-primary-400 ">
                  {{ page }}
                </span>
                <router-link :to="{ query: { page: page } }" v-else :key="page" class="w-full px-4 py-2 even:border odd:border-y text-base text-gray-600 bg-white hover:bg-gray-100 hover:text-primary-400 ">
                  {{ page }}
                </router-link>
              </template>

              <span v-if="pagedResult.currentPage === pagedResult.totalPages" class="w-full p-4 border-y border-r text-base  text-gray-600 bg-gray-50/60">
                <svg width="9" height="8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
              </span>
              <router-link v-else :to="{ query: { page: pagedResult.currentPage + 1 } }" class="w-full p-4 border-y border-r text-base text-gray-600 bg-white hover:bg-gray-100">
                <svg width="9" height="8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
              </router-link>
              <span v-if="pagedResult.currentPage === pagedResult.totalPages" class="w-full p-4 border-y border-r text-base rounded-r-xl text-gray-600 bg-gray-50/60 ">
                <svg width="9" height="8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5l7 7-7 7M5 5l7 7-7 7"></path></svg>
              </span>
              <router-link v-else :to="{ query: { page: pagedResult.totalPages } }" class="w-full p-4 border-y border-r text-base rounded-r-xl text-gray-600 bg-white hover:bg-gray-100 hover:text-primary-400">
                <svg width="9" height="8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5l7 7-7 7M5 5l7 7-7 7"></path></svg>
              </router-link>
            </div>
          </nav>
          <span v-if="vacutainersToCentrifuge && pagedResult"> 
            <span v-if="pagedResult.results">
              <div class="grid justify-items-end" v-if="vacutainersToCentrifuge.vacutainers.length > 0">
                <RouteLink
                  :route="{ name: 'CentrifugeGroupingVacutainers', params: { vacutainers: this.vacutainersToCentrifuge } }"
                  class="flex-auto transition rounded-md text-center py-2 px-2 bg-blue-500 flex items-center text-xs text-white font-mono hover:bg-blue-400 hover:text-blue-900 focus:outline-none transform hover:-translate-y-px hover:scale-110"
                  title="Click to centrifuge"
                  v-if="pagedResult.results[0].type === 'purple-top'"
                >
                  <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                  </svg>
                  <p class="px-2 text-lg font-medium capitalize">Mark as centrifuged</p>
                </RouteLink>
                <RouteLink
                  :route="{ name: 'CentrifugeScreeningVacutainers', params: { vacutainers: this.vacutainersToCentrifuge } }"
                  class="flex-auto transition rounded-md text-center py-2 px-2 bg-blue-500 flex items-center text-xs text-white font-mono hover:bg-blue-400 hover:text-blue-900 focus:outline-none transform hover:-translate-y-px hover:scale-110"
                  title="Click to centrifuge"
                  v-if="pagedResult.results[0].type === 'red-top'"
                >
                  <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                  </svg>
                  <p class="px-2 text-lg font-medium capitalize">Mark as centrifuged</p>
                </RouteLink>
              </div>
            </span>
          </span>
          <span v-if="vacutainersToComplete && pagedResult">
            <span v-if="pagedResult.results">
              <div class="grid justify-items-end" v-if="vacutainersToComplete.vacutainers.length > 0">
                <!-- <button class="text-blue-500 bg-brandblue-200 p-3 rounded-md">
									Upload results
								</button> -->
                <RouteLink
                  :route="{ name: 'SendGroupingVacutainersLabResult', params: { vacutainers: this.vacutainersToComplete } }"
                  class="flex-auto transition rounded-md text-center py-2 px-2 bg-orange-500 flex items-center text-xs text-white font-mono hover:bg-orange-400 hover:text-orange-900 focus:outline-none transform hover:-translate-y-px hover:scale-110"
                  title="Click to submit results"
                  v-if="pagedResult.results[0].type === 'purple-top'"
                >
                  <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5l7 7-7 7M5 5l7 7-7 7"></path>
                  </svg>
                  <p class="px-2 text-lg font-medium capitalize">Send result(s) to Blood Bank</p>
                </RouteLink>
                <RouteLink
                  :route="{ name: 'SendScreeningVacutainersLabResult', params: { vacutainers: this.vacutainersToComplete } }"
                  class="flex-auto transition rounded-md text-center py-2 px-2 bg-orange-500 flex items-center text-xs text-white font-mono hover:bg-orange-400 hover:text-orange-900 focus:outline-none transform hover:-translate-y-px hover:scale-110"
                  title="Click to submit results"
                  v-if="pagedResult.results[0].type === 'red-top'"
                >
                  <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5l7 7-7 7M5 5l7 7-7 7"></path>
                  </svg>
                  <p class="px-2 text-lg font-medium capitalize">Send result(s) to Blood Bank</p>
                </RouteLink>
              </div>
            </span>
          </span>
        </div>
        <router-view> </router-view>
      </div>
    </div>
    <lock v-show="!oidcIsAuthenticated" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Lock from "@/components/layout/Lock";
export default {
  props: {
    title: String,
    url: String,
    showCreate: { type: Boolean, default: true },
    showBack: { type: Boolean, default: false },
    createName: { type: String, default: "Create" },
    createTitle: { type: String, default: "New" },
    showCustomAction: { type: Boolean, default: false },
    customActionParams: {
      type: Array,
      default() {
        return [];
      },
    },
    customActionTitle: { type: String, default: "Action" },
    customActionName: { type: String, default: "Action" },
    tableHeadingTitles: {
      Array,
      default: function() {
        return [];
      },
    },
  },
  components: { Lock },
  computed: {
    ...mapGetters(["oidcIsAuthenticated"]),
    hasList() {
      return this.pagedResult?.results?.length > 0;
    },
    selectAllForCentrifuging: {
      get: function() {
        return this.pagedResult.results ? this.selectedForCentrifuge.length == this.pagedResult.results.length : false;
      },
      set: function(value) {
        var selectedForCentrifuge = [];

        if (value) {
          this.pagedResult.results.forEach(function(result) {
            selectedForCentrifuge.push(result.entityGuid);
          });
        }

        this.selectedForCentrifuge = selectedForCentrifuge;
        for (let guid of selectedForCentrifuge) {
          this.vacutainersToCentrifuge.vacutainers.push({ entityGuid: guid });
        }
        var decider = document.getElementById("allForCentrifuging");
        if (!decider.checked) {
          this.vacutainersToCentrifuge.vacutainers = [];
        }
      },
    },
    selectAllForCompletion: {
      get: function() {
        return this.pagedResult.results ? this.selectedForComplete.length == this.pagedResult.results.length : false;
      },
      set: function(value) {
        var selectedForComplete = [];

        if (value) {
          this.pagedResult.results.forEach(function(result) {
            selectedForComplete.push(result.entityGuid);
          });
        }

        this.selectedForComplete = selectedForComplete;
        for (let guid of selectedForComplete) {
          this.vacutainersToComplete.vacutainers.push({ entityGuid: guid });
        }
        var decider = document.getElementById("allForCompletion");
        if (!decider.checked) {
          this.vacutainersToComplete.vacutainers = [];
        }
      },
    },
  },
  data() {
    return {
      loading: false,
      pagedResult: {},
      error: {},
      q: this.$route.query.q,
      toCentrifuge: true,
      vacutainersToCentrifuge: {
        vacutainers: [],
      },
      vacutainersToComplete: {
        vacutainers: [],
      },
      status: "",
      selectedForCentrifuge: [],
      selectedForComplete: [],
    };
  },
  watch: {
    $route: {
      immediate: true,
      async handler() {
        if (this.$route.matched.length === 1 || (this.$route.matched.length > 1 && Object.keys(this.pagedResult).length === 0 && this.pagedResult.constructor === Object)) {
          await this.fetch();
          this.vacutainersToCentrifuge.vacutainers = [];
          this.vacutainersToComplete.vacutainers = [];
        }
      },
    },
  },
  methods: {
    filter() {
      if (this.status !== "") {
        this.$router.push({ query: { status: this.status } });
      } else {
        this.$router.push({ query: {} });
      }
    },
    addToCentrifugeList(entity_guid, index) {
      let toCentrifugeId = `toCentrifuge${index}`;
      var decider = document.getElementById(toCentrifugeId);
      if (decider.checked) {
        this.vacutainersToCentrifuge.vacutainers.push({
          entityGuid: entity_guid,
        });
      } else {
        this.vacutainersToCentrifuge.vacutainers.pop({
          entityGuid: entity_guid,
        });
      }
      if (this.vacutainersToComplete.vacutainers.length > 0 && this.vacutainersToCentrifuge.vacutainers.length > 0) {
        this.vacutainersToComplete.vacutainers = [];
        this.vacutainersToCentrifuge.vacutainers = [];
      }
    },
    addToCompleteList(entity_guid, index) {
      let toCompleteId = `toComplete${index}`;
      var decider = document.getElementById(toCompleteId);
      if (decider.checked) {
        this.vacutainersToComplete.vacutainers.push({
          entityGuid: entity_guid,
        });
      } else {
        this.vacutainersToComplete.vacutainers.pop({ entityGuid: entity_guid });
      }
      if (this.vacutainersToComplete.vacutainers.length > 0 && this.vacutainersToCentrifuge.vacutainers.length > 0) {
        this.vacutainersToComplete.vacutainers = [];
        this.vacutainersToCentrifuge.vacutainers = [];
      }
    },
    async fetch() {
      this.loading = true;
      this.pagedResult = {};
      //const http = await this.$callApi.get();
      //console.log(http);
      this.$callApi
        .get(this.url, {
          params: { ...this.$route.query },
          //baseURL: "https://localhost:5001",
        })
        .then((x) => {
          this.pagedResult = x.data;
        })
        .catch(() => {
          //this.error = err;
          //console.log(err);
        })
        .then(() => (this.loading = false));
    },
    search() {
      if (this.q.length >= 3) {
        this.$router.push({ query: { q: this.q } });
      } else {
        if (this.$route.query.q !== undefined) {
          this.q = "";
          this.$router.push({ query: {} });
        }
      }
    },
    navigate(queryString) {
      this.$router.push({ path: this.$route.path, query: { ...queryString } });
    },
    isEmpty(obj) {
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    },
    back() {
      this.$router.back();
    },
  },
};
</script>
