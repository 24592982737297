export default [
  {
    path: "/donor-counselling/drives/:driveGuid/:listTitle",
    meta: { breadcrumb: { parent: "DonorCounsellingDrives" } },
    name: "DonorCounsellingQueueByDrive",
    component: () => import(/* webpackChunkName: "DonorCounselling" */ "@/views/donorCounselling/CounsellingQueueByDrive.vue"),
    props: (x) => ({
      driveGuid: x.params.driveGuid,
      listTitle: x.params.listTitle,
      backRouterName: "DonorCounsellingDrives",
    }),
  },
  {
    path: "/donor-counselling/donor-queue",
    name: "DonorCounsellingDonorQueue",
    component: () => import(/* webpackChunkName: "DonorCounselling" */ "@/views/donorCounselling/CounsellingClientQueue.vue"),
    props: () => ({}),
    children: [
      {
        path: "update/:id/:driveGuid",
        name: "UpdateDonorDriveCounselling",
        component: () => import(/* webpackChunkName: "store" */ "@/views/donorCounselling/components/SchemaModal.vue"),
        // component: () => import(/* webpackChunkName: "store" */ "@/components/data/SchemaModal.vue"),
        props: (y) => ({
          toFetch: false,
          title: "Update Drive",
          postUrl: `donation/CounsellingSections/${y.params.id}/reverse-encounter-to-correct-drive`,
          optionsUrls: ['donation/CounsellingSections/active-donation-drive-options'],
          jsonSchema: "donation/DonationForms/update-donor-drive",
          size: "max-w-xl",
          routerParams: true
        }),
      },

    ],
  },
  {
    path: "/donor-counselling/drives",
    name: "DonorCounsellingDrives",
    component: () => import(/* webpackChunkName: "DonorCounselling" */ "@/views/donorCounselling/DrivesList.vue"),
    props: () => ({}),
    children: [],
  },
  {
    path: "/donor-counselling/drives/:driveGuid/:listTitle/encounter/:encounterId",
    meta: { breadcrumb: { parent: "DonorCounsellingDrives" } },
    // name: "ViewPotentialDonor",
    component: () =>
      import(
        /* webpackChunkName: "DonorCounselling" */
        "@/views/donorCounselling/components/ViewPotentialDonor.vue"
      ),
    props: (x) => ({
      readOnly: false,
      driveGuid: x.params.driveGuid,
    }),
    children: [
      {
        path: "healthQuestionnaire",
        name: "CounsellingHealthQuestionnaire",
        component: () =>
          import(
            /* webpackChunkName: "DonorCounselling" */
            "@/views/donorCounselling/components/CounsellingSchemaForm.vue"
          ),
        props: (x) => ({
          postUrl: `donation/CounsellingSections/${x.params.encounterId}/health/create-or-update`,
          fetchUrl: `donation/CounsellingSections/${x.params.encounterId}/details`,
          toFetch: true,
          verb: "POST",
          title: "Health Questionnaire",
          jsonSchema: "donation/DonationForms/health-questionnaire",
          optionsUrls: ["donation/DonationLookups/yesno/options"],
          nextRouteName: "CounsellingRiskAssessmentForm",
          formName: "health",
          verifyResponses:true
        }),
  
      },

      {
        path: "riskAssessmentForm",
        name: "CounsellingRiskAssessmentForm",
        component: () =>
          import(
            /* webpackChunkName: "DonorCounselling" */
            "@/views/donorCounselling/components/CounsellingSchemaForm.vue"
          ),
        props: (x) => ({
          postUrl: `donation/CounsellingSections/${x.params.encounterId}/riskAssessment/create-or-update`,
          fetchUrl: `donation/CounsellingSections/${x.params.encounterId}/details`,
          verb: "POST",
          toFetch: true,
          title: "Risk Assessment Form",
          jsonSchema: "donation/DonationForms/risk-assessment-form",
          optionsUrls: ["donation/DonationLookups/yesno/options"],
          nextRouteName: "DonorCounsellingVitals",
          formName: "riskAssessment",
          verifyResponses:true

        }),
      },
      {
        path: "Vitals",
        name: "DonorCounsellingVitals",
        component: () =>
          import(
            /* webpackChunkName: "DonorCounselling" */
            "@/views/donorCounselling/components/CounsellingSchemaForm.vue"
          ),
        props: (x) => ({
          postUrl: `donation/CounsellingSections/${x.params.encounterId}/vitals/create-or-update`,
          fetchUrl: `donation/CounsellingSections/${x.params.encounterId}/details`,
          toFetch: true,
          verb: "POST",
          title: "Vitals Form ",
          jsonSchema: "donation/DonationForms/vitals-form",
          optionsUrls: ["donation/DonationLookups/yesno/options"],
          nextRouteName: "CounsellingDeclarationForm",
          formName: "vitals",
        }),
      },
      {
        path: "Declaration",
        name: "CounsellingDeclarationForm",
        component: () =>
          import(
            /* webpackChunkName: "DonorCounselling" */
            "@/views/donorCounselling/components/CounsellingSchemaForm.vue"
          ),
        props: (x) => ({
          postUrl: `donation/CounsellingSections/${x.params.encounterId}/declaration/create-or-update`,
          fetchUrl: `donation/CounsellingSections/${x.params.encounterId}/details`,
          toFetch: true,
          verb: "POST",
          title: "Declaration Form",
          jsonSchema: "donation/DonationForms/declaration-form",
          optionsUrls: ["donation/DonationLookups/donation-types/options", "donation/DonationLookups/yesno/options"],
          nextRouteName: "CounsellingElligibilityAssessment",
          formName: "declaration",
        }),
      },
      {
        path: "elligibility",
        name: "CounsellingElligibilityAssessment",
        component: () =>
          import(
            /* webpackChunkName: "DonorCounselling" */
            "@/views/donorCounselling/ElligibilityAssessment.vue"
          ),
        props: (x) => ({
          postUrl: `donation/CounsellingSections/${x.params.encounterId}/eligibility`,
          fetchUrl: `donation/CounsellingSections/${x.params.encounterId}/details`,
          formNames: ["health", "riskAssessment", "vitals", "declaration"],
        }),
      },
      {
        path: "confirm",
        name: "CounsellingSendToDonation",
        component: () =>
          import(
            /* webpackChunkName: "Transfusion" */
            "@/components/data/ConfirmPostModal.vue"
          ),
        props: (x) => ({
          title: "Send to Donation",
          postUrl: `donation/CounsellingSections/${x.params.encounterId}/complete-counselling-and-link-donation-encounter-to-donation-drive`,
          size: "max-w-sm",
          backRouterName: "DonorCounsellingQueueByDrive",
          toFetch: false,
        }),
      },

      {
        path: "defer-donor",
        name: "CounsellingDeferDonor",
        component: () =>
          import(
            /* webpackChunkName: "Transfusion" */
            "@/views/donorCounselling/DeferDonor.vue"
          ),
        props: (x) => ({
          toFetch: false,
          title: "Defer Donor",
          postUrl: `donation/CounsellingSections/${x.params.encounterId}/defer`,
          size: "w-1/3",
          backRouterName: "DonorCounsellingQueueByDrive",
        }),
      },
    ],
  },
  {
    path: "/DonorCounselling/donor-counselling-clients",
    name: "CounsellingClients",
    component: () => import(/* webpackChunkName: "DonorCounselling" */ "@/views/donorCounselling/CounsellingClientsList.vue"),
  },
  {
    path: "/donor-counselling-clients/:clientNumber/:clientName",
    meta: { breadcrumb: { parent: "CounsellingClients" } },
    name: "CounsellingClientsEncounters",
    component: () => import(/* webpackChunkName: "DonorCounselling" */ "@/views/donorCounselling/CounsellingClientsEncountersList.vue"),
    props: (x) => ({
      clientNumber: x.params.clientNumber,
      clientName: x.params.clientName,
      backRouterName: "CounsellingClients",
    }),
    children: [
      {
        path: "encounter/:encounterId/read",
        // name: "ViewPotentialDonor",
        component: () =>
          import(
            /* webpackChunkName: "DonorCounselling" */
            "@/views/donorCounselling/components/ResponsesOnModal.vue"
          ),
        props: (x) => ({
          readOnly: true,
          size: "w-2/3",
          title: "View Responses",
          backRouterName: "CounsellingClientsEncounters",
          backRouterParams: { clientNumber: x.params.clientNumber, clientName: x.params.clientName },
        }),
        children: [
          {
            path: "healthQuestionnaire",
            name: "CounsellingHealthQuestionnaireReadOnly",
            component: () =>
              import(
                /* webpackChunkName: "DonorCounselling" */
                "@/views/donorCounselling/components/CounsellingSchemaForm.vue"
              ),
            props: (x) => ({
              fetchUrl: `donation/CounsellingSections/${x.params.encounterId}/details`,
              toFetch: true,
              verb: "POST",
              title: "Health Questionnaire",
              jsonSchema: "donation/DonationForms/health-questionnaire",
              optionsUrls: ["donation/DonationLookups/yesno/options"],
              formName: "health",
              nextRouteName: "CounsellingRiskAssessmentFormReadOnly",
            }),
          },

          {
            path: "riskAssessmentForm",
            name: "CounsellingRiskAssessmentFormReadOnly",
            component: () =>
              import(
                /* webpackChunkName: "DonorCounselling" */
                "@/views/donorCounselling/components/CounsellingSchemaForm.vue"
              ),
            props: (x) => ({
              fetchUrl: `donation/CounsellingSections/${x.params.encounterId}/details`,
              verb: "POST",
              toFetch: true,
              title: "Risk Assessment Form",
              jsonSchema: "donation/DonationForms/risk-assessment-form",
              optionsUrls: ["donation/DonationLookups/yesno/options"],
              formName: "riskAssessment",
              nextRouteName: "DonorCounsellingVitalsReadOnly",
            }),
          },
          {
            path: "Vitals",
            name: "DonorCounsellingVitalsReadOnly",
            component: () =>
              import(
                /* webpackChunkName: "DonorCounselling" */
                "@/views/donorCounselling/components/CounsellingSchemaForm.vue"
              ),
            props: (x) => ({
              fetchUrl: `donation/CounsellingSections/${x.params.encounterId}/details`,
              toFetch: true,
              verb: "POST",
              title: "Vitals Form ",
              jsonSchema: "donation/DonationForms/vitals-form",
              optionsUrls: ["donation/DonationLookups/yesno/options"],
              formName: "vitals",
              nextRouteName: "CounsellingDeclarationFormReadOnly",
            }),
          },
          {
            path: "Declaration",
            name: "CounsellingDeclarationFormReadOnly",
            component: () =>
              import(
                /* webpackChunkName: "DonorCounselling" */
                "@/views/donorCounselling/components/CounsellingSchemaForm.vue"
              ),
            props: (x) => ({
              postUrl: `donation/CounsellingSections/${x.params.encounterId}/declaration/create-or-update`,
              fetchUrl: `donation/CounsellingSections/${x.params.encounterId}/details`,
              toFetch: true,
              verb: "POST",
              title: "Declaration Form",
              jsonSchema: "donation/DonationForms/declaration-form",
              optionsUrls: ["donation/DonationLookups/donation-types/options", "donation/DonationLookups/yesno/options"],
              nextRouteName: "CounsellingElligibilityAssessmentReadOnly",
              formName: "declaration",
            }),
          },
          {
            path: "elligibility",
            name: "CounsellingElligibilityAssessmentReadOnly",
            component: () =>
              import(
                /* webpackChunkName: "DonorCounselling" */
                "@/views/donorCounselling/ElligibilityAssessment.vue"
              ),
            props: (x) => ({
              postUrl: `donation/CounsellingSections/${x.params.encounterId}/eligibility`,
              fetchUrl: `donation/CounsellingSections/${x.params.encounterId}/details`,
              formNames: ["health", "riskAssessment", "vitals", "declaration"],
            }),
          },
        ],
      },
    ],
  },
  {
    path: "/donor-counselling-forms",
    name: "CounsellingForms",
    component: () =>
      import(
        /* webpackChunkName: "DonorCounselling" */
        "@/views/donorCounselling/FormList.vue"
      ),
    children: [
      {
        path: "create",
        name: "AddCounsellingForm",
        component: () =>
          import(
            /* webpackChunkName: "DonorCounselling" */
            "@/components/data/JsonForm.vue"
          ),
        props: () => ({
          toFetch: false,
          title: "Add Form ",
          postUrl: "donation/DonationForms",
        }),
      },
      {
        path: "edit/:id",
        title: "Update Form",
        name: "UpdateCounsellingForm",
        component: () =>
          import(
            /* webpackChunkName: "DonorCounselling" */
            "@/components/data/JsonForm.vue"
          ),
        props: (x) => ({
          title: "Update Form ",
          postUrl: `donation/DonationForms/${x.params.id}`,
        }),
      },
      {
        path: "delete/:id",
        title: "Delete Form",
        name: "DeleteCounsellingForm",
        component: () =>
          import(
            /* webpackChunkName: "DonorCounselling" */
            "@/components/data/DeleteModal.vue"
          ),
        props: (x) => ({
          title: "Delete Form ",
          deleteUrl: `donation/DonationForms/${x.params.id}`,
          size: "max-w-xs",
          name: "Delete Form",
        }),
      },
    ],
  },
  {
    path: "/donor-counselling/options",
    name: "CounsellingOptionGroups",
    props: (x) => ({
      categoryId: x.params.categoryId,
      genericStatus: x.params.genericStatus,
    }),
    component: () => import(/* webpackChunkName: "store" */ "@/views/donorCounselling/OptionGroupList.vue"),
    children: [
      {
        path: "add",
        name: "addCounsellingOptionGroup",
        component: () => import(/* webpackChunkName: "store" */ "@/components/data/SchemaModal.vue"),
        props: () => ({
          toFetch: false,
          title: "Add Option Group",
          postUrl: `donation/DonationLookups/0`,
          optionsUrls: [],
          jsonSchema: "donation/DonationForms/add-option-group",
          size: "max-w-xl",
        }),
      },
      {
        path: "update/:id",
        name: "updateCounsellingOptionGroup",
        component: () => import(/* webpackChunkName: "store" */ "@/components/data/SchemaModal.vue"),
        props: (y) => ({
          toFetch: true,
          title: "Update Option Group",
          postUrl: `donation/DonationLookups/${y.params.id}`,
          optionsUrls: [],
          jsonSchema: "donation/DonationForms/add-option-group",
          size: "max-w-md",
        }),
      },
    ],
  },
  {
    path: "/donor-counselling/optionGroup/:optionGroupId/options",
    meta: { breadcrumb: { parent: "CounsellingOptionGroups" } },
    name: "CounsellingOptions",
    props: (x) => ({
      optionGroupId: x.params.optionGroupId,
    }),
    component: () => import(/* webpackChunkName: "store" */ "@/views/donorCounselling/OptionsList.vue"),
    children: [
      {
        path: "add",
        name: "addCounsellingOption",
        component: () => import(/* webpackChunkName: "store" */ "@/components/data/SchemaModal.vue"),
        props: (y) => ({
          toFetch: false,
          title: "Add Option",
          postUrl: `donation/DonationLookups/${y.params.optionGroupId}`,
          optionsUrls: [],
          jsonSchema: "donation/DonationForms/add-option",
          size: "max-w-xl",
        }),
      },
      {
        path: "update/:id",
        name: "updateCounsellingOption",
        component: () => import(/* webpackChunkName: "store" */ "@/components/data/SchemaModal.vue"),
        props: (y) => ({
          toFetch: true,
          title: "Update Option",
          postUrl: `donation/DonationLookups/${y.params.id}`,
          optionsUrls: [],
          jsonSchema: "donation/DonationForms/add-option",
          size: "max-w-md",
        }),
      },
    ],
  },
];
