<template>
  <label :for="context.id">
    <span v-if="context.classification !== 'box' && context.classification !== 'group'">
      <span :class="[stateClass, 'font-medium inline-block tracking-wider text-xs  uppercase py-2']">{{ context.label }}</span>
      <span v-if="optional" class="text-gray-500/90 text-xs italic">&ndash;optional</span>
      <svg v-if="tooltip" v-tooltip="tooltip" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
        <path d="M50,11A39,39,0,1,0,89,50,39.05,39.05,0,0,0,50,11Zm4,55a4,4,0,0,1-8,0V47a4,4,0,0,1,8,0ZM50,38a4,4,0,1,1,4-4A4,4,0,0,1,50,38Z" />
      </svg>
    </span>
    <span v-else-if="context.classification === 'group'" class="font-medium text-gray-700/90 inline-block pt-2 pb-1">{{ context.label }}</span>
    <span v-else-if="context.classification === 'box'" class="font-normal text-gray-700 block">{{ context.label }}</span>
    <span v-else class="font-medium text-gray-700 inline-block pt-2 pb-1">{{ context.label }}</span>
  </label>
</template>

<script>
export default {
  name: "optional-label",
  props: {
    context: {
      type: Object,
      required: true,
    },
    tooltip: {
      type: [String, Boolean],
      default: false,
    },
    showOptional: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    optional() {
      return this.showOptional && (this.context.rules.length === 0 || this.context.rules.every((x) => x.ruleName !== "required")) && this.context.classification !== "box";
    },
    stateClass() {
      if (this.context.classification !== "box" && this.context.classification !== "group") {
        if (!this.context.isValid && this.context.showValidationErrors) {
          return "text-red-500/90";
        }
        if (this.context.rules.length > 0 && this.context.isValid && this.context.hasValue) {
          return "text-green-500/90";
        }
        return "text-gray-500";
      } else {
        return "";
      }
    },
  },
};
</script>
